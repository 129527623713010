import React, { useEffect, useState } from "react";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import useStyles from "./styles";
import { Grid, Typography, Button } from "@mui/material";
import DocumentDrawer from "./components/documnetdrawer";
import DocumentAnalysisService from "./services";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Docular from "./components/docular";
import sessionService from "_services/session.service";

const DocumentAnalysis = () => {
  const customerID = sessionService.getCustomerId();
  const classes = useStyles();
  const [isDrawerView, setIsDrawerView] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [comparisonMetaData, setComparisonMetaData] = useState(null);
  // const [comparisonMetaData, setComparisonMetaData] = useState(null);
  const [policyMetaData, setPolicyMetaData] = useState(null);
  const [policyMetaDataForDrawer, setpolicyMetaDataForDrawer] = useState(null);

  const [num, setNum] = useState(10);

  const params = useParams();
  useEffect(() => {
    dispatch(
      DocumentAnalysisService.getGapAnalysis(
        customerID,
        params.id,
        setComparisonMetaData,
        setPolicyMetaData,
        setpolicyMetaDataForDrawer
      )
    );
  }, []);
  let cmpID = null;
  if (policyMetaData) {
    cmpID = policyMetaData?.BASE_FILE[0]?.POLICYCOMPARISONID;
  }

  return (
    <Grid>
      {policyMetaData &&
        policyMetaData.BASE_FILE.length > 0 &&
        policyMetaData.TARGET_FILE.length > 0 && (
          <Typography sx={{ mb: 1 }}>
            <span>Document Recommendations for:</span>{" "}
            <Typography component="span" fontWeight="bold">
              {policyMetaData.BASE_FILE[0].EXTRACTION_FILE_NAME}
            </Typography>{" "}
            and{" "}
            <Typography component="span" fontWeight="bold">
              {policyMetaData.TARGET_FILE[0].EXTRACTION_FILE_NAME}
            </Typography>
            <br />
            <span>Gap Recommendations generated on:</span>{" "}
            <Typography component="span" fontWeight="bold">
              {policyMetaData.TARGET_FILE[0].QUEUE_END_TIME.split("T")[0]}
            </Typography>
          </Typography>
        )}
      {policyMetaData && (
        <Grid className={classes.headContent}>
          <Button
            className={classes.docScannerBtn}
            onClick={() => setIsDrawerView(!isDrawerView)}
          >
            <DocumentScannerIcon sx={{ color: "white" }} />
          </Button>
        </Grid>
      )}

      <Docular
        metaData={comparisonMetaData}
        num={num}
        cmpID={cmpID}
        setComparisonMetaData={setpolicyMetaDataForDrawer}
        policyMetaDataForDrawer={policyMetaDataForDrawer}
      />
      <DocumentDrawer
        show={isDrawerView}
        setShow={setIsDrawerView}
        metaData={policyMetaDataForDrawer}
        setNum={setNum}
        policyMetaData={policyMetaData}
        num={num}
      />
    </Grid>
  );
};

export default DocumentAnalysis;
