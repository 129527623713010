import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import React from "react";

const getRoles = (customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRoles", {
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};
const GetCustomerList = (setCustomerList) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .get("/v1/api/proc/DRegulativDB/GetCustomerList")
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setCustomerList(
            response["#result-set-1"].map((item) => {
              return {
                value: item.CUSTOMER_ID,
                label: item.CUSTOMER_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putRole = (customerID, roleName, roleList, setRoleList) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRole", {
        i_CUSTOMER_ID: customerID,
        i_ROLE_NAME: roleName,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response &&
            response["#result-set-1"] &&
            response["#result-set-1"][0]["LAST_ROLE_ID"]
          ) {
            const newRoleID = response["#result-set-1"][0]["LAST_ROLE_ID"];
            dispatch(alertActions.success("New role added successfully."));
            const tempList = [...roleList];
            const tempData = { ROLE_ID: newRoleID, ROLE_NAME: roleName };
            tempList.push(tempData);
            setRoleList(tempList);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateRole = (
  roleID,
  customerID,
  roleName,
  isActive,
  roleList,
  setRoleList
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateRole", {
        i_ROLE_ID: roleID,
        i_CUSTOMER_ID: customerID,
        i_ROLE_NAME: roleName,
        i_ACTIVE: isActive,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response &&
            response["#result-set-1"] &&
            response["#result-set-1"][0]["#update-count-1"]
          ) {
            if (isActive == 1) {
              dispatch(alertActions.success("Role updated successfully."));
              const updatedRoleList = roleList.map((role) =>
                role.ROLE_ID === roleID
                  ? { ...role, ROLE_NAME: roleName }
                  : role
              );
              setRoleList(updatedRoleList);
            } else {
              dispatch(alertActions.success("Role deleted successfully."));
              const updatedRoleList = roleList.filter(
                (role) => role.ROLE_ID !== roleID
              );
              setRoleList(updatedRoleList);
            }
          }
        },
        (error) => {
          if (error.response) {
            return error.response;
          }
        }
      );
  };
};

export default {
  getRoles,
  putRole,
  updateRole,
  GetCustomerList,
};
