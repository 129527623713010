import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Chip, Divider, Tooltip } from "@mui/material";
import { getFileExtension } from "./EditableFilenameRow";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { Item, Row, innerElementType } from "./DraggableRow";
import { useWindowResize } from "./userWindowResize";
import { Droppable } from "react-beautiful-dnd";
import { Button } from "@material-ui/core";
import OptionComponent from "containers/manage-regulation/Policy/PolicyComparison/ComparisonInsights/component/OptionComponent";
import DeleteIcon from "@material-ui/icons/Delete";
import services from "./services";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { alertActions } from "_actions";
import { ROW } from "containers/ComplianceCenter/components/constants";
function FileTableRow(props) {
  const {
    row,
    setFilteredFiles,
    provided,
    snapshot,
    regulationID,
    handleDeleteCustomerRegulationPolicy,
  } = props;
  const customerID = sessionService.getCustomerId();
  const userID = sessionService.getUserId();
  const dispatch = useDispatch();
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );
  const preProcessingIP = useSelector(
    (state) => state.authentication.detailedInfo.PRE_PROCESSING_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );

  const listRef = React.useRef();
  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap?.current, [index]: size };
    listRef.current?.resetAfterIndex?.(index);
  }, []);
  const getSize = (index) => sizeMap?.current?.[index] || 50;
  const [windowWidth] = useWindowResize();

  const [open, setOpen] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const ExtensionBadge = getFileExtension(row?.name) && (
    <Chip
      sx={{
        textTransform: "uppercase",
        color: "#d32f2f",
        fontWeight: "600",
        px: 1,
      }}
      label={getFileExtension(row?.name)}
      size="small"
      component="span"
    />
  );
  const comparision = async (baseFile, targetFile) => {
    let getCmpIdData = {
      i_BASE_POLICY_ID: baseFile.POLICY_ID,
      i_TARGET_POLICY_ID: targetFile.POLICY_ID,
      i_COMPARISON_NAME: ` ${baseFile.EXTRACTION_FILE_NAME} vs ${targetFile.TEMPLATE_FILENAME}`,
      i_COMPARISON_META_DATA: null,
      i_COMPARISON_FILE_NAME: baseFile.EXTRACTION_FILE_NAME,
      i_STORAGE_FOLDER_URL: null,
      i_START_DATE: moment(),
      i_END_DATE: null,
      i_USER_ID: userID,
    };
    const cmpId = await services.putPolicyComparisonV2(getCmpIdData);
    let comparisionData = {
      comparison_id: cmpId["#result-set-1"][0].new_id,
      customer_id: customerID,
      user_id: userID,
      regulation_id: regulationID,
      base_document_id: baseFile.POLICY_ID,
      target_document_id: targetFile.POLICY_ID,
      action_taken: 1,
      flag: 1,
      base_file: baseFile.EXTRACTION_FILE_NAME,
      target_file: targetFile.TEMPLATE_FILENAME,
      input_subURL_base: baseFile.STORAGE_FOLDER_URL,
      input_subURL_target: targetFile.TEMPLATE_FOLDER,
      input_global_URL: "",
      storageType: docStorageType,
      preProcessingIP: preProcessingIP,
      preProcessingURL: docStorageURL,
    };
    let response = await services.assessmentComparison(comparisionData);
    if (!response.status == "success") {
      dispatch(alertActions.error("Something went wrong in Comparison"));
    }
  };
  const handleCheckboxChange = (itemId) => {
    if (selected === itemId) {
      setHide(false);
      setSelected(null);
    } else {
      setHide(true);
      setSelected(itemId);
    }
  };
  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{ background: snapshot.isDraggingOver ? "lightblue" : "white" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.id}
        </TableCell>
        <TableCell>
          {row?.document_name
            ? row?.document_name
            : row?.POLICY_NAME
            ? row?.POLICY_NAME
            : row[0]?.name}{" "}
          {ExtensionBadge}
        </TableCell>
        <TableCell>0</TableCell>
        <TableCell>{row?.children ? row.children.length : 0}</TableCell>
        <TableCell>
          {row.children
            ? row.children.filter(
                (item) => item.COMPARISONSTATUS === "COMPLETED"
              ).length
            : 0}
        </TableCell>
        <TableCell>
          <Tooltip title="Delete Policy">
            <IconButton
              type="button"
              className="secondary"
              onClick={() => handleDeleteCustomerRegulationPolicy(row)}
            >
              <DeleteIcon style={{ color: "#F0142F" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Box mt={1} py={1} display="flex" gap={1}>
                <Typography
                  flex="1"
                  variant="caption"
                  color="GrayText"
                  fontSize=".75rem"
                >
                  Original Filename -{" "}
                  <strong>{row?.name ? row?.name : row?.POLICY_NAME}</strong>
                </Typography>
                <Divider flexItem orientation="vertical" />
                <Typography
                  flex="1"
                  variant="caption"
                  color="GrayText"
                  fontSize=".75rem"
                >
                  User -{" "}
                  <strong>
                    {row?.FIRST_NAME} {row.LAST_NAME}
                  </strong>
                </Typography>
              </Box>
              <Box gap={1} display="flex" py={1} justifyContent="flex-end">
                {row?.children?.length > 0 && hide && (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    style={{
                      width: "100px",
                    }}
                    onClick={() => {
                      comparision(
                        row,
                        row?.children
                          ?.filter((ele) => ele.uuid2 === selected)
                          .pop()
                      );
                    }}
                  >
                    Compare
                  </Button>
                )}
              </Box>
              {row?.children?.length > 0 && (
                <Box sx={{ width: "100%", height: "100px" }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <VariableSizeList
                        ref={listRef}
                        height={height}
                        itemCount={row?.children?.length}
                        itemSize={getSize}
                        width={width}
                        innerElementType={innerElementType}
                        itemData={{
                          items: row?.children,
                          setFilteredFiles: setFilteredFiles,
                          setSize: setSize,
                          windowWidth: windowWidth,
                          file: row,
                          regulationID: regulationID,
                          handleCheckboxChange: handleCheckboxChange,
                          selected: selected,
                        }}
                      >
                        {Row}
                      </VariableSizeList>
                    )}
                  </AutoSizer>
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

FileTableRow.propTypes = {
  row: PropTypes.object,
  setFilteredFiles: PropTypes.func,
  provided: PropTypes.any,
  snapshot: PropTypes.any,
  regulationID: PropTypes.any,
  handleDeleteCustomerRegulationPolicy: PropTypes.func,
};

const FileTable = ({
  files,
  setFilteredFiles,
  requiredFiles,
  regulationID,
  handleDeleteCustomerRegulationPolicy,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ fontWeight: "bold" }}>No.</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Document Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Size</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Docs Mapped</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Recommendations</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((row, index) => (
            <Droppable
              key={row?.uuid}
              droppableId={row.uuid}
              renderClone={(provided, snapshot, rubric) => {
                return (
                  <Item
                    provided={provided}
                    isDragging={snapshot.isDragging}
                    item={requiredFiles?.find(
                      (item) => item?.uuid === rubric.draggableId
                    )}
                    regulationID={regulationID}
                  />
                );
              }}
            >
              {(provided, snapshot) => (
                <FileTableRow
                  key={row.uuid}
                  row={Object.assign(row, { id: `${index + 1}.` })}
                  setFilteredFiles={setFilteredFiles}
                  provided={provided}
                  snapshot={snapshot}
                  regulationID={regulationID}
                  handleDeleteCustomerRegulationPolicy={
                    handleDeleteCustomerRegulationPolicy
                  }
                />
              )}
            </Droppable>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

FileTable.propTypes = {
  files: PropTypes.array,
  setFilteredFiles: PropTypes.any,
  requiredFiles: PropTypes.array,
  regulationID: PropTypes.number,
  handleDeleteCustomerRegulationPolicy: PropTypes.func,
};

export default FileTable;
