import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import propTypes from "prop-types";
import CommentPanel from "./CommentPanel";

import { useSelector } from "react-redux";

const tabs = [
  {
    value: "comments",
    label: "Comments",
  },
];

export const RightPanelTabs = ({
  commentsList,
  selectedQuestion,
  page,
  submissionID,
  // getActions,
}) => {
  const actions = useSelector((state) => state?.MasterAssessment?.ActionsList);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const onTabChange = (event, value) => {
    // setCurrentTab(value);
    // if (value === tabs[1].value && !actions?.length) {
    //   getActions?.();
    // }
  };

  return (
    <TabContext value={currentTab}>
      <Box>
        <TabList variant="fullWidth" onChange={onTabChange}>
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={tab?.icon}
            />
          ))}
        </TabList>
      </Box>
      <Box py={1}>
        <TabPanel value={tabs[0].value}>
          <Box style={{ height: "calc(100vh - 440px)" }}>
            <CommentPanel
              commentsList={commentsList}
              submissionID={submissionID}
              questionID={selectedQuestion?.QUESTION_ID}
              page={page}
            />
          </Box>
        </TabPanel>
      </Box>
    </TabContext>
  );
};

RightPanelTabs.propTypes = {
  commentsList: propTypes.array,
  selectedQuestion: propTypes.any,
  page: propTypes.number,
  submissionID: propTypes.number,
  // getActions: propTypes.func,
};
