import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";

import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import langConstant from "_lang";
import SaveIcon from "@mui/icons-material/Save";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import RoleServices from "../services";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  width100: {
    width: "100%",
  },

  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },
  filedTitle: {
    color: "#a9a8a8",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  AddressGrid: {
    marginBottom: "20px",
  },
}));

const AddRole = ({ addRoleModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roleID = addRoleModal
    ? addRoleModal.data.roleID
      ? addRoleModal.data.roleID
      : null
    : null;
  const roleName = addRoleModal
    ? addRoleModal.data.roleName
      ? addRoleModal.data.roleName
      : ""
    : "";
  const customerID = sessionService.getCustomerId();
  const roleList = addRoleModal ? addRoleModal.data.roleList : "";
  const setRoleList = addRoleModal ? addRoleModal.data.setRoleList : "";

  const initialTeamData = {
    ROLE_NAME: addRoleModal
      ? addRoleModal.data.roleName
        ? addRoleModal.data.roleName
        : ""
      : "",
    ROLE_ID: addRoleModal
      ? addRoleModal.data.roleID
        ? addRoleModal.data.roleID
        : ""
      : "",
  };

  const [isEditMode, setIsEditMode] = useState(true);

  const validationSchema = Yup.object().shape({
    ROLE_NAME: Yup.string().required("Role Name is required"),
  });

  const saveRole = (values) => {
    const roleName = values.ROLE_NAME;
    if (roleID) {
      dispatch(
        RoleServices.updateRole(
          roleID,
          customerID,
          roleName,
          1,
          roleList,
          setRoleList
        )
      );
    } else {
      dispatch(
        RoleServices.putRole(customerID, roleName, roleList, setRoleList)
      );
    }
    setIsEditMode(false);
    addRoleModal?.closeModal();
  };

  const handleClose = () => {
    setIsEditMode(false);
    addRoleModal?.closeModal();
  };

  return (
    <Dialog
      open={addRoleModal?.isOpen}
      maxWidth="sm"
      fullWidth
      keepMounted
      onClose={addRoleModal?.closeModal}
    >
      <div className={classes.padding}>
        <DialogTitle disableTypography className={classes.title}>
          <Typography style={{ flex: 1 }} variant="h6" align="center">
            {langConstant.ADD_NEW_ROLE}
          </Typography>
          <IconButton
            style={{ position: "absolute", right: 4 }}
            aria-label="close"
            onClick={addRoleModal?.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={initialTeamData}
            validationSchema={validationSchema}
            onSubmit={saveRole}
          >
            {({ values, errors, setFieldValue, resetForm }) => (
              <Form>
                <Grid container className={classes.AddressGrid}>
                  <Grid item xs={1}>
                    <GroupAddIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography className={classes.filedTitle}>
                      {langConstant.ROLE_NAME}
                    </Typography>
                    <Field name="ROLE_NAME">
                      {({ field: { value } }) => (
                        <TextField
                          value={value}
                          disabled={!isEditMode}
                          className={classes.width100}
                          onChange={(e) =>
                            setFieldValue("ROLE_NAME", e.target.value)
                          }
                          variant="outlined"
                          InputProps={{
                            classes: {
                              input: classes.inputProfileField,
                              notchedOutline: "",
                            },
                          }}
                        />
                      )}
                    </Field>

                    <ErrorMessage name="ROLE_NAME">
                      {(msg) => (
                        <Typography style={{ color: "red" }}>{msg}</Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <Grid
                  display="flex"
                  justify="flex-end"
                  style={{
                    marginTop: "10px",
                    textAlign: "right",
                    display: "flex",
                  }}
                >
                  <Grid style={{ marginRight: "5px" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      type="button"
                      size="small"
                      onClick={handleClose}
                    >
                      {" "}
                      {langConstant.CANCEL}{" "}
                    </Button>
                  </Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon />}
                  >
                    {" "}
                    {langConstant.SAVE}{" "}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
};

AddRole.propTypes = {
  addRoleModal: PropTypes.object,
};

export default AddRole;
