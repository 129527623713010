import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Select from "react-select";
import PropTypes from "prop-types";
import { Formik, Field, ErrorMessage, Form } from "formik";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import langConstant from "_lang";
import PolicyServices from "../../NewPolicyUpload/services";
import { alertActions } from "_actions";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { IconButton, makeStyles } from "@material-ui/core";
import { getThemeDetails } from "containers/LoginPage/reducers";
import {
  intialValuesPolicyUploadForm,
  validationSchemaPolicyUploadForm,
} from "../utils";
import regulationAction from "../../../../../NewRegulation/actions";
import getRegulationForDocSerivice from "../../../../../Regulators/services";
import { Add, Remove } from "@mui/icons-material";
import uploadDocumentService from "../../../../../NewRegulation/ManageQuestions/component/UploadDocuments/services";

const useStyles = makeStyles(() => ({
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
    backgroundColor: ({ actionButtonColor }) =>
      actionButtonColor + " !important",
  },
  iconButton: {
    backgroundColor: ({ actionButtonColor }) =>
      actionButtonColor + " !important",
    display: "inline-flex !important",
    padding: "10px !important",
    cursor: "pointer !important",
    color: "#ffffff !important",
  },
  disabledButton: {
    backgroundColor: "grey !important",
  },
  actionButton: {
    color: "#fff",
    fontSize: "18px !important",
    margin: "0 auto",
  },
}));

const PolicyUploadForm = ({ open, onClose }) => {
  const [languages, setLanguages] = useState([]);
  const [owners, setOwners] = useState([]);
  const [types, setTypes] = useState([]);
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const themeDetails = useSelector(getThemeDetails);
  const history = useHistory();
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const [regulationOptions, setRegulationOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [addedRows, setAddedRows] = useState([
    { regulation: null, document_for_regulation: [] },
  ]);

  const handleSubmit = async (values) => {
    const putPolicyData = {
      document_name: values.document_name,
      policy_metadata: [],
      fileName: "",
      document_extension: "php",
      customerID: customerID,
      document_owner: values.document_info,
      document_tags: values.document_tags.trim(),
      document_type: values.document_type,
      userID: userID,
      currentDateTime: moment().toISOString(),
      document_language: values.document_language,
      i_END_DATE: "",
    };
    const putSP = await PolicyServices.putPolicy(putPolicyData);
    if (putSP.status === 200) {
      let id = putSP["#result-set-1"][0]["new_id"];
      try {
        for (const item of values.rows) {
          if (item.regulation) {
            for (const documentForRegulation of item.document_for_regulation) {
              const response =
                await uploadDocumentService.putCustomerRegulationPolicyMapping(
                  customerID,
                  id,
                  item.regulation.value,
                  userID,
                  documentForRegulation.value,
                  null
                );

              if (response.status !== 200) {
                dispatch(alertActions.error("Something went wrong!"));
              }
            }
          }
        }
      } catch (error) {
        dispatch(
          alertActions.error("An error occurred while uploading documents.")
        );
        console.error(error);
      }
      history.push(`policy-explorer/policy-generasion/${id}`);
    } else {
      dispatch(alertActions.error(langConstant.POLICY_INSERTION_FAILED));
    }
  };

  const handleAddRow = (rows, index, setFieldValue) => {
    const newRow = { regulation: null, document_for_regulation: [] };
    const updatedRows = [...rows];
    updatedRows.splice(index + 1, 0, newRow);

    setAddedRows(updatedRows);
    setFieldValue("rows", updatedRows);
  };

  const getAvailableRegulations = (selectedRegulations) => {
    return regulationOptions.filter(
      (regulation) =>
        !selectedRegulations.some(
          (selected) => selected.value === regulation.value
        )
    );
  };
  // Handle Remove a selected row
  const handleRemoveRow = (index, setFieldValue) => {
    const updatedRows = [...addedRows];
    updatedRows.splice(index, 1);
    setAddedRows(updatedRows);
    setFieldValue("rows", updatedRows); // Update Formik values
  };
  const regulationList = useSelector((state) => state.regulationList);
  useEffect(() => {
    (async () => {
      const response = await PolicyServices.getDocumentControlInformation(
        userID
      );
      if (response.status === 200) {
        setOwners(
          response["#result-set-1"].map((item) => {
            return { value: item.ORG_UNIT_ID, label: item.ORG_UNIT_OWNER };
          })
        );
        setTypes(
          response["#result-set-2"].map((item) => {
            return { value: item.ID, label: item.DOCUMENT_CLASSIFICATION_NAME };
          })
        );
        setLanguages(
          response["#result-set-3"].map((item) => {
            return { value: item.LANGUAGE_CODE, label: item.NATIONAL_LANGUAGE };
          })
        );
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
      dispatch(regulationAction.getRegulationList(userID, customerID));
    })();
  }, []);

  useEffect(() => {
    if (regulationList.length > 0) {
      setRegulationOptions(
        regulationList.map((item) => {
          return {
            value: item.REGULATION_ID,
            label: item.REGULATION_SHORT_NAME,
          };
        })
      );
    }
  }, [regulationList]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          Policy Upload Form
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={intialValuesPolicyUploadForm}
          validationSchema={validationSchemaPolicyUploadForm}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
        >
          {({ setFieldValue, values, errors, touched }) => {
            {
              /* const fetchDocuments = async () => {
                let newDocuments = [];
                for (let regulation of values.regulations) {
                  {
                    const data =
                      await getRegulationForDocSerivice.getDocumentsForRegulation(
                        regulation.value,
                        customerID
                      );
                    data["#result-set-2"].forEach((ele) => {
                      console.log({
                        REGULATION_ID: ele.REGULATION_ID,
                        label: ele.GOLD_STANDARD_DOC_NAME,
                        value: ele.POLICY_ID,
                      });
                    });
                  }
                }
                setDocumentOptions(newDocuments);
              };
              fetchDocuments();
             */
            }
            useEffect(() => {
              const fetchDocuments = async () => {
                const newDocuments = [];

                // Iterate over rows, but only fetch documents if a row is not processed
                for (
                  let rowIndex = 0;
                  rowIndex < values.rows.length;
                  rowIndex++
                ) {
                  const row = values.rows[rowIndex];
                  const regulation = row.regulation;

                  // Proceed only if regulation is selected and the row hasn't been processed
                  if (regulation && regulation.value && !row.processed) {
                    const documents =
                      await getRegulationForDocSerivice.getDocumentsForRegulation(
                        regulation.value,
                        customerID
                      );

                    newDocuments[rowIndex] = documents; // Store fetched documents for each row
                    {
                      /* setDocumentOptions(documents["#result-set-2"]); */
                    }

                    const opt = documents["#result-set-1"].map((ele) => {
                      return {
                        label: ele.DOCUMENT_RECOMMENDATION,
                        value: ele.REGULATIONDOCREQ_UNID,
                      };
                    });
                    setDocumentOptions(opt);

                    // Mark this row as processed
                    const updatedRows = [...values.rows];
                    updatedRows[rowIndex].processed = true; // Mark the row as processed
                    setFieldValue("rows", updatedRows); // Update Formik's state with processed flag
                    {
                      /* setFieldValue(
                      `rows[${rowIndex}].document_for_regulation`,
                      documents
                    ); */
                    }
                  }
                }
              };

              // Run the effect only if at least one regulation is selected and not processed yet
              if (
                values.rows.some(
                  (row) =>
                    row.regulation && row.regulation.value && !row.processed
                )
              ) {
                fetchDocuments();
              }
            }, [values.rows]); // Dependencies: `values.rows`
            return (
              <Form>
                <Grid container spacing={3} style={{ marginTop: "10px" }}>
                  {/* Document Name Field */}
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="subtitle1"
                      component="label"
                      style={{ marginBottom: "5px", display: "block" }}
                    >
                      Document Name
                    </Typography>
                    <Field
                      name="document_name"
                      size="small"
                      fullWidth
                      type="text"
                      as={TextField}
                      variant="outlined"
                      placeholder="Enter Document Name"
                      value={values.document_name}
                    />
                    <ErrorMessage name="document_name">
                      {(msg) => (
                        <Typography
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "0.9rem",
                          }}
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>

                  {/* Document Information Dropdown */}
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="subtitle1"
                      component="label"
                      style={{ marginBottom: "5px", display: "block" }}
                    >
                      Document Information
                    </Typography>
                    <Select
                      options={owners}
                      placeholder="Select Document Information"
                      name="document_info"
                      value={values.document_info}
                      onChange={(option) =>
                        setFieldValue("document_info", option)
                      }
                      isClearable
                    />
                    {errors.document_info && touched.document_info && (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        {errors.document_info}
                      </Typography>
                    )}
                  </Grid>

                  {/* Document Language Dropdown */}
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="subtitle1"
                      component="label"
                      style={{ marginBottom: "5px", display: "block" }}
                    >
                      Document Language
                    </Typography>
                    <Select
                      options={languages}
                      placeholder="Select Document Language"
                      name="document_language"
                      value={values.document_language}
                      onChange={(option) =>
                        setFieldValue("document_language", option)
                      }
                      isClearable
                    />
                    {errors.document_language && touched.document_language && (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        {errors.document_language}
                      </Typography>
                    )}
                  </Grid>

                  {/* Document Type Dropdown */}
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="subtitle1"
                      component="label"
                      style={{ marginBottom: "5px", display: "block" }}
                    >
                      Document Type
                    </Typography>
                    <Select
                      options={types}
                      placeholder="Select Document Type"
                      name="document_type"
                      value={values.document_type}
                      onChange={(option) =>
                        setFieldValue("document_type", option)
                      }
                      isClearable
                    />
                    {errors.document_type && touched.document_type && (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        {errors.document_type}
                      </Typography>
                    )}
                  </Grid>

                  {/* Document Tags Field */}
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="subtitle1"
                      component="label"
                      style={{ marginBottom: "5px", display: "block" }}
                    >
                      Document Tags
                    </Typography>
                    <Field
                      name="document_tags"
                      type="text"
                      size="small"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      placeholder="Enter Document Tags"
                      value={values.document_tags}
                    />
                    <ErrorMessage name="document_tags">
                      {(msg) => (
                        <Typography
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "0.9rem",
                          }}
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                  {values.rows.map((row, index) => {
                    const selectedRegulations = values.rows
                      .filter((row) => row.regulation)
                      .map((row) => row.regulation);

                    const availableRegulations =
                      getAvailableRegulations(selectedRegulations);

                    return (
                      <Grid
                        container
                        spacing={3}
                        key={index}
                        style={{ padding: "24px" }}
                      >
                        {/* Regulation Select (Single Selection) */}
                        <Grid item md={5} xs={12}>
                          <Typography
                            variant="subtitle1"
                            component="label"
                            style={{ marginBottom: "5px" }}
                          >
                            Select Regulation
                          </Typography>
                          <Select
                            options={availableRegulations}
                            placeholder="Select Regulation"
                            value={row.regulation}
                            onChange={(option) => {
                              const updatedRows = [...values.rows];
                              updatedRows[index].regulation = option;
                              setFieldValue("rows", updatedRows); // Update Formik values
                            }}
                            isClearable
                          />
                          {errors.rows &&
                            errors.rows[index]?.regulation &&
                            touched.rows?.[index]?.regulation && (
                              <Typography
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {errors.rows[index]?.regulation}
                              </Typography>
                            )}
                        </Grid>

                        {/* Document for Regulation Select (Multiple Selection) */}
                        <Grid item md={5} xs={12}>
                          <Typography
                            variant="subtitle1"
                            component="label"
                            style={{ marginBottom: "5px" }}
                          >
                            Select Document for Regulation
                          </Typography>
                          <Select
                            options={documentOptions}
                            placeholder="Select Documents"
                            value={row.document_for_regulation}
                            onChange={(selected) => {
                              const updatedRows = [...values.rows];
                              updatedRows[index].document_for_regulation =
                                selected;
                              setFieldValue("rows", updatedRows); // Update Formik values
                            }}
                            isMulti
                            isClearable
                          />
                          {errors.rows &&
                            errors.rows[index]?.document_for_regulation &&
                            touched.rows?.[index]?.document_for_regulation && (
                              <Typography
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {errors.rows[index]?.document_for_regulation}
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          md={1}
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "40px",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleAddRow(values.rows, index, setFieldValue)
                            }
                          >
                            <Add />
                          </IconButton>
                        </Grid>

                        {index !== 0 && (
                          <Grid
                            item
                            md={1}
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingTop: "40px",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                handleRemoveRow(index, setFieldValue)
                              }
                            >
                              <Remove style={{ fontSize: "30px" }} />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{ textAlign: "center", marginTop: "20px" }}
                >
                  <Button type="submit" className={classes.iconButton}>
                    Submit
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

PolicyUploadForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PolicyUploadForm;
