import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  inputProfileField: {
    padding: "13px 14px",
    widt: "100%",
    color: "#000",
    fontSize: "14px",
    borderRadius: "5px",
  },
  insBoxLRField: {
    width: "80%",
    padding: "10px 0",
  },
  insBoxLRFieldFile: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "5px",
    padding: "10px 14px",
  },
  modalTitleWrapper: {
    borderBottom: "1px solid #dfdfdf",
    paddingBottom: "5px",
    marginBottom: "20px",
  },
  modalTitle: {
    fontWeight: "600",
    fontSize: "24px",
    display: "inline-block",
  },
  policyModal: {
    textAlign: "left",
  },
  policyFormLable: {
    fontSize: "14px",
    fontWeight: "500",
    margin: "10px 0",
  },
  fieldWrapper: {
    "& .MuiGrid-root": {
      marginBottom: "8px",
    },
    "& > .MuiTypography-root": {
      borderBottom: "1px solid lightgrey",
      paddingBottom: "6px",
      marginBottom: "16px",
    },
  },
  titlesInfoWrapper: {
    "& .MuiGrid-root": {
      paddingBottom: "0px",
    },
    "& .MuiButtonBase-root": {
      padding: "0px",
      marginTop: "4px",
      marginRight: "6px",
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
  DocDetailsWrapper: {
    marginBottom: "16px",
    "& .MuiGrid-root": {
      "&:first-child": {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
  },
  pageExtractBox: {
    marginBottom: "0px",
    "& .MuiTypography-h6": {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "500",
      marginTop: "4px",
    },
  },
  borderNone: {
    border: "none",
  },
  flex: {
    display: "flex",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  marginLeft: {
    marginLeft: "auto !important",
  },
  uploadProgress_wrap: {
    // borderLeft: "4px solid grey",
  },
  uploadPolicy_wrap: {
    paddingRight: "20px",
  },
  loaderWrap: {
    textAlign: "center",

    "&:before": {
      content: "''",
      width: "calc(100% - 24%)",
      height: "100%",
      background: "black",
      opacity: "0.3",
      position: "absolute",
      top: "0px",
      left: "-24px",
    },
  },
  policyUploadDialogContent: {
    overflow: "auto",
  },
  loaderInnerWrap: {
    position: "absolute",
    top: "50%",
    left: "calc(50% - 15%)",
    transform: "translate(-50%, -50%)",
  },
  showProgress: {
    "&>div": {
      left: "50%",
    },
    "&:before": {
      width: "100%",
      left: "0",
    },
  },
  standardWarning: {
    margin: "10px 0",
  },
  insBoxWrapper: {
    alignItems: "flex-end",
  },
  insBoxWrap: {
    height: "43px",
    display: "flex",
    alignItems: "center",
    marginBottom: "7px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "50px",
  },
  progressText: {
    fontSize: "14px",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  step2Header: {
    fontWeight: "900",
  },
  inputField: {
    padding: "7px 0px !important",
    width: "100%",
    color: "#000",
  },
  documentClass: {
    "& #header-bar": {
      display: "none !important",
    },
    "& #pdf-download": {
      display: "none !important",
    },
    "& #proxy-renderer": {
      overflow: "hidden",
    },
  },
  textWrapper: {
    fontWeight: "500",
    fontSize: "14px",
    paddingBottom: "10px",
  },
  predictionwrapper: {
    padding: "0",
    height: "100%",
    maxHeight: "640px",
    "& > .MuiGrid-root": {
      height: "97%",
      "& > form": {
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  predictionWrap: {
    padding: "0px 20px",
    paddingBottom: "7px",
    border: "1px solid #8683834d",
    borderRadius: "10px",
    height: "calc(100% - 110px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  wrapGrid: {
    alignItems: "center",
    "& p": {
      fontWeight: "500",
    },
  },
  wrapGridFlex: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontWeight: "500",
    },
  },
  iconWrap: {
    margin: "5px 0",
  },
  profileField: {
    padding: "10px 15px",
    color: "#000000",
    width: "100%",
  },
  profileInputWrapper: {
    width: "100%",
    padding: "10px 0px 10px",
  },
  profileDropdown: {
    width: "100%",
    padding: "10px 0px 10px",
    zIndex: "500",
    position: "relative",
  },
  iconArrow: {
    marginRight: "50px",
    marginLeft: "40px",
  },
  subCategoryWrap: {
    paddingTop: "5px",
  },
  subCategoryInput: {
    width: "100%",
    padding: "10px 10px",
  },
  subCategoryInputWrapper: {
    padding: "10px 0px 0px",
  },
  compareIcon: {
    boxShadow: "0 2px 5px 2px rgba(0, 0, 0, 0.2)",
    color: "black !important",
    fontSize: "16px !important",
    padding: "10px 20px !important",
    marginTop: "20px !important",
  },
  docViewer: {
    padding: "10px",
    background: "#80808052",
    height: "600px",
  },
  docViewerWrap: {},
  textWrap: {
    textAlign: "center",
  },
  titleWrap: {
    position: "relative",
  },
  closeWrap: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  predictionBtnWrapper: {
    padding: "20px 0px",
    textAlign: "right",
  },
  rotateBTN: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
    marginRight: "20px !important",
  },
  findReplaceBTN: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
    marginRight: "5px !important",
  },
  paginationWrapper: {
    padding: "10px 0px",
  },
  docScannerBtn: {
    right: 0,
    position: "absolute !important",
    top: "50%",
    transform: "translateY(-50%)",
    background: "#000 !important",
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
    padding: "5px !important",
    minWidth: "30px !important",
  },
  progressBtn: {
    right: 0,
    position: "absolute !important",
    top: "50%",
    transform: "translateY(-50%)",
    background: "#000 !important",
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
    padding: "5px !important",
    minWidth: "30px !important",
    backgroundColor: "red !important",
    color: "white !important",
  },
  contentEditor: {
    height: "67vh",
    border: "1px solid",
    borderRadius: "5px",
    padding: "10px",
    display: "block",
  },
  saveBtn: {
    width: "100%",
    textAlign: "right",
    marginTop: "20px",
  },
  contentToolBar: {
    background: "#80808038",
    borderRadius: "10px",
  },
  headWrap: {
    "& .MuiTypography-h6": {
      display: "flex",
      justifyContent: "space-between",
    },
    padding: "5px 24px !important",
  },
  inputWrap: {
    width: "100%",
  },
  replaceWrap: {
    marginTop: "10px",
  },
}));
export default useStyles;
