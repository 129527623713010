import React, { useEffect, useState } from "react";
import {
  newUploadOnlyValidationSchema,
  newValidationSchema,
  putErrorLogs,
  putUserErrorLogs,
} from "../../../PolicyUploadPopup/utils";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CustomTextField from "../../../PolicyUploadPopup/component/formField";
import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import classNames from "classnames";
import Select from "react-select";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Tooltip,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import PolicyServices from "../../services";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { alertActions, loadingActions } from "_actions";
import useStyles from "../../styles";
import axios from "axios";
import moment from "moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import { addErrorMessages } from "containers/manage-regulation/Policy/PolicyExplorer/actions";
import { CircularProgress } from "@mui/material";
import { increaseActiveStep } from "_components/Stepper/action";
import {
  setStep1Values,
  setOriginalDocumentInfo,
  setProcessedDocumentInfo,
  updatePolicyId,
  setProcessedPredictedtextValues,
  setOriginalPredictedtextValues,
  updateBackView,
  updateParentPolicyID,
} from "../../actions";
import PolicyDataServices from "../../../../services";
import HelperTextComponent from "_components/HelpText";
import { contentUpdatedFlag, firstStepHelperText } from "../utils";
import { virusCheckerService } from "_helpers/utils";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import getRegulationForDocSerivice from "../../../../../../Regulators/services";
import { Add, Remove } from "@mui/icons-material";
import uploadDocumentService from "../../../../../../NewRegulation/ManageQuestions/component/UploadDocuments/services";

const Step1Form = ({
  uploadOnly,
  setUploadOnly,
  handleClose,
  setProgressLogsData,
  setProgressView,
  progressView,
  setCurrentStep,
}) => {
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [owners, setOwners] = useState([]);
  const [types, setTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isVirusFile, setIsVirusFile] = useState(false);
  const [regulationOptions, setRegulationOptions] = useState([]);

  const preProcessingIP = useSelector(
    (state) => state.authentication.detailedInfo.PRE_PROCESSING_IP
  );
  const preProcessingURL = useSelector(
    (state) => state.authentication.detailedInfo.PRE_PROCESSING_URL
  );
  const pollingTimer = useSelector(
    (state) => state?.authentication?.detailedInfo?.POLLING_TIMER
  );

  const errorMessages = useSelector(
    (state) => state.policyReducer.errorMessages
  );
  const step1FormData = useSelector((state) => state.uploadPolicyReducer.step1);
  const isReRunMode = useSelector(
    (state) => state.uploadPolicyReducer.isReRunMode
  );
  const documentInfo = useSelector(
    (state) => state.uploadPolicyReducer.documentInfo
  );
  const isBackView = useSelector(
    (state) => state.uploadPolicyReducer.isBackView
  );
  const isVirusCheckingAvailable = useSelector(
    (state) => state.authentication?.detailedInfo?.VIRUS_CHECKER
  );
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  useEffect(() => {
    (async () => {
      const response = await PolicyServices.getDocumentControlInformation(
        userID
      );
      if (response.status === 200) {
        setOwners(
          response["#result-set-1"].map((item) => {
            return { value: item.ORG_UNIT_ID, label: item.ORG_UNIT_OWNER };
          })
        );
        setTypes(
          response["#result-set-2"].map((item) => {
            return { value: item.ID, label: item.DOCUMENT_CLASSIFICATION_NAME };
          })
        );
        setLanguages(
          response["#result-set-3"].map((item) => {
            return { value: item.LANGUAGE_CODE, label: item.NATIONAL_LANGUAGE };
          })
        );
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    })();
  }, []);

  const regulationList = useSelector((state) => state.regulationList);

  useEffect(() => {
    if (regulationList.length > 0) {
      setRegulationOptions(
        regulationList.map((item) => {
          return {
            value: item.REGULATION_ID,
            label: item.REGULATION_SHORT_NAME,
          };
        })
      );
    }
  }, [regulationList]);

  const updateUserErrorLogs = (error) => {
    const userError = errorMessages.find(
      (item) => item.TECHNICAL_ERROR_MESSAGE == error
    );
    if (userError && userError["TECHNICAL_ERROR_MESSAGE"]) {
      const errorText = userError["USER_ERROR_MESSAGE"]
        ? userError["USER_ERROR_MESSAGE"]
        : userError["TECHNICAL_ERROR_MESSAGE"];
      dispatch(alertActions.error(errorText));
      const errorData = {
        CUSTOMER_ID: userID,
        URL: "POLICY_UPLOAD",
        TECHNICAL_ERROR_MESSAGE: error,
      };
      putErrorLogs(errorData);
    } else {
      dispatch(alertActions.error(error));
      const errorTextData = {
        ACTIVE: true,
        CUSTOMER_ID: customerID,
        TECHNICAL_ERROR_MESSAGE: error,
        URL: "POLICY_UPLOAD",
        USER_ERROR_MESSAGE: "",
      };
      dispatch(addErrorMessages(errorTextData));
      putUserErrorLogs(errorTextData);
      putErrorLogs(errorTextData);
    }
  };

  const getAvailableRegulations = (selectedRegulations) => {
    return regulationOptions.filter(
      (regulation) =>
        !selectedRegulations.some(
          (selected) => selected.value === regulation.value
        )
    );
  };

  const updateUserProgress = async (policyId) => {
    const userProgressResponse = await PolicyServices.getUserProgress(policyId);
    setProgressLogsData(userProgressResponse["#result-set-1"]);
  };

  const uploadPolicyInfo = async (values) => {
    values["currentDateTime"] = moment().format("YYYY-MM-DD HH:mm:ss");

    values["userID"] = userID;
    values["policy_metadata"] = {
      aliasName: values.document_name,
      startPage: values.pages_extraction_start,
      endPage: values.pages_extraction_end,
      document_language: values.document_language,
      document_type: values.document_type,
      document_owner: values.document_owner,
      document_tags: values.document_tags.trim(),
      document_extension: values.document_extension,
      upload_only: values.upload_only,
    };
    values["customerID"] = customerID;
    if (isReRunMode) {
      const fileData = values.document.split(".");
      values["document_extension"] =
        fileData.length >= 2 ? fileData[fileData.length - 1] : "";
      values["policy_metadata"]["fileName"] = values.document;
      (values["policy_metadata"]["is_pre_proccesing"] =
        values.is_pre_proccesing),
        (values["fileName"] = values.document);

      const contentUpdateFlag = contentUpdatedFlag(step1FormData, values);
      if (contentUpdateFlag) {
        dispatch(setStep1Values(values));
        dispatch(
          setOriginalDocumentInfo({
            name: values.document,
            extension: values.document.split(".").pop(),
          })
        );
        dispatch(loadingActions.start());
        const putSP = await PolicyServices.putPolicy(values);
        dispatch(loadingActions.end());
        if (putSP.status === 200) {
          try {
            for (const item of values.rows) {
              if (item.regulation) {
                for (const documentForRegulation of item.document_for_regulation) {
                  const response =
                    await uploadDocumentService.putCustomerRegulationPolicyMapping(
                      customerID,
                      putSP["#result-set-1"][0]["new_id"],
                      item.regulation.value,
                      userID,
                      documentForRegulation.value,
                      null
                    );

                  if (response.status !== 200) {
                    dispatch(alertActions.error("Something went wrong!"));
                  }
                }
              }
            }
          } catch (error) {
            dispatch(
              alertActions.error("An error occured while uploading document.")
            );
            console.error(error);
          }
          const reRunPayload = {
            parentPolicyID: values.policy_id,
            policyID: putSP["#result-set-1"][0]["new_id"],
            fileName: values.document,
            userID: userID,
            customerID: customerID,
          };
          dispatch(updatePolicyId(putSP["#result-set-1"][0]["new_id"]));
          dispatch(loadingActions.start());
          const reRunResponse = await PolicyServices.reRunService(reRunPayload);
          dispatch(loadingActions.end());
          if (
            reRunResponse.status === 200 &&
            reRunResponse.data &&
            reRunResponse.data.status === 200
          ) {
            let policyType = values.is_pre_proccesing
              ? "processed"
              : "original";
            const predictionResponse = await PolicyServices.getPolicyPrediction(
              { policyId: values.policy_id, type: policyType }
            );
            if (predictionResponse.status === 200) {
              if (predictionResponse.predictions["data"].length > 0) {
                const response = await PolicyServices.putPolicyPrediction({
                  policyId: values.policy_id,
                  type: policyType,
                  predictions: predictionResponse.prodictionString,
                });
                if (values.is_pre_proccesing) {
                  dispatch(
                    setProcessedDocumentInfo({
                      name: values.document,
                      extension: values["document_extension"],
                    })
                  );
                  dispatch(
                    setProcessedPredictedtextValues(
                      predictionResponse.predictions["data"]
                    )
                  );
                } else {
                  dispatch(
                    setOriginalDocumentInfo({
                      name: values.document,
                      extension: values["document_extension"],
                    })
                  );
                  dispatch(
                    setOriginalPredictedtextValues(
                      predictionResponse.predictions["data"]
                    )
                  );
                }
                setCurrentStep(2);
                dispatch(updateBackView(false));
                dispatch(increaseActiveStep());
              } else {
                // const userProgressTimer = setInterval(async function(){
                //     await updateUserProgress(putSP['#result-set-1'][0]['new_id'])
                // }, 5000)
                dispatch(loadingActions.start());
                dispatch(
                  loadingActions.setLoaderText(
                    langConstant.DOCUMENT_CLASSIFICATION_INPROGRESS
                  )
                );

                const getPridictionResponse =
                  await PolicyServices.getPrediction({
                    docName: values.document,
                    fileId: putSP["#result-set-1"][0]["new_id"],
                    startPage: values.pages_extraction_start,
                    endPage: values.pages_extraction_end,
                    docType: values.is_pre_proccesing
                      ? "processed"
                      : "original",
                    stepper: "1",
                    customerID: customerID,
                    version_1_prediction: values.version_1_prediction,
                    language: values.document_language.label,
                    languageCode: values.document_language.value,
                  });
                dispatch(loadingActions.end());
                dispatch(loadingActions.setLoaderText(null));
                // clearInterval(userProgressTimer)
                if (
                  getPridictionResponse.status === 200 &&
                  getPridictionResponse.data.status === 200
                ) {
                  // await updateUserProgress(putSP['#result-set-1'][0]['new_id'])
                  if (values.is_pre_proccesing) {
                    dispatch(
                      setProcessedPredictedtextValues(
                        getPridictionResponse.data.data
                      )
                    );
                  } else {
                    dispatch(
                      setOriginalPredictedtextValues(
                        getPridictionResponse.data.data
                      )
                    );
                  }
                }
                setCurrentStep(2);
                dispatch(updateBackView(false));
                dispatch(increaseActiveStep());
              }
            }
          } else {
            reRunResponse.data && reRunResponse.data.message
              ? dispatch(alertActions.error(reRunResponse.data.message))
              : dispatch(alertActions.error("Something Went Wrong"));
          }
          if (reRunResponse.status) {
            if (reRunResponse.status == 200) {
              let policyType = values.is_pre_proccesing
                ? "processed"
                : "original";
              const predictionResponse =
                await PolicyServices.getPolicyPrediction({
                  policyId: values.policy_id,
                  type: policyType,
                });
              if (predictionResponse.status === 200) {
                if (predictionResponse.predictions["data"].length > 0) {
                  const response = await PolicyServices.putPolicyPrediction({
                    policyId: values.policy_id,
                    type: policyType,
                    predictions: predictionResponse.prodictionString,
                  });
                  if (values.is_pre_proccesing) {
                    dispatch(
                      setProcessedDocumentInfo({
                        name: values.document,
                        extension: values["document_extension"],
                      })
                    );
                    dispatch(
                      setProcessedPredictedtextValues(
                        predictionResponse.predictions["data"]
                      )
                    );
                  } else {
                    dispatch(
                      setOriginalDocumentInfo({
                        name: values.document,
                        extension: values["document_extension"],
                      })
                    );
                    dispatch(
                      setOriginalPredictedtextValues(
                        predictionResponse.predictions["data"]
                      )
                    );
                  }
                  setCurrentStep(2);
                  dispatch(updateBackView(false));
                  dispatch(increaseActiveStep());
                } else {
                  // const userProgressTimer = setInterval(async function(){
                  //     await updateUserProgress(putSP['#result-set-1'][0]['new_id'])
                  // }, 5000)
                  dispatch(loadingActions.start());
                  dispatch(
                    loadingActions.setLoaderText(
                      langConstant.DOCUMENT_CLASSIFICATION_INPROGRESS
                    )
                  );
                  const getPridictionResponse =
                    await PolicyServices.getPrediction({
                      docName: values.document,
                      fileId: putSP["#result-set-1"][0]["new_id"],
                      startPage: values.pages_extraction_start,
                      endPage: values.pages_extraction_end,
                      docType: values.is_pre_proccesing
                        ? "processed"
                        : "original",
                      stepper: "1",
                      customerID: customerID,
                      version_1_prediction: values.version_1_prediction,
                      language: values.document_language.label,
                      languageCode: values.document_language.value,
                    });

                  dispatch(loadingActions.end());
                  dispatch(loadingActions.setLoaderText(null));
                  // clearInterval(userProgressTimer)
                  if (
                    getPridictionResponse.status === 200 &&
                    getPridictionResponse.data.status === 200
                  ) {
                    // await updateUserProgress(putSP['#result-set-1'][0]['new_id'])
                    if (values.is_pre_proccesing) {
                      dispatch(
                        setProcessedPredictedtextValues(
                          getPridictionResponse.data.data
                        )
                      );
                    } else {
                      dispatch(
                        setOriginalPredictedtextValues(
                          getPridictionResponse.data.data
                        )
                      );
                    }
                  }
                  setCurrentStep(2);
                  dispatch(updateBackView(false));
                  dispatch(increaseActiveStep());
                }
              }
            } else {
              if (reRunResponse.message) {
                dispatch(alertActions.error(reRunResponse.message));
              }
            }
          } else {
            if (reRunResponse.data && reRunResponse.data.message) {
              dispatch(
                alertActions.error(
                  reRunResponse.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
            }
          }
        }
      } else {
        if (values.extraction_status) {
          dispatch(loadingActions.start());
          const updateSP = await PolicyServices.updatePolicy(values);
          dispatch(loadingActions.end());
          if (updateSP.status == 200) {
            try {
              for (const item of values.rows) {
                if (item.regulation) {
                  // for (const documentForRegulation of item.document_for_regulation) {
                  //   const response =
                  //     await uploadDocumentService.updateCustomerRegulationPolicyMap(
                  //       customerID,
                  //       item.regulation.value,
                  //       values["policy_id"],
                  //       documentForRegulation.value,
                  //       documentForRegulation.value,
                  //       userID,
                  //       1
                  //     );
                  //   if (response.status !== 200) {
                  //     dispatch(alertActions.error("Something went wrong!"));
                  //   }
                  // }
                }
              }
            } catch (error) {
              dispatch(
                alertActions.error("An error occured while uploading document.")
              );
              console.error(error);
            }
            handleClose();
            dispatch(alertActions.success("Policy Data Updated Successfully"));
          } else {
            dispatch(alertActions.error("Update SP Failed"));
          }
        } else {
          values.is_pre_proccesing
            ? dispatch(
                setProcessedDocumentInfo({
                  name: values.document,
                  extension: values["document_extension"],
                })
              )
            : dispatch(
                setOriginalDocumentInfo({
                  name: values.document,
                  extension: values["document_extension"],
                })
              );
          dispatch(loadingActions.start());
          dispatch(
            loadingActions.setLoaderText(
              langConstant.FETCHING_DOCUMENT_CLASSIFICATION
            )
          );
          const predictionResponse = await PolicyServices.getPolicyPrediction({
            policyId: values.policy_id,
            type: values.is_pre_proccesing ? "processed" : "original",
          });
          dispatch(loadingActions.end());
          dispatch(loadingActions.setLoaderText(null));
          if (predictionResponse.status === 200) {
            values.is_pre_proccesing
              ? dispatch(
                  setProcessedPredictedtextValues(
                    predictionResponse.predictions["data"]
                  )
                )
              : dispatch(
                  setOriginalPredictedtextValues(
                    predictionResponse.predictions["data"]
                  )
                );
          }
          dispatch(setStep1Values(values));
          dispatch(updatePolicyId(values.policy_id));
          setCurrentStep(2);
          dispatch(updateBackView(false));
          dispatch(increaseActiveStep());
        }
      }
    } else {
      if (!isVirusFile) {
        setProgressLogsData([]);
        const fileData = values.document.name.split(".");
        values["document_extension"] =
          fileData.length >= 2 ? fileData[fileData.length - 1] : "";
        values["policy_metadata"]["fileName"] = values.document.name;
        values["policy_metadata"]["is_pre_proccesing"] =
          values.is_pre_proccesing;
        values["fileName"] = values.document.name;
        if (
          values.document.name.split(".").pop() == "docx" ||
          values.document.name.split(".").pop() == "doc" ||
          values.is_pre_proccesing
        ) {
          values["is_pre_processing"] = true;
          dispatch(
            setProcessedDocumentInfo({
              name:
                values.document.name.substr(
                  0,
                  values.document.name.lastIndexOf(".")
                ) + "_processed.pdf",
              extension: "pdf",
            })
          );
        }
        dispatch(setStep1Values(values));
        dispatch(
          setOriginalDocumentInfo({
            name: values.document.name,
            extension: values.document.name.split(".").pop(),
          })
        );
        if (!isBackView) {
          const putSP = await PolicyServices.putPolicy(values);
          if (putSP.status === 200) {
            try {
              for (const item of values.rows) {
                if (item.regulation) {
                  for (const documentForRegulation of item.document_for_regulation) {
                    const response =
                      await uploadDocumentService.putCustomerRegulationPolicyMapping(
                        customerID,
                        putSP["#result-set-1"][0]["new_id"],
                        item.regulation.value,
                        userID,
                        documentForRegulation.value,
                        null
                      );

                    if (response.status !== 200) {
                      dispatch(alertActions.error("Something went wrong!"));
                    }
                  }
                }
              }
            } catch (error) {
              dispatch(
                alertActions.error("An error occured while uploading document.")
              );
              console.error(error);
            }

            let data = new FormData();
            data.append("document_obj", values.document);
            data.append("fileName", values.document.name);
            data.append("fileId", putSP["#result-set-1"][0]["new_id"]);
            data.append("aliasName", values.document_name);
            data.append("preProcessingIP", preProcessingIP);
            data.append("preProcessingURL", preProcessingURL);
            data.append("start_extract_page", values.pages_extraction_start);
            data.append("end_extract_page", values.pages_extraction_end);
            data.append(
              "document_owner",
              JSON.stringify(values.document_owner)
            );
            data.append("document_tags", values.document_tags.trim());
            data.append("document_type", JSON.stringify(values.document_type));
            data.append("is_pre_proccesing", values.is_pre_proccesing);
            data.append("document_extension", values.document_extension);
            data.append("languageCode", values.document_language.value);
            data.append("version_1_prediction", values.version_1_prediction);
            data.append("language", values.document_language.label);
            data.append("polling_timer", pollingTimer ? pollingTimer : 5);
            data.append(
              "document_language",
              JSON.stringify(values.document_language)
            );
            data.append("upload_only", values.upload_only);
            data.append("user_id", userID);
            data.append("customerID", customerID);
            data.append("storePreProcessingIP", docStorageIP);
            data.append("storePreProcessingURL", docStorageURL);
            data.append("storageType", docStorageType.toLowerCase());
            const userProgressTimer = setInterval(async function () {
              await updateUserProgress(putSP["#result-set-1"][0]["new_id"]);
            }, 5000);
            setProgressView(true);
            const response = await PolicyServices.newUpload(data);
            clearInterval(userProgressTimer);
            if (response.status) {
              await updateUserProgress(putSP["#result-set-1"][0]["new_id"]);
              setProgressView(false);
              if (response.status === 200) {
                if (response.data.length > 0) {
                  if (
                    values.document.name.split(".").pop() == "docx" ||
                    values.document.name.split(".").pop() == "doc" ||
                    values.is_pre_proccesing
                  ) {
                    dispatch(
                      setProcessedDocumentInfo({
                        name:
                          values.document.name.substr(
                            0,
                            values.document.name.lastIndexOf(".")
                          ) + "_processed.pdf",
                        extension: "pdf",
                      })
                    );
                    dispatch(setProcessedPredictedtextValues(response.data));
                  } else {
                    dispatch(setOriginalPredictedtextValues(response.data));
                  }
                }
                dispatch(updatePolicyId(putSP["#result-set-1"][0]["new_id"]));
                setCurrentStep(2);
                dispatch(updateBackView(false));
                dispatch(increaseActiveStep());
              } else {
                updateUserErrorLogs(response.message);
                if (
                  response.message &&
                  response.message === "Predictions could not be extracted"
                ) {
                  if (
                    values.document.name.split(".").pop() == "docx" ||
                    values.document.name.split(".").pop() == "doc" ||
                    values.is_pre_proccesing
                  ) {
                    dispatch(
                      setProcessedDocumentInfo({
                        name:
                          values.document.name.substr(
                            0,
                            values.document.name.lastIndexOf(".")
                          ) + "_processed.pdf",
                        extension: "pdf",
                      })
                    );
                  }
                  dispatch(updatePolicyId(putSP["#result-set-1"][0]["new_id"]));
                  setCurrentStep(2);
                  dispatch(updateBackView(false));
                  dispatch(increaseActiveStep());
                }
              }
            } else {
              setProgressView(false);
            }
          } else {
            dispatch(alertActions.error("PutPolicy SP Failed"));
          }
        } else {
          values["policy_id"] = documentInfo.policyId;
          const updateSP = await PolicyServices.updatePolicy(values);
          if (updateSP.status == 200) {
            try {
              for (const item of values.rows) {
                if (item.regulation) {
                  // for (const documentForRegulation of item.document_for_regulation) {
                  //   const response =
                  //     await uploadDocumentService.updateCustomerRegulationPolicyMap(
                  //       customerID,
                  //       item.regulation.value,
                  //       values["policy_id"],
                  //       documentForRegulation.value,
                  //       documentForRegulation.value,
                  //       userID,
                  //       1
                  //     );
                  //   if (response.status !== 200) {
                  //     dispatch(alertActions.error("Something went wrong!"));
                  //   }
                  // }
                }
              }
            } catch (error) {
              dispatch(
                alertActions.error("An error occured while uploading document.")
              );
              console.error(error);
            }
            setCurrentStep(2);
            dispatch(updateBackView(false));
            dispatch(increaseActiveStep());
          } else {
            dispatch(alertActions.error("Update SP Failed"));
          }
        }
      } else {
        dispatch(alertActions.error("FILE CONTAINS VIRUS"));
      }
    }
  };

  const uploadOnlyPolicy = async (values) => {
    if (!isVirusFile) {
      values["currentDateTime"] = moment().format("YYYY-MM-DD HH:mm:ss");
      values["userID"] = userID;
      values["customerID"] = customerID;
      values["policy_metadata"] = {
        fileName: values.document.name,
        aliasName: values.document_name,
        startPage: values.pages_extraction_start,
        endPage: values.pages_extraction_end,
        normalText: { text: "", page_no: "" },
        titlesList: [{ id: 0, text: "", page_no: "" }],
        headersText: { text: "", page_no: "" },
        footersText: { text: "", page_no: "" },
        document_language: values.document_language,
        document_type: values.document_type,
        document_owner: values.document_owner,
        document_tags: values.document_tags.trim(),
        cleanDocument: false,
        document_extension: values.document_extension,
      };
      values["fileName"] = values.document.name;
      const putSP = await PolicyServices.putPolicy(values);
      if (putSP.status === 200) {
        try {
          for (const item of values.rows) {
            if (item.regulation) {
              for (const documentForRegulation of item.document_for_regulation) {
                const response =
                  await uploadDocumentService.putCustomerRegulationPolicyMapping(
                    customerID,
                    putSP["#result-set-1"][0]["new_id"],
                    item.regulation.value,
                    userID,
                    documentForRegulation.value,
                    null
                  );

                if (response.status !== 200) {
                  dispatch(alertActions.error("Something went wrong!"));
                }
              }
            }
          }
        } catch (error) {
          dispatch(
            alertActions.error("An error occured while uploading document.")
          );
          console.error(error);
        }

        let data = new FormData();
        data.append("document_obj", values.document);
        data.append("fileName", values.document.name);
        data.append("fileId", putSP["#result-set-1"][0]["new_id"]);
        data.append("aliasName", values.document_name);
        data.append("user_id", userID);
        data.append("customerID", customerID);
        data.append("storePreProcessingIP", docStorageIP);
        data.append("storePreProcessingURL", docStorageURL);
        data.append("storageType", docStorageType.toLowerCase());
        dispatch(loadingActions.start());
        // const response = await axios.post(
        //   `${window["FLASK_EXTRACTION_URL"]}/documentUpload`,
        //   data
        // );
        const response = await PolicyServices.documentUpload(data);
        dispatch(loadingActions.end());
        if (response.status) {
          if (response.status === 200) {
            handleClose();
            dispatch(alertActions.success(response.message));
            dispatch(PolicyDataServices.getAvailablePolicies(userID));
          } else {
            updateUserErrorLogs(response.message);
          }
        }
      } else {
        updateUserErrorLogs(langConstant.POLICY_INSERTION_FAILED);
      }
    } else {
      dispatch(alertActions.error("FILE CONTAINS VIRUS"));
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      paddingTop: 4,
      paddingBottom: 4,
    }),
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...step1FormData,
        rows: [
          {
            regulation: null,
            document_for_regulation: [],
          },
        ],
      }}
      validationSchema={
        uploadOnly ? newUploadOnlyValidationSchema : newValidationSchema
      }
      onSubmit={(value) => {
        uploadOnly ? uploadOnlyPolicy(value) : uploadPolicyInfo(value);
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {
        const [documentOptions, setDocumentOptions] = useState([]);
        const [addedRows, setAddedRows] = useState([
          { regulation: null, document_for_regulation: [] },
        ]);

        const handleAddRow = (rows, index, setFieldValue) => {
          const newRow = { regulation: null, document_for_regulation: [] };
          const updatedRows = [...rows];
          updatedRows.splice(index + 1, 0, newRow);

          setAddedRows(updatedRows);
          setFieldValue("rows", updatedRows);
        };

        const handleRemoveRow = (index, setFieldValue) => {
          const updatedRows = [...addedRows];
          updatedRows.splice(index, 1);
          setAddedRows(updatedRows);
          setFieldValue("rows", updatedRows);
        };

        useEffect(() => {
          const fetchDocuments = async () => {
            const newDocuments = [];

            for (let rowIndex = 0; rowIndex < values.rows.length; rowIndex++) {
              const row = values.rows[rowIndex];
              const regulation = row.regulation;

              if (regulation && regulation.value && !row.processed) {
                const documents =
                  await getRegulationForDocSerivice.getDocumentsForRegulation(
                    regulation.value,
                    customerID
                  );

                newDocuments[rowIndex] = documents;

                const opt = documents["#result-set-1"].map((ele) => {
                  return {
                    label: ele.DOCUMENT_RECOMMENDATION,
                    value: ele.REGULATIONDOCREQ_UNID,
                  };
                });
                setDocumentOptions(opt);

                const updatedRows = [...values.rows];
                updatedRows[rowIndex].processed = true;
                setFieldValue("rows", updatedRows);
              }
            }
          };

          if (
            values.rows.some(
              (row) => row.regulation && row.regulation.value && !row.processed
            )
          ) {
            fetchDocuments();
          }
        }, [values.rows]);

        return (
          <Form style={{ width: "100%" }}>
            <Grid container>
              <Grid
                container
                item
                md={12}
                xs={12}
                className={classNames(classes.insBoxWrapper)}
              >
                <Grid item md={6} xs={12}>
                  <Typography
                    className={classes.policyFormLable}
                    style={{ display: "inline" }}
                  >
                    {values.document
                      ? langConstant.UPLOADED_DOCUMENT
                      : langConstant.UPLOAD_DOCUMENT}
                  </Typography>
                  <HelperTextComponent
                    id={firstStepHelperText["document"]["id"]}
                    textData={firstStepHelperText["document"]["text"]}
                  />
                  {isReRunMode ? (
                    <Typography>{values.document}</Typography>
                  ) : (
                    <>
                      {isBackView ? (
                        <Typography>{values.document.name}</Typography>
                      ) : (
                        <>
                          <Field name="document">
                            {() => (
                              <input
                                type="file"
                                accept={
                                  values.upload_only
                                    ? ".doc,.docx,.pdf,.png,.jpg,.jpeg,.xlsx,.csv,.txt"
                                    : ".doc,.docx,.pdf"
                                }
                                className={classNames(
                                  classes.insBoxLRField,
                                  classes.insBoxLRFieldFile
                                )}
                                onChange={async (e) => {
                                  if (e.target.files.length > 0) {
                                    if (isVirusCheckingAvailable == 1) {
                                      const fData = new FormData();
                                      fData.append("file", e.target.files[0]);
                                      dispatch(loadingActions.start());
                                      dispatch(
                                        loadingActions.setLoaderText(
                                          "Checking Virus In Uploaded File"
                                        )
                                      );
                                      const response =
                                        await virusCheckerService(fData);
                                      dispatch(
                                        loadingActions.setLoaderText(null)
                                      );
                                      dispatch(loadingActions.end());
                                      if (response.status == 200) {
                                        if (
                                          response.data &&
                                          response.data.hasVirus
                                        ) {
                                          setIsVirusFile(true);
                                          dispatch(
                                            alertActions.success(
                                              response.data.message
                                            )
                                          );
                                        } else {
                                          setIsVirusFile(false);
                                          dispatch(
                                            alertActions.success(
                                              response.data.message
                                            )
                                          );
                                          setFieldValue(
                                            "document",
                                            e.target.files[0]
                                          );
                                          const fileData =
                                            e.target.files[0].name.split(".");
                                          setFieldValue(
                                            "document_extension",
                                            fileData.length >= 2
                                              ? fileData[fileData.length - 1]
                                              : ""
                                          );
                                          if (
                                            e.target.files[0].name
                                              .split(".")
                                              .pop() == "docx" ||
                                            e.target.files[0].name
                                              .split(".")
                                              .pop() == "doc"
                                          ) {
                                            setFieldValue(
                                              "is_pre_proccesing",
                                              true
                                            );
                                          }
                                        }
                                      } else {
                                        dispatch(
                                          alertActions.error(
                                            "Virus Checker Service Failed"
                                          )
                                        );
                                      }
                                    } else {
                                      setFieldValue(
                                        "document",
                                        e.target.files[0]
                                      );
                                      const fileData =
                                        e.target.files[0].name.split(".");
                                      setFieldValue(
                                        "document_extension",
                                        fileData.length >= 2
                                          ? fileData[fileData.length - 1]
                                          : ""
                                      );
                                      if (
                                        e.target.files[0].name
                                          .split(".")
                                          .pop() == "docx" ||
                                        e.target.files[0].name
                                          .split(".")
                                          .pop() == "doc"
                                      ) {
                                        setFieldValue(
                                          "is_pre_proccesing",
                                          true
                                        );
                                      }
                                    }
                                  } else {
                                    setFieldValue("document", "");
                                    setFieldValue("document_extension", "");
                                    setFieldValue("is_pre_proccesing", false);
                                  }
                                }}
                              />
                            )}
                          </Field>
                          {isVirusCheckingAvailable == 1 && (
                            <span style={{ paddingLeft: "10px" }}>
                              {values.document &&
                                (isVirusFile ? (
                                  <Tooltip
                                    title="Virus Found In File"
                                    placement="right"
                                  >
                                    <CoronavirusIcon color="error" />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Virus Not Found In File"
                                    placement="right"
                                  >
                                    <VerifiedIcon color="success" />
                                  </Tooltip>
                                ))}
                            </span>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <ErrorMessage name="document">
                    {(msg) => (
                      <Typography style={{ color: "red" }}>{msg}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid
                  container
                  item
                  md={6}
                  xs={12}
                  className={classNames(classes.insBoxWrap)}
                >
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_pre_proccesing}
                          onClick={(e) =>
                            setFieldValue("is_pre_proccesing", e.target.checked)
                          }
                          sx={{
                            "&.Mui-checked": {
                              color: "#124571",
                            },
                            display: "inline",
                          }}
                          disabled={
                            isReRunMode
                              ? true
                              : !values.upload_only
                              ? values.document_extension === "pdf" &&
                                !isBackView
                                ? false
                                : true
                              : true
                          }
                          size="large"
                        />
                      }
                      label={<span>{langConstant.PREPROCESS_YOUR_PDF}</span>}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.upload_only}
                          onClick={(e) => {
                            setFieldValue("upload_only", e.target.checked);
                            setFieldValue("is_pre_proccesing", false);
                            setUploadOnly(e.target.checked);
                          }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#124571",
                            },
                            display: "inline",
                          }}
                          size="large"
                        />
                      }
                      disabled={isReRunMode || isBackView ? true : false}
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          {langConstant.UPLOAD_ONLY}
                        </span>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Alert
                  icon={<InfoIcon fontSize="medium" />}
                  severity="warning"
                  style={{
                    border: "2px solid #fff0a2",
                    width: "70%",
                    backgroundColor: "#fffdf2",
                    margin: "15px 0",
                  }}
                >
                  {langConstant.PRE_PROCESSED_WARN_TEXT}
                </Alert>
              </Grid>
              <Grid container item md={12} xs={12}>
                <Grid item md={6} xs={12}>
                  <Typography
                    className={classes.policyFormLable}
                    style={{ display: "inline" }}
                  >
                    {langConstant.DOCUMENT_NAME}
                  </Typography>
                  <HelperTextComponent
                    id={firstStepHelperText["document_name"]["id"]}
                    textData={firstStepHelperText["document_name"]["text"]}
                  />
                  <Field
                    name="document_name"
                    type="text"
                    component={CustomTextField}
                    onChange={(e) => {
                      setFieldValue("document_name", e.target.value);
                    }}
                    placeholder="Document Name"
                    value={values.document_name}
                  />
                  <ErrorMessage name="document_name">
                    {(msg) => (
                      <Typography style={{ color: "red" }}>{msg}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.policyFormLable}>
                    {langConstant.PAGES_OF_EXTRACTION}
                  </Typography>
                  <Grid container alignItems="center" item md={12} xs={12}>
                    <Grid
                      item
                      xs={4}
                      style={{ marginBottom: "0px", position: "relative" }}
                    >
                      <Field
                        name="pages_extraction_start"
                        type="number"
                        component={CustomTextField}
                        onChange={(e) =>
                          setFieldValue(
                            "pages_extraction_start",
                            e.target.value
                          )
                        }
                        placeholder="Start Page"
                        value={values.pages_extraction_start}
                      />
                      <ErrorMessage name="pages_extraction_start">
                        {(msg) => (
                          <Typography
                            style={{
                              color: "red",
                              position: "absolute",
                              bottom: "",
                            }}
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={2} style={{ marginBottom: "0px" }}>
                      <Typography variant="h6" component="h6">
                        to
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ marginBottom: "0px", position: "relative" }}
                    >
                      <Field
                        name="pages_extraction_end"
                        type="number"
                        component={CustomTextField}
                        onChange={(e) =>
                          setFieldValue("pages_extraction_end", e.target.value)
                        }
                        placeholder="End Page"
                        value={values.pages_extraction_end}
                      />
                      <ErrorMessage name="pages_extraction_end">
                        {(msg) => (
                          <Typography
                            style={{
                              color: "red",
                              position: "absolute",
                              bottom: "",
                            }}
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  className={classes.policyFormLable}
                  style={{ display: "inline" }}
                >
                  {langConstant.DOCUMENT_INFORMATION}
                </Typography>
                <HelperTextComponent
                  id={firstStepHelperText["document_owner"]["id"]}
                  textData={firstStepHelperText["document_owner"]["text"]}
                />
                <Grid container item md={12} xs={12}>
                  <Grid item xs={12} md={6}>
                    <Grid style={{ width: "80%", padding: "10px 0" }}>
                      <Select
                        options={owners}
                        placeholder={langConstant.DOCUMENT_OWNER}
                        value={values["document_owner"]}
                        onChange={(owner) => {
                          setFieldValue("document_owner", owner);
                        }}
                        styles={customStyles}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid style={{ width: "80%", padding: "10px 0" }}>
                      <Select
                        options={types}
                        placeholder={langConstant.DOCUMENT_TYPES}
                        isMulti
                        value={values["document_type"]}
                        onChange={(types) => {
                          setFieldValue("document_type", types);
                        }}
                        styles={customStyles}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md={12} xs={12}>
                <Grid item xs={12} md={6}>
                  <Typography
                    className={classes.policyFormLable}
                    style={{ display: "inline" }}
                  >
                    {langConstant.DOCUMENT_LANGUAGE}
                  </Typography>
                  <HelperTextComponent
                    id={firstStepHelperText["document_language"]["id"]}
                    textData={firstStepHelperText["document_language"]["text"]}
                  />
                  <Grid style={{ width: "80%", padding: "10px 0px" }}>
                    <Select
                      options={languages}
                      placeholder={langConstant.DOCUMENT_LANGUAGE}
                      value={values["document_language"]}
                      onChange={(language) => {
                        setFieldValue("document_language", language);
                      }}
                      styles={customStyles}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.policyFormLable}>
                    {langConstant.DOCUMENT_TAGS}
                  </Typography>
                  <Field
                    name="document_tags"
                    type="text"
                    component={CustomTextField}
                    onChange={(e) => {
                      setFieldValue("document_tags", e.target.value);
                    }}
                    placeholder="Document Tags"
                    value={values.document_tags}
                  />
                </Grid>
              </Grid>
              <Grid container item md={12} xs={12}>
                {values.rows.map((row, index) => {
                  const selectedRegulations = values.rows
                    .filter((row) => row.regulation)
                    .map((row) => row.regulation);

                  const availableRegulations =
                    getAvailableRegulations(selectedRegulations);

                  return (
                    <Grid
                      container
                      spacing={3}
                      key={index}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item md={5} xs={5}>
                        <Typography
                          variant="subtitle1"
                          component="label"
                          style={{ marginBottom: "5px" }}
                        >
                          Select Regulation
                        </Typography>
                        <Select
                          options={availableRegulations}
                          placeholder="Select Regulation"
                          value={row.regulation}
                          onChange={(option) => {
                            const updatedRows = [...values.rows];
                            updatedRows[index].regulation = option;
                            updatedRows[index].processed = false;
                            setFieldValue("rows", updatedRows);
                          }}
                          isClearable
                        />
                        {errors.rows &&
                          errors.rows[index]?.regulation &&
                          touched.rows?.[index]?.regulation && (
                            <Typography
                              style={{
                                color: "red",
                                marginTop: "5px",
                                fontSize: "0.9rem",
                              }}
                            >
                              {errors.rows[index]?.regulation}
                            </Typography>
                          )}
                      </Grid>

                      <Grid item md={5} xs={5}>
                        <Typography
                          variant="subtitle1"
                          component="label"
                          style={{ marginBottom: "5px" }}
                        >
                          Select Document for Regulation
                        </Typography>
                        <Select
                          options={documentOptions}
                          placeholder="Select Documents"
                          value={row.document_for_regulation}
                          onChange={(options) => {
                            const updatedRows = [...values.rows];
                            updatedRows[index].document_for_regulation =
                              options;
                            setFieldValue("rows", updatedRows);
                          }}
                          isMulti
                          isClearable
                        />
                        {errors.rows &&
                          errors.rows[index]?.document_for_regulation &&
                          touched.rows?.[index]?.document_for_regulation && (
                            <Typography
                              style={{
                                color: "red",
                                marginTop: "5px",
                                fontSize: "0.9rem",
                              }}
                            >
                              {errors.rows[index]?.document_for_regulation}
                            </Typography>
                          )}
                      </Grid>
                      <Grid
                        item
                        md={1}
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleAddRow(values.rows, index, setFieldValue)
                          }
                        >
                          <Add />
                        </IconButton>
                      </Grid>

                      {index !== 0 && (
                        <Grid
                          item
                          md={1}
                          xs={2}
                          style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              handleRemoveRow(index, setFieldValue)
                            }
                          >
                            <Remove style={{ fontSize: "30px" }} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              style={{ textAlign: "right", paddingBottom: "10px" }}
              className={classes.btnWrapper}
            >
              <Typography className={classes.policyFormLable}>
                Version 1 Prediction
              </Typography>
              <Checkbox
                checked={values["version_1_prediction"]}
                disabled={isBackView}
                onChange={(e) =>
                  setFieldValue("version_1_prediction", e.target.checked)
                }
                name="checkedA"
                color="primary"
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{ marginRight: "10px" }}
                startIcon={
                  progressView ? (
                    <CircularProgress
                      style={{ width: "25px", height: "25px", color: "white" }}
                    />
                  ) : (
                    !uploadOnly && <ArrowForwardIcon />
                  )
                }
                disabled={progressView}
                sx={{
                  "&.Mui-disabled": {
                    background: "#eaeaea",
                    color: "white",
                  },
                }}
              >
                {uploadOnly ? langConstant.UPLOAD_ONLY : langConstant.NEXT}
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={handleClose}
                disabled={progressView}
              >
                {langConstant.CANCEL}
              </Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

Step1Form.propTypes = {
  uploadOnly: PropTypes.bool,
  setUploadOnly: PropTypes.func,
  handleClose: PropTypes.func,
  setProgressLogsData: PropTypes.func,
  setProgressView: PropTypes.func,
  progressView: PropTypes.bool,
  setCurrentStep: PropTypes.func,
};

export default Step1Form;
