import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import useStyles from "../styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RegulationQuestions from "./regulationQuestions";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import GettingStartedServices from "../services";
import ProgressBarLarge from "_components/ProgressBarLarge";

import { useDispatch } from "react-redux";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const PrepareDocument = ({ customerID }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [regulationSetupQuestionList, setRegulationSetupQuestionList] =
    useState([]);
  const [customerRegulationSetupStatus, setCustomerRegulationSetupStatus] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [uniqueControlGroup, setUniqueControlGroup] = useState([]);
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState({});

  const handleAnswerChange = (id, value) => {
    // setAnswers((prevAnswers) => ({
    //   ...prevAnswers,
    //   [id]: value,
    // }));
  };

  const getStatus = () => {
    dispatch(
      GettingStartedServices.getCustomerRegulationSetupStatus(
        customerID,
        setCustomerRegulationSetupStatus
      )
    );
  };
  useEffect(() => {
    const intervalID = setTimeout(() => {
      setIsLoading(false);
      setIsLoadingStatus(false);
    }, 2000);

    dispatch(
      GettingStartedServices.getCustomerRegulationSetupQuestions(
        customerID,
        setRegulationSetupQuestionList,
        setUniqueControlGroup
      )
    );
    getStatus();

    return () => clearTimeout(intervalID);
  }, []);

  return (
    <Grid container>
      <Grid item md={10} xs={8}>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "150px",
              marginRight: "250px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <RegulationQuestions
            regulatoryControlGroup={uniqueControlGroup[index]}
            questionList={
              regulationSetupQuestionList[uniqueControlGroup[index]]
            }
            answers={answers}
            setAnswers={setAnswers}
            index={index}
            totalRegulatoryControlGroup={uniqueControlGroup.length}
            setIndex={setIndex}
          />
        )}
      </Grid>
      <Grid item md={2} xs={4}>
        {isLoadingStatus && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45%",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!isLoading &&
          customerRegulationSetupStatus.length > 0 &&
          customerRegulationSetupStatus.map((reg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                // height: "100%",
              }}
            >
              <Typography className={classes.heading}>
                {reg.REGULATION_SHORT_NAME}
              </Typography>
              <Box
                style={{
                  width: "100%",
                  paddingBottom: 14,
                  marginTop: "10px",
                  marginBottom: "40px",
                }}
              >
                <ProgressBarLarge
                  percentage={parseFloat(
                    (reg.TOTAL_REGULATIONS === 0
                      ? 100
                      : (reg.TOTAL_COMPLETED_REGULATIONS /
                          reg.TOTAL_REGULATIONS) *
                        100
                    ).toFixed(2)
                  )}
                  size={"small"}
                  // color={detail.percentage ? "" : "red"}
                />
              </Box>
            </Box>
          ))}
      </Grid>
    </Grid>
  );
};

PrepareDocument.propTypes = {
  customerID: PropTypes.string,
};

export default PrepareDocument;
