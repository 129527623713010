import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Button, makeStyles } from "@material-ui/core";
import { updateAssessmentStatus } from "../utils/actions";
import AprroversDialog from "./components/ApproversDialog";
import FilterByStatus from "./components/TabFilter";
import TabPanel from "./components/TabPanel";
import TextField from "@mui/material/TextField";
import AssessmentTabs from "../component/AssessmentTabs";
import PropTypes from "prop-types";
import langConstant from "_lang";
import ProgressBarLarge from "_components/ProgressBarLarge";
import CustomizedSlider from "../component/SliderGraph";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ActionBar from "../component/ActionBar";
import SearchBar from "../component/SearchBar";
import ToolBar from "../component/ToolBar";
import CommentPanel from "../component/CommentPanel";
import ObservationDialogBox from "../component/ObservationDialogBox";
import TrustScoreDialogBox from "../component/TrustScoreDialogBox";
import TabsContent from "../component/TabsContent";
import NavigatorDrawer from "../component/NavigatorDrawer/NavigatorDrawer";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Divider from "@mui/material/Divider";
import _, { isNull } from "lodash";
import {
  getCommentsList,
  getQuestionDataQualityPoints,
  getEvidenceForMasterAssessment,
  getDataAssestsForMasterAssessment,
  UpdateAuditorQuestionStatus,
  updateRFI,
  updateQuestionStatusForRfiDialog,
  GetAuditObservationforQuestion,
  GetQuestionResponsesForAssessments,
  saveSingleQuestion,
  updateComplianceDecision,
} from "../utils/actions";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { increaseActiveStep } from "_components/Stepper/action";
import sessionService from "_services/session.service";
import moment from "moment";
import MailIcon from "@material-ui/icons/Mail";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import LockIcon from "@material-ui/icons/Lock";
import RestorePageIcon from "@material-ui/icons/RestorePage";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CheckIcon from "@material-ui/icons/Check";
import RfiDialogBox from "../component/RfiDialogBox";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import Autocomplete from "@mui/material/Autocomplete";
import storeProcedure from "./services";
import { alertActions } from "_actions";
import { RightPanelTabs } from "../component/RightPanelTabs";
import { useModal } from "_hooks";
import { AddActionDialog } from "../component/AddActionDialog";
import DashboardView from "../component/DashboardPanel";
import PlanView from "../component/Plan";
import {
  MasterAssessmentConstants,
  QUESTION_STATUS_TYPES,
} from "../utils/constants";
import getActionService from "containers/manage-regulation/Policy/PolicyExplorer/services";
import { useParams } from "react-router-dom";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles((theme) => ({
  height100: {
    height: "100%",
  },
  width100: {
    width: "100%",
  },
  marginTop: {
    marginTop: "10px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  statusicon: {
    fontSize: 20,
    marginRight: "5px",

    "&.COMPLETED": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.RFI_SENT": {
      color: "#105BA1",
    },

    "&.RFI_RESOLVED": {
      color: "#1a751a",
    },

    "&.RFI_PENDING": {
      color: "#895514",
    },

    RFI_OVERDUE: {
      color: "#d62000",
    },

    "&.SENT_BACK": {
      color: "rgba(240, 20, 47, 1)",
    },

    "&.FINAL": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.PENDING_DATA": {
      color: "rgba(187, 174, 0, 1)",
    },

    "&.READY_FOR_REVIEW": {
      color: "rgba(1, 148, 25, 1)",
    },

    "&.COMPLETED_AND_LOCKED": {
      color: "rgba(0, 159, 26, 1)",
    },
  },
}));

const initialTabs = [
  { name: langConstant.OBJECTIVES, count: 0 },
  { name: langConstant.GUIDANCE, count: 0 },
  { name: langConstant.ARCHITECTURE, count: 0 },
];

const BottomTabsArray = [
  { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
  { name: "PAST YEAR + 1", count: 0, isCount: true, id: 1 },
  { name: "PAST YEAR + 2", count: 0, isCount: true, id: 2 },
  { name: "PAST YEAR + 3", count: 0, isCount: true, id: 3 },
  { name: "DATA ASSETS", count: 0, isCount: false, id: 4 },
  { name: "EVIDENCES", count: 0, isCount: false, id: 5 },
  { name: "Responses and narratives", count: 0, isCount: true, id: 6 },
];

const CompleteAssessmentStep = ({
  TopTabsArray = [],
  QuestionList,
  ReviewScreenStatus,
  IsAudit = false,
  observationList,
  identifier,
  RfiitemsList,
  setPlanActionsDataLength,
}) => {
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState({
    name: "",
    id: 0,
  });
  const TotalFinalStatusQuestions = [...QuestionList].filter(
    (el) => el.QUESTION_STATUS === ReviewScreenStatus["Reviewed"]
  );

  const [approversDialog, setApproversDialog] = useState(false);
  const [TopSelectedTab, setTopSelectedTab] = useState(TopTabsArray[0]);
  const [BottomTabs, setBottomTabs] = useState(BottomTabsArray);
  const [BottomSelectedTab, setBottomSelectedTab] = useState(
    BottomTabsArray[0]
  );
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(QuestionList?.length);
  const [filteredQuestionList, setfilteredQuestionList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [selectedQuestionFromPlan, setSelectedQuestionFromPlan] =
    useState(null);
  const [DecisionSelectedOption, setDecisionSelectedOption] = useState(null);
  const [DecisionSelectedOption2, setDecisionSelectedOption2] = useState(null);
  const [complianceDecision, setComplianceDecision] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(
    DecisionSelectedOption?.RESPONSE_OPTION ===
      selectedQuestion?.QUESTION_COMPLIANCE_DECISION ||
      DecisionSelectedOption === null
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [score, setScore] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [ObservationDialog, setObservationDialog] = useState({
    visible: false,
    mode: "",
    ObservationList: {},
  });
  const [QuestionResponses, setQuestionResponses] = useState([]);

  const [rfiNarrative, setRfiNarrative] = useState(null);
  const [value, setValue] = React.useState(0);
  const commentsList = useSelector(
    (state) => state.MasterAssessment.AssessmentComments
  );
  const DataPointsList = useSelector(
    (state) => state.MasterAssessment.QuestionDataPoints
  );
  const EvidenceList = useSelector(
    (state) => state.MasterAssessment.EvidencesList
  );
  const DataAssetsList = useSelector(
    (state) => state.MasterAssessment.DataAssetsList
  );
  const themeDetails = useSelector(getThemeDetails);
  const ArrayOfQuestionIDOfObservations = observationList?.map((el) =>
    el.QUESTION_ID.trim()
  );
  const currentRfi = RfiitemsList?.find(
    (rfi) =>
      rfi.REGULATORS_QUESTION_REF === selectedQuestion?.REGULATORS_QUESTION_REF
  );
  const addActionModal = useModal();
  const { sid, id } = useParams();

  const { sid: SUBMISSION_ID, id: ASSESSMENT_ID } = useParams();

  const isDashboardView = Boolean(
    TopSelectedTab?.id == 0 && identifier == "myassessment"
  );
  const isPlanView = Boolean(
    TopSelectedTab?.id == 1 && identifier == "myassessment"
  );

  const actions = useSelector((state) => state?.MasterAssessment?.ActionsList),
    assessmentDetails = useSelector(
      (state) => state?.MyAssessmnetReducer?.resultSet2
    )?.[0],
    assessmentKpiData = useSelector(
      (state) => state?.MyAssessmnetReducer?.AssessmentKpiData
    )?.[0],
    approversList = useSelector(
      (state) => state?.MyAssessmnetReducer?.ApproversList
    ),
    regulatorsList = useSelector(
      (state) => state?.MyAssessmnetReducer?.RegulatorsList
    );

  const CallBack = () => {
    history.push("/my-audits");
  };

  const updateAssessmentStatusHandler = () => {
    dispatch(
      updateAssessmentStatus(
        sid,
        id,
        "PENDING_FOR_REVIEW",
        CallBack,
        "The Assessmnet is sent for Review"
      )
    );
    // dispatch(resetStepper());
  };

  const onChange = (e, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        setSelectedTab({ name: langConstant.OBJECTIVES, id: 0 });
        break;
      case 1:
        setSelectedTab({ name: langConstant.GUIDANCE, id: 1 });
        break;
      case 2:
        setSelectedTab({ name: langConstant.ARCHITECTURE, id: 2 });
        break;
    }
  };
  const StatusTagObject = {
    RFI_SENT: {
      statusBackground: "rgba(123, 192, 255, 1)",
      statusTag: langConstant.RFI_SENT,
      icon: <MailIcon className={`${classes.statusicon} RFI_SENT`} />,
    },
    RFI_PENDING: {
      statusBackground: "rgba(255, 248, 156, 1)",
      statusTag: langConstant.RFI_PENDING,
      icon: <HelpOutlineIcon className={`${classes.statusicon} RFI_PENDING`} />,
    },
    RFI_OVERDUE: {
      statusBackground: "rgba(255, 188, 188, 1)",
      statusTag: langConstant.RFI_OVERDUE,
      icon: (
        <ReportProblemIcon className={`${classes.statusicon} RFI_OVERDUE`} />
      ),
    },
    RFI_RESOLVED: {
      statusBackground: "rgba(175, 243, 187, 0.8)",
      statusTag: langConstant.RFI_RESOLVED,
      icon: (
        <CheckOutlinedIcon className={`${classes.statusicon} RFI_RESOLVED`} />
      ),
    },
    SENT_BACK: {
      statusBackground: "rgba(255, 188, 188, 1)",
      statusTag: langConstant.SENT_BACK,
      icon: <ReplyAllIcon className={`${classes.statusicon} SENT_BACK`} />,
    },
    FINAL: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.FINAL,
      icon: <ThumbUpAltIcon className={`${classes.statusicon} FINAL`} />,
    },
    PENDING_DATA: {
      statusBackground: "rgba(255, 248, 156, 1)",
      statusTag: langConstant.PENDING_DATA,
      icon: (
        <RestorePageIcon className={`${classes.statusicon} PENDING_DATA`} />
      ),
    },
    READY_FOR_REVIEW: {
      statusBackground: "rgba(175, 243, 187, 0.7)",
      statusTag: langConstant.READY_FOR_REVIEW,
      icon: (
        <FlashOnIcon className={`${classes.statusicon} READY_FOR_REVIEW`} />
      ),
    },
    COMPLETED_AND_LOCKED: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.COMPLETED_AND_LOCKED,
      icon: (
        <LockIcon className={`${classes.statusicon} COMPLETED_AND_LOCKED`} />
      ),
    },
    COMPLETED: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.COMPLETED,
      icon: <LockIcon className={`${classes.statusicon} COMPLETED`} />,
    },
  };

  const getQuestionResponse = async () => {
    const data = await dispatch(
      GetQuestionResponsesForAssessments(
        // filteredQuestionList[page]?.QUESTION_ID,
        selectedQuestion?.QUESTION_ID,
        customerID
      )
    );
    if (data?.length > 0) {
      setQuestionResponses(data);
      const tempdata = data.find(
        (el) =>
          el.RESPONSE_OPTION?.trim() ===
          filteredQuestionList[page]?.QUESTION_COMPLIANCE_DECISION?.trim()
      );

      if (tempdata) {
        setDecisionSelectedOption(tempdata);
      } else {
        const temp = {
          ACTIVE: 1,

          QUESTION_ID: selectedQuestion?.QUESTION_ID,
          RESPONSE_OPTION: complianceDecision,
        };
        setDecisionSelectedOption(temp);
      }
    } else {
      setQuestionResponses([]);
    }
  };

  useEffect(
    () => {
      setQuestionResponses([]);
      setDecisionSelectedOption(null);
      setDecisionSelectedOption2(null);
      // if (filteredQuestionList[page]?.QUESTION_ID) {
      if (selectedQuestion?.QUESTION_ID) {
        getQuestionResponse();
      }
    },
    // [page, filteredQuestionList]
    [selectedQuestion]
  );

  useEffect(() => {
    setfilteredQuestionList(QuestionList);
  }, [QuestionList]);

  useEffect(() => {
    getActions();
  }, [page]);

  useEffect(() => {
    if (filteredQuestionList.length > 0) {
      let questionText = filteredQuestionList[page]?.ARCHITECTURE_TEXT;
      const filterTabs = _.cloneDeep(initialTabs);

      //setting the values for Guidance Text, Objectives Text and Architecture Text
      if (questionText != null) {
        filterTabs[2].count = 1;
        setSelectedTab({ name: langConstant.ARCHITECTURE, id: 2 });
        setValue(2);
      }
      questionText = filteredQuestionList[page]?.GUIDANCE_TEXT;
      if (questionText != null) {
        filterTabs[1].count = 1;
        setSelectedTab({ name: langConstant.GUIDANCE, id: 1 });
        setValue(1);
      }
      questionText = filteredQuestionList[page]?.OBJECTIVE_TEXT;
      if (questionText != null) {
        filterTabs[0].count = 1;
        setSelectedTab({ name: langConstant.OBJECTIVES, id: 0 });
        setValue(0);
      }

      setTabs(filterTabs);

      setPageCount(filteredQuestionList?.length);
      setSelectedQuestion(filteredQuestionList[page]);
      setComplianceDecision(
        filteredQuestionList[page].QUESTION_COMPLIANCE_DECISION
          ? filteredQuestionList[page].QUESTION_COMPLIANCE_DECISION
          : "No Decision Selected"
      );
      setCompletedPercentage(getPercentage(QuestionList));
    }
  }, [filteredQuestionList, page]);

  useEffect(() => {
    let tempSelected = null;

    if (selectedQuestionFromPlan && selectedQuestionFromPlan?.QUESTION_ID) {
      tempSelected = { ...selectedQuestionFromPlan };
    } else {
      if (selectedQuestion && selectedQuestion?.QUESTION_ID) {
        tempSelected = { ...selectedQuestion };
      } else {
        tempSelected = filteredQuestionList[page];
      }
    }

    if (tempSelected && tempSelected.REGULATION_REFERENCE) {
      tempSelected = {
        ...tempSelected,
        QUESTION_ID: tempSelected.REGULATION_REFERENCE,
      };
    }
    if (tempSelected) {
      setSelectedQuestion(tempSelected);
      setComplianceDecision(
        tempSelected.QUESTION_COMPLIANCE_DECISION
          ? tempSelected.QUESTION_COMPLIANCE_DECISION
          : "No Decision Selected"
      );

      dispatch(
        getCommentsList(
          tempSelected.QUESTION_ID,
          tempSelected.SUBMISSION_ID,
          true
        )
      );
      dispatch(getQuestionDataQualityPoints(tempSelected.QUESTION_ID));
      dispatch(
        getEvidenceForMasterAssessment(
          tempSelected.QUESTION_ID,
          tempSelected.SUBMISSION_ID
        )
      );
      dispatch(
        getDataAssestsForMasterAssessment(tempSelected.QUESTION_ID, customerID)
      );
      tempSelected.QUESTION_ID && currentRfi?.RFI_ID && getRfiNarrativeByApi();
    }
    setSelectedQuestionFromPlan(null);
  }, [page, filteredQuestionList, currentRfi]);

  useEffect(() => {
    ScoreSetterFunc();
  }, [DataPointsList]);

  useEffect(() => {
    if (filteredQuestionList.length > 0) {
      let currQue = filteredQuestionList[page];

      let tempArr = [
        { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
        {
          name: "PAST YEAR + 1",
          count: currQue?.HIST_ANSWER_RESPONSE_01
            ? currQue?.HIST_ANSWER_MATCH_SCORE_01
            : 0,
          isCount: true,
          id: 1,
        },
        {
          name: "PAST YEAR + 2",
          count: currQue?.HIST_ANSWER_RESPONSE_02
            ? currQue?.HIST_ANSWER_MATCH_SCORE_02
            : 0,
          isCount: true,
          id: 2,
        },
        {
          name: "PAST YEAR + 3",
          count: currQue?.HIST_ANSWER_RESPONSE_03
            ? currQue?.HIST_ANSWER_MATCH_SCORE_03
            : 0,
          isCount: true,
          id: 3,
        },
        {
          name: "DATA ASSETS",
          count: DataAssetsList.length,
          isCount: false,
          id: 4,
        },
        {
          name: "EVIDENCES",
          count: EvidenceList.length,
          isCount: false,
          id: 5,
        },
        { ...BottomTabsArray?.[6], count: rfiNarrative?.length },
      ];

      setBottomTabs(tempArr);
    }
  }, [EvidenceList, DataAssetsList, page, rfiNarrative]);

  const getPercentage = (data) => {
    const total = data?.length || 0;
    if (IsAudit) {
      const completed = data.filter(
        (que) =>
          que.QUESTION_STATUS === "AUDIT_REVIEWED" ||
          que.QUESTION_STATUS === "FINAL"
      );
      if (completed.length > 0) {
        let count = (completed.length * 100) / total;
        count =
          count === 100 || count === 0 ? count : parseFloat(count.toFixed(2));
        return count;
      } else {
        return 0;
      }
    }

    if (identifier === "myassessment") {
      const completedQuestions = data?.filter(
          (q) =>
            q?.QUESTION_STATUS?.trim() === "COMPLETED" ||
            q?.QUESTION_STATUS?.trim() === "COMPLETED_AND_LOCKED"
        ),
        completionPercentage =
          (Number(completedQuestions?.length || 0) / total) * 100;
      return completionPercentage?.toFixed(2) || 0;
    }
  };

  const getProgressPercentage = (data) => {
    if (!data?.length) return 0;
    const total = data?.length || 0;
    const completedQuestions = data?.filter(
        (q) =>
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.PENDING_DATA ||
          q?.QUESTION_STATUS?.trim() ===
            QUESTION_STATUS_TYPES.READY_FOR_REVIEW ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_SENT ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_PENDING ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_OVERDUE ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_RESOLVED ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.SENT_BACK
      ),
      progressPercentage =
        (Number(completedQuestions?.length || 0) / total) * 100;
    return progressPercentage?.toFixed(2) || 0;
  };

  const ScoreSetterFunc = () => {
    if (DataPointsList.length === 0) {
      return setScore(0);
    }
    let average = 0;
    average =
      (_.sumBy(DataPointsList, "TRUST_SCORE") / DataPointsList.length / 125) *
      100;
    average = Math.round(average);
    setScore(average);
  };

  const getFilteredQuestion = (status) => {
    return [...QuestionList].filter(
      (q) => q?.QUESTION_STATUS?.trim() === status
    );
  };

  const onTopTabChange = (e, value) => {
    setTopSelectedTab(TopTabsArray[value]);
    setBottomSelectedTab(BottomTabsArray[0]);
    setPage(0);
    switch (TopTabsArray[value]["name"]) {
      case "All": {
        setfilteredQuestionList(QuestionList);
        setSelectedQuestion(QuestionList[0]);
        setComplianceDecision(
          QuestionList[0].QUESTION_COMPLIANCE_DECISION
            ? QuestionList[0].QUESTION_COMPLIANCE_DECISION
            : "No Decision Selected"
        );

        break;
      }

      case "Observation": {
        const data = [...QuestionList].filter((el) =>
          ArrayOfQuestionIDOfObservations.includes(el.QUESTION_ID.trim())
        );
        setfilteredQuestionList(data);
        setSelectedQuestion(data[0]);
        setComplianceDecision(
          data[0].QUESTION_COMPLIANCE_DECISION
            ? data[0].QUESTION_COMPLIANCE_DECISION
            : "No Decision Selected"
        );
        break;
      }

      case "Reviewed": {
        setfilteredQuestionList(getFilteredQuestion("AUDIT_REVIEWED"));
        break;
      }

      case "RFI Sent": {
        setfilteredQuestionList(getFilteredQuestion("RFI_SENT"));
        break;
      }

      case "RFI Pending": {
        setfilteredQuestionList(getFilteredQuestion("RFI_PENDING"));
        break;
      }

      case "RFI Overdue": {
        setfilteredQuestionList(getFilteredQuestion("RFI_OVERDUE"));
        break;
      }

      case "RFI RESOLVED": {
        setfilteredQuestionList(getFilteredQuestion("RFI_RESOLVED"));
        break;
      }

      case "Sent Back": {
        setfilteredQuestionList(getFilteredQuestion("SENT_BACK"));
        break;
      }

      case "Completed": {
        const data = [...QuestionList].filter(
          (el) =>
            el.QUESTION_STATUS?.trim() === "COMPLETED" ||
            el.QUESTION_STATUS?.trim() === "COMPLETED_AND_LOCKED"
        );
        setSelectedQuestion(data[0]);
        setComplianceDecision(
          data[0].QUESTION_COMPLIANCE_DECISION
            ? data[0].QUESTION_COMPLIANCE_DECISION
            : "No Decision Selected"
        );
        setfilteredQuestionList(data);
        break;
      }

      case "Open": {
        const data = [...QuestionList].filter(
          (el) =>
            el.QUESTION_STATUS?.trim() !== "COMPLETED_AND_LOCKED" &&
            el.QUESTION_STATUS?.trim() !== "COMPLETED"
        );

        setSelectedQuestion(data[0]);
        setComplianceDecision(
          data[0].QUESTION_COMPLIANCE_DECISION
            ? data[0].QUESTION_COMPLIANCE_DECISION
            : "No Decision Selected"
        );
        setfilteredQuestionList(data);
        break;
      }

      default:
        break;
    }
  };

  const showAlert = (error, type = "error") => {
    dispatch(
      alertActions?.[type](
        error?.data?.message?.map((item, index) => {
          return <span key={index}>{item}</span>;
        }) ?? error?.toString()
      )
    );
  };

  const getRfiNarrativeByApi = async () => {
    const toNumber = (num) => {
      if (!num) return null;
      return Number(num);
    };
    const payload = {
      i_RFI_ID: toNumber(currentRfi?.RFI_ID),
      i_QUESTION_ID: toNumber(
        selectedQuestion?.QUESTION_ID
          ? selectedQuestion?.QUESTION_ID
          : selectedQuestion?.REGULATION_REFERENCE
      ),
      i_SUBMISSION_ID: toNumber(selectedQuestion?.SUBMISSION_ID),
    };
    await storeProcedure
      .getRfiNarrative(payload)
      .then((response) => {
        if (response?.status !== 200) return showAlert(response);
        setRfiNarrative(response?.["#result-set-1"]);
      })
      .catch((error) => {
        showAlert(error);
        console.log("error", error);
      });
  };

  const onBottomTabChange = (e, value) => {
    setBottomSelectedTab(BottomTabsArray[value]);
  };

  const onChangePage = (value) => {
    setPage(value);
    setSelectedQuestion(filteredQuestionList[value]);
    setComplianceDecision(
      filteredQuestionList[value].QUESTION_COMPLIANCE_DECISION
        ? filteredQuestionList[value].QUESTION_COMPLIANCE_DECISION
        : "No Decision Selected"
    );
    setBottomSelectedTab(BottomTabsArray[0]);
  };

  const DrawerItemClick = (pageNumber) => {
    setTopSelectedTab(TopTabsArray[2]);

    onChangePage(pageNumber);
    setPage(pageNumber);
  };

  const OnContinueClick = () => {
    dispatch(increaseActiveStep());
  };

  const onAddAction = () => {
    addActionModal.setData(selectedQuestion);
    addActionModal.openModal();
  };

  const QuestionStatusUpdater = (status) => {
    let dataobj = {
      i_QUESTION_ID: selectedQuestion?.QUESTION_ID,
      i_SUBMISSION_ID: selectedQuestion?.SUBMISSION_ID,
      i_QUESTION_STATUS: status,
      i_USER_ID: userID,
    };
    dispatch(
      UpdateAuditorQuestionStatus(
        dataobj,
        QuestionList,
        selectedQuestion,
        () => {
          let currentQuestion = {
            ...selectedQuestion,
            QUESTION_STATUS: "AUDIT_REVIEWED",
          };
          setSelectedQuestion({
            ...selectedQuestion,
            QUESTION_STATUS: "AUDIT_REVIEWED",
          });
          const data = [...QuestionList].map((que) => {
            if (
              `${que.REGULATORS_QUESTION_REF}_${que.QUESTION_ID}` ===
              `${selectedQuestion?.REGULATORS_QUESTION_REF}_${selectedQuestion?.QUESTION_ID}`
            ) {
              return { ...currentQuestion };
            }
            return que;
          });

          UpdateMyAssessmentQuestionArray(data);
        },
        false,
        identifier
      )
    );
  };

  const CardBox = (identifier, currentQuestion) => {
    return (
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardContent style={{ padding: "5px", height: "100%" }}>
          <PerfectScrollbar>
            {identifier === 1 && (
              <div style={{ display: "flex", fontSize: "15px" }}>
                <div>
                  {" "}
                  <span>
                    {" "}
                    <strong>
                      {currentQuestion?.REGULATORS_QUESTION_REF}
                    </strong>{" "}
                    -
                  </span>
                  &nbsp;{currentQuestion?.QUESTION_TEXT}
                </div>
              </div>
            )}

            {identifier === 2 && (
              <div style={{ fontSize: "15px" }}>
                <Box pb={2}>
                  <FilterByStatus
                    selectedTab={selectedTab}
                    onChange={onChange}
                    tabs={tabs}
                  />
                </Box>{" "}
                <TabPanel value={value} index={0}>
                  {currentQuestion?.OBJECTIVE_TEXT}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {currentQuestion?.GUIDANCE_TEXT}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {currentQuestion?.ARCHITECTURE_TEXT}
                </TabPanel>
              </div>
            )}

            {identifier === 3 && (
              <div style={{ fontSize: "15px", height: "100%" }}>
                <TabsContent
                  bottomTabs={true}
                  selectedTab={BottomSelectedTab}
                  EvidenceList={EvidenceList}
                  DataAssetsList={DataAssetsList}
                  currentQuestion={selectedQuestion}
                  rfiNarrative={rfiNarrative}
                  isQuestionEditable={IsAudit ? false : true}
                  SaveAnswerFromRichTextEditor={SaveAnswerFromRichTextEditor}
                  currentRfi={currentRfi}
                  showAlert={showAlert}
                />
              </div>
            )}
          </PerfectScrollbar>
        </CardContent>
      </Card>
    );
  };

  const AuditorToolbar = () => {
    return (
      <>
        <Box style={{ width: "190px", display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={
              selectedQuestion?.QUESTION_STATUS === "AUDIT_REVIEWED" ||
              selectedQuestion?.QUESTION_STATUS === "FINAL"
            }
            color="success"
            inputProps={{ "aria-label": "Mark as Reviewed" }}
            onClick={() => {
              QuestionStatusUpdater("AUDIT_REVIEWED");
            }}
            disabled={
              selectedQuestion?.QUESTION_STATUS === "AUDIT_REVIEWED" ||
              selectedQuestion?.QUESTION_STATUS === "FINAL"
            }
          />
          <Typography>{langConstant.MARK_AS_REVIEWED}</Typography>
        </Box>

        <Box>
          {!ArrayOfQuestionIDOfObservations?.includes(
            selectedQuestion?.QUESTION_ID?.trim()
          ) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setObservationDialog({
                  visible: true,
                  mode: "add",
                  ObservationList: {},
                });
              }}
              style={{
                borderRadius: "50px",
                height: "35px",
              }}
              startIcon={<EditIcon style={{ fontSize: "20px" }} />}
            >
              ADD OBSERVATION
            </Button>
          )}

          {ArrayOfQuestionIDOfObservations?.includes(
            selectedQuestion?.QUESTION_ID?.trim()
          ) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setObservationDialog({
                  visible: true,
                  mode: "edit",
                  ObservationList: observationList.find(
                    (el) => el.QUESTION_ID === selectedQuestion?.QUESTION_ID
                  ),
                });
              }}
              style={{
                borderRadius: "50px",
                height: "35px",
              }}
              startIcon={<EditIcon style={{ fontSize: "20px" }} />}
            >
              Edit OBSERVATION
            </Button>
          )}
        </Box>
      </>
    );
  };

  const GetRfiDueDate = () => {
    const currentRfi = [...RfiitemsList].find(
      (rfi) =>
        rfi.REGULATORS_QUESTION_REF ===
        selectedQuestion?.REGULATORS_QUESTION_REF
    );

    if (currentRfi !== undefined) {
      return moment(new Date(currentRfi.RFI_DUE_DATE.slice(0, 10))).format(
        "DD-MMM-YY"
      );
    }
  };

  const GetRfiPastDueDate = () => {
    const currentRfi = [...RfiitemsList].find(
      (rfi) =>
        rfi.REGULATORS_QUESTION_REF ===
        selectedQuestion?.REGULATORS_QUESTION_REF
    );

    if (currentRfi !== undefined) {
      let now = moment(new Date()).format("DD-MMM-YY h:mm:ss a");
      let duePlusOne = new Date(currentRfi.RFI_DUE_DATE);
      duePlusOne.setDate(duePlusOne.getDate() + 1);
      let dateToCheck = moment(duePlusOne).format("DD-MMM-YY h:mm:ss a");

      if (moment(dateToCheck).isBefore(now)) {
        let pastDate = moment(dateToCheck, "DD-MMM-YY")
          .fromNow()
          .split(" ")
          .slice(0, 2)
          .join(" ");

        return (
          <Typography
            variant="caption"
            style={{ color: "#ff1616", marginTop: "8px", marginRight: "20px" }}
          >
            {pastDate}&nbsp;{langConstant.PAST_DUE_DATE}
          </Typography>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const OpenObservationDialogInViewMode = async () => {
    const obsData = await dispatch(
      GetAuditObservationforQuestion(
        selectedQuestion?.SUBMISSION_ID,
        selectedQuestion?.QUESTION_ID
      )
    );
    setObservationDialog({
      visible: true,
      mode: "view",
      ObservationList: obsData[0],
    });
  };

  const UpdateMyAssessmentQuestionArray = (data) => {
    dispatch({ type: "SET_MY_ASSESSMENT_QUESTIONS", payload: data });
  };

  const MyAssessmentCheckboxClick = (checked) => {
    let currentQuestion = { ...selectedQuestion };

    if (checked) {
      currentQuestion.QUESTION_STATE = "1";
      currentQuestion.QUESTION_STATUS = "COMPLETED";
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_STATE: "1",
        QUESTION_STATUS: "COMPLETED",
      });
    } else {
      currentQuestion.QUESTION_STATE = "0";
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_STATE: "0",
      });
    }

    const MyAssessmentCallback = () => {
      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATORS_QUESTION_REF}_${que.QUESTION_ID}` ===
          `${selectedQuestion?.REGULATORS_QUESTION_REF}_${selectedQuestion?.QUESTION_ID}`
        ) {
          return { ...currentQuestion };
        }
        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(saveSingleQuestion(currentQuestion, MyAssessmentCallback));
  };

  const SaveAnswerFromRichTextEditor = (answer) => {
    let currentQuestion = { ...selectedQuestion };
    currentQuestion.CURRENT_ANSWER_SELECTED_RESPONSE = answer;

    setSelectedQuestion({
      ...selectedQuestion,
      CURRENT_ANSWER_SELECTED_RESPONSE: answer,
      QUESTION_STATUS: "DRAFT",
    });

    const RichTextCallback = () => {
      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATORS_QUESTION_REF}_${que.QUESTION_ID}` ===
          `${selectedQuestion?.REGULATORS_QUESTION_REF}_${selectedQuestion?.QUESTION_ID}`
        ) {
          return { ...currentQuestion };
        }

        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(saveSingleQuestion(currentQuestion, RichTextCallback));
  };

  const MyAssessmentToolbar = () => {
    return (
      <>
        {selectedQuestion?.QUESTION_STATUS && (
          <Box
            wrap="nowrap"
            spacing={1}
            display={"flex"}
            justifyContent={"space-between"}
            className={`${classes.width100} ${classes.height100}`}
          >
            <Box display={"flex"}>
              {selectedQuestion?.QUESTION_STATUS !== "DRAFT" && (
                <Box
                  style={{
                    backgroundColor:
                      StatusTagObject[selectedQuestion?.QUESTION_STATUS]
                        ?.statusBackground,
                    padding: "5px 10px",
                    borderRadius: "4px",
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {StatusTagObject[selectedQuestion?.QUESTION_STATUS]?.icon}
                  <Typography variant="body2" style={{ color: "black" }}>
                    {StatusTagObject[
                      selectedQuestion?.QUESTION_STATUS
                    ]?.statusTag.toUpperCase()}
                  </Typography>
                </Box>
              )}

              {(selectedQuestion?.QUESTION_STATUS === "DRAFT" ||
                selectedQuestion?.QUESTION_STATUS === "RFI_RESOLVED" ||
                selectedQuestion?.QUESTION_STATUS === "SENT_BACK" ||
                selectedQuestion?.QUESTION_STATUS ===
                  "COMPLETED_AND_LOCKED") && (
                <Box
                  style={{
                    width: "190px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    color="success"
                    inputProps={{ "aria-label": "Mark as Completed" }}
                    checked={
                      selectedQuestion?.QUESTION_STATE === "1" ? true : false
                    }
                    onClick={(e) => {
                      setPlanActionsDataLength((length) => length - 1);
                      MyAssessmentCheckboxClick(e.target.checked);
                    }}
                  />
                  <Typography>{langConstant.MARK_AS_COMPLETED}</Typography>
                </Box>
              )}

              {selectedQuestion?.QUESTION_STATUS != "DRAFT" &&
                selectedQuestion?.QUESTION_STATUS != "RFI_RESOLVED" &&
                selectedQuestion?.QUESTION_STATUS != "SENT_BACK" &&
                selectedQuestion?.QUESTION_STATUS != "FINAL" &&
                selectedQuestion?.QUESTION_STATUS != "READY_FOR_REVIEW" &&
                selectedQuestion?.QUESTION_STATUS != "PENDING_DATA" &&
                selectedQuestion?.QUESTION_STATUS != "COMPLETED_AND_LOCKED" &&
                selectedQuestion?.QUESTION_STATUS != "COMPLETED" && (
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ color: "#777", marginRight: "10px" }}>
                      {langConstant.DUE_DATE}
                    </Typography>

                    <Typography style={{ color: "#000", marginRight: "20px" }}>
                      {GetRfiDueDate()}
                    </Typography>
                  </Box>
                )}

              {selectedQuestion?.QUESTION_STATUS == "RFI_OVERDUE" &&
                GetRfiPastDueDate()}
            </Box>

            <Box
              wrap="nowrap"
              style={{ gap: 8 }}
              spacing={1}
              display={"flex"}
              alignItems="center"
            >
              {selectedQuestion?.OBS_COUNT !== null &&
                selectedQuestion?.OBS_COUNT !== 0 &&
                selectedQuestion?.OBS_COUNT > 0 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      OpenObservationDialogInViewMode();
                    }}
                    style={{
                      borderRadius: "50px",
                      height: "35px",
                    }}
                    startIcon={<EditIcon style={{ fontSize: "20px" }} />}
                  >
                    VIEW OBSERVATION
                  </Button>
                )}

              {selectedQuestion?.QUESTION_STATUS !== "DRAFT" &&
                selectedQuestion?.QUESTION_STATUS !== "RFI_RESOLVED" &&
                selectedQuestion?.QUESTION_STATUS !== "SENT_BACK" &&
                selectedQuestion?.QUESTION_STATUS !== "FINAL" &&
                selectedQuestion?.QUESTION_STATUS !== "READY_FOR_REVIEW" &&
                selectedQuestion?.QUESTION_STATUS !== "PENDING_DATA" &&
                selectedQuestion?.QUESTION_STATUS !== "COMPLETED" &&
                selectedQuestion?.QUESTION_STATUS !==
                  "COMPLETED_AND_LOCKED" && (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{
                      borderRadius: "50px",
                      height: "35px",
                    }}
                    onClick={() => {
                      dispatch(
                        confirmationDialogActions.open({
                          message: langConstant.RESOLVE_RFI_PROMPT_FN(
                            selectedQuestion?.REGULATORS_QUESTION_REF
                          ),
                          onConfirm: () => {
                            dispatch(
                              updateRFI(
                                selectedQuestion,
                                "RFI_RESOLVED",
                                moment(new Date()).format(
                                  "yyyy-MM-DD hh:mm:ss"
                                ),
                                null,
                                null,
                                RfiitemsList,
                                currentRfi?.RFI_ID
                              )
                            );
                            dispatch(
                              updateQuestionStatusForRfiDialog(
                                selectedQuestion?.QUESTION_ID,
                                selectedQuestion?.SUBMISSION_ID,
                                "RFI_RESOLVED",
                                userID
                              )
                            );
                            const data = [...QuestionList].map((el) => {
                              if (
                                `${el.REGULATORS_QUESTION_REF}_${el.QUESTION_ID}` ===
                                `${selectedQuestion?.REGULATORS_QUESTION_REF}_${selectedQuestion?.QUESTION_ID}`
                              ) {
                                return {
                                  ...el,
                                  QUESTION_STATUS: "RFI_RESOLVED",
                                };
                              }

                              return el;
                            });

                            UpdateMyAssessmentQuestionArray(data);
                          },
                          title: langConstant.RESOLVE_RFI_CONFIRMATION,
                          option1: langConstant.YES_PLEASE,
                          option2: langConstant.NO_THANKS,
                        })
                      );
                    }}
                  >
                    <CheckIcon
                      style={{ fontSize: "20px", marginRight: "10px" }}
                    />
                    {langConstant.RESOLVE_RFI}
                  </Button>
                )}
              <Button
                onClick={onAddAction}
                style={{ borderRadius: "50px", height: "35px" }}
                variant="contained"
                color="primary"
                title="Add Action"
              >
                Add Action
              </Button>

              <RfiDialogBox questionData={selectedQuestion} />
            </Box>
          </Box>
        )}
      </>
    );
  };

  const saveComplianceDecision = () => {
    const complainceCallback = () => {
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_COMPLIANCE_DECISION: DecisionSelectedOption2
          ? DecisionSelectedOption2
          : DecisionSelectedOption?.RESPONSE_OPTION
          ? DecisionSelectedOption?.RESPONSE_OPTION
          : complianceDecision,
      });

      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATORS_QUESTION_REF}_${que.QUESTION_ID}` ===
          `${selectedQuestion?.REGULATORS_QUESTION_REF}_${selectedQuestion?.QUESTION_ID}`
        ) {
          return {
            ...que,
            QUESTION_COMPLIANCE_DECISION: DecisionSelectedOption2
              ? DecisionSelectedOption2
              : DecisionSelectedOption?.RESPONSE_OPTION
              ? DecisionSelectedOption?.RESPONSE_OPTION
              : complianceDecision,
          };
        }
        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(
      updateComplianceDecision(
        selectedQuestion,
        DecisionSelectedOption2
          ? DecisionSelectedOption2
          : DecisionSelectedOption?.RESPONSE_OPTION
          ? DecisionSelectedOption?.RESPONSE_OPTION
          : complianceDecision,
        complainceCallback
      )
    );
  };

  const getActions = async () => {
    if (!SUBMISSION_ID || !userID) return;
    await getActionService
      ?.getActions({
        i_SUBMISSION_ID: SUBMISSION_ID,
        i_USER_ID: userID,
        i_QUESTION_ID: !isDashboardView
          ? selectedQuestion?.QUESTION_ID ||
            filteredQuestionList[page]?.QUESTION_ID
          : null,
      })
      .then((response) => {
        if (response?.status !== 200)
          return showAlert(response || "Something went wrong");
        dispatch({
          type: MasterAssessmentConstants.SET_ACTIONS_LIST,
          payload: response?.["#result-set-1"],
        });
      })
      .catch((error) => {
        console.error("error", error);
        showAlert(error);
      });
  };

  return (
    <>
      <AddActionDialog addActionModal={addActionModal} />
      <Box
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ width: isDashboardView || isPlanView ? "100%" : "72%" }}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <AssessmentTabs
              selectedTab={TopSelectedTab}
              onChange={onTopTabChange}
              tabs={TopTabsArray}
              currentQuestion={selectedQuestion}
              topTabs={true}
            />
            {!isDashboardView && !isPlanView && (
              <SearchBar
                QuestionList={filteredQuestionList}
                setPage={setPage}
                setSelectedQuestion={setSelectedQuestion}
              />
            )}
          </Box>

          {!isDashboardView && !isPlanView && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "160px",
              }}
              className={classes.marginTop}
            >
              <Box style={{ width: "55%" }}>{CardBox(1, selectedQuestion)}</Box>
              <Box style={{ width: "44%" }}>{CardBox(2, selectedQuestion)}</Box>
            </Box>
          )}
          {isDashboardView && (
            <DashboardView
              questionList={QuestionList}
              getActions={getActions}
              kpiData={{
                completedPercentage,
                progress: getProgressPercentage(QuestionList),
              }}
              questionResponses={QuestionResponses}
              assessmentId={ASSESSMENT_ID}
              submissionId={SUBMISSION_ID}
              actions={actions}
              assessmentDetails={assessmentDetails}
              assessmentKpiData={assessmentKpiData}
              approversList={approversList}
              regulatorsList={regulatorsList}
            />
          )}
          {isPlanView && (
            <PlanView
              QuestionList={[...QuestionList].filter(
                (el) =>
                  el.QUESTION_STATUS?.trim() !== "COMPLETED_AND_LOCKED" &&
                  el.QUESTION_STATUS?.trim() !== "COMPLETED"
              )}
              observationList={observationList}
              RfiitemsList={RfiitemsList}
              identifier={identifier}
              submissionId={SUBMISSION_ID}
              assessmentDetails={assessmentDetails}
              TopTabsArray={TopTabsArray}
              setSelectedQuestionFromPlan={setSelectedQuestionFromPlan}
              DrawerItemClick={DrawerItemClick}
              setTopSelectedTab={setTopSelectedTab}
              onTopTabChange={onTopTabChange}
              setPage={setPage}
              setPlanActionsDataLength={setPlanActionsDataLength}
              IsAudit={IsAudit}
              getActions={getActions}
            />
          )}

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 42,
            }}
            className={classes.marginTop}
          >
            {identifier === "audit" && AuditorToolbar()}
            {identifier === "myassessment" &&
              !isDashboardView &&
              !isPlanView &&
              MyAssessmentToolbar()}
          </Box>

          {!isDashboardView && !isPlanView && (
            <>
              {" "}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  alignItems: "center",
                }}
                className={classes.marginTop}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "40%",
                  }}
                >
                  <Box>
                    <Typography>Decision : </Typography>
                  </Box>
                  <Box style={{ width: "60%" }}>
                    {(IsAudit || QuestionResponses.length == 0) && (
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setIsSaveDisabled(false);
                          setComplianceDecision(e.target.value);
                        }}
                        value={complianceDecision}
                        className={classes.width100}
                        inputProps={{ readOnly: false }}
                      />
                    )}
                    {!IsAudit && QuestionResponses.length > 0 && (
                      <Autocomplete
                        freeSolo
                        size="small"
                        className={`${classes.width100}`}
                        autoHighlight
                        openOnFocus
                        id="checkboxes-tags-demo"
                        options={QuestionResponses}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search"
                            onChange={(e) => {
                              setIsSaveDisabled(false);

                              setDecisionSelectedOption2(e.target.value);
                            }}
                          />
                        )}
                        getOptionLabel={(option) =>
                          option.RESPONSE_OPTION ? option.RESPONSE_OPTION : ""
                        }
                        onChange={(e, val) => {
                          setIsSaveDisabled(false);
                          setDecisionSelectedOption(val);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.RESPONSE_OPTION?.trim() ===
                          value?.RESPONSE_OPTION?.trim()
                        }
                        value={DecisionSelectedOption}
                        disabled={
                          selectedQuestion?.QUESTION_STATUS ===
                          "COMPLETED_AND_LOCKED"
                        }
                      />
                    )}
                  </Box>

                  <Box style={{ width: "17%" }}>
                    {!IsAudit && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<SaveIcon />}
                        onClick={saveComplianceDecision}
                        disabled={isSaveDisabled}
                      >
                        {langConstant.SAVE}
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box
                  style={{ height: "85px", width: "35%", marginRight: "5px" }}
                >
                  <Box
                    style={{
                      height: "60px",
                      display: "flex",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    <CustomizedSlider score={score} />
                  </Box>

                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>Data Trust Score</Box>

                    {DataPointsList.length > 0 && (
                      <Box
                        className="linkIcon"
                        style={{ display: "flex", justifyContent: "end" }}
                        onClick={() => {
                          setOpenPopup(true);
                        }}
                      >
                        {langConstant.VIEW_DETAIL}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box>
                <AssessmentTabs
                  selectedTab={BottomSelectedTab}
                  onChange={onBottomTabChange}
                  tabs={BottomTabs}
                  bottomTabs={true}
                  currentQuestion={selectedQuestion}
                />
              </Box>
              <Box style={{ height: "calc(100vh - 611px)" }}>
                <Box style={{ width: "100%", height: "100%" }}>
                  {CardBox(3)}
                </Box>
              </Box>
            </>
          )}
        </Box>

        {!isDashboardView && !isPlanView && (
          <>
            <Divider
              style={{ height: "calc(100vh - 197px)" }}
              orientation="vertical"
              flexItem
            />
            <Box style={{ width: "25%" }}>
              <Box className={classes.marginTop}>
                <ProgressBarLarge
                  percentage={parseInt(completedPercentage)}
                  size="small"
                />
              </Box>

              <Box className={classes.marginTop}>
                <ToolBar selectedQuestion={selectedQuestion} />
              </Box>

              <RightPanelTabs
                commentsList={commentsList}
                selectedQuestion={selectedQuestion}
                getActions={getActions}
              />
            </Box>
          </>
        )}
      </Box>
      <TrustScoreDialogBox
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        DataQualityProfile={DataPointsList}
      />
      <NavigatorDrawer
        QuestionList={filteredQuestionList}
        DrawerItemClick={DrawerItemClick}
        setPage={setPage}
      />
      <ActionBar
        onChangePage={onChangePage}
        page={page}
        pageCount={pageCount}
        isPagination={!isDashboardView && !isPlanView ? true : false}
        PrimaryBtn={{
          btnicon: <SubdirectoryArrowRightIcon />,
          // btntext: langConstant.CONTINUE,
          btntext: langConstant.SUBMIT_FOR_REVIEW,
          // onbtnclick: OnContinueClick,
          onbtnclick: () => {
            // updateAssessmentStatusHandler();
            setApproversDialog(true);
          },
          btndisabled: parseInt(completedPercentage) !== 100,
        }}
      />
      {ObservationDialog.visible &&
        (identifier === "audit" || identifier === "myassessment") && (
          <ObservationDialogBox
            Controls={selectedQuestion?.CISO_CONTROL_NAME}
            submissionID={selectedQuestion?.SUBMISSION_ID}
            questionID={selectedQuestion?.QUESTION_ID}
            questionText={selectedQuestion?.QUESTION_TEXT}
            miscData={ObservationDialog}
            setObservationDialog={setObservationDialog}
            TotalObservationList={observationList}
          />
        )}
      {approversDialog && (
        <AprroversDialog
          TotalFinalStatusQuestions={TotalFinalStatusQuestions}
          status="PENDING_FOR_REVIEW"
        />
      )}
    </>
  );
};

CompleteAssessmentStep.propTypes = {
  TopTabsArray: PropTypes.array,
  QuestionList: PropTypes.array,
  ReviewScreenStatus: PropTypes.object,
  IsAudit: PropTypes.bool,
  observationList: PropTypes.array,
  identifier: PropTypes.string,
  RfiitemsList: PropTypes.array,
  setPlanActionsDataLength: PropTypes.func,
};

export default CompleteAssessmentStep;
