/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Typography,
  DialogActions,
  TextField,
} from "@material-ui/core";
import langConstant from "_lang";
import FilterByStatus from "_components/FilterByStatusTab";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useDispatch, useSelector } from "react-redux";
import rootStyles from "rootStyles";
import approveRegulationAction from "../actions";
import SingleQuestion from "./SingleQuestion";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import AnchorSideNavigation from "_components/AnchorSideNavigation";
import DialogBox from "_components/DialogBox";
const ReviewStep = ({
  draftId,
  questionList,
  draftList,
  assessmentObj,
  roleBasedConstant,
  selectQuestion,
}) => {
  const dispatch = useDispatch();
  const rootClasses = rootStyles();
  const initialTabs = [
    { name: langConstant.ALL, count: 0 },
    { name: langConstant.SENT_BACK, count: 0 },
    { name: langConstant.REVIEWED, count: 0 },
  ];
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState({ name: "", id: 0 });
  const [checkedAll, setCheckedAll] = useState(false);
  const [listByCategory, setListByCategory] = useState({});
  const [categoryAnchor, setCategoryAnchor] = useState([]);
  const [sendBackDialog, setSendBackDialog] = useState(false);
  const [remarkError, setRemarkError] = useState(false);
  const [user_ID, setUserID] = useState(null);
  const [teamleader_ID, setTeamleaderID] = useState(null);
  const [manager_ID, setManagerID] = useState(null);
  const sendBackRemark = useRef();

  const userID = useSelector((state) => state.approveRegulationDetails.USER_ID);
  const teamleaderID = useSelector(
    (state) => state.approveRegulationDetails.TL_ID
  );
  const managerID = useSelector(
    (state) => state.approveRegulationDetails.MANAGER_ID
  );

  useEffect(() => {
    setUserID(userID);
    setTeamleaderID(teamleaderID);
    setManagerID(managerID);
  }, [questionList]);

  const onChange = (e, newValue) => {
    switch (newValue) {
      case 1:
        setSelectedTab({ name: "SENT_BACK", id: 1 });
        // unselectAllQuestion();
        updateAnchor(questionList, "SENT_BACK");
        break;
      case 2:
        setSelectedTab({
          name: roleBasedConstant.questionReviewedStatus,
          id: 2,
        });
        // unselectAllQuestion();
        updateAnchor(questionList, roleBasedConstant.questionReviewedStatus);
        break;
      default:
        setSelectedTab({ name: "", id: 0 });
        updateAnchor(questionList);
    }
  };

  const unselectAllQuestion = () => {
    const newList = draftList.map((item) => {
      if (item.selected) {
        let newItem = item;
        newItem.selectedForReview = false;
        return newItem;
      } else {
        return item;
      }
    });

    setCheckedAll(false);
    dispatch(
      approveRegulationAction.updateMultipleItemsInDraft(
        newList,
        user_ID,
        teamleader_ID,
        manager_ID
      )
    );
  };

  const selectAllQuestion = (e) => {
    const val = e.target.checked;

    const newList = draftList.map((item) => {
      if (item.selected) {
        if (selectedTab.name) {
          if (item.status == selectedTab.name) {
            let newItem = item;
            newItem.selectedForReview = val;
            return newItem;
          } else {
            return item;
          }
        } else {
          let newItem = item;
          newItem.selectedForReview = val;
          return newItem;
        }
      } else {
        return item;
      }
    });

    setCheckedAll(e.target.checked);
    dispatch(
      approveRegulationAction.updateMultipleItemsInDraft(
        newList,
        user_ID,
        teamleader_ID,
        manager_ID
      )
    );
  };
  const onSendBack = () => {
    let updatedDraft = [];
    const remark = sendBackRemark.current;
    if (remark.value != "") {
      setRemarkError(false);
      updatedDraft = draftList.map((item) => {
        if (item.selectedForReview) {
          let newItem = item;
          newItem.status = "SENT_BACK";
          newItem.selectedForReview = false;
          newItem.remark = remark.value;
          return newItem;
        } else {
          return item;
        }
      });
      setCheckedAll(false);
      dispatch(
        approveRegulationAction.updateDraft(
          draftId,
          updatedDraft,
          user_ID,
          teamleader_ID,
          manager_ID,
          0,
          langConstant.SELECTED_QUESTION_SENT_BACK
        )
      );
      handleSendBackDialogClose();
    } else {
      setRemarkError(true);
    }
  };
  const onMarkAsReviewed = () => {
    let updatedDraft = [];

    updatedDraft = draftList.map((item) => {
      if (item.selectedForReview) {
        let newItem = item;
        newItem.status = "FINAL";
        newItem.selectedForReview = false;

        return newItem;
      } else {
        return item;
      }
    });

    setCheckedAll(false);
    dispatch(
      approveRegulationAction.updateDraft(
        draftId,
        updatedDraft,
        user_ID,
        teamleader_ID,
        manager_ID,
        0,
        langConstant.SELECTED_QUESTION_REVIEWED
      )
    );
  };
  const printSingleQuestion = (data) => {
    return data.map((item) => {
      if (item.status == selectedTab.name && selectedTab != "") {
        return (
          <SingleQuestion
            key={item.id}
            item={item}
            assessmentObj={assessmentObj}
            roleBasedConstant={roleBasedConstant}
            selectQuestion={selectQuestion}
          />
        );
      }
      if (selectedTab.name == "") {
        return (
          <SingleQuestion
            key={item.id}
            item={item}
            assessmentObj={assessmentObj}
            roleBasedConstant={roleBasedConstant}
            selectQuestion={selectQuestion}
          />
        );
      }
    });
  };
  const updateAnchor = (list, tabName = "") => {
    const groupByCategory = {};
    const anchor = [];
    const tempKey = {};

    list.forEach((item) => {
      if (tabName) {
        if (tabName == item.status) {
          if (groupByCategory[item.category]) {
            groupByCategory[item.category].push(item);
          } else {
            groupByCategory[item.category] = [];
            groupByCategory[item.category].push(item);
          }
          if (tempKey[item.category] == undefined) {
            tempKey[item.category] = "";
            anchor.push({ title: item.category, id: "_" + item.id });
          }
        }
      } else {
        if (groupByCategory[item.category]) {
          groupByCategory[item.category].push(item);
        } else {
          groupByCategory[item.category] = [];
          groupByCategory[item.category].push(item);
        }
        if (tempKey[item.category] == undefined) {
          tempKey[item.category] = "";
          anchor.push({ title: item.category, id: "_" + item.id });
        }
      }
    });
    const sortedAnchor = _.sortBy(anchor, "title");

    const sortedGroupByCategory = {};
    _(groupByCategory)
      .keys()
      .sort()
      .each(function (key) {
        sortedGroupByCategory[key] = groupByCategory[key];
      });

    // Replacing "null" category with "Uncategorized"

    const anchorToSet = sortedAnchor.map((anchor) => {
      if (anchor.title == null || anchor.title == "") {
        return { title: langConstant.UNCATEGORIZED, id: anchor.id };
      } else {
        return anchor;
      }
    });

    const uniqAnchor = _.uniq(anchorToSet, "title");

    if (
      sortedAnchor[sortedAnchor.length - 1].title == null ||
      sortedAnchor[sortedAnchor.length - 1].title == ""
    ) {
      sortedAnchor[sortedAnchor.length - 1].title = langConstant.UNCATEGORIZED;
    }

    setListByCategory(sortedGroupByCategory);
    setCategoryAnchor(anchorToSet);
    // console.log("sortedAnchor", sortedAnchor);
    // console.log("anchor to set", anchorToSet);
    // console.log("uniq", uniqAnchor);
  };

  const handleSendBackDialogOpen = () => {
    setSendBackDialog(true);
    setRemarkError(false);
  };
  const handleSendBackDialogClose = () => {
    setSendBackDialog(false);
  };
  useEffect(() => {
    const filterTabs = _.cloneDeep(initialTabs);
    const FINAL = _.filter(questionList, {
      status: roleBasedConstant.questionReviewedStatus,
    });

    const SENT_BACK = _.filter(questionList, { status: "SENT_BACK" });
    filterTabs[0].count = questionList.length;
    filterTabs[1].count = SENT_BACK.length;
    filterTabs[2].count = FINAL.length;
    var matched = filterTabs.filter(
      (e) => _.filter(tabs, { name: e.name, count: e.count }).length == 0
    );
    if (matched.length > 0) {
      setTabs(filterTabs);
    }
    if (
      (selectedTab.name == "SENT_BACK" &&
        !_.some(questionList, { status: selectedTab.name })) ||
      (selectedTab.name == roleBasedConstant.questionReviewedStatus &&
        !_.some(questionList, { status: selectedTab.name }))
    ) {
      onChange();
    } else {
      updateAnchor(questionList);
    }
  }, [questionList]);
  return (
    <>
      <Box pb={2}>
        <FilterByStatus
          selectedTab={selectedTab}
          onChange={onChange}
          tabs={tabs}
        />
      </Box>{" "}
      {categoryAnchor.length > 1 && selectedTab.name == "" && (
        <AnchorSideNavigation anchors={categoryAnchor} currentAnchor={2} />
      )}
      <Box
        mt={2}
        style={
          categoryAnchor.length > 1 && selectedTab.name == ""
            ? { paddingRight: "200px" }
            : null
        }
      >
        <Box mb={2}>
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              {/* {selectedTab.name != roleBasedConstant.questionReviewedStatus && ( */}
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    checked={checkedAll}
                    color="default"
                    inputProps={{
                      "aria-label": langConstant.SELECT_ALL_QUESTIONS,
                    }}
                    onChange={selectAllQuestion}
                    className={rootClasses.greenCheckbox}
                    name="checkedAll"
                    id="checkedAll"
                  />
                }
                label={langConstant.SELECT_ALL}
                labelPlacement="end"
              />
              {/* )} */}
            </Grid>
            <Grid item>
              {selectedTab.name != "SENT_BACK" && (
                <Box style={{ display: "inline-block" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    style={{ marginRight: "20px", borderRadius: "30px" }}
                    startIcon={<ReplyAllIcon />}
                    onClick={() => {
                      handleSendBackDialogOpen();
                    }}
                  >
                    {langConstant.SEND_BACK}
                  </Button>
                  <DialogBox
                    title={langConstant.SEND_BACK_CONFIRMATION}
                    open={sendBackDialog}
                    handleClose={handleSendBackDialogClose}
                    maxWidth={"sm"}
                  >
                    <Typography
                      align="center"
                      gutterBottom
                      style={{ textAlign: "left" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          langConstant.SEND_BACK_QUESTIONS_CONFIRMATION_FN(),
                      }}
                    ></Typography>
                    <Box mt={2}>
                      <Typography variant={"body2"}>
                        {langConstant.ADD_REMARKS} <span>*</span>
                      </Typography>
                      <TextField
                        inputRef={sendBackRemark}
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="sendBackRemarks"
                        label=""
                        name="sendBackRemarks"
                        multiline
                        placeholder=""
                        rows={4}
                        rowsMax={Infinity}
                        error={remarkError}
                      />
                    </Box>
                    <Box align="center">
                      <DialogActions style={{ justifyContent: "center" }}>
                        <Button
                          onClick={onSendBack}
                          color="primary"
                          size="medium"
                          autoFocus
                        >
                          {langConstant.YES_PLEASE}
                        </Button>
                        <Button
                          onClick={handleSendBackDialogClose}
                          color="primary"
                          size="medium"
                        >
                          {langConstant.NO_THANKS}
                        </Button>
                      </DialogActions>
                    </Box>
                  </DialogBox>
                </Box>
              )}
              {selectedTab.name != roleBasedConstant.questionReviewedStatus &&
                tabs[2].count != questionList.length && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    style={{ borderRadius: "30px" }}
                    startIcon={<DoneAllIcon />}
                    onClick={() => {
                      dispatch(
                        confirmationDialogActions.open({
                          message: langConstant.CONFIRM_REVIEWED_QUESTIONS_FN(),
                          onConfirm: onMarkAsReviewed,
                          title: langConstant.REVIEW_QUESTION_CONFIRMATION,
                          option1: langConstant.YES_PLEASE,
                          option2: langConstant.NO_THANKS,
                        })
                      );
                    }}
                  >
                    {langConstant.MARK_AS_REVIEWED}
                  </Button>
                )}
            </Grid>
          </Grid>
        </Box>
        {Object.keys(listByCategory).length > 0
          ? Object.keys(listByCategory).map((key, parentIndex) => {
              {
                return listByCategory[key].length > 0 ? (
                  <Box key={parentIndex}>
                    {_.filter(listByCategory[key], { status: selectedTab.name })
                      .length > 0 &&
                      selectedTab.name != "" && (
                        <Typography
                          className={rootClasses.catTitle}
                          component="h2"
                          id={"_" + listByCategory[key][0].id}
                        >
                          {key == "null" || key == ""
                            ? langConstant.UNCATEGORIZED
                            : key}
                        </Typography>
                      )}

                    {selectedTab.name == "" && (
                      <Typography
                        className={rootClasses.catTitle}
                        component="h2"
                        id={"_" + listByCategory[key][0].id}
                      >
                        {key == "null" || key == ""
                          ? langConstant.UNCATEGORIZED
                          : key}
                      </Typography>
                    )}
                    {printSingleQuestion(listByCategory[key])}
                  </Box>
                ) : null;
              }
            })
          : null}
      </Box>
    </>
  );
};
ReviewStep.propTypes = {
  draftId: PropTypes.string,
  questionList: PropTypes.array,
  draftList: PropTypes.array,
  assessmentObj: PropTypes.object,
  roleBasedConstant: PropTypes.object,
  selectQuestion: PropTypes.func,
};
export default ReviewStep;
