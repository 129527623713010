import {
  Box,
  Typography,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PolicyMappingActions from "../../ManageQuestions/actions";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SentenceMappingDialog from "./SentenceMapping";
import SentenceMappingService from "../services";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
}));

const PolicyMappingTable = ({
  regulationID,
  userID,
  customerID,
  questionID,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [policyMapDialog, setPolicyMapDialog] = useState(false);
  const [policyMappingData, setPolicyMappingData] = useState([]);
  const [sentenceMappingData, setSentenceMappingData] = useState([]);
  const [sentenceView, setSentenceView] = useState({
    selectedPolicyId: "",
    isSentenceView: false,
  });

  const fetchPolicyMappingData = async () => {
    const payload = {
      i_REGULATION_ID: regulationID,
      i_USER_ID: userID,
      i_CUSTOMER_ID: customerID,
    };
    await dispatch(
      PolicyMappingActions.getRegulationPolicyMap(payload, setPolicyMappingData)
    );
  };

  const fetchSentenceMappingData = async () => {
    const payload = {
      i_REGULATION_ID: regulationID,
      i_QUESTION_ID: questionID,
      i_CUSTOMER_ID: customerID,
    };
    await dispatch(
      SentenceMappingService.getQuestionPolicyMap(
        payload,
        setSentenceMappingData
      )
    );
  };

  useEffect(() => {
    (async () => {
      await fetchPolicyMappingData();
      await fetchSentenceMappingData();
    })();
  }, [sentenceView]);

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className={classes.filedTitle}>
          {langConstant.POLICY_MAPPING}
        </Typography>
        <AddIcon
          color="success"
          sx={{ fontSize: "26px", cursor: "pointer" }}
          onClick={() => {
            setSentenceView({ selectedPolicyId: null, isSentenceView: false });
            setPolicyMapDialog(true);
          }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label={langConstant.POLICY_MAPPING} className="data-table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                {langConstant.POLICY_DETAILS}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ textAlign: "right" }}
              >
                {langConstant.ACTIONS}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} style={{ maxHeight: "26vh" }}>
        <Table className="data-table">
          <TableBody>
            {policyMappingData.map((policy, index) => {
              const isAvailable = sentenceMappingData.some(
                (sentence) => sentence.POLICY_ID == policy.POLICY_ID
              );
              return (
                <React.Fragment key={index}>
                  {isAvailable && (
                    <TableRow>
                      <TableCell component="td" scope="row">
                        {policy.POLICY_NAME}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        style={{ textAlign: "right" }}
                      >
                        <VisibilityIcon
                          fontSize="small"
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => {
                            setSentenceView({
                              selectedPolicyId: policy.POLICY_ID,
                              isSentenceView: true,
                            });
                            setPolicyMapDialog(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {policyMapDialog && (
        <SentenceMappingDialog
          show={policyMapDialog}
          setShow={setPolicyMapDialog}
          policyMappingData={policyMappingData}
          regulationID={regulationID}
          questionID={questionID}
          sentenceMappingData={sentenceMappingData}
          sentenceView={sentenceView}
          setSentenceView={setSentenceView}
          setSentenceMappingData={setSentenceMappingData}
        />
      )}
    </>
  );
};

PolicyMappingTable.propTypes = {
  regulationID: PropTypes.string,
  userID: PropTypes.string,
  customerID: PropTypes.string,
  questionID: PropTypes.string,
};

export default PolicyMappingTable;
