/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import FilterByStatus from "./../../../../../_components/MasterAssessmentComp/steps/components/TabFilter";
import TabPanel from "./../../../../../_components/MasterAssessmentComp/steps/components/TabPanel";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SaveIcon from "@material-ui/icons/Save";
import rootStyles from "rootStyles";
import { useModal } from "_hooks";

import langConstant from "_lang";
import initiateAssessmentAction from "./../actions";
import evidenceAction from "../../../../../_components/EvidenceLocker/actions";
import individualQuestionAction from "../../../../manage-regulation/NewRegulation/IndividualQuestion/actions";
import CheckIcon from "@material-ui/icons/Check";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import moment from "moment";
import styles from "../styles";
import DataPointQuality from "_components/DataPointQuality";
import Truncate from "_components/Truncate";
import CurrentAnswerData from "./CurrentAnswerData";
import sessionService from "_services/session.service";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardContent } from "@mui/material";
import classNames from "classnames";
import { AddActionDialog } from "_components/MasterAssessmentComp/component/AddActionDialog";

const useStyles = makeStyles(() => ({
  marginTop: {
    margin: "10px 0",
  },
}));

const SelectAnswer = (props) => {
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const rootClasses = rootStyles();
  const dispatch = useDispatch();
  const addActionModal = useModal();

  const customerID = sessionService.getCustomerId();

  const { questionData, onRfiCancellation } = props;
  const { REGULATORS_QUESTION_REF } = questionData;
  const [DecisionSelectedOption, setDecisionSelectedOption] = useState(null);
  const [DecisionSelectedOption2, setDecisionSelectedOption2] = useState(null);
  const [complianceDecision, setComplianceDecision] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(
    DecisionSelectedOption?.RESPONSE_OPTION ===
      questionData?.QUESTION_COMPLIANCE_DECISION ||
      DecisionSelectedOption === null
  );
  let dueDate = "";
  let pastDate = "";
  let currentRfi = [];
  let currentQuestion = [];
  let dateInPast = false;

  const [value, setValue] = React.useState(0);

  const [selectedTab, setSelectedTab] = useState({
    name: "",
    id: 0,
  });

  const initialTabs = [
    { name: langConstant.OBJECTIVES, count: 0 },
    { name: langConstant.GUIDANCE, count: 0 },
    { name: langConstant.ARCHITECTURE, count: 0 },
  ];

  const [tabs, setTabs] = useState(initialTabs);

  const onChange = (e, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 1:
        setSelectedTab({ name: langConstant.GUIDANCE, id: 1 });
        break;
      case 2:
        setSelectedTab({ name: langConstant.ARCHITECTURE, id: 2 });
        break;
      default:
        setSelectedTab({ name: langConstant.OBJECTIVES, id: 0 });
    }
  };
  const allRfi = useSelector(
    (state) => state.vendorRiskManagementRfiDetails.rfiItems
  );
  const evidenceList = useSelector((state) => state.evidenceLocker.evidence);
  const vrmAssesmentDetails = useSelector(
    (state) => state.vendorRiskManagementAssesmentDetails
  );
  const questionResponses = useSelector(
    (state) => state.manageIndividualQuestion.questionResponses
  );
  const allQuestions = vrmAssesmentDetails.questionList;
  currentQuestion =
    allQuestions?.length &&
    allQuestions.filter(
      (question) => question.REGULATORS_QUESTION_REF == REGULATORS_QUESTION_REF
    );
  const dqProfile = vrmAssesmentDetails.dqProfile;
  let QUESTION_STATUS;
  const dataAssetsList = vrmAssesmentDetails.dataAssetsList;

  if (currentQuestion?.length && currentQuestion[0]) {
    QUESTION_STATUS = currentQuestion[0].QUESTION_STATUS;
  }

  if (allRfi?.length) {
    currentRfi = allRfi.filter(
      (rfi) => rfi.REGULATORS_QUESTION_REF == REGULATORS_QUESTION_REF
    );
  }

  if (currentRfi.length != 0) {
    dueDate = moment(new Date(currentRfi[0].RFI_DUE_DATE.slice(0, 10))).format(
      "DD-MMM-YY"
    );

    let now = moment(new Date()).format("DD-MMM-YY h:mm:ss a");
    let duePlusOne = new Date(currentRfi[0].RFI_DUE_DATE);
    duePlusOne.setDate(duePlusOne.getDate() + 1);
    let dateToCheck = moment(duePlusOne).format("DD-MMM-YY h:mm:ss a");

    if (moment(dateToCheck).isBefore(now)) {
      dateInPast = true;
      pastDate = moment(dateToCheck, "DD-MMM-YY")
        .fromNow()
        .split(" ")
        .slice(0, 2)
        .join(" ");
    } else {
      dateInPast = false;
    }
  }
  const [questionState, setQuestionState] = useState(
    questionData.QUESTION_STATUS === "ACCEPTED" ? true : false
  );
  const [highestRating, setHighestRating] = useState(0);

  const [tabsData, setTabsData] = useState({
    dataAssetsList: dataAssetsList ? dataAssetsList : [],
    evidenceList: evidenceList ? evidenceList : [],
  });
  const [option, setOption] = React.useState("");

  const returnHighestRating = (data) => {
    return data.matches.reduce(
      (max, p) => (p.score > max ? p.score : max),
      data.currentAnswerScore
    );
  };
  const goBackOnError = () => {};
  const markCompleted = (e) => {
    setQuestionState(e.target.checked);
    dispatch(
      initiateAssessmentAction.updateQuestionStatus(
        questionData.QUESTION_ID,
        questionData.SUBMISSION_ID,
        e.target.checked ? "ACCEPTED" : "DRAFT",
        goBackOnError,
        userID
      )
    );
  };

  const onAddAction = () => {
    addActionModal.setData(questionData);
    addActionModal.openModal();
  };

  const saveAnswer = (answer) => {
    const data = { ...questionData };
    data.CURRENT_ANSWER_SELECTED_RESPONSE = answer;
    dispatch(initiateAssessmentAction.updateCurrentAnswer(data));
  };
  const getClassName = (score) => {
    return `rating${highestRating == score ? "Highest" : ""}`;
  };

  const saveComplianceDecision = () => {
    dispatch(
      initiateAssessmentAction.updateComplianceDecision(
        questionData.QUESTION_ID,
        DecisionSelectedOption2
          ? DecisionSelectedOption2
          : DecisionSelectedOption?.RESPONSE_OPTION
          ? DecisionSelectedOption?.RESPONSE_OPTION
          : complianceDecision,
        questionData.SUBMISSION_ID
      )
    );
  };

  const CardBox = (identifier, currentQuestion) => {
    return (
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardContent style={{ padding: "5px", height: "100%" }}>
          <PerfectScrollbar>
            {identifier === 1 && (
              <div style={{ display: "flex", fontSize: "15px" }}>
                <div>
                  {" "}
                  <span>
                    {" "}
                    <strong>{currentQuestion?.refId}</strong> -
                  </span>
                  &nbsp;{currentQuestion?.question}
                </div>
              </div>
            )}

            {identifier === 2 && (
              <div style={{ fontSize: "15px" }}>
                <Box pb={2}>
                  <FilterByStatus
                    selectedTab={selectedTab}
                    onChange={onChange}
                    tabs={tabs}
                  />
                </Box>{" "}
                <TabPanel value={value} index={0}>
                  {currentQuestion?.OBJECTIVE_TEXT}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {currentQuestion?.GUIDANCE_TEXT}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {currentQuestion?.ARCHITECTURE_TEXT}
                </TabPanel>
              </div>
            )}
          </PerfectScrollbar>
        </CardContent>
      </Card>
    );
  };

  useEffect(() => {
    setQuestionState(questionData.status === "ACCEPTED" ? true : false);
    setHighestRating(returnHighestRating(questionData));
    setDecisionSelectedOption2(null);
    setDecisionSelectedOption(null);

    setComplianceDecision(
      questionData?.QUESTION_COMPLIANCE_DECISION
        ? questionData?.QUESTION_COMPLIANCE_DECISION
        : "No Decision Selected"
    );
  }, [questionData]);

  useEffect(() => {
    let questionText = questionData.ARCHITECTURE_TEXT;
    const filterTabs = _.cloneDeep(initialTabs);

    //setting the values for Guidance Text, Objectives Text and Architecture Text
    if (questionText != null) {
      filterTabs[2].count = 1;
      setSelectedTab({ name: langConstant.ARCHITECTURE, id: 2 });
    }
    questionText = questionData.GUIDANCE_TEXT;
    if (questionText != null) {
      filterTabs[1].count = 1;
      setSelectedTab({ name: langConstant.GUIDANCE, id: 1 });
    }
    questionText = questionData.OBJECTIVE_TEXT;
    if (questionText != null) {
      filterTabs[0].count = 1;
      setSelectedTab({ name: langConstant.OBJECTIVES, id: 0 });
    }

    setTabs(filterTabs);
    if (questionData.historyAnswers.currentAnswer) {
      questionData.historyAnswers.currentAnswer.className = getClassName(
        questionData.historyAnswers.currentAnswer.score
      );
    }
    if (questionData.historyAnswers.pastAnsOne) {
      questionData.historyAnswers.pastAnsOne.className = getClassName(
        questionData.historyAnswers.pastAnsOne.score
      );
    }
    if (questionData.historyAnswers.pastAnsTwo) {
      questionData.historyAnswers.pastAnsTwo.className = getClassName(
        questionData.historyAnswers.pastAnsTwo.score
      );
    }
    if (questionData.historyAnswers.pastAnsThree) {
      questionData.historyAnswers.pastAnsThree.className = getClassName(
        questionData.historyAnswers.pastAnsThree.score
      );
    }

    const data = Object.assign(
      {
        dataAssetsList: dataAssetsList ? dataAssetsList : [],
        evidenceList: evidenceList ? evidenceList : [],
      },
      questionData && questionData.historyAnswers
    );
    setTabsData(data);
  }, [dataAssetsList, evidenceList, questionData.historyAnswers]);

  useEffect(() => {
    dispatch(initiateAssessmentAction.getQuestionDQProfile(questionData.id));
    dispatch(
      initiateAssessmentAction.getDataAssestsList(questionData.id, customerID)
    );
    dispatch(
      evidenceAction.getEvidence(questionData.id, questionData.SUBMISSION_ID)
    );
    if (!questionData.QUESTION_COMPLIANCE_DECISION) {
      dispatch(
        individualQuestionAction.getIndividualQuestionResponses(
          questionData.id,
          customerID
        )
      );
    }
  }, [questionData.id]);

  useEffect(() => {
    const tempdata = questionResponses.find(
      (el) =>
        el.RESPONSE_OPTION?.trim() ===
        questionData?.QUESTION_COMPLIANCE_DECISION?.trim()
    );

    if (tempdata) {
      setDecisionSelectedOption(tempdata);
    } else {
      const temp = {
        ACTIVE: 1,

        QUESTION_ID: questionData?.QUESTION_ID,
        RESPONSE_OPTION: complianceDecision,
      };
      setDecisionSelectedOption(temp);
    }
  }, [questionResponses]);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "160px",
        }}
        className={classes.marginTop}
      >
        {/*Card variant="outlined" style={{ height: "100%" }}>
          <CardContent style={{ padding: "5px", height: "100%" }}>
            <PerfectScrollbar >
              <div style={{ display: "flex", fontSize: "15px" }}>
                <div> <span> <strong>{questionData.refId}</strong> -</span>&nbsp;{questionData.question}</div>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </Box>
      {questionData.guidance ? (
        <Box className={classNames(classes.marginTop, "info-box")}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <InfoIcon color="inherit" />
            </Grid>
            <Grid item>
              <Truncate content={questionData.guidance} wordCount={50} />
            </Grid>
          </Grid>
        </Box>
      ) : null}{" "} */}

        <Box style={{ width: "55%" }}>{CardBox(1, questionData)}</Box>
        <Box style={{ width: "44%" }}>{CardBox(2, questionData)}</Box>
      </Box>

      {/* Render question  */}
      {/* <Grid container wrap="nowrap" spacing={1}>
        <Grid item>
          <Typography>
            <strong>{questionData.refId}</strong> - 
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography style={{fontSize: "15px"}}>{questionData.question}</Typography>
        </Grid>
      </Grid> */}
      {/* //*Checking if Question Status exists */}

      <Box
        container
        wrap="nowrap"
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box item>
          <FormControlLabel
            control={
              <Checkbox
                checked={questionState}
                color="default"
                inputProps={{ "aria-label": "Mark as Accepted" }}
                onChange={markCompleted}
                className={rootClasses.greenCheckbox}
                name="questionState"
              />
            }
            label={langConstant.MARK_AS_ACCEPTED}
            labelPlacement="end"
          />
        </Box>

        <Box
          container
          wrap="nowrap"
          spacing={2}
          display="flex"
          style={{ gap: 8 }}
        >
          <Button
            onClick={onAddAction}
            style={{ borderRadius: "50px", height: "35px" }}
            variant="contained"
            color="primary"
            title="Add Action"
          >
            Add Action
          </Button>
          <Button
            variant="outlined"
            disableElevation
            color="primary"
            disabled={
              QUESTION_STATUS === "ACCEPTED" || QUESTION_STATUS === "SENT_BACK"
            }
            style={{
              borderRadius: "50px",
              height: "35px",
            }}
            onClick={() => {
              dispatch(
                confirmationDialogActions.open({
                  message: langConstant.SEND_BACK_TO_SUPPLIER_FN(),
                  onConfirm: () => {
                    dispatch(
                      initiateAssessmentAction.updateQuestionStatus(
                        questionData.QUESTION_ID,
                        questionData.SUBMISSION_ID,
                        "SENT_BACK",
                        goBackOnError,
                        userID
                      )
                    );
                    onRfiCancellation();
                  },
                  title: langConstant.SEND_BACK_CONFIRMATION,
                  option1: langConstant.YES_PLEASE,
                  option2: langConstant.NO_THANKS,
                })
              );
            }}
          >
            <CheckIcon style={{ fontSize: "20px", marginRight: "10px" }} />
            {langConstant.SEND_BACK_TO_SUPPLIER_SPOC}
          </Button>
        </Box>
      </Box>
      <Box mt={2}>
        <Grid container>
          <Grid item md={9}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ marginRight: "5px" }}>
                <h3>Decision:</h3>
              </Box>
              <Box style={{ width: "30%", marginLeft: "5px" }}>
                {questionResponses.length == 0 && (
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ maxWidthidth: "400px" }}
                    onChange={(e) => {
                      setIsSaveDisabled(false);
                      setComplianceDecision(e.target.value);
                    }}
                    value={complianceDecision}
                    inputProps={{ readOnly: false }}
                  />
                )}
                {questionResponses.length > 0 && (
                  <Autocomplete
                    freeSolo
                    size="small"
                    className={`${classes.width100}`}
                    autoHighlight
                    openOnFocus
                    id="checkboxes-tags-demo"
                    options={questionResponses}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search"
                        onChange={(e) => {
                          setIsSaveDisabled(false);

                          setDecisionSelectedOption2(e.target.value);
                        }}
                      />
                    )}
                    getOptionLabel={(option) =>
                      option.RESPONSE_OPTION ? option.RESPONSE_OPTION : ""
                    }
                    onChange={(e, val) => {
                      setIsSaveDisabled(false);
                      setDecisionSelectedOption(val);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.RESPONSE_OPTION?.trim() ===
                      value?.RESPONSE_OPTION?.trim()
                    }
                    value={DecisionSelectedOption}
                    disabled={
                      questionData?.QUESTION_STATUS === "COMPLETED_AND_LOCKED"
                    }
                  />
                )}
              </Box>
              <Box style={{ width: "17%", marginLeft: "5px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  onClick={saveComplianceDecision}
                  disabled={isSaveDisabled}
                >
                  {langConstant.SAVE}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3}>
            <DataPointQuality dqProfile={dqProfile ? dqProfile : []} />
          </Grid>
        </Grid>
      </Box>
      <CurrentAnswerData
        tabsData={tabsData}
        saveAnswer={saveAnswer}
        currentQuestion={questionData}
      />
      <AddActionDialog addActionModal={addActionModal} />
    </Box>
  );
};
SelectAnswer.propTypes = {
  questionData: PropTypes.object,
  onRfiCancellation: PropTypes.func,
};
export default SelectAnswer;
