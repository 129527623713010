/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import langConstant from "_lang";
import PropTypes from "prop-types";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { alertActions, loadingActions } from "_actions";
import { virusCheckerService } from "_helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";

const UploadFileComponent = ({
  setFieldValue,
  index,
  handleChange,
  values,
}) => {
  const inputFileRef = useRef([]);
  const isVirusCheckingAvailable = useSelector(
    (state) => state.authentication?.detailedInfo?.VIRUS_CHECKER
  );
  const dispatch = useDispatch();
  //Trigger file Input
  const triggerFileUpload = () => {
    inputFileRef.current.click();
  };

  // Reset file Input
  const resetFileUpload = () => {
    setFieldValue(`evidenceItems.${index}.evidenceFile`, null);
    inputFileRef.current.value = "";
  };

  return (
    <Box
      display="flex"
      style={{ height: "100%", alignItems: "center", justifyContetn: "center" }}
    >
      {values.evidenceItems[index].evidenceFile && (
        <Box
          pt={1}
          display="flex"
          alignItems="center"
          style={{ marginLeft: "10px" }}
        >
          <Typography variant="body2" noWrap style={{ maxWidth: "150px" }}>
            {values.evidenceItems[index].evidenceFile.name}
          </Typography>
          <IconButton
            aria-label="Upload Data"
            size="small"
            onClick={resetFileUpload}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          {isVirusCheckingAvailable == 1 && (
            <span style={{ paddingLeft: "10px" }}>
              {values.evidenceItems[index].evidenceFile &&
                (values.evidenceItems[index].isVirusFile ? (
                  <Tooltip title="Virus Found In File" placement="right">
                    <CoronavirusIcon fontSize="small" color="error" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Virus Not Found In File" placement="right">
                    <VerifiedIcon fontSize="small" color="success" />
                  </Tooltip>
                ))}
            </span>
          )}
        </Box>
      )}
      <IconButton
        onClick={triggerFileUpload}
        size="small"
        variant="contained"
        color="default"
      >
        <FolderOpenIcon />
      </IconButton>
      <input
        ref={inputFileRef}
        type="file"
        name={`evidenceItems.${index}.evidenceFile`}
        className="hidden-input"
        onChange={async (event) => {
          if (event.target.files.length > 0) {
            if (isVirusCheckingAvailable == 1) {
              const fData = new FormData();
              fData.append("file", event.target.files[0]);
              dispatch(loadingActions.start());
              dispatch(
                loadingActions.setLoaderText("Checking Virus In Uploaded File")
              );
              const response = await virusCheckerService(fData);
              dispatch(loadingActions.setLoaderText(null));
              dispatch(loadingActions.end());
              if (response.status == 200) {
                if (response.data && response.data.hasVirus) {
                  setFieldValue(`evidenceItems.${index}.isVirusFile`, true);
                  dispatch(alertActions.success(response.data.message));
                } else {
                  setFieldValue(`evidenceItems.${index}.isVirusFile`, false);
                  dispatch(alertActions.success(response.data.message));
                  setFieldValue(
                    `evidenceItems.${index}.evidenceFile`,
                    event.target.files[0]
                  );
                }
              } else {
                dispatch(alertActions.error("Virus Checker Service Failed"));
              }
            } else {
              setFieldValue(
                `evidenceItems.${index}.evidenceFile`,
                event.target.files[0]
              );
            }
          } else {
            setFieldValue(`evidenceItems.${index}.evidenceFile`, null);
          }
        }}
      />
    </Box>
  );
};

UploadFileComponent.propTypes = {
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.any,
  index: PropTypes.number,
};

export default UploadFileComponent;
