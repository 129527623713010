/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// Team Member screen

import { Grid, makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import RolesPill from "./RolePill";
import RoleServices from "./services";
import sessionService from "_services/session.service";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import AddIcon from "@mui/icons-material/Add";
import AddRole from "./components/addRoleDialog";

import { useModal } from "_hooks";

import Select from "react-select";

const useStyles = makeStyles(() => ({
  subtitle: {
    color: "#707070",
    marginBottom: "20px",
    marginTop: "20px",
  },
  teamGridToggle: {
    paddingRight: "475px",
  },
  customerWrapper: {
    display: "inline-flex",
    width: "100%",
    padding: "20px 10px",
    alignItems: "center",
  },
  customerSelect: {
    width: "70%",
  },
  customerLabel: {
    fontSize: "18px",
    fontWeight: "bold",
    paddingRight: "15px",
  },
}));

const Roles = () => {
  const classes = useStyles();
  const addRoleModal = useModal();
  const user_ID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const [customerList, setCustomerList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [twoFADetail, setTwoFADetail] = useState([]);
  const [notificationPreferences, setNotificationPreferences] = useState([]);
  const [loading, setLoading] = useState(false);

  const onOpenAddRoleDialog = () => {
    addRoleModal?.setData({
      roleList,
      setRoleList,
    });
    addRoleModal?.openModal();
  };

  useEffect(() => {
    dispatch(RoleServices.GetCustomerList(setCustomerList));
  }, []);
  useEffect(() => {
    (async () => {
      setLoading(true);

      dispatch(loadingActions.start());
      const response = await RoleServices.getRoles(customerID);

      dispatch(loadingActions.end());

      if (response) {
        setLoading(false);

        if (response.status === 200) {
          if (response["#result-set-1"].length) {
            const activeRoleList = response["#result-set-1"].filter(
              (role) => role.ACTIVE !== 0
            );
            setRoleList(activeRoleList);
          } else {
            if (response.data && response.data.message) {
              dispatch(
                alertActions.error(
                  response.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
            }
          }
        }
      }
    })();
  }, []);

  return (
    <Grid
      className={
        (show && selectedUserId) || addUserPopUp ? classes.teamGridToggle : ""
      }
    >
      <Grid container>
        <Grid
          item
          md={12}
          style={{
            textAlign: "right",
            marginBottom: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={onOpenAddRoleDialog}
          >
            Add New Role
          </Button>
        </Grid>
      </Grid>
      <RolesPill
        roleList={roleList}
        loading={loading}
        setRoleList={setRoleList}
      />

      {addRoleModal?.isOpen && <AddRole addRoleModal={addRoleModal} />}
    </Grid>
  );
};

export default Roles;
