import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { scrollToTop } from "_helpers/utils";
import { useModal } from "_hooks";

import sessionService from "_services/session.service";
import AppPagination from "_components/AppPagination";
import SelectPageRange from "_components/SelectPageRange";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { ListItemButton } from "@mui/material";
import langConstant from "_lang";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import { NoBackpackSharp } from "@mui/icons-material";
import RoleServices from "../services";
import AddRole from "../components/addRoleDialog";

const useStyles = makeStyles(() => ({
  role: {
    color: "#444444",
    fontSize: "14px",
  },
  role1: {
    color: "#444444",
    fontSize: "14px",
    paddingLeft: "20px",
  },
}));

const RolesPill = ({ roleList, loading, setRoleList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerID = sessionService.getCustomerId();
  const addRoleModal = useModal();

  const calculatePageCount = (roleList) => {
    return roleList.length > 0
      ? Math.ceil(Math.min(roleList.length / rowsPerPage))
      : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(roleList));
  const [filteredRoleList, setFilteredRoleList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const columns = [
    { field: "ROLE_ID", headerName: "ROLE_ID", minWidth: 40 },
    { field: "ROLE_NAME", headerName: "ROLE_NAME", minWidth: 80 },
    { field: "ACTIONS", headerName: "ACTIONS", minWidth: 100, sticky: true },
  ];
  const onOpenAddRoleDialog = (val) => {
    const roleID = val?.ROLE_ID;
    const roleName = val?.ROLE_NAME;

    addRoleModal?.setData({
      roleID,
      roleName,
      roleList,
      setRoleList,
    });
    addRoleModal?.openModal();
  };

  const updaterFunction = (data) => {
    setFilteredRoleList(
      roleList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    );
    setPageCount(calculatePageCount(roleList));
  };
  const handleChangePage = (event) => {
    setPage(event);
  };
  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      roleList?.length > 0
        ? Math.round(Math.ceil(roleList.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (item) => {
    dispatch(
      confirmationDialogActions.open({
        message: "Are you sure you want to delete this role?",

        onConfirm: () => {
          const roleID = item?.ROLE_ID;
          const roleName = item?.ROLE_NAME;
          dispatch(
            RoleServices.updateRole(
              roleID,
              customerID,
              roleName,
              0,
              roleList,
              setRoleList
            )
          );
        },
        title: "",
        option1: langConstant.YES,
        option2: langConstant.NO,
      })
    );
  };

  useEffect(() => {
    updaterFunction(roleList);
  }, [page, roleList, pageCount]);

  return (
    <>
      <TableContainer component={Paper} className={classes.marginTop}>
        <Table className="data-table">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={
                    index === 1
                      ? classNames(classes.cursorPointer)
                      : classes.stickyColl
                  }
                  style={{
                    minWidth: item.minWidth,
                    textAlign: index === 2 ? "right" : "",
                    paddingRight: index === 2 ? "30px" : "",
                  }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && roleList && roleList.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        <Grid
          style={{
            textAlign: "center",
            paddingTop: "100px",
            paddingRight: "100px",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <List sx={{ width: "90%", bgcolor: "background.paper" }}>
          {filteredRoleList &&
            filteredRoleList.map((item, index) => {
              return (
                <Grid key={index}>
                  <ListItemButton>
                    {/* <ListItemAvatar>
             {item.AVATAR_IMAGE ? (
               <Avatar
                 className={classes.avatarstyle}
                 src={
                   "data:image/png;base64," +
                   window.atob(item.AVATAR_IMAGE)
                 }
               />
             ) : (
               <Avatar className={classes.avatarstyle}>
                 {item.ROLE_NAME[0]}
               </Avatar>
             )}
           </ListItemAvatar> */}
                    <ListItemText
                      primary={
                        <Grid container display="flex">
                          <Grid item md={3}>
                            <Typography className={classes.role}>
                              {item.ROLE_ID}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography className={classes.role1}>
                              {item.ROLE_NAME}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      // secondary={
                      //   <Typography className={classes.authorrole}>
                      //     {item.ROLE_NAME}
                      //   </Typography>
                      // }
                    />
                    <IconButton onClick={() => onOpenAddRoleDialog(item)}>
                      <EditIcon
                        sx={{
                          color: "green",
                        }}
                        size="small"
                      />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(item)}>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          "&.Mui-disabled": {
                            color: "lightcoral",
                          },
                        }}
                        size="small"
                      />
                    </IconButton>
                  </ListItemButton>
                  <Divider varient="inset" component="li" />
                </Grid>
              );
            })}
        </List>
      )}
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
      {addRoleModal?.isOpen && <AddRole addRoleModal={addRoleModal} />}
    </>
  );
};

RolesPill.propTypes = {
  roleList: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  setRoleList: PropTypes.func,
};
export default RolesPill;
