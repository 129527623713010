import { Box, Checkbox, Divider, FormControl, Typography } from "@mui/material";
import Spinner from "_components/Spinner";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NoDataCard from "_components/MasterAssessmentComp/component/DashboardPanel/components/Graphs/NoDataCard";
import { InsertDriveFileTwoTone, Refresh } from "@mui/icons-material";
import services, { GetDocumentsForRegulation } from "./services";
import sessionService from "_services/session.service";
import { useModal } from "_hooks";
import BatchUploadDialog from "./BatchUploadDialog";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { uniqBy } from "lodash";
import { VariableSizeList } from "react-window";
import { FilterMenu } from "./FilterMenu";
import ArticleIcon from "@mui/icons-material/Article";
import AutoSizer from "react-virtualized-auto-sizer";
import { Row, Item, innerElementType } from "./DraggableRow";
import uploadDocuments from "./services";
import { alertActions } from "_actions";
import { useWindowResize } from "./userWindowResize";
import { v4 as uuidv4 } from "uuid";
import FileTable from "./FileTable";
import regulationAction from "../../../actions";
import Select from "react-select";
import { Add } from "@material-ui/icons";
import PolicyUploadForm from "containers/manage-regulation/Policy/PolicyExplorer/component/PolicyDataTable/component/policyUploadForm";
import { makeStyles } from "@material-ui/styles";
const styles = {
  docWrapper: {
    display: "flex",
    gap: 2,
    flexDirection: "column",
    border: "1px solid #0000001f",
    borderRadius: 1,
    flex: 1,
    p: 1,
    overflowY: "hidden",
    height: "69dvh",
  },
  contentWrapper: { display: "flex", gap: 2, flex: "auto", p: 1.5 },
  scrollbleListWrapper: {
    display: "flex",
    gap: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    overflow: "auto",
  },
};
const useStyles = makeStyles(() => ({
  heightInside: {
    maxHeight: "50dvh !important",
    height: "50dvh !important",
  },
}));
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "blue",
});

const droppableIds = {
  FIRST: "1",
  SECOND: "2",
};

const getFilterOptions = (list = [], key = "") => {
  if (!list?.length || !key) return;
  list = list?.filter((i) => i?.[key]);
  return (
    uniqBy(list, key)?.map((item) => ({
      label: item?.[key],
      value: item?.[key]?.trim()?.toLowerCase(),
    })) ?? []
  );
};

const UploadDocuments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const uploadFilesModal = useModal();
  const [filesForUpload, setFilesForUpload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredFiles, setRequiredFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [isPolicyFilter, setIsPolicyFilter] = useState(true);
  const [activeFilters, setActiveFilters] = useState();
  const [selectedRegulation, setSelectedRegulation] = useState();
  const [regulationOptions, setRegulationOptions] = useState([]);
  const [comparisonStatusData, setComparisonStatusData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [regulationId, setRegulationId] = useState();
  const rows = useSelector((state) => state.regulationList);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filteredData, setFilteredData] = useState(filesForUpload);
  const [openAddPolicyForm, setOpenAddPolicyForm] = useState(false);

  const listRef = useRef({});
  const sizeMap = useRef({});

  const handleClickOpenForm = () => {
    setOpenAddPolicyForm(true);
  };

  const handleCloseForm = () => {
    setOpenAddPolicyForm(false);
  };

  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);
  const getSize = (index) => sizeMap?.current?.[index] || 50;
  const [windowWidth] = useWindowResize();

  const filterOptions = useMemo(() => {
    setFilteredFiles(requiredFiles);
    return {
      article: getFilterOptions(requiredFiles, "ARTICLE") ?? [],
      section: getFilterOptions(requiredFiles, "SECTION") ?? [],
    };
  }, [requiredFiles]);

  const fetchCustomerRegulationPolicyUploaded = async (regulationID) => {
    const response = await uploadDocuments.getCustomerRegulationPolicyUploaded(
      customerID,
      regulationID
    );
    if (response?.status === 200) {
      let parentFiles = [];
      response["#result-set-1"]?.forEach((item, index) => {
        if (item.DOCUMENT_REQUIRED_ID == null) {
          let childerns = [];
          response["#result-set-1"]?.forEach((innerItem) => {
            if (
              innerItem.DOCUMENT_REQUIRED_ID &&
              innerItem.POLICY_ID == item.POLICY_ID
            ) {
              childerns.push(innerItem);
            }
          });
          parentFiles.push({
            ...item,
            uuid: uuidv4(),
            no: index + 1,
            children: childerns,
          });
        }
      });
      setFilesForUpload(parentFiles);
    } else {
      dispatch(alertActions.error("Something went wrong"));
    }
    return response;
  };

  useEffect(() => {
    setRegulationOptions(getFilterOptions(rows, "REGULATION_NAME"));
  }, [rows]);

  useEffect(() => {
    if (filterStatus) {
      const filtered = filesForUpload
        .map((file) => ({
          ...file,
          children: file.children.filter(
            (child) =>
              child.COMPARISONSTATUS === filterStatus.value.toUpperCase()
          ),
        }))
        .filter((file) => file.children.length > 0);

      setFilteredData(filtered);
    } else {
      setFilteredData(filesForUpload);
    }
  }, [filterStatus, filesForUpload]);

  useEffect(() => {
    dispatch(regulationAction.getRegulationList(userID, customerID));
  }, []);
  async function onDragEnd(result) {
    const source = result.source;
    const destination = result.destination;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (sInd === dInd) {
      return;
    } else {
      if (droppableIds.FIRST === dInd) {
        return;
      }

      if (droppableIds.FIRST === sInd) {
        // push the element into list for ltr drop
        const sourceElement = requiredFiles?.find(
          (item) => item.uuid === result.draggableId
        );
        const destinationElement = filesForUpload?.find(
          (item) => item.uuid === dInd
        );

        // Object.defineProperty(sourceElement, "location", {
        //   value: "right",
        //   writable: true,
        // });
        // setFilteredFiles(
        //   filteredFiles?.filter((i) => i?.uuid !== result.draggableId)
        // );
        let newSourceElement;
        filteredFiles?.forEach((i) => {
          if (i?.uuid === result.draggableId) {
            newSourceElement = {
              ...i,
              uuid: uuidv4(),
              location: "right",
            };
          }
        });
        if (
          !destinationElement.children.some(
            (child) => child.uuid2 === newSourceElement.uuid2
          )
        ) {
          destinationElement.children.push(newSourceElement);

          let response = await services.putCustomerRegulationPolicyMapping(
            parseInt(customerID),
            destinationElement.POLICY_ID,
            destinationElement.REGULATION_ID
              ? destinationElement.REGULATION_ID
              : regulationId,
            parseInt(userID),
            newSourceElement.REGULATIONDOCREQ_UNID,
            newSourceElement.POLICY_ID ? newSourceElement.POLICY_ID : null
          );
          // }
          if (response?.status != 200) {
            destinationElement.children.splice(-1);
            throw new Error("Failed to Mapping Policy");
          }
        }
      }
      // } else {
      //   // console.log("Second Zone");
      //   // // remove and push the element into list for rtr drop
      //   // const sourceParentElement = filesForUpload?.find(
      //   //   (item) => item.uuid === sInd
      //   // );
      //   // const sourceElement = requiredFiles?.find(
      //   //   (item) => item.uuid === result.draggableId
      //   // );
      //   // const destinationElement = filesForUpload?.find(
      //   //   (item) => item.uuid === dInd
      //   // );
      //   // sourceParentElement.children = sourceParentElement.children.filter(
      //   //   (item) => item.uuid !== result.draggableId
      //   // );
      //   // Object.defineProperty(sourceElement, "location", {
      //   //   value: "right",
      //   //   writable: true,
      //   // });
      //   // // setFilteredFiles(
      //   // //   filteredFiles?.filter((i) => i?.uuid !== result.draggableId)
      //   // // );
      //   // destinationElement.children.push(sourceElement);
      // }
    }
  }

  const handleRegulationChange = (option) => {
    const tempSelectedRegulation = rows.find(
      (row) => row.REGULATION_NAME?.trim()?.toLowerCase() === option.value
    );
    setSelectedRegulation(tempSelectedRegulation);
    dispatch(
      GetDocumentsForRegulation(
        tempSelectedRegulation.REGULATION_ID,
        customerID,
        setRequiredFiles,
        setIsLoading
      )
    );
    setRegulationId(tempSelectedRegulation.REGULATION_ID);
    fetchCustomerRegulationPolicyUploaded(tempSelectedRegulation.REGULATION_ID);
  };
  // outer
  const handleDeleteCustomerRegulationPolicy = async (deletedPolicy) => {
    const response = await services.deleteCustomerRegulationPolicyUpload(
      deletedPolicy.CUSTOMER_ID,
      deletedPolicy.REGULATION_ID,
      deletedPolicy.POLICY_ID,
      deletedPolicy.USER_ID,
      userID
    );
    if (response?.status != 200) throw new Error("Failed to delete Policy");
    let data = filesForUpload.filter((i) => i.uuid !== deletedPolicy.uuid);
    setFilesForUpload(data);
  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      setRefresh((prevState) => !prevState);
    }, 2 * 60000); // 60000 milliseconds = 1 minute
  }, []);
  const setFileForUploadChildern = async () => {
    // set right files
    filesForUpload.forEach(async (element) => {
      element.children.forEach((childFile, index) => {
        let innerFile = filteredFiles.find((i) => {
          if (
            i.REGULATIONDOCREQ_UNID == childFile.DOCUMENT_REQUIRED_ID &&
            childFile.POLICY_ID2 == i.POLICY_ID
          ) {
            element.children[index] = {
              ...i,
              uuid: uuidv4(),
              location: "right",
            };
          }
        });
      });
      // set comparison status
      comparisonStatusData.forEach((ele) => {
        if (element.POLICY_ID == ele.POLICY_ID1) {
          element.children.forEach((innerFile, i) => {
            if (innerFile.POLICY_ID == ele.POLICY_ID2) {
              element.children[i] = {
                ...innerFile,
                COMPARISONSTATUS: ele.ITEM_STATUS,
                QUEUE_UNIQUE_ID: ele.QUEUE_UNIQUE_ID,
              };
            }
          });
        }
      });
    });
    setFilesForUpload((prev) => [...prev]);
  };

  const setComparisonStatus = async () => {
    const response = await services.getProcessingQueueItems(customerID, null);
    setComparisonStatusData(response["#result-set-1"]);
  };

  useEffect(() => {
    setComparisonStatus();
  }, [refresh]);
  useEffect(() => {
    setFileForUploadChildern();
  }, [filteredFiles, comparisonStatusData]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {uploadFilesModal?.isOpen && (
        <BatchUploadDialog
          modal={uploadFilesModal}
          setFilesForUpload={setFilesForUpload}
          filesForUpload={filesForUpload}
          regulationID={selectedRegulation?.REGULATION_ID}
        />
      )}
      <Grid container>
        <Grid item md={6} style={{ marginLeft: 12 }}>
          <Select
            options={regulationOptions}
            menuPortalTarget={document.body}
            placeholder="Select Regulation"
            onChange={handleRegulationChange}
          />
        </Grid>
      </Grid>
      <Box p={1.5} display="flex" flex="auto">
        <Box sx={{ m: 0, p: 0, flex: 1, color: "#9d9d9d" }}>
          Upload Documents for{" "}
          <span
            style={{
              fontStyle: "italic",
              textDecoration: "underline",
              color: "#000",
            }}
          >
            {selectedRegulation?.REGULATION_NAME || "Regulation Name"}
          </span>
        </Box>
        <Button
          style={{ margin: "auto" }}
          variant="contained"
          color="primary"
          onClick={uploadFilesModal?.openModal}
          disabled={selectedRegulation ? false : true}
        >
          Policy Upload
        </Button>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Box sx={styles.docWrapper}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography mr={2}>Documents Required</Typography>
            <Divider flexItem orientation="vertical" />
            <Box display="flex" gap={0.25}>
              <FilterMenu
                dataList={requiredFiles}
                tooltipTitle="Apply Article Filter"
                filterOptions={filterOptions?.article}
                setDataList={setFilteredFiles}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                icon={<ArticleIcon />}
                dataVariable="ARTICLE"
                filesForUpload={filesForUpload}
              />
              <FilterMenu
                dataList={requiredFiles}
                tooltipTitle="Apply Section Filter"
                filterOptions={filterOptions?.section}
                setDataList={setFilteredFiles}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                dataVariable="SECTION"
                filesForUpload={filesForUpload}
              />
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box alignSelf="flex-end" flex={1}>
              <Checkbox
                id="checkbox-filter"
                checked={isPolicyFilter}
                onChange={() => setIsPolicyFilter(!isPolicyFilter)}
              />
              <Typography
                fontSize={12}
                htmlFor="checkbox-filter"
                component="label"
              >
                Show only Policy, Procedures, Processes, Controls
              </Typography>
            </Box>
            <Box alignSelf="flex-end">
              <Tooltip
                title={"Add Policy"}
                onClick={handleClickOpenForm}
                style={{ cursor: "pointer" }}
              >
                <Add color="primary" />
              </Tooltip>
            </Box>
          </Box>
          {openAddPolicyForm && (
            <PolicyUploadForm
              open={openAddPolicyForm}
              onClose={handleCloseForm}
            />
          )}
          <Divider flexItem />
          {isLoading ? (
            <Spinner />
          ) : filteredFiles?.length > 0 ? (
            <>
              <Box
                sx={{
                  border: "1px solid #0000001f",
                  borderRadius: 1,
                  display: "inline-flex",
                  gap: 2,
                  alignItems: "center",
                  p: 0.5,
                  px: 1,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>No.</Typography>
                <Typography
                  sx={{
                    flex: 1,
                    fontWeight: "bold",
                  }}
                >
                  Document Recommendation ({filteredFiles?.length} Records)
                </Typography>
              </Box>
              <Droppable
                droppableId={droppableIds.FIRST}
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => (
                  <Item
                    provided={provided}
                    isDragging={snapshot.isDragging}
                    item={requiredFiles?.find(
                      (item) => item?.uuid === rubric.draggableId
                    )}
                    regulationID={selectedRegulation?.REGULATION_ID}
                  />
                )}
              >
                {(provided, snapshot) => (
                  <AutoSizer
                    // className="heightInsideOut"
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {({ height, width }) => (
                      <VariableSizeList
                        className={classes.heightInside}
                        ref={listRef}
                        height={height}
                        itemCount={filteredFiles?.length}
                        itemSize={getSize}
                        width={width}
                        border={1}
                        itemData={{
                          items: filteredFiles,
                          setSize: setSize,
                          windowWidth: windowWidth,
                          regulationID: selectedRegulation?.REGULATION_ID,
                        }}
                        outerRef={provided.innerRef}
                        innerElementType={innerElementType}
                      >
                        {Row}
                      </VariableSizeList>
                    )}
                  </AutoSizer>
                )}
              </Droppable>
            </>
          ) : (
            <Typography>No Documents Found</Typography>
          )}
        </Box>
        <Box sx={styles.docWrapper}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>Documents Uploaded </Typography>
            </Grid>

            {selectedRegulation?.REGULATION_ID && (
              <Grid
                item
                container
                xs
                auto
                style={{
                  justifyContent: "flex-end",
                }}
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <FormControl size="small" sx={{ minWidth: 210 }}>
                    <Select
                      value={filterStatus}
                      onChange={(selectedOption) =>
                        setFilterStatus(selectedOption)
                      }
                      options={[
                        { value: "Completed", label: "Completed" },
                        { value: "Failed", label: "Failed" },
                        { value: "Pending", label: "Pending" },
                      ]}
                      menuPortalTarget={document.body}
                      isClearable
                      placeholder="Select Status"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <IconButton
                    style={{
                      backgroundColor: "#3c0292",
                    }}
                    onClick={() => {
                      setRefresh((prevState) => !prevState);
                    }}
                  >
                    <Refresh
                      sx={{
                        color: "white",
                        ":hover": {
                          color: "primary.main",
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* <Box
            sx={{
              border: "1px solid #0000001f",
              borderRadius: 1,
              display: "inline-flex",
              gap: 2,
              alignItems: "center",
              p: 0.5,
              px: 1,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>No.</Typography>

            <Typography
              sx={{
                flex: 1,
                fontWeight: "bold",
              }}
            >
              Document Name
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>Size</Typography>
            <Typography sx={{ fontWeight: "bold" }}>Docs Mapped</Typography>
            <Typography sx={{ fontWeight: "bold" }}>Recommendations</Typography>
          </Box> */}

          <Box sx={styles.scrollbleListWrapper} background="#F0F4F8">
            {filesForUpload?.length ? (
              // filesForUpload?.map((item, index) => (
              //   <Droppable
              //     key={item?.uuid}
              //     droppableId={item.uuid}
              //     renderClone={(provided, snapshot, rubric) => {
              //       return (
              //         <Item
              //           provided={provided}
              //           isDragging={snapshot.isDragging}
              //           item={requiredFiles?.find(
              //             (item) => item?.uuid === rubric.draggableId
              //           )}
              //         />
              //       );
              //     }}
              //   >
              //     {(provided, snapshot) => (
              //       <Box
              //         ref={provided.innerRef}
              //         {...provided.droppableProps}
              //         style={getListStyle(snapshot.isDraggingOver)}
              //       >
              //         <EditableFilenameRow
              //           file={Object.assign(item, { id: `${index + 1}.` })}
              //           setFilteredFiles={setFilteredFiles}
              //         />
              //         {provided.placeholder}
              //       </Box>
              //     )}
              //   </Droppable>
              // ))
              <FileTable
                files={filteredData}
                setFilteredFiles={setFilteredFiles}
                requiredFiles={requiredFiles}
                regulationID={selectedRegulation?.REGULATION_ID}
                handleDeleteCustomerRegulationPolicy={
                  handleDeleteCustomerRegulationPolicy
                }
              />
            ) : (
              <NoDataCard
                Icon={InsertDriveFileTwoTone}
                message="No files selected"
                textColor="gray"
                iconProps={{ sx: { fontSize: 10 } }}
              />
            )}
          </Box>
        </Box>
      </Box>

      {/* <Box
        sx={{ p: 1.5, justifyContent: "flex-start", display: "flex", gap: 2 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={uploadDocuments?.assessmentComparison}
        >
          Initiate Matching sss
        </Button>
        <Button disabled variant="contained" color="primary">
          Save Mappings
        </Button>
      </Box> */}
    </DragDropContext>
  );
};

export default UploadDocuments;
