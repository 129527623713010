import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const CustomRichTextEditor = ({
  onChangeHandler,
  data,
  onCancelClick,
  identifier,
}) => {
  const wrapperStyle = {
    border: "1px solid #969696",
    height: "100%",
    padding: "10px",
  };
  const editorStyle = {
    height: identifier === 2 ? "calc(100% - 50px)" : "calc(100% - 10px)",
    // lineHeight: "25%"
  };
  const contentBlock = htmlToDraft(data === null ? "" : data);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  // const [editedData, setEditedData] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    // setEditedData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    onChangeHandler(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const CustomButton = ({ editorState, onChange }) => {
    if (identifier === 1) {
      let htmlToString = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      htmlToString = htmlToString.replace(/<style([\s\S]*?)<\/style>/gi, "");
      htmlToString = htmlToString.replace(/<script([\s\S]*?)<\/script>/gi, "");
      htmlToString = htmlToString.replace(/<\/div>/gi, "");
      htmlToString = htmlToString.replace(/<\/li>/gi, "");
      htmlToString = htmlToString.replace(/<li>/gi, "• ");
      htmlToString = htmlToString.replace(/\n/gi, " ");
      htmlToString = htmlToString.replace(/\t/gi, "");
      htmlToString = htmlToString.replace(/<\/ul>/gi, "");
      htmlToString = htmlToString.replace(/<\/p>/gi, "");
      htmlToString = htmlToString.replace(/<br\s*[/]?>/gi, "");
      htmlToString = htmlToString.replace(/<[^>]+>/gi, "");
      htmlToString = htmlToString.replace(/&nbsp;/g, "");

      return (
        <div>
          {htmlToString.trim() !== "" && (
            <IconButton type="submit">
              <CheckIcon color="success" sx={{ fontSize: "26px" }} />
            </IconButton>
          )}

          <IconButton onClick={onCancelClick}>
            <CloseIcon color="error" sx={{ fontSize: "26px" }} />
          </IconButton>
        </div>
      );
    }

    return (
      <div>
        <IconButton type="submit">
          <CheckIcon color="success" sx={{ fontSize: "26px" }} />
        </IconButton>

        <IconButton onClick={onCancelClick}>
          <CloseIcon color="error" sx={{ fontSize: "26px" }} />
        </IconButton>
      </div>
    );
  };

  CustomButton.propTypes = {
    editorState: PropTypes.func,
    onChange: PropTypes.func,
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="wrapper-class"
      wrapperStyle={wrapperStyle}
      editorStyle={editorStyle}
      editorClassName="demo-editor"
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "history",
        ],
      }}
      toolbarCustomButtons={[<CustomButton key={1} />]}
    />
  );
};

CustomRichTextEditor.propTypes = {
  onChangeHandler: PropTypes.func,
  onCancelClick: PropTypes.func,
  data: PropTypes.string,
  identifier: PropTypes.number,
};

export default CustomRichTextEditor;
