import React from "react";
import { components } from "react-select";
import PropTypes from "prop-types";
import useStyles from "./styles";

const OptionComponent = (props) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex" }}>
      <components.Option {...props} className={classes.optionsStyle}>
        <label className={classes.labelStyle}>{props?.data?.value}</label>
        <label className={classes.labelStyle2}>{props?.data?.label}</label>
        <label className={classes.labelStyle3}>{props.data?.UPLOADED_BY}</label>
        <label className={classes.labelStyle4}>{props?.data?.date}</label>

        <input
          className={classes.checkboxStyle}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ accentColor: "#124571" }}
        />
      </components.Option>
    </div>
  );
};

OptionComponent.propTypes = {
  data: PropTypes.any,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  value: PropTypes.string,
  date: PropTypes.any,
};

export default OptionComponent;
