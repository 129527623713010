/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Button, TextField, Input } from "@material-ui/core";
import { inputStyles } from "../styles";
import NearMeIcon from "@material-ui/icons/NearMe";
import commentsAction from ".././actions";
import sessionService from "_services/session.service";

import { useDispatch } from "react-redux";

const CommentInput = ({ question_ID, submission_ID, remarksCount }) => {
  const classes = inputStyles();
  const [comment, setComment] = useState("");
  const [formError, setFormError] = useState(false);
  const user_ID = sessionService.getUserId();
  const userData = JSON.parse(sessionService.getItem("userData"));
  let username = "";
  if (userData) {
    username = userData.userName;
  }

  const dispatch = useDispatch();

  const onChangeHandler = (e) => {
    setComment(e.target.value);
    setFormError(false);
  };

  //* This function posts the comment
  const onPostHandle = (question_ID, submission_ID, user_ID, comment) => {
    if (comment.trim() == "") {
      setFormError(true);
      setComment("");
    } else {
      dispatch(
        commentsAction.postComment(
          question_ID,
          submission_ID,
          user_ID,
          comment,
          username
        )
      );
      remarksCount();
      setComment("");
    }
  };

  useEffect(() => {});

  return (
    <Box className={classes.inputWrap} style={{ marginBottom: "20px" }}>
      <TextField
        id="outlined-basic"
        placeholder="Enter your remarks"
        variant="outlined"
        inputProps={{ maxLength: 280 }}
        size="small"
        value={comment}
        className={classes.inputField}
        onChange={(e) => onChangeHandler(e)}
        error={formError}
      />

      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.postButton}
        onClick={() =>
          onPostHandle(question_ID, submission_ID, user_ID, comment)
        }
      >
        Post It222222222 <NearMeIcon />
      </Button>
    </Box>
  );
};

CommentInput.propTypes = {
  question_ID: PropTypes.string,
  submission_ID: PropTypes.number,
  remarksCount: PropTypes.func,
};

export default CommentInput;
