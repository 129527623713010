import React from "react";
// import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
// import langConstant from "_lang";
import axios from "axios";
import { loadingActions } from "_actions";
import sessionService from "_services/session.service";

const getPrediction = async (data) => {
  const authToken = sessionService.getItem("jwtToken");
  data["authToken"] = authToken;
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/getPrediction", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
  // return await axios.post(`${window.FLASK_EXTRACTION_URL}/getPrediction`, data);
};

const getExtraction = async (data) => {
  const authToken = sessionService.getItem("jwtToken");
  data["authToken"] = authToken;
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/extraction", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
  // return await axios.post(`${window.FLASK_EXTRACTION_URL}/extraction`, data);
};

const saveExtraction = async (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/saveExtraction", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
  // return await axios.post(
  //   `${window.FLASK_EXTRACTION_URL}/saveExtraction`,
  //   data
  // );
};

const putPolicy = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutPolicy", {
      i_POLICY_NAME: data.document_name,
      i_POLICY_META_DATA: JSON.stringify(data.policy_metadata),
      i_POLICY_FILE_NAME: data.fileName,
      i_POLICY_FILE_FORMAT: data.document_extension,
      i_STORAGE_FOLDER_URL:
        data.customerID.toString() + "/Policy_Management/Extraction/",
      i_DOCUMENT_OWNER: data.document_owner
        ? JSON.stringify(data.document_owner)
        : data.document_owner,
      i_DOCUMENT_TAGS: data.document_tags,
      i_DOCUMENT_TYPE: data.document_type.length
        ? JSON.stringify(data.document_type)
        : "",
      i_USER_ID: data.userID,
      i_START_DATE: data.currentDateTime,
      i_Language_Code: data.document_language["value"],
      i_END_DATE: "",
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updatePolicy = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdatePolicy", {
      i_POLICY_ID: data.policy_id,
      i_POLICY_NAME: data.document_name,
      i_POLICY_META_DATA: JSON.stringify(data.policy_metadata),
      i_DOCUMENT_OWNER: data.document_owner
        ? JSON.stringify(data.document_owner)
        : data.document_owner,
      i_DOCUMENT_TAGS: data.document_tags,
      i_DOCUMENT_TYPE: data.document_type.length
        ? JSON.stringify(data.document_type)
        : "",
      i_Language_Code: data.document_language["value"],
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getDocumentControlInformation = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetDocumentControlInformation", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getUserProgress = (policyId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetUserProgress", {
      i_id: policyId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyExtractionDetailsByPage = (data) => {
  return apiService
    .apiCall("dbapi")
    .send(`/v1/api/proc/DRegulativDB/GetPolicyExtractionDetailsByPage`, {
      i_POLICY_ID: data.policyId,
      i_PAGE_NUMBER: data.pageNo,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const reRunService = async (data) => {
  const authToken = sessionService.getItem("jwtToken");
  data["authToken"] = authToken;
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/reRun", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
  // return await axios.post(`${window.FLASK_EXTRACTION_URL}/reRun`, data);
};

const getPolicyPrediction = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyPrediction", {
      i_POLICY_ID: data.policyId,
      i_POLICY_TYPE: data.type,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          response["predictions"] = { data: [] };
          if (response["#result-set-1"].length > 0) {
            const predictionData = JSON.parse(
              response["#result-set-1"][0]["POLICY_PREDICTION"]
            );
            response["prodictionString"] =
              response["#result-set-1"][0]["POLICY_PREDICTION"];
            response["predictions"] = predictionData;
          }
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putPolicyPrediction = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutPolicyPrediction", {
      i_POLICY_ID: data.policyId,
      i_POLICY_TYPE: data.type,
      i_POLICY_PREDICTION_JSON: data.predictions,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const documentUpload = (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/upload/documentUpload", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const newUpload = (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/upload/newUpload", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putPolicyDocVersion = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutPolicyDocVersion", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const getPolicyDocVersion = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyDocVersion", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response["#result-set-1"];
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

export default {
  getPrediction,
  updatePolicy,
  putPolicy,
  getDocumentControlInformation,
  getUserProgress,
  getExtraction,
  getPolicyExtractionDetailsByPage,
  saveExtraction,
  reRunService,
  getPolicyPrediction,
  putPolicyPrediction,
  documentUpload,
  newUpload,
  putPolicyDocVersion,
  getPolicyDocVersion,
};
