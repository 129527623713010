/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Box, Grid, Divider, Typography } from "@material-ui/core";

import langConstant from "_lang";
import CommentsDialog from "./CommentsDialog";

import rootStyles from "rootStyles";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";

import sessionService from "_services/session.service";
import manageRfiActions from "../../../actions";
import EvidenceLocker from "_components/EvidenceLocker/index";

const RfiReviewQuestions = () => {
  const dispatch = useDispatch();
  const rootClasses = rootStyles();
  const { assessmentId } = useParams();

  const rfiSummaryList = useSelector(
    (state) => state.rfiListForAssessment.rfiListForSummary
  );

  useEffect(
    () =>
      dispatch(
        manageRfiActions.getRfisSummaryForConfirmation(
          sessionService.getUserId(),
          assessmentId
        )
      ),
    []
  );
  return (
    <>
      {rfiSummaryList.map((rfiItem, index) => (
        <Grid container spacing={1} key={index}>
          <Grid item xs zeroMinWidth>
            <Grid
              container
              spacing={1}
              style={{ paddingLeft: "0.875rem" }}
              className={rootClasses.flexNoWrap}
            >
              <Grid item style={{ minWidth: "130px" }}>
                <Typography color="textSecondary" variant="body1" gutterBottom>
                  <strong>{rfiItem.REGULATORS_QUESTION_REF} -</strong>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" gutterBottom>
                  <strong>{rfiItem.QUESTION_TEXT}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ paddingLeft: "0.875rem" }}
              className={rootClasses.flexNoWrap}
            >
              <Grid item style={{ minWidth: "130px" }}>
                <Typography color="textSecondary" variant="body2" gutterBottom>
                  {langConstant.ANSWER}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  {rfiItem["RFI Answer"]
                    ? JSON.parse(rfiItem["RFI Answer"]).NARRATIVE
                    : langConstant.NO_RESPONSE_PROVIDED}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} className={rootClasses.flexNoWrap}>
              <Grid item style={{ display: "flex" }}>
                {/* <CommentsDialog
                  questionId={rfiItem.QUESTION_ID}
                  submissionId={+assessmentId}
                  remarks={rfiItem["Remarks Count"]}
                /> */}
                <EvidenceLocker
                  questionId={rfiItem.QUESTION_ID}
                  renderForRfi={true}
                  evidenceCount={rfiItem["Evidence Count"]}
                  submissionId={assessmentId}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ padding: 0 }}>
            <Box mb={2}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

RfiReviewQuestions.propTypes = {
  item: PropTypes.object,
  roleBasedConstant: PropTypes.object,
};

export default React.memo(RfiReviewQuestions);
