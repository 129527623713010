import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import DocumentViewer from "_components/DocumentViewer";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import langConstant from "_lang";
import predictionServices from "../../services";
import {
  setProcessedPredictedtextValues,
  setOriginalPredictedtextValues,
} from "../../actions";
import { alertActions, loadingActions } from "_actions";
import { baseTextObj } from "../utils";
import sessionService from "_services/session.service";

const CompareFileView = ({
  show,
  setShow,
  setPredictionFromProcessed,
  setCurrentUrl,
  setPredictionFormData,
  setGroupByCategory,
  setCategoryOptions,
}) => {
  const handleClose = () => setShow(false);
  const customerID = sessionService.getCustomerId();
  const classes = useStyles();
  const dispatch = useDispatch();

  const documentsInfo = useSelector(
    (state) => state.uploadPolicyReducer.documentInfo
  );
  const predictedData = useSelector(
    (state) => state.uploadPolicyReducer.predictedText
  );
  const startPage = useSelector(
    (state) => state.uploadPolicyReducer.step1.pages_extraction_start
  );
  const endPage = useSelector(
    (state) => state.uploadPolicyReducer.step1.pages_extraction_end
  );
  const step1Data = useSelector((state) => state.uploadPolicyReducer.step1);

  const updatePredictionData = async (isProcessed) => {
    let predictedText = [];
    if (isProcessed) {
      if (predictedData.processed.length == 0) {
        dispatch(loadingActions.start());
        const response = await predictionServices.getPrediction({
          docName: documentsInfo.processed.name,
          fileId: documentsInfo.policyId,
          startPage: startPage,
          endPage: endPage,
          docType: "processed",
          stepper: "2",
          customerID: customerID,
          version_1_prediction: step1Data.version_1_prediction,
          language: step1Data.document_language.label,
          languageCode: step1Data.document_language.value,
        });
        dispatch(loadingActions.end());
        if (response.status) {
          if (response.status === 200 && response.data.length > 0) {
            dispatch(setProcessedPredictedtextValues(response.data));
            predictedText = [...response.data];
          } else {
            if (response.message) {
              dispatch(alertActions.error(response.message));
            }
          }
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      }
    } else {
      if (predictedData.original.length == 0) {
        dispatch(loadingActions.start());
        const response = await predictionServices.getPrediction({
          docName: documentsInfo.original.name,
          fileId: documentsInfo.policyId,
          startPage: startPage,
          endPage: endPage,
          docType: "original",
          stepper: "2",
          customerID: customerID,
          version_1_prediction: step1Data.version_1_prediction,
          language: step1Data.document_language.label,
          languageCode: step1Data.document_language.value,
        });
        dispatch(loadingActions.end());
        if (response.status) {
          if (response.status === 200 && response.data.length > 0) {
            dispatch(setOriginalPredictedtextValues(response.data));
            predictedText = [...response.data];
          } else {
            if (response.message) {
              dispatch(alertActions.error(response.message));
            }
          }
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      }
    }
    if (predictedText.length > 0) {
      let tempCategoryWiseData = {
        "Page-footer": [],
        "Page-header": [],
        "Section-header": [],
        Text: [],
      };
      let tempCategoryOptions = {
        "Page-footer": [],
        "Page-header": [],
        "Section-header": [],
        Text: [],
      };
      predictedText.forEach((element) => {
        if (tempCategoryWiseData[element[1]]) {
          tempCategoryWiseData[element[1]].push(element);
        }
        if (tempCategoryOptions[element[1]]) {
          tempCategoryOptions[element[1]].push({
            label: element[0],
            value: element[0],
          });
        }
      });
      setPredictionFormData({
        "Page-header":
          tempCategoryWiseData["Page-header"].length > 0
            ? {
                text: tempCategoryWiseData["Page-header"][0][0],
                pageNo: tempCategoryWiseData["Page-header"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Page-header"][0][0],
              }
            : { ...baseTextObj },
        "Page-footer":
          tempCategoryWiseData["Page-footer"].length > 0
            ? {
                text: tempCategoryWiseData["Page-footer"][0][0],
                pageNo: tempCategoryWiseData["Page-footer"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Page-footer"][0][0],
              }
            : { ...baseTextObj },
        Text:
          tempCategoryWiseData["Text"].length > 0
            ? {
                text: tempCategoryWiseData["Text"][0][0],
                pageNo: tempCategoryWiseData["Text"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Text"][0][0],
              }
            : { ...baseTextObj },
        "Section-header":
          tempCategoryWiseData["Section-header"].length > 0
            ? {
                text: tempCategoryWiseData["Section-header"][0][0],
                pageNo: tempCategoryWiseData["Section-header"][0][2],
                isEdit: false,
                selectedText: tempCategoryWiseData["Section-header"][0][0],
                subCategory: [],
              }
            : { ...baseTextObj, subCategory: [] },
      });
      setGroupByCategory(tempCategoryWiseData);
      setCategoryOptions(tempCategoryOptions);
    }
  };

  return (
    <Dialog maxWidth={"lg"} fullWidth={true} open={show} onClose={handleClose}>
      <DialogTitle>
        <Grid className={classes.titleWrap}>
          <Typography className={classes.textWrap}>
            {langConstant.COMPARE_ORIGINAL_AND_PRE_PROCESSED_FILE}
          </Typography>
          <CloseIcon onClick={handleClose} className={classes.closeWrap} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.docViewerWrap}>
          <Grid item md={6} xs={12} className={classes.docViewer}>
            <DocumentViewer
              documentClass={classes.documentClass}
              height={"580px"}
              filePath={
                customerID.toString() +
                "/Policy_Management/Extraction/" +
                documentsInfo.policyId +
                "/"
              }
              fileName={documentsInfo.original.name}
              fileType={documentsInfo.original.extension}
            />
          </Grid>
          <Grid item md={6} xs={12} className={classes.docViewer}>
            <DocumentViewer
              documentClass={classes.documentClass}
              height={"580px"}
              filePath={
                customerID.toString() +
                "/Policy_Management/Extraction/" +
                documentsInfo.policyId +
                "/"
              }
              fileName={documentsInfo.processed.name}
              fileType={documentsInfo.processed.extension}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            setPredictionFromProcessed(false);
            updatePredictionData(false);
            setCurrentUrl({
              filePath:
                customerID.toString() +
                "/Policy_Management/Extraction/" +
                documentsInfo.policyId +
                "/",
              fileName: documentsInfo.original.name,
              fileType: documentsInfo.original.extension,
            });
            handleClose();
          }}
        >
          {langConstant.SWITCH_TO_ORIGINAL}
        </Button>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            setPredictionFromProcessed(true);
            updatePredictionData(true);
            setCurrentUrl({
              filePath:
                customerID.toString() +
                "/Policy_Management/Extraction/" +
                documentsInfo.policyId +
                "/",
              fileName: documentsInfo.processed.name,
              fileType: documentsInfo.processed.extension,
            });
            handleClose();
          }}
        >
          {langConstant.CONTINUE_WITH_PRE_PROCESSED_FILE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CompareFileView.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setPredictionFromProcessed: PropTypes.func,
  setCurrentUrl: PropTypes.func,
  setCategoryOptions: PropTypes.func,
  setPredictionFormData: PropTypes.func,
  setGroupByCategory: PropTypes.func,
};

export default CompareFileView;
