import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import IconButton from "@material-ui/core/IconButton";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DialogBox from "_components/DialogBox";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { Formik, Field, Form, FieldArray } from "formik";
import langConstant from "_lang";
import { ChipInput } from "material-ui-formik-components";
import UploadFileComponent from "./component/UploadFileComponent";
import LoupeIcon from "@material-ui/icons/Loupe";
import rootStyles from "rootStyles";
import PublishIcon from "@material-ui/icons/Publish";
import evidenceAction from "./actions";
import sessionService from "_services/session.service";
import DeleteIcon from "@material-ui/icons/Delete";
import Loader from "_components/Loader";
import * as Yup from "yup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import UploadServices from "./actions";
import { GetCustomerInfoClassfication } from "containers/user-administration/ConfigurationManagement/components/EvidenceTypes/services";

const AttachEvidence = ({
  questionId,
  submissionId,
  isDisabled = false,
  rfiID = null,
}) => {
  const dispatch = useDispatch();
  const rootClasses = rootStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [virusAvailable, setVirusAvailable] = useState(false);
  const user_ID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const [count, setCount] = useState(0);
  const [classificationOptions, setClassificationOptions] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const userInfo = useSelector((state) => state.authentication.detailedInfo);

  const EVIDENCE_TRAINING_FOLDER =
    userInfo?.EVIDENCE_TRAINING || "Evidence_Training";

  const evidenceTypes = useSelector(
    (state) => state.uploadEvidence.evidenceTypes
  );
  const evidenceLocker = useSelector(
    (state) => state.authentication?.detailedInfo?.EVIDENCE_LOCKER
  );
  const loadingFile = useSelector((state) => state.loadingFile);
  let expiryPriodity = useSelector(
    (state) => state.uploadEvidence.evidenceTypes[0]?.EXPIRY_PERIODICITY
  );
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const period = expiryPriodity?.substring(0, expiryPriodity.length - 1);
  const expiryString = expiryPriodity?.charAt(expiryPriodity.length - 1);

  const setExpiryDate = (expiryString) => {
    if (expiryString === "m") {
      return "months";
    } else if (expiryString === "y") {
      return "years";
    } else if (expiryString === "d") {
      return "days";
    }
  };

  const initialValues = {
    evidenceItems: [
      {
        evidenceType: "select",
        evidenceClassification: "select",
        evidenceTag: [],
        evidenceFile: "",
        comments: "",
        expiryDate:
          expiryString && period
            ? moment().add(parseInt(period), setExpiryDate(expiryString))
            : moment(),
        isVirusFile: false,
      },
    ],
  };
  const showAlert = (message, type = "error") => {
    if (!message) return;
    dispatch(alertActions?.[type]?.(message));
  };

  const resetUploadStates = () => {
    const intervalID = setTimeout(() => setIsUploading(false), 2000);

    setProgressMessage("");
  };

  //   const validationSchema = Yup.object().shape({
  //     evidenceItems: Yup.array().of(
  //       Yup.object({
  //         evidenceType: Yup.string().required("Required"),
  //         evidenceClassification: Yup.string().required("Required"),
  //         evidenceTag: Yup.array().min(1, "Required"),
  //         evidenceFile: Yup.mixed().required("Required"),
  //         comments: Yup.string().required("Required"),
  //         expiryDate: Yup.string().required("Required"),
  //       })
  //     )
  // });

  const fileUploadProgress = (event) => {
    setCount(Math.round((100 * event.loaded) / event.total));
  };

  // * Toggles the RFI Filter Dialog box
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const openDialogAndGetEvidenceTypes = () => {
    dispatch(evidenceAction.getEvidenceType(questionId, customerID));
    toggleDialog();
  };

  const getUserClassificationsByAPI = async () => {
    try {
      const response = await GetCustomerInfoClassfication(user_ID);
      if (response?.status !== 200)
        throw new Error("Unable to get customer classifications");
      setClassificationOptions(
        response?.["#result-set-1"]?.map((item) => ({
          value: item?.INFO_CLASSIFICATION_ID,
          label: item?.DESCRIPTION,
          payload: item,
        }))
      );
    } catch (error) {
      dispatch(
        alertActions.error(
          error?.toString() || "Unable to get customer classifications"
        )
      );
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserClassificationsByAPI();
  }, []);

  return (
    <Box ml={1}>
      <Tooltip
        title={
          isDisabled
            ? langConstant.ATTACHING_EVIDENCE_DISABLED
            : langConstant.ATTACH_EVIDENCE
        }
        placement="top"
      >
        <span>
          <IconButton
            style={{
              borderRadius: "50%",
              width: "25px",
              height: "25px",
              fontSize: "22px",
              color: "white",
              backgroundColor: isDisabled ? "#B1B1B1" : "#007BFF",
            }}
            disabled={isDisabled}
            onClick={openDialogAndGetEvidenceTypes}
          >
            <AttachmentIcon color="inherit" fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>

      <DialogBox
        maxWidth={"lg"}
        title={langConstant.ATTACH_EVIDENCE}
        open={dialogOpen}
        handleClose={toggleDialog}
      >
        {loadingFile ? (
          <Loader count={count} />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async (values) => {
              let extensionUnsupported = false;
              let classificationNotSelected = false;
              let typeNotSelected = false;
              let data = new FormData();
              let lessThan20 = true;
              let isFile = true;
              let isVirusCheck = false;

              let evidenceTypeId = [];
              let classifications = [];
              let tags = [];
              let file = null;
              data.append("submissionId", submissionId);
              data.append("questionId", questionId);
              data.append("evidenceBy", user_ID);
              const validFileTypes = [
                "pdf",
                "jpeg",
                "jpg",
                "xls",
                "xlsx",
                "doc",
                "docx",
                "csv",
                "bmp",
                "png",
              ];

              values.evidenceItems.forEach((evidence, index) => {
                let joinedTags = evidence.evidenceTag.join(";");
                if (!evidence.evidenceFile) {
                  isFile = false;
                  return;
                }

                let extension = evidence.evidenceFile.name.split(".").pop();

                if (evidence.evidenceFile.size > 20400000) {
                  lessThan20 = false;
                }
                if (!validFileTypes.includes(extension.toLowerCase())) {
                  extensionUnsupported = true;
                }

                if (evidence.evidenceClassification == "select") {
                  classificationNotSelected = true;
                }
                if (evidence.evidenceType == "select") {
                  typeNotSelected = true;
                }
                if (evidence.isVirusFile) {
                  isVirusCheck = true;
                }

                data.append(`evidenceTypeId`, evidence.evidenceType);
                data.append(`tags`, joinedTags);
                data.append(`classifications`, evidence.evidenceClassification);

                data.append(`files`, evidence.evidenceFile);
                data.append(`comments`, evidence.comments);

                data.append(
                  "expiryDate",
                  moment(evidence.expiryDate).format("YYYY-MM-DD HH:mm:ss")
                );
              });

              if (extensionUnsupported) {
                dispatch(
                  alertActions.error(
                    langConstant.EXTENSION_NOT_SUPPORTED_ATTACH_EVIDENCE
                  )
                );
                return;
              } else if (!isFile) {
                dispatch(
                  alertActions.error(langConstant.PLEASE_SELECT_EVIDENCE_FILE)
                );
                return;
              } else if (classificationNotSelected) {
                dispatch(
                  alertActions.error(langConstant.CLASSIFICATION_NOT_SELECTED)
                );
                return;
              } else if (typeNotSelected) {
                dispatch(
                  alertActions.error(langConstant.EVIDENCE_TYPE_NOT_SELECTED)
                );
                return;
              } else if (!lessThan20) {
                dispatch(alertActions.error(langConstant.FILE_LESS_THAN_20));
                return;
              } else if (isVirusCheck) {
                dispatch(
                  alertActions.error(langConstant.PLEASE_REMOVE_VIRUS_FILE)
                );
                return;
              } else {
                const redirectTo = () => {};
                const filePath =
                  customerID.toString() + "/" + evidenceLocker + "/";
                // let filePath = `${customerID}/${EVIDENCE_TRAINING_FOLDER}/`,
                //   fileName = file.name.replaceAll(" ", "_");
                data.append("fileLocation", filePath);
                data.append("bucketName", window.BUCKET_NAME);
                data.append("storageType", docStorageType);
                data.append("preProcessingIP", docStorageIP);
                data.append("preProcessingURL", docStorageURL);
                dispatch(
                  evidenceAction.uploadEvidence(
                    data,
                    { "Content-type": "multipart/form-data" },
                    redirectTo,
                    fileUploadProgress
                  )
                );
              }
            }}
            // validator={() => ({})}
            render={({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <FieldArray
                    name="evidenceItems"
                    render={({ insert, remove, push }) => (
                      <div>
                        {values.evidenceItems.length > 0 &&
                          values.evidenceItems.map((item, index) => (
                            <Box
                              key={index}
                              display="flex"
                              alignItems="center"
                              style={{
                                marginTop: "1px",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  minWidth: "185px",
                                }}
                              >
                                <Box style={{ marginRight: "25px" }}>
                                  {index + 1}.
                                </Box>
                                <Box
                                  style={{
                                    position: "relative",
                                    width: "130px",
                                  }}
                                >
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        position: "absolute",
                                        top: "-25px",
                                        left: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.EVIDENCE_TYPE_REQUIRED}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}

                                  <TextField
                                    name={`evidenceItems.${index}.evidenceType`}
                                    placeholder="Select"
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    style={{ width: "100%" }}
                                    value={
                                      values.evidenceItems[index].evidenceType
                                    }
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  >
                                    <MenuItem key={1} value="select">
                                      {langConstant.SELECT_TYPE}
                                    </MenuItem>

                                    {evidenceTypes.map((evidence, index) => {
                                      return (
                                        <MenuItem
                                          key={index + 2}
                                          value={evidence.EVIDENCE_TYPE_ID}
                                        >
                                          {evidence.EVIDENCE_SHORTNAME}
                                        </MenuItem>
                                      );
                                    })}

                                    {/* <MenuItem key={2} value="1">
                                      Policy Document
                                    </MenuItem> */}
                                  </TextField>
                                </Box>
                                {errors.evidenceItems &&
                                  errors.evidenceItems[index] &&
                                  errors.evidenceItems[index].evidenceType &&
                                  touched.evidenceItems &&
                                  touched.evidenceItems[index].evidenceType && (
                                    <div className="field-error">
                                      {errors.evidenceItems[index].evidenceType}
                                    </div>
                                  )}
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "20px",
                                  width: "20%",
                                }}
                              >
                                <Box style={{ position: "relative" }}>
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        position: "absolute",
                                        top: "-25px",
                                        left: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_CLASSIFICATION_REQUIRED}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <TextField
                                    name={`evidenceItems.${index}.evidenceClassification`}
                                    placeholder="Select"
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "200px",
                                      marginLeft: "00px",
                                    }}
                                    select
                                    value={
                                      values.evidenceItems[index]
                                        .evidenceClassification
                                    }
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <MenuItem value="select">
                                      {langConstant.SELECT_CLASSIFICATION}
                                    </MenuItem>
                                    {classificationOptions?.map((item) => (
                                      <MenuItem
                                        key={item?.value}
                                        value={item?.value}
                                      >
                                        {item?.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Box>
                                {errors.evidenceItems &&
                                  errors.evidenceItems[index] &&
                                  errors.evidenceItems[index]
                                    .evidenceClassification &&
                                  touched.evidenceItems &&
                                  touched.evidenceItems[index]
                                    .evidenceClassification && (
                                    <div className="field-error">
                                      {
                                        errors.evidenceItems[index]
                                          .evidenceClassification
                                      }
                                    </div>
                                  )}
                              </Box>
                              <Box style={{ marginTop: "-10px", width: "25%" }}>
                                <Box style={{ position: "relative" }}>
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        position: "absolute",
                                        top: "-7px",
                                        left: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_TAGS_REQUIRED}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <Field
                                    name={`evidenceItems.${index}.evidenceTag`}
                                    variant="outlined"
                                    size="small"
                                    component={ChipInput}
                                    placeholder="Add tags here"
                                    type="text"
                                    defaultValue={
                                      values.evidenceItems[index].evidenceTag
                                    }
                                  />
                                </Box>
                                {errors.evidenceItems &&
                                  errors.evidenceItems[index] &&
                                  errors.evidenceItems[index].evidenceTags &&
                                  touched.evidenceItems &&
                                  touched.evidenceItems[index].evidenceTags && (
                                    <div className="field-error">
                                      {errors.evidenceItems[index].evidenceTags}
                                    </div>
                                  )}
                              </Box>
                              <Box style={{ marginTop: "-10px", width: "25%" }}>
                                <Box style={{ position: "relative" }}>
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        position: "absolute",
                                        top: "-20px",
                                        left: "0px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_COMMENTS}*
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <TextField
                                    name={`evidenceItems.${index}.comments`}
                                    variant="outlined"
                                    size="small"
                                    placeholder={langConstant.COMMENTS}
                                    type="text"
                                    defaultValue={
                                      values.evidenceItems[index].comments
                                    }
                                    onChange={handleChange}
                                    InputProps={{
                                      style: {
                                        paddingTop: "7.5px",
                                        paddingBottom: "7.5px",
                                        margin: "0px 10px",
                                        top: "3px",
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box style={{ marginTop: "-10px", width: "25%" }}>
                                <Box style={{ position: "relative" }}>
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        position: "absolute",
                                        top: "-20px",
                                        left: "0px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_EXPIRY_DATE}*
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      style={{ marginRight: "1em" }}
                                      inputVariant="outlined"
                                      variant="inline"
                                      format="dd-MMM-yy"
                                      autoOk
                                      minDate={moment()}
                                      disablePast
                                      value={
                                        values.evidenceItems[index].expiryDate
                                      }
                                      onChange={(val) =>
                                        setFieldValue(
                                          `evidenceItems.${index}.expiryDate`,
                                          val
                                        )
                                      }
                                      // InputProps={{ readOnly: true }}
                                      // className={classNames(classes.input, classes.dateInput)}
                                      // error={touched.v_start_date && Boolean(errors.v_start_date)}
                                      // helperText={touched.v_start_date && errors.v_start_date}
                                      // disabled={this.state.canEdit}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "20px",
                                  position: "relative",
                                }}
                              >
                                {index == 0 ? (
                                  <Typography
                                    style={{
                                      position: "absolute",
                                      top: "-40px",
                                      left: "-20px",
                                      fontSize: "14px",
                                      width: "80px",
                                    }}
                                  >
                                    {langConstant.UPLOAD_FILE_REQUIRED}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                                <UploadFileComponent
                                  setFieldValue={setFieldValue}
                                  index={index}
                                  handleChange={handleChange}
                                  values={values}
                                />
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "20px",
                                }}
                              >
                                <IconButton
                                  type="button"
                                  className="secondary"
                                  onClick={() => remove(index)}
                                >
                                  <DeleteIcon style={{ color: "#F0142F" }} />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                        <Button
                          type="button"
                          onClick={() =>
                            push({
                              evidenceType: "select",
                              evidenceClassification: "select",
                              evidenceTag: [],
                              evidenceFile: "",
                            })
                          }
                          color="primary"
                          style={{
                            // color: "#007BFF",
                            // fontWeight: "400",
                            // fontSize: "14px",
                            margin: "20px 0 0 30px",
                          }}
                        >
                          <LoupeIcon style={{ marginRight: "10px" }} />
                          {langConstant.ADD_MORE_ATTACHMENT}
                        </Button>
                      </div>
                    )}
                  />
                  <br />
                  {/* <button
                  onClick={(event) => {
                    event.preventDefault();
                    handleReset();
                  }}
                >
                  Reset
                </button> */}

                  <Box
                    mt={3}
                    display="flex"
                    style={{ width: "25%", margin: "0 auto" }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="default"
                      size="small"
                      className={rootClasses.greenButton}
                      startIcon={<PublishIcon />}
                    >
                      {langConstant.UPLOAD_FILE}
                    </Button>
                    <Button
                      color="primary"
                      style={{
                        marginLeft: "20px",
                      }}
                      onClick={toggleDialog}
                    >
                      {langConstant.CANCEL}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          />
        )}
      </DialogBox>
    </Box>
  );
};

AttachEvidence.propTypes = {
  questionId: PropTypes.string,
  submissionId: PropTypes.number,
  isDisabled: PropTypes.bool,
  rfiID: PropTypes.number,
};

export default AttachEvidence;
