/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import { combineReducers } from "redux";
import authentication from "./containers/LoginPage/reducers";
import regulationList from "./containers/manage-regulation/NewRegulation/reducers";
import assessmentList from "./containers/cyber-assessment/MyAssessments/reducers";
import vendorRiskManagementList from "./containers/vendor-management/VendorRiskManagement/reducers";
import vendorRiskManageAssesment from "./containers/vendor-management/VendorRiskManagement/InitiateAssessment/reducers";
import supplierQuestionnaireList from "./containers/vendor-management/SupplierQuestionnaireManagement/reducers";
import supplierQuestionnaireAssessment from "./containers/vendor-management/SupplierQuestionnaireManagement/InitiateAssessment/reducers";
import rfiAssessmentList from "./containers/cyber-assessment/ManageRfi/reducers";
import rfiListForAssessment from "./containers/cyber-assessment/ManageRfi/ManageRfiProcess/reducers";
import commentsList from "./_components/CommentSection/reducers";
import questionDraft from "./containers/manage-regulation/NewRegulation/QuestionsMapping/reducers";
import manageQuestions from "./containers/manage-regulation/NewRegulation/ManageQuestions/reducers";
import manageIndividualQuestion from "./containers/manage-regulation/NewRegulation/IndividualQuestion/reducers";
import myAssessments from "./containers/cyber-assessment/MyAssessments/InitiateAssessment/reducers";
import manageAssessment from "./containers/cyber-assessment/ManageAssessments/reducers";
import uploadData from "./containers/cyber-assessment/UploadDataForm/reducers";
import reviewAssessmentDetails from "./containers/cyber-assessment/ManageAssessments/ReviewAssessment/reducers";
import approveRegulationDetails from "./containers/manage-regulation/NewRegulation/ApproveRegulation/reducers";
import assignAssessment from "./containers/cyber-assessment/ManageAssessments/AssignAssessment/reducers";
import assessmentEvidenceLocker from "./containers/cyber-assessment/AssessmentEvidence/reducers";
import uploadEvidence from "./_components/AttachEvidence/reducers";
import evidenceLocker from "./_components/EvidenceLocker/reducers";
import policyReducer from "containers/manage-regulation/Policy/PolicyExplorer/reducers";
import manageTeamMember from "./containers/cyber-assessment/ManageTeam/reducers";
import manageRegulators from "./containers/manage-regulation/Regulators/reducers";
import stepper from "_components/Stepper/reducer";
import audit from "./containers/cyber-assessment/Audits/reducers";
import MasterAssessment from "_components/MasterAssessmentComp/utils/reducers";
import reviewManagerReducer from "containers/cyber-assessment/ManageAssessments/NewReviewAssessment/utils/reducers";
import MyAssessmnetReducer from "containers/cyber-assessment/MyAssessments/NewInitiateMyAssessment/reducer";
import uploadPolicyReducer from "containers/manage-regulation/Policy/PolicyExplorer/component/NewPolicyUpload/reducers";
import auditActions from "containers/cyber-assessment/AuditActions/reducers";
import complianceReducer from "containers/ComplianceCenter/reducers";

import {
  alert,
  loading,
  loadingFile,
  loaderTextReducer,
  countriesList,
  sectorsList,
  notificationsList,
  confirmationDialog,
  controlGroupsList,
  userCalendarEntries,
} from "./_reducers";

const reducers = combineReducers({
  authentication,
  alert,
  loading,
  loadingFile,
  loaderTextReducer,
  countriesList,
  sectorsList,
  regulationList,
  assessmentList,
  vendorRiskManagementList,
  supplierQuestionnaireList,
  rfiAssessmentList,
  rfiListForAssessment,
  questionDraft,
  manageQuestions,
  manageIndividualQuestion,
  confirmationDialog,
  controlGroupsList,
  vendorRiskManagementAssesmentDetails:
    vendorRiskManageAssesment.vendorRiskManagementAssesmentDetails,
  vendorRiskManagementRfiDetails:
    vendorRiskManageAssesment.vendorRiskManagementRfiDetails,
  supplierQuestionnaireAssessmentDetails:
    supplierQuestionnaireAssessment.supplierQuestionnaireAssessmentDetails,
  supplierQuestionnaireRfiDetails:
    supplierQuestionnaireAssessment.supplierQuestionnaireRfiDetails,
  initiateAssessmentDetails: myAssessments.initiateAssessmentDetails,
  initiateRfiDetails: myAssessments.initiateRfiDetails,
  initiateAssessmentEachQuestionData: myAssessments.EachQuestionData,
  commentsList,
  reviewAssessmentDetails,
  approveRegulationDetails,
  assignAssessment,
  manageAssessment,
  notificationsList,
  uploadData,
  assessmentEvidenceLocker,
  uploadEvidence,
  evidenceLocker,
  userCalendarEntries,
  policyReducer,
  manageTeamMember,
  manageRegulators,
  stepper,
  audit,
  MasterAssessment,
  reviewManagerReducer,
  MyAssessmnetReducer,
  uploadPolicyReducer,
  auditActions,
  complianceReducer,
});
export default reducers;
