import React, { memo, useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Button,
  DialogActions,
  InputLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";
import jsPDF from "jspdf";
import { Download, Info, Visibility } from "@mui/icons-material";
import { Box, DialogTitle, Grid, Typography } from "@material-ui/core";
import { QuestionAnswer } from "@material-ui/icons";

const PDFViewer = memo(({ data }) => {
  const themeDetails = useSelector(getThemeDetails);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [customHeader, setCustomHeader] = useState("");
  const [customFooter, setCustomFooter] = useState("");
  const [headerAlignment, setHeaderAlignment] = useState("center");
  const [actionType, setActionType] = useState("");
  const [open, setOpen] = useState(false);

  const generatePdf = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 40;
    let y = margin + 40;

    const title = "Standardized Document";
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text(title, pageWidth / 2, y, { align: "center" });
    y += 30;

    // Add Custom Header
    const addHeader = (doc, header, alignment) => {
      if (header) {
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);

        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 40;

        if (alignment === "left") {
          doc.text(header, margin, margin);
        } else if (alignment === "right") {
          const textWidth = doc.getTextWidth(header);
          const x = pageWidth - margin - textWidth;
          doc.text(header, x, margin);
        } else if (alignment === "center") {
          doc.text(header, pageWidth / 2, margin, { align: "center" });
        }
      }
    };
    // Add Footer
    const addFooter = (doc, footer, pageNumber, totalPages) => {
      if (footer) {
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        const footerText = `${footer}`;
        doc.text(footerText, pageWidth / 2, pageHeight - margin, {
          align: "center",
        });
      }
      // Page Number
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const pageNumberText = `Page ${pageNumber} of ${totalPages}`;
      doc.text(pageNumberText, pageWidth - margin, pageHeight - margin, {
        align: "right",
      });
    };

    let content = Object.values(
      data.reduce((acc, current) => {
        if (!acc[current.SECTION_NAME]) {
          acc[current.SECTION_NAME] = {
            header: current.SECTION_NAME,
            metadata: [],
          };
        }
        if (current.modified_sentence_copy) {
          acc[current.SECTION_NAME].metadata.push(
            current.modified_sentence_copy
          );
        }
        return acc;
      }, {})
    );

    const renderContent = () => {
      content.forEach((section, index) => {
        // Render Section Header
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);

        const wrappedHeader = doc.splitTextToSize(
          `${index + 1}. ${section.header}`,
          pageWidth - 2 * margin
        );
        doc.text(wrappedHeader, margin, y);
        y += wrappedHeader.length * 14 + 10;

        // Render Metadata
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);

        if (Array.isArray(section.metadata)) {
          section.metadata.forEach((item, metaIndex) => {
            const wrappedText = doc.splitTextToSize(
              `${metaIndex + 1}. ${item}`,
              pageWidth - 2 * margin - 20
            );
            doc.text(wrappedText, margin + 20, y);
            y += wrappedText.length * 14 + 10;

            // Check if new page is required
            if (y > pageHeight - 2 * margin) {
              const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
              const totalPages = doc.internal.getNumberOfPages();
              addFooter(doc, customFooter, currentPage, totalPages);

              doc.addPage();
              y = margin + 40;
              addHeader(doc, customHeader, headerAlignment);
            }
          });
        } else {
          doc.text("No metadata available for this section.", margin + 20, y);
          y += 20;
        }

        y += 20; // Add spacing between sections
      });
    };

    // Render Content and Footer
    addHeader(doc, customHeader, headerAlignment);
    renderContent();

    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      addFooter(doc, customFooter, i, totalPages);
    }

    return doc;
  };

  const handleFormSubmit = () => {
    setFormOpen(false);

    const doc = generatePdf();
    if (actionType === "preview") {
      // Generate preview
      const pdfBlob = doc.output("blob");
      const url = URL.createObjectURL(pdfBlob);
      setPreviewUrl(url);
      setPreviewOpen(true);
    } else if (actionType === "download") {
      // Trigger download
      doc.save("Sentences-document.pdf");
    }
  };

  // Open Form Dialog
  const handleAction = (type) => {
    setActionType(type);
    setFormOpen(true);
  };

  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "flex-end",
        // gap: "10px",
        marginTop: "5px",
      }}
    >
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Key to Understanding the Formatting
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexWrap="wrap" sx={{ gap: "10px !important" }}>
            {/* First Row */}
            <Box
              sx={{
                backgroundColor: "#f8d7da",
                padding: "6px 12px",
                borderRadius: 1,
              }}
            >
              <Typography variant="body2">Original Text</Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#f5c6cb",
                padding: "6px 12px",
                borderRadius: 1,
              }}
            >
              <Typography variant="body2">Text to aemend</Typography>
            </Box>

            {/* Second Row */}
            <Box
              sx={{
                backgroundColor: "#d4edda",
                padding: "6px 12px",
                borderRadius: 1,
              }}
            >
              <Typography variant="body2">Recommended / Edited Text</Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#a3d9a5",
                padding: "6px 12px",
                borderRadius: 1,
              }}
            >
              <Typography variant="body2">Agent recommended changes</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Info">
        <IconButton size="small" color="primary" onClick={() => setOpen(true)}>
          <Info />
        </IconButton>
      </Tooltip>
      <Tooltip title="Preview">
        <IconButton
          size="small"
          color="primary"
          onClick={() => handleAction("preview")}
        >
          <Visibility />
        </IconButton>
      </Tooltip>

      <Tooltip title="Download">
        <IconButton
          size="small"
          color="primary"
          onClick={() => handleAction("download")}
        >
          <Download />
        </IconButton>
      </Tooltip>

      <Dialog
        open={formOpen}
        onClose={() => setFormOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <h2>Customize Your PDF</h2>

          <TextField
            label="Custom Header"
            value={customHeader}
            onChange={(e) => setCustomHeader(e.target.value)}
            fullWidth
            style={{ marginBottom: "10px" }}
          />

          <TextField
            label="Custom Footer"
            value={customFooter}
            onChange={(e) => setCustomFooter(e.target.value)}
            fullWidth
            style={{ marginBottom: "10px" }}
          />
          <InputLabel id="demo-simple-select-label">
            Header Alignment
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={headerAlignment}
            onChange={(e) => setHeaderAlignment(e.target.value)}
            fullWidth
            style={{ marginBottom: "10px" }}
          >
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="right">Right</MenuItem>
          </Select>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            style={{
              backgroundColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
            }}
          >
            Generate
          </Button>
          <Button onClick={() => setFormOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {previewUrl && (
            <div>
              <iframe
                src={previewUrl}
                title="PDF Preview"
                style={{ width: "100%", height: "600px", border: "none" }}
              ></iframe>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
});

PDFViewer.propTypes = {
  data: PropTypes.array,
};
export default PDFViewer;
