import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  ListItemIcon,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import langConstant from "_lang";
import PropTypes from "prop-types";
import AppPagination from "_components/AppPagination";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPolicies } from "../../actions";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
// import PolicyUploadPopup from '../PolicyUploadPopup';
import { alertActions, loadingActions } from "_actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
// import Switch from '@mui/material/Switch';
import PolicyServices from "../../../PolicyExplorer/services";
import { setPolicyData } from "../../actions";
import DataTable from "./component/dataTable";
import { groupingOnDocumentTypeOrOwner } from "./utils";
import GroupingDataTable from "./component/groupingDataTable";
import TuneIcon from "@material-ui/icons/Tune";
import FilterComponent from "./component/filterComponent";
import CompareIcon from "@mui/icons-material/Compare";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Menu, MenuItem } from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PolicySearchComponent from "./component/policySearchComponent";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import ActionMenu from "./component/actionMenu";
import { scrollToTop } from "_helpers/utils";
import ChatIcon from "@mui/icons-material/Chat";
import SelectPageRange from "_components/SelectPageRange";
import ChatBox from "_components/ChatBot/ChatBox";
import ChatBotServices from "./ChatBot/services";
import DocumentSplitter from "./ChatBot/documentSplitter";
import { getThemeDetails } from "containers/LoginPage/reducers";
import ChatServices from "./ChatBot/services";
import Refrences from "_components/ChatBot/Refrences";
import { Add } from "@mui/icons-material";
import PolicyUploadForm from "./component/policyUploadForm";

// import PolicyUpload from '../NewPolicyUpload';

const useStyles = makeStyles(() => ({
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
    backgroundColor: ({ actionButtonColor }) =>
      actionButtonColor + " !important",
  },
  iconButton: {
    backgroundColor: ({ actionButtonColor }) =>
      actionButtonColor + " !important",
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
  },
  disabledButton: {
    backgroundColor: "grey !important",
  },
  actionButton: {
    color: "#fff",
    fontSize: "18px !important",
    margin: "0 auto",
  },
  progressWraper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000057",
    zIndex: 9999999,
    // height: "100%",
    // width: "100%",
  },
  progressBar: {
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    margin: "auto",
    zIndex: "9",
  },
}));

const PolicyDataTable = ({
  columns,
  selectedPolicies,
  userID,
  setPoliciesDetailToCompare,
  policiesDetailToCompare,
  setNewPolicyShow,
  runComparison,
  setPolicyReRun,
  setMetaData,
  setPolicyPreview,
  policyPreview,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });

  const permissionData = useSelector(
    (state) => state.authentication?.userData?.allowedNavItems
  );

  const dispatch = useDispatch();
  let rows = useSelector((state) => state.policyReducer.policies);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterData, setFilterData] = useState({
    orderByID: "",
    orderByName: "",
    clickBy: "",
    searchText: "",
    isCheckBoxSelected: false,
    isArchived: "0",
    document_language: "all",
    document_owner: "all",
    document_type: "all",
    document_option: {
      value: "document_type",
      name: langConstant.DOCUMENT_TYPE_VIEW,
    },
    uploaded_by: "all",
    isDocumentOptionView: false,
  });
  const [documentLanguages, setDocumentLanguages] = useState([
    { value: "all", name: "All" },
  ]);
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [documentOwners, setDocumentOwners] = useState([
    { value: "all", name: "All" },
  ]);
  const [documentTypes, setDocumentTypes] = useState([
    { value: "all", name: "All" },
  ]);
  const [groupingData, setGroupingData] = useState({});
  const [filterPopUp, setFilterPopUp] = useState(false);
  const [refPopUp, setRefPopUp] = useState(false);
  const [refData, setRefData] = useState([]);
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [advanceSearchResult, setAdvanceSearchResult] = useState({});
  const [uploadedByOptions, setUploadedByOptions] = useState([
    { value: "all", name: "All" },
  ]);
  const [isChatBoxAvailable, setIsChatBoxAvailable] = useState(false);
  const [documentSplitterPopUp, setDocumentSplitterPopUp] = useState(false);

  const handleChatBoxClose = () => {
    setIsChatBoxAvailable(false);
  };

  const documentOption = [
    { value: "explorer_view", name: langConstant.DOCUMENT_EXPLORER_VIEW },
    { value: "document_owner", name: langConstant.DOCUMENT_OWNER_VIEW },
    { value: "document_type", name: langConstant.DOCUMENT_TYPE_VIEW },
    { value: "uploaded_by", name: langConstant.UPLOADED_BY_VIEW },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const viewHandleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const viewHandleClose = () => {
    setAnchorEl(null);
  };

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const handleMenuClick = (event) => {
    event.preventDefault();
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(rows));

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );
  const [isLoading, setIsLoading] = useState(false);
  const [splittedDataList, setSplittedDataList] = useState([]);
  const [openAddPolicyForm, setOpenAddPolicyForm] = useState(false);

  const handleClickOpenForm = () => {
    setOpenAddPolicyForm(true);
  };

  const handleCloseForm = () => {
    setOpenAddPolicyForm(false);
  };

  const [initialValues, setInitialValues] = useState({
    CHUNK_SIZE: "600",
    CHUNK_OVERLAP: "100",
    DATABASE: "2",
    DATABASE_COLLECTION: "my_information3",
    EMBEDDING: "1",
  });
  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const advanceSearch = async () => {
    const response = await PolicyServices.getPolicyAdvanceSearch(
      filterData.searchText,
      userID
    );
    setSearchPopUp(true);
    if (response.status && response.status === 200) {
      setAdvanceSearchResult({
        documentTag: response["#result-set-1"],
        documentText: response["#result-set-2"].sort(
          (a, b) => b.score - a.score
        ),
      });
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const handleArchiveChange = async (isChecked, policyID) => {
    dispatch(loadingActions.start());
    const response = await PolicyServices.updatePolicyArchiveStatus({
      userID: userID,
      policyID: policyID,
      status: isChecked ? 1 : 0,
    });
    dispatch(loadingActions.end());
    if (response.status === 200) {
      if (isChecked) {
        dispatch(
          alertActions.success(langConstant.ADDED_SUCCESSFULLY_TO_ARCHIVED)
        );
      } else {
        dispatch(
          alertActions.success(langConstant.REMOVED_SUCCESSFULLY_FROM_ARCHIVED)
        );
      }
      let tempPoliciesData = [...rows];
      const policyIndex = tempPoliciesData.findIndex(
        (item) => item.POLICY_ID === policyID
      );
      tempPoliciesData[policyIndex]["ARCHIVED"] = isChecked ? 1 : 0;
      dispatch(setPolicyData(tempPoliciesData));
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  const getDocumentControlData = async () => {
    dispatch(
      PolicyServices.getDocumentControlInformation(
        userID,
        true,
        setDocumentOwners,
        setDocumentTypes,
        setDocumentLanguages
      )
    );
  };

  useEffect(() => {
    (async () => {
      await getDocumentControlData();
    })();
  }, []);

  useEffect(() => {
    let tempUploadedByOptions = _.reject(
      _.uniq(_.map(rows, "UPLOADED_BY")).sort(),
      _.isNull
    );

    if (tempUploadedByOptions.length > 0 && tempUploadedByOptions[0]) {
      tempUploadedByOptions = tempUploadedByOptions.map((item) => {
        return { value: item, name: item };
      });
      setUploadedByOptions([
        { value: "all", name: "All" },
        ...tempUploadedByOptions,
      ]);
    }

    const updatedRows =
      rows.length > 0
        ? rows.map((item) => {
            const data = item;
            let folderData = [];
            if (item.STORAGE_FOLDER_URL) {
              folderData = item.STORAGE_FOLDER_URL?.split("/");
            }
            const documentOwner = data["DOCUMENT_OWNER"]
              ? JSON.parse(data["DOCUMENT_OWNER"])
              : null;
            const documentType = data["DOCUMENT_TYPE"]
              ? JSON.parse(data["DOCUMENT_TYPE"])
              : null;
            let documentTypeString = "";
            if (documentType && documentType.length) {
              for (let i = 0; i < documentType.length; i++) {
                documentTypeString =
                  documentTypeString + documentType[i].label + ", ";
              }
              documentTypeString = documentTypeString.trim();
              documentTypeString = documentTypeString.slice(0, -1);
            }
            data["DOCUMENT_OWNER_DATA"] = (
              <span>
                {documentOwner && documentOwner["label"]
                  ? documentOwner["label"]
                  : langConstant.NOT_AVAILABLE}
              </span>
            );
            data["DOCUMENT_TYPE_DATA"] = (
              <span>
                {documentTypeString
                  ? documentTypeString
                  : langConstant.NOT_AVAILABLE}
              </span>
            );
            data["POLICY_NAME_WITH_INFO"] = (
              <Box style={{ display: "flex", alignItems: "center" }}>
                {item.POLICY_NAME}
              </Box>
            );
            data["DOCUMENT_TYPE_STRING"] = documentTypeString;
            data["ACTION"] = (
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title={langConstant.ACTIONS} placement="top">
                  <Box
                    className={classes.actionWrap}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      handleMenuClick(e);
                      setSelectedPolicy(item);
                    }}
                  >
                    <MenuIcon
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        margin: "0 auto",
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            );

            data["Extraction_Status_Icon"] = (
              <Box>
                {item.Extraction_Status === "success" ? (
                  <Tooltip title={langConstant.SUCCESS} placement="top">
                    <CheckCircleIcon color="success" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      item.Extraction_Status === "unsuccessful"
                        ? langConstant.FAILED
                        : langConstant.INCOMPLETE
                    }
                    placement="top"
                  >
                    <CancelIcon
                      sx={{
                        color:
                          item.Extraction_Status === "unsuccessful"
                            ? "#c13636"
                            : "#d48d31",
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            );

            return data;
          })
        : [];

    const updatedData = filterFunction(updatedRows);
    if (filterData.document_option.value !== "explorer_view") {
      const documentGroupingData = groupingOnDocumentTypeOrOwner(
        updatedData,
        filterData.document_option.value,
        documentTypes
      );
      setGroupingData(documentGroupingData);
    } else {
      setFilteredRows(updatedData);
      setPageCount(calculatePageCount(updatedData));
    }
  }, [rows, filterData]);

  const updatePolicyMetaData = (policydata) => {
    if (policydata["POLICY_META_DATA"]) {
      const policyMetaDataValues = JSON.parse(
        window.atob(policydata["POLICY_META_DATA"])
      );
      const metaDataValues = {
        document: policyMetaDataValues["fileName"],
        document_name: policyMetaDataValues["aliasName"],
        pages_extraction_start: policyMetaDataValues["startPage"],
        pages_extraction_end: policyMetaDataValues["endPage"],
        // normal_text: policyMetaDataValues['normalText'],
        // titles_list: policyMetaDataValues['titlesList'],
        // headers_list: policyMetaDataValues['headersText'],
        // footers_list: policyMetaDataValues['footersText'],
        document_language: policyMetaDataValues["document_language"]
          ? policyMetaDataValues["document_language"]
          : "",
        document_type: policyMetaDataValues["document_type"]
          ? policyMetaDataValues["document_type"]
          : "",
        document_owner: policyMetaDataValues["document_owner"]
          ? policyMetaDataValues["document_owner"]
          : "",
        document_tags: policyMetaDataValues["document_tags"]
          ? policyMetaDataValues["document_tags"]
          : "",
        document_extension: policyMetaDataValues["document_extension"]
          ? policyMetaDataValues["document_extension"]
          : "",
        is_pre_proccesing:
          policyMetaDataValues["is_pre_proccesing"] ||
          policyMetaDataValues["cleanDocument"]
            ? true
            : false,
        upload_only: Object.keys(policyMetaDataValues).includes("upload_only")
          ? policyMetaDataValues["upload_only"]
          : policydata["EXTRACTION_FILE_NAME"]
          ? false
          : true,
      };
      if (Object.keys(policyMetaDataValues).includes("cleanDocument")) {
        const payload = {
          policyId: policydata["POLICY_ID"],
          metaData: metaDataValues,
        };
        PolicyServices.updatePolicyMetaData(payload);
      }
      metaDataValues["policy_id"] = policydata["POLICY_ID"];
      metaDataValues["extraction_status"] = policydata.Extraction_Status;
      setPolicyReRun(true);
      setNewPolicyShow(true);
      setMetaData(metaDataValues);
    } else {
      dispatch(alertActions.error(langConstant.POLICY_META_DATA_NOT_AVAILABLE));
    }
  };

  const selectPoliciesToCompare = (policyId, isChecked, policy) => {
    if (isChecked) {
      if (selectedPolicies.length === 2) {
        let tempSelectedPolicies = [...selectedPolicies];
        let tempPoliciesDetailToCompare = [...policiesDetailToCompare];
        tempPoliciesDetailToCompare.shift();
        tempPoliciesDetailToCompare.push(policy);
        tempSelectedPolicies.shift();
        tempSelectedPolicies.push(policyId);
        dispatch(setSelectedPolicies(tempSelectedPolicies));
        setPoliciesDetailToCompare(tempPoliciesDetailToCompare);
      } else {
        dispatch(setSelectedPolicies([...selectedPolicies, policyId]));
        setPoliciesDetailToCompare([...policiesDetailToCompare, policy]);
      }
    } else {
      setPoliciesDetailToCompare(
        policiesDetailToCompare.filter((item) => item["POLICY_ID"] !== policyId)
      );
      dispatch(
        setSelectedPolicies(
          selectedPolicies.filter((item) => item !== policyId)
        )
      );
    }
  };

  const pageSetter = (sortedData) => {
    const totalrows = calculatePageCount(sortedData);
    if (page + 1 > totalrows) {
      setPage(0);
    }
  };

  const clearFilterSearchText = () => {
    setFilterData((prevState) => ({ ...prevState, searchText: "" }));
  };

  const filterFunction = (data) => {
    let sortedData = data;
    if (filterData.orderByID !== "" && filterData.clickBy === "sortByID") {
      sortedData = sortedData.sort((a, b) =>
        filterData.orderByID === "ASC"
          ? a.POLICY_ID - b.POLICY_ID
          : b.POLICY_ID - a.POLICY_ID
      );
      pageSetter(sortedData);
    }
    if (filterData.orderByName !== "" && filterData.clickBy === "sortByName") {
      sortedData = sortedData.sort((a, b) =>
        filterData.orderByName === "ASC"
          ? a.POLICY_NAME.localeCompare(b.POLICY_NAME)
          : b.POLICY_NAME.localeCompare(a.POLICY_NAME)
      );
      pageSetter(sortedData);
    }
    if (filterData.isCheckBoxSelected === true) {
      sortedData = sortedData.filter(
        (policy) => policy.EXTRACTION_FILE_NAME !== null
      );
      pageSetter(sortedData);
    }
    if (filterData.uploaded_by !== "all") {
      sortedData = sortedData.filter(
        (policy) => policy["UPLOADED_BY"] === filterData.uploaded_by
      );
    }

    if (filterData.isArchived !== "all") {
      if (filterData.isArchived == "1") {
        sortedData = sortedData.filter((policy) => policy["ARCHIVED"] == "1");
      } else {
        sortedData = sortedData.filter((policy) => policy["ARCHIVED"] != "1");
      }
    }

    if (filterData.document_language !== "all") {
      sortedData = sortedData.filter(
        (policy) => policy["LANGUAGE_CODE"] === filterData.document_language
      );
    }

    if (filterData.document_owner !== "all") {
      sortedData = sortedData.filter((policy) => {
        const documentOwner = policy["DOCUMENT_OWNER"]
          ? JSON.parse(policy["DOCUMENT_OWNER"])
          : null;
        if (documentOwner && documentOwner["value"]) {
          if (documentOwner["value"] == filterData.document_owner) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }

    if (filterData.document_type !== "all") {
      sortedData = sortedData.filter((policy) => {
        const documentType = policy["DOCUMENT_TYPE"]
          ? JSON.parse(policy["DOCUMENT_TYPE"])
          : null;
        if (documentType && documentType.length) {
          const documentTypeList = documentType.map((item) => {
            return item.value;
          });
          if (documentTypeList.includes(filterData.document_type)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }

    return sortedData;
  };

  const onFilterChange = (fieldName, e) => {
    setFilterData((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const onEnterKeyPress = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      advanceSearch();
    }
  };

  const findGroupRowData = () => {
    if (filterData.document_option.value == "document_owner") {
      return documentOwners;
    } else if (filterData.document_option.value == "document_type") {
      return documentTypes;
    } else if (filterData.document_option.value == "uploaded_by") {
      return uploadedByOptions;
    }
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredRows.length > 0
        ? Math.round(Math.ceil(filteredRows.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  let splitid = null;
  const splitingData = async () => {
    const splitterResponse = await ChatServices.getSplitterDetails();
    if (splitterResponse.status === 200) {
      splitid = splitterResponse["#result-set-1"][0]?.SPLITTER_ID;
    } else {
      dispatch(alertActions.error("Splitter Service Failed"));
    }
    await ifNotAdministrationDebug();
  };

  const performSplitting = async () => {
    const payload = {
      FolderPath: selectedPolicy.STORAGE_FOLDER_URL,
      FileName: selectedPolicy.POLICY_FILE_NAME,
      ChunkSize: initialValues.CHUNK_SIZE,
      ChunkOverlap: initialValues.CHUNK_OVERLAP,
      PolicyID: selectedPolicy.POLICY_ID,
      SplitterID: parseInt(splitid),
      EmbeddingID: parseInt(initialValues.EMBEDDING),
      DbID: parseInt(initialValues.DATABASE),
      docStorageType: docStorageType.toLowerCase(),
      docStorageIP: docStorageIP,
      docStorageURL: docStorageURL,
    };
    setIsLoading(true);
    const response = await ChatServices.performSplits(payload);
    setIsLoading(false);
    if (response.status) {
      if (response.status == 200) {
        if (
          response.data &&
          response.data.Splits &&
          response.data.Splits.length
        ) {
          setSplittedDataList(response.data.Splits);
          return true;
        } else {
          dispatch(alertActions.error("Splitting Data Not Available"));
          return false;
        }
      }
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
      return false;
    }
  };

  const ifNotAdministrationDebug = async () => {
    if (splittedDataList.length > 0) {
      setIsChatBoxAvailable(true);
    } else {
      const splitResponse = await performSplitting();
      if (splitResponse) {
        setIsChatBoxAvailable(true);
      }
    }
  };

  const checkChunkingAvailability = async () => {
    const response = await ChatBotServices.getPolicyChunkingAvailable(
      selectedPolicy.POLICY_ID
    );
    if (response.status === 200) {
      if (response["DOC_ID_Exists"]) {
        setIsChatBoxAvailable(true);
      } else {
        // permissionData.push("USER_ADMINISTRATION/DEBUG");
        if (permissionData.includes("USER_ADMINISTRATION/DEBUG")) {
          setDocumentSplitterPopUp(true);
        } else {
          await splitingData();
        }
      }
    } else {
      dispatch(alertActions.error("Policy Chunking Service Failed"));
    }
  };

  return (
    <Box>
      {isLoading && (
        <Grid className={classes.progressWraper}>
          {" "}
          <CircularProgress className={classes.progressBar} />{" "}
        </Grid>
      )}
      <PolicyUploadForm open={openAddPolicyForm} onClose={handleCloseForm} />
      <Grid container spacing={5} alignItems="center">
        <Grid
          item
          xs={6}
          md={3}
          spacing={1}
          style={{ textAlign: "left" }}
          container
        >
          <Grid item md={2} xs={6}>
            <IconButton
              className={classes.iconButton}
              onClick={runComparison}
              disabled={
                permissionData
                  ? !permissionData.includes(
                      "POLICY_MANAGEMENT/POLICY_COMPARISONS"
                    )
                  : true
              }
              classes={{ disabled: classes.disabledButton }}
            >
              <Tooltip title={langConstant.RUN_COMPARISON}>
                <CompareIcon style={{ color: "white" }} />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item md={2} xs={6}>
            <IconButton
              className={classes.iconButton}
              onClick={() => setNewPolicyShow(true)}
            >
              <Tooltip title={langConstant.NEW_POLICY}>
                <CloudUploadIcon style={{ color: "white" }} />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item md={2} xs={6}>
            <IconButton
              className={classes.iconButton}
              onClick={() => setFilterPopUp(true)}
            >
              <Tooltip title={langConstant.SET_FILTERS}>
                <TuneIcon style={{ color: "white" }} />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item md={2} xs={6}>
            <IconButton className={classes.iconButton}>
              <Tooltip title={langConstant.CHANGE_VIEW}>
                <ChangeCircleIcon
                  style={{ color: "white" }}
                  onClick={viewHandleClick}
                />
              </Tooltip>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={viewHandleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {documentOption.map((option, optionIndex) => {
                  return (
                    <MenuItem
                      style={{ fontWeight: "500" }}
                      key={optionIndex}
                      onClick={() => {
                        viewHandleClose();
                        setFilterData({
                          ...filterData,
                          document_option: option,
                        });
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </IconButton>
          </Grid>
          <Grid item md={2} xs={6}>
            <IconButton
              className={classes.iconButton}
              onClick={handleClickOpenForm}
            >
              <Tooltip title={langConstant.ADD_POLICY}>
                {/* <Link
                  to={{
                    pathname: `policy-generasion`,
                  }}
                  className="linkIcon"
                >
                </Link> */}
                <Add style={{ color: "white" }} />
              </Tooltip>
            </IconButton>
          </Grid>
          {/* <Grid item md={2} xs={6}>
                        <IconButton className={classes.iconButton}>
                            <Tooltip title={langConstant.CHAT}>
                                <ChatIcon style={{backgroundColor: "#124571", color: "white"}}/>
                            </Tooltip>
                        </IconButton>
                    </Grid> */}
        </Grid>
        <Grid item xs={6} md={4} container>
          <Grid item xs={9} md={10}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="searchText"
              value={filterData.searchText}
              name="searchText"
              placeholder={langConstant.ENTER_KEYWORDS_TO_SEARCH_POLICY}
              onChange={(e) =>
                setFilterData({ ...filterData, searchText: e.target.value })
              }
              onKeyUp={(e) => onEnterKeyPress(e)}
              InputProps={{
                autoComplete: "off",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} md={2} style={{ textAlign: "center" }}>
            <IconButton className={classes.iconButton} onClick={advanceSearch}>
              <Tooltip title={langConstant.SEARCH}>
                <SearchIcon style={{ color: "white" }} />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} style={{ textAlign: "right" }}>
          {filterData.document_option.value === "explorer_view" &&
            pageCount > 1 && (
              <Box my={2}>
                <Grid container justify="flex-end">
                  <Grid style={{ paddingBottom: "5px" }}>
                    <SelectPageRange
                      selectedRange={rowsPerPage}
                      handleChange={handleSelectPageRange}
                    />
                  </Grid>
                  <AppPagination
                    colSpan={3}
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </Box>
            )}
        </Grid>
      </Grid>
      {filterPopUp && (
        <FilterComponent
          show={filterPopUp}
          setShow={setFilterPopUp}
          filterData={filterData}
          onFilterChange={onFilterChange}
          documentLanguages={documentLanguages}
          documentOwners={documentOwners}
          documentTypes={documentTypes}
          setFilterData={setFilterData}
          uploadedByOptions={uploadedByOptions}
        />
      )}
      {filterData.document_option.value !== "explorer_view" ? (
        <GroupingDataTable
          groupingData={groupingData}
          filterData={filterData}
          setFilterData={setFilterData}
          groupRowsData={findGroupRowData()}
          selectedPolicies={selectedPolicies}
          selectPoliciesToCompare={selectPoliciesToCompare}
          policyPreview={policyPreview}
        />
      ) : (
        <DataTable
          columns={columns}
          selectedPolicies={selectedPolicies}
          filteredRows={filteredRows}
          selectPoliciesToCompare={selectPoliciesToCompare}
          setFilterData={setFilterData}
          filterData={filterData}
          policyPreview={policyPreview}
          rowsPerPage={rowsPerPage}
          page={page}
        />
      )}
      {searchPopUp && (
        <PolicySearchComponent
          show={searchPopUp}
          setShow={setSearchPopUp}
          advanceSearchResult={advanceSearchResult}
          clearFilterSearchText={clearFilterSearchText}
        />
      )}
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          handleMenuClose={handleMenuClose}
          openMenu={openMenu}
          selectedPolicy={selectedPolicy}
          updatePolicyMetaData={updatePolicyMetaData}
          handleArchiveChange={handleArchiveChange}
          checkChunkingAvailability={checkChunkingAvailability}
          setPolicyPreview={setPolicyPreview}
        />
      )}
      <ChatBox
        handleClose={handleChatBoxClose}
        isChatBoxAvailable={isChatBoxAvailable}
        selectedPolicy={selectedPolicy}
        entitlement="AI_ASSIST_PM_POLICY"
        setRefPopUp={setRefPopUp}
        setRefData={setRefData}
      />
      {refPopUp && <Refrences datalist={refData} setRefPopUp={setRefPopUp} />}
      {documentSplitterPopUp && (
        <DocumentSplitter
          open={documentSplitterPopUp}
          setOpen={setDocumentSplitterPopUp}
          selectedPolicy={selectedPolicy}
          setIsChatBoxAvailable={setIsChatBoxAvailable}
        />
      )}
    </Box>
  );
};

PolicyDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  selectedPolicies: PropTypes.array,
  userID: PropTypes.string,
  policiesDetailToCompare: PropTypes.array,
  setPoliciesDetailToCompare: PropTypes.func,
  setNewPolicyShow: PropTypes.func,
  runComparison: PropTypes.func,
  setPolicyReRun: PropTypes.func,
  setMetaData: PropTypes.func,
  setPolicyPreview: PropTypes.func,
  policyPreview: PropTypes.object,
};

export default PolicyDataTable;
