import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";
import propTypes from "prop-types";
import { QUESTION_STATUS_TYPES } from "_components/MasterAssessmentComp/utils/constants";
import { ActionPanel } from "../ActionPanel";
import { TopBarView } from "./components/TopBarView";
import { QuestionView } from "./components/QuestionView";
import { GraphsContainer } from "./components/GraphsContainer";
import ApproversList from "./components/ApproversList";
import RfiGraph from "./components/Graphs/RfiGraph";
import NoDataCard from "./components/Graphs/NoDataCard";
import { styles } from "./styles";
import { GetRFIStatusForSubmission } from "./services";
import { RfiKpis } from "./components/CenterKpiView";

const DashboardView = ({
  questionList,
  selectedQuestion,
  getActions,
  questionResponses,
  kpiData = {
    completedPercentage: 0,
    progress: 0,
    controlCount: 0,
    completeCount: 0,
  },
  hideStepper = true,
  assessmentId,
  submissionId,
  approversList,
  actions,
  assessmentDetails,
  assessmentKpiData,
  regulatorsList,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const [rfiGraphData, setRfiGraphData] = useState(null);

  const approverKpis = {
    required: approversList?.length,
    approved: approversList?.filter((i) => i?.APPROVAL_STATUS)?.length,
    rejected: approversList?.filter((i) => !i?.APPROVAL_STATUS).length,
  };
  const filterOptions = Object.values(QUESTION_STATUS_TYPES)
    ?.map((i) => ({
      value: i,
      label: i?.replaceAll("_", " "),
      type: "status",
    }))
    .concat(
      questionResponses?.map((i) => ({
        value: i?.RESPONSE_OPTION_ID,
        label: i?.RESPONSE_OPTION,
        type: "decision",
        payload: i,
      }))
    );

  const getRfiGraphData = async () => {
    if (isNaN(submissionId)) return;
    try {
      const response = await GetRFIStatusForSubmission(submissionId);
      if (response?.status !== 200) throw new Error("Unable to fetch data");
      setRfiGraphData(response?.["#result-set-1"]);
    } catch (error) {
      console.error("error", error);
      // showAlert(error);
    }
  };

  useEffect(() => {
    getRfiGraphData();
    if (!hideStepper) return;
    let stepper = document.getElementById("stepper");
    if (stepper) stepper.style.display = "none";
    return () => {
      if (stepper) stepper.style.display = "flex";
    };
  }, []);

  return (
    <React.Fragment>
      <Card
        id="elementToCapture"
        sx={{
          my: 1,
          backgroundColor: "#F0F4F8",
          border: "none",
          overflow: "auto",
          // scrollbarWidth: "none",
          height: "100%",
        }}
        variant="outlined"
      >
        <CardContent
          sx={{
            p: 2,
            display: "flex",
            gap: 2,
            flex: "auto",
            flexDirection: "column",
          }}
        >
          <Box>
            <TopBarView
              assessmentDetails={assessmentDetails}
              kpiData={kpiData}
              assessmentKpiData={{
                ...assessmentKpiData,
                SENT_BACK:
                  questionList?.filter(
                    (i) =>
                      i?.QUESTION_STATUS === QUESTION_STATUS_TYPES.SENT_BACK
                  )?.length || 0,
              }}
            />
          </Box>

          <Box
            height="50vh"
            flex="auto"
            alignItems="stretch"
            display="flex"
            gap={2}
          >
            <QuestionView
              questionList={questionList}
              filterOptions={filterOptions}
              regulatorsList={regulatorsList}
            />
            <GraphsContainer SUBMISSION_ID={submissionId} />
          </Box>

          {/* <Box height="15vh">
            <CenterKpiView
              rfiKpis={{
                overdue: assessmentKpiData?.RFI_OVERDUE,
                pending: assessmentDetails?.RFI_PENDING,
                sent: assessmentDetails?.RFI_SENT,
              }}
              evidenceKpis={evidenceKpiData}
              approverKpis={approverKpis}
            />
          </Box> */}
          <Box
            height="50vh"
            overflow="hidden"
            alignItems="stretch"
            display="flex"
            gap={2}
          >
            <Box
              display="flex"
              p={1}
              borderRadius="4px"
              gap={2}
              bgcolor="white"
              flex={0.6}
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                bgcolor="white"
                flex={1}
                overflow="auto"
                scrollbehavior="smooth"
              >
                <Typography fontWeight="500" variant="subtitle1">
                  Action Panel
                </Typography>

                <ActionPanel getActions={getActions} actions={actions} />
              </Box>
              <Divider flexItem orientation="vertical" />
              <ApproversList approvers={approversList} kpis={approverKpis} />
            </Box>
            <Box
              sx={{
                ...styles.whiteWrapper,
                flex: 0.4,
                overflow: "auto",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography fontWeight="500" variant="subtitle1">
                RFI Graph
              </Typography>
              <RfiKpis
                overdue={assessmentKpiData?.RFI_OVERDUE}
                pending={assessmentDetails?.RFI_PENDING}
                sent={assessmentDetails?.RFI_SENT}
                resolved={assessmentDetails?.RFI_RESOLVED}
              />
              <>
                {rfiGraphData?.length ? (
                  <RfiGraph data={rfiGraphData} />
                ) : (
                  <NoDataCard />
                )}
              </>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

DashboardView.propTypes = {
  questionList: propTypes.array,
  selectedQuestion: propTypes.object,
  questionResponses: propTypes.array,
  getActions: propTypes.func,
  kpiData: propTypes.object,
  hideStepper: propTypes.bool,
  assessmentId: propTypes.oneOfType([propTypes.number, propTypes.string]),
  submissionId: propTypes.oneOfType([propTypes.number, propTypes.string]),
  approversList: propTypes.array,
  actions: propTypes.array,
  assessmentDetails: propTypes.object,
  assessmentKpiData: propTypes.object,
  regulatorsList: propTypes.array,
};

export default DashboardView;
