/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  "@keyframes zsiqcwhanim": {
    "0%": { transform: "translate(0)", opacity: 1 },
    "100%": { transform: "translate(0,50em)", opacity: 0 },
  },
  serviceConnectionWrapper: {
    width: "500px",
    backgroundColor: "#fff",
    paddingTop: "20px",
  },
  chatIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ actionButtonColor }) => actionButtonColor,
    borderRadius: "100%",
    position: "fixed",
    bottom: "20px",
    right: "30px",
    zIndex: "999",
    width: "60px",
    height: "60px",
    cursor: "pointer",
  },

  chatBox: {
    position: "fixed",
    bottom: "-100%",
    right: "20px",
    borderRadius: "20px",
    boxShadow: "0 9.5px 12.7px 10px rgba(0,0,0,.05)",
    width: "370px",
    height: "600px",
    backgroundColor: "#eaeef3",
    overflow: "hidden",
    zIndex: "999",
    maxWidth: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
    transition: "all 0.5s linear",
    animation: ".5s zsiqcwanim",
    left: "auto",
    top: "auto",
  },
  ChatBoxShow: {
    bottom: "10px !important",
  },
  extendedView: {
    width: "75% !important",
    height: "550px !important",
    transition: "all 0.5s linear",
  },
  chatboxWrap: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  chatboxheader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    background: "#ffffff",
    width: "100%",
    position: "relative",
    boxShadow: "0 9.5px 12.7px 0 rgba(0,0,0,.05)",
  },
  chatboxicon: {
    width: "60px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ actionButtonColor }) => actionButtonColor,
    borderRadius: "100%",
    position: "relative",
  },
  chatStatus: {
    borderRadius: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    bottom: "7%",
    display: "block",
    height: "16%",
    position: "absolute",
    right: "7%",
    width: "16%",
    borderColor: "rgb(255, 255, 255)",
    background: "rgb(105, 222, 64)",
  },
  chatboxnamewrap: {
    width: "calc(100% - 60px)",
    paddingLeft: "15px",
  },
  chatName: {
    marginTop: "0",
    marginBottom: "0px",
  },
  chatonline: {
    margin: "0",
  },
  chatclose: {
    position: "absolute",
    right: "10px",
    top: "10px",
    fontSize: "19px",
    cursor: "pointer",
  },
  message: {
    position: "relative",
  },
  avatar: {
    borderRadius: "100%",
    height: "20px",
    marginRight: "6px",
    overflow: "hidden",
    width: "20px",
  },
  avatarImg: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
  },
  caption: {
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",
    marginBottom: "8px",
  },
  conversation: {
    padding: "20px 15px",
    display: "flex",
    flex: "1 0 0",
    flexDirection: "column",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    overscrollBehaviorY: "contain",
    width: "100%",
  },
  startAgain: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    width: "100%",
  },
  startBtN: {
    fontSize: "16px",
    color: "#fff",
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    padding: "10px",
    fontWeight: 400,
    width: "100%",
    textAlign: "center",
    borderRadius: "5px",
  },
  poweredBy: {
    background: "#f9f9f9",
    textAlign: "center",
    fontSize: "12px",
    padding: "8px 10px",
    borderTop: "1px solid #efecec",
  },
  poweredName: {
    color: "#9ba6b2",
  },
  poweredLink: {
    color: "#124571",
    textDecoration: "none",
    fontWeight: "bold",
  },
  botResponse: {
    display: "flex",
    msFlexDirection: "column",
    flexDirection: "column",
    marginBottom: "10px",
    width: "100%",
  },
  botRepliesText: {
    wordWrap: "break-word",
    borderRadius: "20px",
    fontSize: "14px",
    lineHeight: "20px",
    maxWidth: "100%",
    padding: "15px 17px",
    whiteSpace: "pre-wrap",
    background: "#ffffff",
    color: "#000000",
    borderBottomLeftRadius: "5px",
  },
  chatInputWrapper: {
    width: "100%",
    WebkitBoxFlex: "1",
    background: "transparent",
    border: "0",
    msFlex: "1 0 0px",
    flex: "1 0 0",
    fontSize: "16px",
    height: "100%",
    lineHeight: "20px",
    padding: "0 20px",
  },
  typing: {
    background: "#ffffff",
    display: "flex",
    width: "100%",
    borderTop: "1px solid #eaeaea",
    height: "56px",
  },
  borderNone: {
    border: "none !important",
  },
  messageYou: {
    textAlign: "right",
    width: "100%",
    padding: "10px",
  },
  messageYouIcon: {
    fontWeight: "bold",
  },
  inputMessageText: {
    wordWrap: "break-word",
    borderRadius: "20px",
    fontSize: "14px",
    lineHeight: "20px",
    maxWidth: "100%",
    padding: "15px 17px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    backgroundColor: ({ actionButtonColor }) => actionButtonColor,
    borderBottomRightRadius: "5px",
    color: "#fff",
    fontWeight: "400",
  },
  inputMessageWrapper: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
  inputMessage: {
    marginBottom: "10px",
  },
  rootWrapper: {
    zIndex: "9999 !important",
  },
  bouncingLoader: {
    display: "flex",
    justifyContent: "start",
  },
  loaderDiv: {
    width: "8px",
    height: "8px",
    margin: "3px 2px",
    borderRadius: "50%",
    backgroundColor: "#a3a1a1",
    opacity: "1",
    animation: "$loader123 0.6s infinite alternate",
    "&:nth-child(2)": {
      animationDelay: "0.2s",
    },
    "&:nth-child(3)": {
      animationDelay: "0.4s",
    },
  },

  "@keyframes loader123": {
    to: {
      opacity: "0.1",
      // transform: 'translateY(-16px)',
    },
    from: {
      opacity: "1",
      //  transform: 'translateY(0px)',
    },
  },
}));

export default useStyles;
