/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SaveIcon from "@material-ui/icons/Save";
import rootStyles from "rootStyles";

import langConstant from "_lang";
import initiateAssessmentAction from "./../actions";
import evidenceAction from '../../../../../_components/EvidenceLocker/actions'
import individualQuestionAction from "../../../../manage-regulation/NewRegulation/IndividualQuestion/actions";
import CheckIcon from "@material-ui/icons/Check";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import moment from "moment";
import styles from "../styles";
import DataPointQuality from "_components/DataPointQuality";
import Truncate from "_components/Truncate";
import CurrentAnswerData from "./CurrentAnswerData"
import sessionService from "_services/session.service";



const SelectAnswer = (props) => {
  const userID = sessionService.getUserId();
  const rootClasses = rootStyles();
  const dispatch = useDispatch();

  const customerID = sessionService.getCustomerId()

  const { questionData } = props;
  const {
    REGULATORS_QUESTION_REF,
  } = questionData;

  let dueDate = "";
  let pastDate = "";
  let currentRfi = [];
  let currentQuestion = [];
  let dateInPast = false;

  const allRfi = useSelector((state) => state.supplierQuestionnaireRfiDetails.rfiItems);
  const evidenceList = useSelector((state) => state.evidenceLocker.evidence);
  const sqmAssesmentDetails = useSelector((state) => state.supplierQuestionnaireAssessmentDetails);
  const questionResponses = useSelector(
    (state) => state.manageIndividualQuestion.questionResponses
  );
  const narrative = sqmAssesmentDetails.narrative;
  const allQuestions = sqmAssesmentDetails.questionList;
  currentQuestion = allQuestions?.length && allQuestions.filter(
    (question) => question.REGULATORS_QUESTION_REF == REGULATORS_QUESTION_REF
  );
  const dqProfile = sqmAssesmentDetails.dqProfile;
  let QUESTION_STATUS;
  const dataAssetsList = sqmAssesmentDetails.dataAssetsList;
  const supplierQuestionAssetList = sqmAssesmentDetails.supplierQuestionList;


  if (currentQuestion?.length && currentQuestion[0]) {
    QUESTION_STATUS = currentQuestion[0].QUESTION_STATUS;
  }

  if (allRfi?.length) {
    currentRfi = allRfi.filter(
      (rfi) => rfi.REGULATORS_QUESTION_REF == REGULATORS_QUESTION_REF
    );
  }

  if (currentRfi.length != 0) {
    dueDate = moment(new Date(currentRfi[0].RFI_DUE_DATE.slice(0, 10))).format(
      "DD-MMM-YY"
    );

    let now = moment(new Date()).format("DD-MMM-YY h:mm:ss a");
    let duePlusOne = new Date(currentRfi[0].RFI_DUE_DATE);
    duePlusOne.setDate(duePlusOne.getDate() + 1);
    let dateToCheck = moment(duePlusOne).format("DD-MMM-YY h:mm:ss a");

    if (moment(dateToCheck).isBefore(now)) {
      dateInPast = true;
      pastDate = moment(dateToCheck, "DD-MMM-YY")
        .fromNow()
        .split(" ")
        .slice(0, 2)
        .join(" ");
    } else {
      dateInPast = false;
    }
  }
  const [questionState, setQuestionState] = useState(
    questionData.QUESTION_STATUS === 'RESOLVED' ? true : false
  );
  const [highestRating, setHighestRating] = useState(0);

  // const [tabsData, setTabsData] = useState({ dataAssetsList: dataAssetsList ? dataAssetsList : [], evidenceList: evidenceList ? evidenceList : [] });
  // const [option, setOption] = React.useState('');

  const returnHighestRating = (data) => {
    return data.matches.reduce(
      (max, p) =>
        (p.score > max ? p.score : max),
      data.currentAnswerScore
    );
  };
  const goBackOnError = () => { };
  const markResolved = (e) => {
    setQuestionState(!e.target.checked);
    dispatch(initiateAssessmentAction.updateQuestionStatus(
      questionData.QUESTION_ID,
      questionData.SUBMISSION_ID,
      e.target.checked ? 'RESOLVED' : 'DRAFT',
      goBackOnError,
      userID));
  };
  const handleFormInputChange = (attributeType, id, questionData, value) => {
    dispatch(initiateAssessmentAction.handleDataEntryChange(attributeType, id, questionData, value));
  };

  useEffect(() => {
    setQuestionState(questionData.QUESTION_STATUS === 'RESOLVED' ? true : false);
    setHighestRating(returnHighestRating(questionData));
  }, [questionData]);


  useEffect(() => {
    setQuestionState(questionData.QUESTION_STATUS === 'RESOLVED' ? true : false);
    dispatch(initiateAssessmentAction.setSelectedQuestion(questionData));
    dispatch(initiateAssessmentAction.getQuestionDQProfile(questionData.id));
    dispatch(initiateAssessmentAction.getSupplierQuestionResponses(questionData.id, questionData.SUBMISSION_ID,customerID));
    dispatch(evidenceAction.getEvidence(questionData.id,questionData.SUBMISSION_ID));
    if (!questionData.QUESTION_COMPLIANCE_DECISION) {
      dispatch(individualQuestionAction.getIndividualQuestionResponses(questionData.id, customerID));
    }
  }, [questionData.id]);

  return (
    <Box>
      <Box
        container
        wrap="nowrap"
        spacing={1}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box item>
          <FormControlLabel
            control={
              <Checkbox
                checked={questionState}
                color="default"
                inputProps={{ "aria-label": "Mark as Resolved" }}
                onChange={(e) => {markResolved(e)}}
                className={rootClasses.greenCheckbox}
                name="questionState"
              />
            }
            label={langConstant.MARK_AS_RESOLVED}
            labelPlacement="end"
          />
        </Box>


      </Box>

      {/* Render question  */}
      <Grid container wrap="nowrap" spacing={1}>
        <Grid item>
          <Typography color="textSecondary" variant="h6" gutterBottom>
            <strong>{questionData.refId} -</strong>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h6">{questionData.question}</Typography>
          {questionData.guidance ? (
            <Box className="info-box">
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <InfoIcon color="inherit" />
                </Grid>
                <Grid item>
                  <Truncate content={questionData.guidance} wordCount={50} />
                </Grid>
              </Grid>
            </Box>
          ) : null}{" "}
        </Grid>
      </Grid>

      {dataAssetsList?.length > 0 && dataAssetsList.map((dataEntry, index) => {
        return (
          <Box
            key={index}
            style={{
              margin: "15px 0px",
            }}
          >
            <Typography>{dataEntry.DATA_ASSET_ATTRIBUTE_NAME}</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={langConstant.PROVIDE_ANSWER}
              value={dataEntry.SOURCE_DATA_POINT_VALUE}
              // disabled={isFormDisabled}
              size="small"
              onChange={(e) =>
                handleFormInputChange(
                  "ATTRIBUTE",
                  dataEntry.DATA_ASSET_ATTRIBUTE_NAME,
                  questionData,
                  e.target.value,
                )
              }
            />
          </Box>
        );
      })}
      {supplierQuestionAssetList?.length > 0 && supplierQuestionAssetList.map((dataEntry, index) => {
        return (
          <Box
            key={index}
            style={{
              margin: "15px 0px",
            }}
          >
            <Typography>{dataEntry.DATA_ASSET_ATTRIBUTE_NAME}</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={langConstant.PROVIDE_ANSWER}
              value={dataEntry.SOURCE_DATA_POINT_VALUE}
              // disabled={isFormDisabled}
              size="small"
              onChange={(e) =>
                handleFormInputChange(
                  "ATTRIBUTE",
                  dataEntry.DATA_ASSET_ATTRIBUTE_NAME,
                  questionData,
                  e.target.value,
                )
              }
            />
          </Box>
        );
      })}
      <Box style={{
        margin: "15px 0px",
        display: "flex",
        flexDirection: "column",
      }} >
        <Typography>{langConstant.ADD_NARRATIVES}</Typography>
        <Box
          style={{
            display: "flex",
          }}
        >
          <TextField
            style={{
              flex: 1,
            }}
            id="outlined-basic"
            variant="outlined"
            value={narrative ? narrative.NARRATIVE : ''}
            placeholder={langConstant.PROVIDE_NARRATIVE}
            // disabled={isFormDisabled}
            onChange={(e) => {
              handleFormInputChange(
                "NARRATIVE",
                1,
                questionData,
                e.target.value
              );
            }}
          />
          <Box
            style={{
              width: "145px",
            }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};
SelectAnswer.propTypes = {
  questionData: PropTypes.object,
};
export default SelectAnswer;
