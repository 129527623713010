/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styles from "./styles";
import AppBreadcrumbs from "../AppBreadcrumbs";
import ErrorBoundary from "../ErrorBoundary";
import ConfirmationDialog from "../ConfirmationDialog";
import Loading from "./Loading";
import sessionService from "_services/session.service";
const Layout = (props) => {
  const classes = styles();
  const alert = useSelector((state) => state.alert);
  const loggingIn = useSelector((state) => state.authentication.loggedIn);
  const userData = useSelector((state) => state.authentication.userData);

  const userAvatar = useSelector(
    (state) => state.authentication.detailedInfo?.AVATAR_IMAGE
  );
  const primaryLogo = useSelector(
    (state) => state.authentication.detailedInfo?.PRIMARY_LOGO
  );
  const confirmationDialog = useSelector((state) => state.confirmationDialog);
  const loading = useSelector((state) => state.loading);
  const [openAlert, setAlertOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tempLogin, setTempLogin] = useState(null);

  useEffect(() => {
    if (alert && alert.message) {
      setAlertOpen(true);
    }
  }, [alert]);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSidebarOpen = () => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  };

  useEffect(() => {
    setTempLogin(sessionService.getTempLogin());
  }, [userData]);

  return (
    <>
      <Loading show={loading} />
      <ConfirmationDialog
        state={confirmationDialog.state}
        onConfirm={confirmationDialog.onConfirm}
        message={confirmationDialog.message}
        title={confirmationDialog.title}
        option1={confirmationDialog.option1}
        option2={confirmationDialog.option2}
      />
      <Snackbar
        style={{ zIndex: 999999999 }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert && alert.message && openAlert}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        {tempLogin !== null && (
          <>
            {loggingIn && !tempLogin ? (
              <React.Fragment>
                <NavBar
                  handleSidebarOpen={handleSidebarOpen}
                  userData={userData}
                  userAvatar={userAvatar}
                  primaryLogo={primaryLogo}
                  setTempLogin={setTempLogin}
                />
                <Sidebar open={sidebarOpen} />
                <main className={classes.content}>
                  <ErrorBoundary>
                    <AppBreadcrumbs {...props} />
                    {props.children}
                  </ErrorBoundary>
                </main>
              </React.Fragment>
            ) : (
              <ErrorBoundary>{props.children}</ErrorBoundary>
            )}
          </>
        )}
      </div>
    </>
  );
};
Layout.propTypes = {
  children: PropTypes.element,
};
export default Layout;
