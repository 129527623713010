import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  TextField,
  Modal,
  MenuItem,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  Stack,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  Add,
  Edit,
  Close,
  Check,
  Remove,
  ArrowForward,
  ArrowBack,
  Visibility,
  Download,
  Settings,
  Save,
} from "@mui/icons-material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { alertActions } from "_actions";
import ExtractionService from "../../../../PolicyExplorer/component/NewPolicyUpload/services";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import jsPDF from "jspdf";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InfiniteScroll from "react-infinite-scroll-component";
import UploadPhoto from "_components/UploadPhoto";
import ExtractionServicesForUpload from "../../NewPolicyUpload/services";
import sessionService from "_services/session.service";
import langConstant from "_lang";
import { loadingActions } from "_actions";
import { styled } from "@mui/material/styles";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
    backgroundColor: ({ actionButtonColor }) =>
      actionButtonColor + " !important",
  },
  iconButton: {
    backgroundColor: ({ actionButtonColor }) =>
      actionButtonColor + " !important",
    display: "inline-flex !important",
    borderRadius: "50% !important",
    // padding: "10px !important",
    cursor: "pointer !important",
  },
  disabledButton: {
    backgroundColor: "grey !important",
  },
  actionButton: {
    color: "#fff",
    fontSize: "18px !important",
    margin: "0 auto",
  },
}));

const PolicyGenerasion = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  //intital data
  const [data, setData] = useState([]);
  const [editorStatesMap, setEditorStatesMap] = useState({});

  //Searching
  const [searchTerm, setSearchTerm] = useState("");
  const [replaceTerm, setReplaceTerm] = useState("");
  const [occurrencesCount, setOccurrencesCount] = useState(0);

  //infinite loading data
  const [loaderData, setloaderData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(data.length >= 5 ? 5 : 1);

  // Modal for adding a new header
  const [isModalOpen, setModalOpen] = useState(false);
  const [newHeaderName, setNewHeaderName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("end");
  const [specificHeaderIndex, setSpecificHeaderIndex] = useState("");

  // Navigation
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [headerFound, setHeaderFound] = useState(false);
  const [clickedRowItem, setClickedRowItem] = useState(null);
  const scrollableRef = useRef();

  // pdf download
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isPreviewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfTitle, setPdfTitle] = useState("");
  const [pdfAuthorName, setPdfAuthorName] = useState("");
  const [pdfVersion, setPdfVersion] = useState("");
  const [docVersionPosition, setDocVersionPosition] = useState(true);
  const [logoPhotoView, setLogoPhotoView] = useState(false);
  const [logoImage, setLogoImage] = useState(null);

  const [headerPosition, setHeaderPosition] = useState("left");
  const [customHeader, setCustomHeader] = useState("");
  const [headerImagePosition, setHeaderImagePosition] = useState("right");
  const [headerPhotoView, setHeaderPhotoView] = useState(false);
  const [hederImage, setHeaderImage] = useState(null);

  const [footerPosition, setFooterPosition] = useState("left");
  const [customFooter, setCustomFooter] = useState("");
  const [pageNumbering, setPageNumbering] = useState(true);
  const [footerImagePosition, setFooterImagePosition] = useState("right");
  const [footerPhotoView, setFooterPhotoView] = useState(false);
  const [footerImage, setFooterImage] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [policyDetails, setpolicyDetails] = useState({});
  const [versionDescription, setVersionDescription] = useState("");
  const [flatDataWithPageNumber, setFlatDataWithPageNumber] = useState([]);
  const [versionData, setVersionData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        border: "1px dashed #ccc",
      },
      "&:hover fieldset": {
        borderColor: "#aaa",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6200ea",
      },
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: 500,
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      const policyResponse =
        await ExtractionService.getPolicyExtractionDetailsByPage({
          policyId: id,
          pageNo: null,
        });
      if (policyResponse.status == 200) {
        const result = [];
        let stack = [];

        policyResponse["#result-set-1"].forEach((item) => {
          const currentLevel = item.LEVEL.length;
          const currentItem = {
            POLICY_ID: item.POLICY_ID,
            EXTRACTION_CSV_FILE_NAME: item.EXTRACTION_CSV_FILE_NAME,
            LEVEL: item.LEVEL,
            SENTENCES: JSON.parse(item.SENTENCES),
            SENTENCESDATA: JSON.parse(item.SENTENCES),
            NAME: item.NAME,
            ROW_ITEM_NO: item.ROW_ITEM_NO,
            ITEM_BODY_CONTENT: item.ITEM_BODY_CONTENT,
            PAGE_NUMBER: null,
            children: [],
          };

          while (
            stack.length &&
            stack[stack.length - 1].LEVEL.length >= currentLevel
          ) {
            stack.pop();
          }

          if (stack.length === 0) {
            result.push(currentItem);
          } else {
            stack[stack.length - 1].children.push(currentItem);
          }

          stack.push(currentItem);
        });
        setData(result);
      } else {
        dispatch(alertActions.error("Policy Extraction Details SP Failed."));
      }
      const data = await ExtractionService.getPolicyDocVersion({
        i_POLICY_ID: parseInt(id),
      });
      setVersionData(data);
    };

    if (id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    updateOccurrencesCount(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    loadMoreHeaders();
  }, [data]);

  useEffect(() => {
    const headerElement = document.getElementById(`header-${clickedRowItem}`);
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      setTimeout(() => {
        const headerElement = document.getElementById(
          `header-${clickedRowItem}`
        );
        if (headerElement) {
          headerElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 50);
    }
  }, [headerFound, clickedRowItem]);

  const loadMoreHeaders = (flag = -1) => {
    if (isLoading || page > data.length) return;

    setIsLoading(true);
    const newHeaders = data.slice(
      0,
      page + 4 > data.lengt ? page + 1 : page + 4
    );

    if (newHeaders.length === 0) {
      setHasMore(false);
      setIsLoading(false);
      return;
    }
    if (data.length == 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setloaderData((prevData) => [...newHeaders]);
    setPage((prevPage) => prevPage + 1);

    if (page + 1 >= data.length) {
      setHasMore(false);
    }

    if (flag != -1) {
      const findIndexByRowItemNo = (data) => {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          if (item.ROW_ITEM_NO === flag) {
            return i;
          }

          if (item.children.length > 0) {
            const result = findIndexByRowItemNo(item.children);
            if (result !== -1) {
              return i;
            }
          }
        }
        return -1;
      };
      const mainArrayIndex = findIndexByRowItemNo(data);
      if (loaderData.length < mainArrayIndex) {
        setloaderData(data.slice(0, mainArrayIndex));
        setPage(mainArrayIndex);
        setHeaderFound(true);
        setClickedRowItem(flag);
        setData((prev) => [...prev]);
      }
    }

    setIsLoading(false);
  };
  const highlightText = (text) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleReplace = (mode) => {
    let firstOccurrenceReplaced = false;

    const newData = data.map((header) => {
      if (mode === "first" && !firstOccurrenceReplaced) {
        const newHeaderName = header.NAME.replace(
          new RegExp(searchTerm, "i"),
          (match) => {
            firstOccurrenceReplaced = true;
            return replaceTerm;
          }
        );
        header.NAME = newHeaderName;
      } else if (mode === "all") {
        header.NAME = header.NAME.replace(
          new RegExp(searchTerm, "gi"),
          replaceTerm
        );
      }

      header.SENTENCES = header.SENTENCES.map((sentence) => {
        if (mode === "first" && !firstOccurrenceReplaced) {
          return sentence.replace(new RegExp(searchTerm, "i"), (match) => {
            firstOccurrenceReplaced = true;
            return replaceTerm;
          });
        } else if (mode === "all") {
          return sentence.replace(new RegExp(searchTerm, "gi"), replaceTerm);
        }
        return sentence;
      });

      if (header.children && header.children.length > 0) {
        header.children = replaceChildren(
          header.children,
          mode,
          firstOccurrenceReplaced
        );
      }

      return header;
    });

    setData(newData);
    updateOccurrencesCount(searchTerm); //
  };

  const replaceChildren = (children, mode, firstOccurrenceReplaced) => {
    return children.map((child) => {
      if (mode === "first" && !firstOccurrenceReplaced) {
        const newChildName = child.NAME.replace(
          new RegExp(searchTerm, "i"),
          (match) => {
            firstOccurrenceReplaced = true;
            return replaceTerm;
          }
        );
        child.NAME = newChildName;
      } else if (mode === "all") {
        child.NAME = child.NAME.replace(
          new RegExp(searchTerm, "gi"),
          replaceTerm
        );
      }

      child.SENTENCES = child.SENTENCES.map((sentence) => {
        if (mode === "first" && !firstOccurrenceReplaced) {
          return sentence.replace(new RegExp(searchTerm, "i"), (match) => {
            firstOccurrenceReplaced = true;
            return replaceTerm;
          });
        } else if (mode === "all") {
          return sentence.replace(new RegExp(searchTerm, "gi"), replaceTerm);
        }
        return sentence;
      });

      if (child.children && child.children.length > 0) {
        child.children = replaceChildren(
          child.children,
          mode,
          firstOccurrenceReplaced
        );
      }

      return child;
    });
  };

  const swapAtLevel = (path, direction) => {
    const newData = [...data];
    let parent = newData;

    for (let i = 0; i < path.length - 1; i++) {
      parent = parent[path[i]].children;
    }

    const index = path[path.length - 1];
    const siblingIndex = direction === "up" ? index - 1 : index + 1;

    if (siblingIndex >= 0 && siblingIndex < parent.length) {
      [parent[index], parent[siblingIndex]] = [
        parent[siblingIndex],
        parent[index],
      ];

      const tempRowItemNo = parent[index].ROW_ITEM_NO;
      parent[index].ROW_ITEM_NO = parent[siblingIndex].ROW_ITEM_NO;
      parent[siblingIndex].ROW_ITEM_NO = tempRowItemNo;

      setloaderData(newData.slice(0, page));
      setData(newData);
    }
  };
  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setNewHeaderName("");
    setSelectedPosition("end");
    setSpecificHeaderIndex("");
  };

  const handleAddHeader = () => {
    const headerName = newHeaderName.trim() || `New Header ${data.length + 1}`;

    const newData = [...data];
    let position;

    switch (selectedPosition) {
      case "start":
        position = 0;
        break;
      case "end":
        position = newData.length;
        break;
      case "specific":
        position = parseInt(specificHeaderIndex, 10);
        if (isNaN(position) || position < 0 || position > newData.length) {
          alert(`Invalid index. Please select a valid header.`);
          return;
        }
        break;
      default:
        return;
    }

    const newHeader = {
      LEVEL: "H",
      POLICY_ID: id ? id : data && data[0].policyId,
      EXTRACTION_CSV_FILE_NAME: data && data[0].EXTRACTION_CSV_FILE_NAME,
      SENTENCES: [],
      NAME: headerName,
      ROW_ITEM_NO: Date.now(),
      children: [],
    };

    newData.splice(position, 0, newHeader);
    setloaderData(newData.slice(0, page));
    setData(newData);
    handleCloseModal();
  };

  const addHeader = (path = []) => {
    if (path.length === 0 && data.length > 0) {
      handleOpenModal();
      return;
    }

    const newData = [...data];
    let parent = newData;
    for (let i = 0; i < path.length; i++) {
      parent = parent[path[i]].children;
    }
    const newHeader = {
      LEVEL: "H".repeat(path.length + 1),
      SENTENCES: [],
      POLICY_ID: id ? id : data && data[0]?.policyId,
      EXTRACTION_CSV_FILE_NAME: data && data[0]?.EXTRACTION_CSV_FILE_NAME,
      NAME: `New Header ${parent.length + 1}`,
      ROW_ITEM_NO: Date.now(),
      children: [],
    };
    parent.push(newHeader);

    setloaderData(newData.slice(0, page));
    setData(newData);
  };

  const flattenAndReassignIds = async (nestedData) => {
    let flatArray = [];
    let counter = 0;

    const traverse = (items, parentRowItemNo = null) => {
      items.forEach((item) => {
        const newItem = {
          ...item,
          ROW_ITEM_NO: counter,
          // PARENT_ROW_ITEM_NO: parentRowItemNo,
          SENTENCES: JSON.stringify(item.SENTENCES),
        };

        delete newItem.children;

        flatArray.push(newItem);
        counter++;

        if (item.children && item.children.length > 0) {
          traverse(item.children, newItem.ROW_ITEM_NO);
        }
      });
    };
    traverse(nestedData);
    return flatArray;
  };
  const policyUpdated = async () => {
    const payload = {
      policyId: policyDetails?.POLICY_ID,
      data: flatDataWithPageNumber,
      policyName: policyDetails?.EXTRACTION_CSV_FILE_NAME,
      userID: userID,
      customerID: `${customerID}`,
      storageType: docStorageType.toLowerCase(),
      storePreProcessingIP: docStorageIP,
      storePreProcessingURL: docStorageURL,
    };
    dispatch(loadingActions.start());
    const saveExtraction = await ExtractionServicesForUpload.saveExtraction(
      payload
    );
    dispatch(loadingActions.end());
    if (saveExtraction.status) {
      if (saveExtraction.status == 200) {
        dispatch(alertActions.success("Save Policy"));
        ExtractionServicesForUpload.putPolicyDocVersion({
          i_POLICY_ID: id,
          i_USER_ID: userID,
          i_VERSION_DESCRIPTION: versionDescription,
        });
      } else {
        if (saveExtraction.message) {
          dispatch(alertActions.error(saveExtraction.message));
        }
      }
    } else {
      if (saveExtraction.data && saveExtraction.data.message) {
        dispatch(
          alertActions.error(
            saveExtraction.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  // flattenAndReassignIds(data);
  const updateOccurrencesCount = (searchTerm) => {
    const countOccurrences = (text, searchTerm) => {
      if (!searchTerm) return 0;
      const regex = new RegExp(searchTerm, "gi");
      const matches = text.match(regex);
      return matches ? matches.length : 0;
    };

    const countOccurrencesInData = (headers) => {
      let totalCount = 0;

      headers.forEach((header) => {
        totalCount += countOccurrences(header.NAME, searchTerm);

        header.SENTENCES.forEach((sentence) => {
          totalCount += countOccurrences(sentence, searchTerm);
        });

        if (header.children && header.children.length > 0) {
          totalCount += countOccurrencesInData(header.children);
        }
      });

      return totalCount;
    };

    const totalCount = countOccurrencesInData(data);
    setOccurrencesCount(totalCount);
  };

  const handleHeaderEditorChange = (editorState, rowItemNo) => {
    setEditorStatesMap((prevMap) => ({
      ...prevMap,
      [rowItemNo]: {
        ...prevMap[rowItemNo],
        headerEditorState: editorState,
      },
    }));
  };

  const handleSentencesEditorChange = (editorState, rowItemNo) => {
    setEditorStatesMap((prevMap) => ({
      ...prevMap,
      [rowItemNo]: {
        ...prevMap[rowItemNo],
        sentencesEditorState: editorState,
      },
    }));
  };
  // flattenAndReassignIds(data);
  const saveHeaderChanges = (rowItemNo) => {
    const editorState = editorStatesMap[rowItemNo]?.headerEditorState;
    if (editorState) {
      const newHeaderName = editorState.getCurrentContent().getPlainText();

      const updateData = (headers) => {
        for (let header of headers) {
          if (header.ROW_ITEM_NO === rowItemNo) {
            header.NAME = newHeaderName;
            return true;
          }
          if (header.children?.length > 0) {
            if (updateData(header.children)) {
              return true;
            }
          }
        }
        return false;
      };
      const newData2 = [...loaderData];
      updateData(newData2);
      setloaderData(newData2);

      const newData = [...data];
      updateData(newData);
      setData(newData);
    }
    closeHeaderEditor(rowItemNo);
  };

  const closeHeaderEditor = (rowItemNo) => {
    setEditorStatesMap((prevMap) => {
      const newMap = { ...prevMap };
      delete newMap[rowItemNo]?.headerEditorState;
      return newMap;
    });
  };

  const saveSentenceChanges = (rowItemNo) => {
    const editorState = editorStatesMap[rowItemNo]?.sentencesEditorState;
    if (editorState) {
      const newSentences = editorState
        .getCurrentContent()
        .getPlainText()
        .split("\n");

      const updateData = (headers) => {
        for (let header of headers) {
          if (header.ROW_ITEM_NO === rowItemNo) {
            header.SENTENCES = newSentences;
            return true;
          }
          if (header.children?.length > 0) {
            if (updateData(header.children)) {
              return true;
            }
          }
        }
        return false;
      };

      const newData2 = [...loaderData];
      updateData(newData2);
      setloaderData(newData2);

      const newData = [...data];
      updateData(newData);
      setData(newData);
    }
    closeSentencesEditor(rowItemNo);
  };

  const removeHeader = (path = []) => {
    const newData = [...data];
    let parent = newData;

    // Traverse to the correct parent level based on path
    for (let i = 0; i < path.length - 1; i++) {
      parent = parent[path[i]].children;
    }

    // Get the index of the header to remove
    const indexToRemove = path[path.length - 1];
    parent.splice(indexToRemove, 1);

    setloaderData(newData.slice(0, page));
    setData(newData);
  };

  const closeSentencesEditor = (rowItemNo) => {
    setEditorStatesMap((prevMap) => {
      const newMap = { ...prevMap };
      delete newMap[rowItemNo]?.sentencesEditorState;
      return newMap;
    });
  };

  const renderHeaders = (headers, path = []) => {
    return headers.map((header, index) => {
      const currentPath = [...path, index];
      const headerEditorState =
        editorStatesMap[header.ROW_ITEM_NO]?.headerEditorState;
      const sentencesEditorState =
        editorStatesMap[header.ROW_ITEM_NO]?.sentencesEditorState;

      return (
        <Paper
          key={header.ROW_ITEM_NO}
          id={`header-${header.ROW_ITEM_NO}`}
          elevation={2}
          sx={{ margin: 2, padding: 2 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {/* Header Name Editor */}
              {headerEditorState ? (
                <Box>
                  <Editor
                    editorState={headerEditorState}
                    onEditorStateChange={(newEditorState) =>
                      handleHeaderEditorChange(
                        newEditorState,
                        header.ROW_ITEM_NO
                      )
                    }
                  />
                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <Tooltip title="Save Changes" arrow>
                      <IconButton
                        color="success"
                        onClick={() => saveHeaderChanges(header.ROW_ITEM_NO)}
                      >
                        <Check sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Close Editor" arrow>
                      <IconButton
                        color="error"
                        onClick={() => closeHeaderEditor(header.ROW_ITEM_NO)}
                      >
                        <Close sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    display="flex"
                    // justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      {highlightText(header.NAME)}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Tooltip title="Edit Header" arrow>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleHeaderEditorChange(
                              EditorState.createWithContent(
                                ContentState.createFromText(header.NAME)
                              ),
                              header.ROW_ITEM_NO
                            )
                          }
                        >
                          <Edit sx={{ fontSize: 18 }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </>
              )}

              {/* Sentences Editor */}
              {sentencesEditorState ? (
                <Box sx={{ mt: 2 }}>
                  <Editor
                    editorState={sentencesEditorState}
                    onEditorStateChange={(newEditorState) =>
                      handleSentencesEditorChange(
                        newEditorState,
                        header.ROW_ITEM_NO
                      )
                    }
                  />
                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <Tooltip title="Save Sentences" arrow>
                      <IconButton
                        color="success"
                        onClick={() => saveSentenceChanges(header.ROW_ITEM_NO)}
                      >
                        <Check sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Close Editor" arrow>
                      <IconButton
                        color="error"
                        onClick={() => closeSentencesEditor(header.ROW_ITEM_NO)}
                      >
                        <Close sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{ fontSize: "14px", color: "text.secondary", flex: 1 }}
                  >
                    {highlightText(header.SENTENCES.join(" "))}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Tooltip title="Edit Sentences" arrow>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          const newEditorState = EditorState.createWithContent(
                            ContentState.createFromText(
                              header.SENTENCES.join("\n")
                            )
                          );
                          handleSentencesEditorChange(
                            newEditorState,
                            header.ROW_ITEM_NO
                          );
                        }}
                      >
                        <Edit sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
            </Box>

            <Box>
              {/* Action buttons */}
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                {index > 0 && (
                  <Tooltip title="Move Up" arrow>
                    <IconButton
                      color="primary"
                      onClick={() => swapAtLevel(currentPath, "up")}
                    >
                      <ArrowUpward sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                {index < headers.length - 1 && (
                  <Tooltip title="Move Down" arrow>
                    <IconButton
                      color="secondary"
                      onClick={() => swapAtLevel(currentPath, "down")}
                    >
                      <ArrowDownward sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Tooltip title="Add Sub-Header" arrow>
                      <IconButton
                        color="success"
                        onClick={() => addHeader(currentPath)}
                      >
                        <Add sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Header" arrow>
                      <IconButton
                        color="error"
                        onClick={() => removeHeader(currentPath)}
                      >
                        <Remove sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {header.children && header.children.length > 0 && (
            <Box sx={{ marginLeft: 4 }}>
              {renderHeaders(header.children, currentPath)}
            </Box>
          )}
        </Paper>
      );
    });
  };

  const headerAllOptions = ["left", "center", "right"];

  const getHeaderImagePositionOptions = () => {
    if (headerPosition === "left") {
      return ["center", "right"];
    } else if (headerPosition === "center") {
      return ["left", "right"];
    } else if (headerPosition === "right") {
      return ["left", "center"];
    }
    return headerAllOptions;
  };

  const footerAllOptions = ["left", "center", "right"];

  const getFooterPositionOptions = () => {
    if (pageNumbering) {
      return ["left", "right"];
    } else {
      return footerAllOptions;
    }
  };

  const getFooterImagePositionOptions = () => {
    if (pageNumbering && footerPosition == "left") {
      return ["right"];
    } else if (pageNumbering && footerPosition == "right") {
      return ["left"];
    } else if (footerPosition == "left") {
      return ["center", "right"];
    } else if (footerPosition == "right") {
      return ["left", "center"];
    }
  };

  const updateLogoPhoto = (fieldValue) => {
    setLogoImage(fieldValue);
    setLogoPhotoView(false);
  };

  const updateHeaderPhoto = (fieldValue) => {
    setHeaderImage(fieldValue);
    setHeaderPhotoView(false);
  };

  const updateFooterPhoto = (fieldValue) => {
    setFooterImage(fieldValue);
    setFooterPhotoView(false);
  };

  const generatePDF = async (type = null) => {
    const content = await flattenAndReassignIds(data);
    const doc = new jsPDF();
    const lineHeight = 8;
    const pageHeight = 297;
    const pageWidth = 210;
    const maxContentWidth = pageWidth - 40;
    let y = 30; // Start at the top of the page
    let currentPage = 1;

    const rightX = pageWidth - 20; // Align text to the right

    // Title
    if (pdfTitle) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(40);
      const titleLines = doc.splitTextToSize(pdfTitle, maxContentWidth);
      titleLines.forEach((line) => {
        doc.text(line, rightX, y, { align: "right" });
        y += 16; // Increase spacing for title
      });
    }

    // Author Name
    if (pdfAuthorName) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(24);
      const pdfAuthorNameLines = doc.splitTextToSize(
        pdfAuthorName,
        maxContentWidth
      );
      pdfAuthorNameLines.forEach((line) => {
        doc.text(line, rightX, y, { align: "right" });
        y += 12; // Increased spacing
      });
    }

    // Version Name
    if (pdfVersion) {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(20);
      const versionDetails = doc.splitTextToSize(
        "Version " + pdfVersion,
        maxContentWidth
      );
      versionDetails.forEach((line) => {
        doc.text(line, rightX, y, { align: "right" });
        y += 12; // Increased spacing
      });
    }

    // Logo
    if (logoImage) {
      y += 20; // Space before logo
      const imgData = "data:image/jpeg;base64," + logoImage;
      doc.addImage(imgData, "JPEG", pageWidth - 100, y, 80, 30);
      y += 50; // Space after logo
    }
    doc.addPage();
    currentPage++;

    // Table of Contents
    if (docVersionPosition) {
      y = 20;
      let x = 20;
      // Table Title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      const table_header = "Table Of Content";
      doc.text(table_header, pageWidth / 2, y, { align: "center" });
      y += 15;

      // Contents
      content.forEach((data) => {
        if (y + lineHeight > pageHeight - 20) {
          doc.addPage();
          currentPage++;
          y = 20;
        }

        if (data.LEVEL == "H") {
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.setLineWidth(0.25);
          doc.line(20, y - 5, pageWidth - 20, y - 5);

          const nameLines = doc.splitTextToSize(data.NAME, maxContentWidth);
          nameLines.forEach((line) => {
            doc.text(line, 20, y);
            y += lineHeight;
          });
        }

        if (data.LEVEL == "HH") {
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          const nameLines = doc.splitTextToSize(
            data.NAME,
            maxContentWidth + 10
          );
          nameLines.forEach((line) => {
            doc.text(line, 30, y);
            y += 6;
          });
        }

        if (data.LEVEL == "HHH") {
          doc.setFont("helvetica", "normal");
          doc.setFontSize(9);
          const nameLines = doc.splitTextToSize(
            data.NAME,
            maxContentWidth + 20
          );
          nameLines.forEach((line) => {
            doc.text(line, 40, y);
            y += 6;
          });
        }
      });

      // Move to the Document Version page
      if (versionData.length > 0) {
        doc.addPage();
        currentPage++;
        y = 20;

        const tableStartX = 10;
        const tableWidth = 190;
        const headerHeight = 10;
        const rowHeight = 10;
        const cellPadding = 3;

        // Define column widths
        const columnWidths = [40, 100, 50];

        doc.setFont("helvetica", "bold");
        doc.setFontSize(16);
        doc.text("Document Version", 105, 15, { align: "center" });

        doc.setFontSize(12);
        doc.rect(tableStartX, y, columnWidths[0], headerHeight);
        doc.rect(
          tableStartX + columnWidths[0],
          y,
          columnWidths[1],
          headerHeight
        );
        doc.rect(
          tableStartX + columnWidths[0] + columnWidths[1],
          y,
          columnWidths[2],
          headerHeight
        );

        doc.text(
          "Date",
          tableStartX + cellPadding,
          y + headerHeight / 2 + cellPadding
        );
        doc.text(
          "Description",
          tableStartX + columnWidths[0] + cellPadding,
          y + headerHeight / 2 + cellPadding
        );
        doc.text(
          "Contributor",
          tableStartX + columnWidths[0] + columnWidths[1] + cellPadding,
          y + headerHeight / 2 + cellPadding
        );

        y += headerHeight;

        // Draw Table Content
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        versionData.forEach((user) => {
          if (y + rowHeight > pageHeight - 20) {
            doc.addPage();
            y = 20;
          }

          // Extract and format user data
          const createDate = user.CREATE_DATE
            ? user.CREATE_DATE.split("T")[0]
            : "N/A";
          const description = user.VERSION_DESCRIPTION || "No Description";
          const contributor = `${user.FIRST_NAME || ""} ${
            user.LAST_NAME || ""
          }`.trim();

          // Handle wrapping text and calculate row height dynamically
          const descriptionLines = doc.splitTextToSize(
            description,
            columnWidths[1] - cellPadding * 2
          );
          const contributorLines = doc.splitTextToSize(
            contributor,
            columnWidths[2] - cellPadding * 2
          );
          const maxLines = Math.max(
            descriptionLines.length,
            contributorLines.length
          );
          const dynamicRowHeight = maxLines * 5 + cellPadding * 2;

          // Draw table row borders for each cell
          doc.rect(tableStartX, y, columnWidths[0], dynamicRowHeight);
          doc.rect(
            tableStartX + columnWidths[0],
            y,
            columnWidths[1],
            dynamicRowHeight
          ); // Description column
          doc.rect(
            tableStartX + columnWidths[0] + columnWidths[1],
            y,
            columnWidths[2],
            dynamicRowHeight
          ); // Contributor column

          // Render text inside each cell with padding
          doc.text(createDate, tableStartX + cellPadding, y + cellPadding + 5); // Date cell
          descriptionLines.forEach((line, index) => {
            doc.text(
              line,
              tableStartX + columnWidths[0] + cellPadding,
              y + cellPadding + 5 + index * 5
            );
          });
          contributorLines.forEach((line, index) => {
            doc.text(
              line,
              tableStartX + columnWidths[0] + columnWidths[1] + cellPadding,
              y + cellPadding + 5 + index * 5
            );
          });

          y += dynamicRowHeight; // Move to the next row
        });

        y += 10;
      }
      doc.addPage();
      currentPage++;
      y = 20;

      content.forEach((item) => {
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);

        if (y + lineHeight > pageHeight - 20) {
          doc.addPage();
          currentPage++;
          y = 20;
        }

        const headerLines = doc.splitTextToSize(item.NAME, maxContentWidth);
        headerLines.forEach((line) => {
          doc.text(line, 10, y); // Left-align the heading
          y += lineHeight;
        });

        y += 5;

        // Update the page number for the heading
        item.PAGE_NUMBER = currentPage;
        item.SENTENCES = JSON.parse(item.SENTENCES);
        item.SENTENCESDATA = item.SENTENCES;
        item.ITEM_BODY_CONTENT = item.SENTENCESDATA.join(" ");

        // Set font for the description (normal)
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);

        // Render each sentence as part of the description
        item.SENTENCES.forEach((sentence) => {
          const sentenceLines = doc.splitTextToSize(sentence, maxContentWidth);
          sentenceLines.forEach((line) => {
            if (y + lineHeight > pageHeight - 20) {
              doc.addPage();
              currentPage++;
              y = 20;
            }
            doc.text(line, 20, y);
            y += lineHeight;
          });

          y += 2;
        });

        y += 10;
      });

      // let x = 20;
      if (docVersionPosition) {
        let flagPage = 2;
        doc.setPage(2);
        y = 20;
        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.text(table_header, pageWidth / 2, y, { align: "center" });
        y += 15;

        // Contents
        content.forEach((data) => {
          if (y + lineHeight > pageHeight - 20) {
            flagPage++;
            doc.setPage(flagPage);
            y = 20;
          }

          if (data.LEVEL == "H") {
            doc.setFont("helvetica", "bold");
            doc.setFontSize(12);
            doc.setLineWidth(0.25);
            doc.line(20, y - 5, pageWidth - 20, y - 5);

            const nameText = data.NAME;
            const pageText = `${data.PAGE_NUMBER}`;
            const textWidth =
              ((doc.getStringUnitWidth(pageText) * 12) / 72) * 25.4;
            doc.text(nameText, 20, y);
            doc.text(pageText, pageWidth - 20 - textWidth, y);
            y += lineHeight;
          }

          if (data.LEVEL == "HH") {
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);

            const nameText = data.NAME;
            const pageText = `${data.PAGE_NUMBER}`;
            const textWidth =
              ((doc.getStringUnitWidth(pageText) * 10) / 72) * 25.4;
            doc.text(nameText, 30, y);
            doc.text(pageText, pageWidth - 20 - textWidth, y);
            y += 6;
          }

          if (data.LEVEL == "HHH") {
            doc.setFont("helvetica", "normal");
            doc.setFontSize(9);

            const nameText = data.NAME;
            const pageText = `${data.PAGE_NUMBER}`;
            const textWidth =
              ((doc.getStringUnitWidth(pageText) * 9) / 72) * 25.4;
            doc.text(nameText, 40, y);
            doc.text(pageText, pageWidth - 20 - textWidth, y);
            y += 6;
          }
        });
      }

      // Add custom headers, footers, page numbers
      const pageCount = doc.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);

        // Custom Header
        if (customHeader) {
          const x =
            headerPosition === "left"
              ? 10
              : headerPosition === "center"
              ? pageWidth / 2
              : pageWidth - 10;
          doc.text(customHeader, x, 10, { align: headerPosition });
        }

        if (hederImage) {
          const x =
            headerImagePosition === "left"
              ? 10
              : headerImagePosition === "center"
              ? pageWidth / 2
              : pageWidth - 30;
          const headerImgData = "data:image/jpeg;base64," + hederImage;
          doc.addImage(headerImgData, "JPEG", x, 5, 20, 8);
        }

        // Custom Footer
        if (pageNumbering) {
          doc.text(`Page ${i}`, pageWidth / 2, pageHeight - 10, {
            align: "center",
          });
        }

        if (customFooter) {
          const footerX =
            footerPosition === "left"
              ? 10
              : footerPosition === "center"
              ? pageWidth / 2
              : pageWidth - 10;

          const align =
            footerPosition === "left"
              ? "left"
              : footerPosition === "center"
              ? "center"
              : "right";
          doc.text(customFooter, footerX, pageHeight - 10, { align });
        }

        if (footerImage) {
          const x =
            footerImagePosition === "left"
              ? 10
              : footerImagePosition === "center"
              ? pageWidth / 2
              : pageWidth - 30;
          const footerImgData = "data:image/jpeg;base64," + footerImage;
          doc.addImage(footerImgData, "JPEG", x, pageHeight - 16, 20, 8);
        }
      }
    }
    setpolicyDetails({
      POLICY_ID: content[0].POLICY_ID,
      EXTRACTION_CSV_FILE_NAME: content[0].EXTRACTION_CSV_FILE_NAME
        ? content[0].EXTRACTION_CSV_FILE_NAME
        : versionData[0].POLICY_NAME,
    });
    // Final steps
    const transformedObj = content.reduce((acc, curr, index) => {
      acc[index] = [curr];
      return acc;
    }, {});

    setFlatDataWithPageNumber(transformedObj);

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl);

    if (type) {
      if (type === "preview") {
        setPreviewDialogOpen(true);
      } else if (type === "download") {
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `${pdfTitle || "Document"}.pdf`;
        link.click();
      }
    } else {
      if (actionType === "preview") {
        setPreviewDialogOpen(true);
      } else if (actionType === "download") {
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `${pdfTitle || "Document"}.pdf`;
        link.click();
      }
    }

    setDialogOpen(false);
  };

  const handleAction = (type) => {
    setActionType(type);
    setDialogOpen(true);
  };

  const ensureHeaderLoaded = async (rowItemNo) => {
    setClickedRowItem(rowItemNo);
    loadMoreHeaders(rowItemNo);
  };

  const scrollToHeader = async (rowItemNo) => {
    let isLoaded = await ensureHeaderLoaded(rowItemNo);

    if (isLoaded) {
      const headerElement = document.getElementById(`header-${rowItemNo}`);
      if (headerElement) {
        headerElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const renderNavHeaders = (headers) => {
    return (
      <List>
        {headers.map((header) => (
          <React.Fragment key={header.ROW_ITEM_NO}>
            <ListItem
              onClick={() => scrollToHeader(header.ROW_ITEM_NO)}
              sx={{
                padding: "4px 8px",
                cursor: "pointer",
                border: "1px solid #ccc",
                marginBottom: "4px",
                borderRadius: "4px",
                textAlign: "center",
                backgroundColor: "#f9f9f9",
                "&:hover": { backgroundColor: "#e0e0e0" },
              }}
            >
              {header.NAME}
            </ListItem>
            {header.children &&
              header.children.length > 0 &&
              renderNavHeaders(header.children)}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Box
        display="flex"
        // alignItems="center"
        justifyContent="space-between"
        width="100%"
        // maxWidth="800px"
        gap={3}
        marginLeft={2}
      >
        <Box flex={1} display="flex" gap={2} alignItems="center">
          <TextField
            label="Search"
            size="small"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flex: 1, maxWidth: "250px" }}
          />
          <TextField
            label="Replace with"
            variant="outlined"
            size="small"
            value={replaceTerm}
            onChange={(e) => setReplaceTerm(e.target.value)}
            sx={{ flex: 1, maxWidth: "250px" }}
          />
          <Button
            disabled={
              replaceTerm && replaceTerm && occurrencesCount > 0 ? false : true
            }
            onClick={() => {
              handleReplace("first");
            }}
            variant="outlined"
            // sx={{ marginRight: 2 }}
          >
            Replace
          </Button>
          <Typography>{occurrencesCount}</Typography>
          <Button
            disabled={
              replaceTerm && replaceTerm && occurrencesCount > 0 ? false : true
            }
            onClick={() => {
              handleReplace("all");
            }}
            variant="outlined"
            // sx={{ marginRight: 2 }}
          >
            Replace All
          </Button>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          {data.length > 0 && (
            <>
              <Tooltip title="Customise Pdf" arrow>
                <IconButton
                  color="primary"
                  onClick={() => handleAction("preview")}
                  sx={{ fontSize: "large" }}
                >
                  <Settings sx={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Preview Pdf" arrow>
                <IconButton
                  color="primary"
                  onClick={() => {
                    generatePDF("preview");
                  }}
                  sx={{ fontSize: "large" }}
                >
                  <Visibility sx={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </>
          )}

          {/* <Tooltip title="Download Pdf" arrow>
              <IconButton
                color="primary"
                onClick={() => {
                  generatePDF("download");
                }}
                sx={{ fontSize: "large" }}
              >
                <Download sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip> */}
        </Box>
      </Box>
      <Box
        textAlign="center"
        sx={{ maxWidth: "1720px", margin: "auto", mt: 2, mb: 1 }}
        // fullWidth
      >
        {isEditing ? (
          <StyledTextField
            variant="outlined"
            value={pdfTitle}
            onChange={(e) => setPdfTitle(e.target.value)}
            onBlur={handleBlur}
            autoFocus
            fullWidth
          />
        ) : (
          <Typography
            variant="h6"
            sx={{
              border: "1px dashed #ccc",
              borderRadius: "8px",
              padding: "8px",
              cursor: "text",
              color: pdfTitle ? "black" : "#aaa",
            }}
            onClick={() => setIsEditing(true)}
          >
            {pdfTitle || "Add document title"}
          </Typography>
        )}
      </Box>
      {/* Example button to add header */}
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box flex={1} display="flex" justifyContent="center">
          <Tooltip title="Add New Header" arrow>
            <IconButton
              color="primary"
              onClick={() => addHeader()}
              sx={{ fontSize: "large" }}
            >
              <Add sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Modal for adding a new header */}
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            component={Paper}
            sx={{
              width: 400,
              padding: 4,
              margin: "auto",
              marginTop: "15%",
              outline: "none",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6" textAlign="center">
              Add New Header
            </Typography>
            <TextField
              label="Header Name"
              value={newHeaderName}
              onChange={(e) => setNewHeaderName(e.target.value)}
              fullWidth
            />

            <FormControl fullWidth>
              <Select
                value={selectedPosition}
                onChange={(e) => setSelectedPosition(e.target.value)}
              >
                <MenuItem value="start">At the Beginning</MenuItem>
                <MenuItem value="end">At the End</MenuItem>
                <MenuItem value="specific">After a Specific Header</MenuItem>
              </Select>
            </FormControl>

            {selectedPosition === "specific" && (
              <FormControl fullWidth>
                <InputLabel>Select Header</InputLabel>
                <Select
                  value={specificHeaderIndex}
                  onChange={(e) => setSpecificHeaderIndex(e.target.value)}
                  sx={{ mt: 1 }}
                >
                  {data.map((header, index) => (
                    <MenuItem key={header.ROW_ITEM_NO} value={index}>
                      {header.NAME || `Header ${index + 1}`} (Index {index + 1})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              mt={2}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddHeader}
              >
                Add
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Box>
      <Box>
        {/* Dialog for Customization */}
        <Dialog
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          fullWidth={true}
        >
          <DialogTitle>{langConstant.UPDATE_DOCUMENT_INFORMATION}</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <TextField
              fullWidth
              label={langConstant.PDF_TITLE}
              size="small"
              value={pdfTitle}
              onChange={(e) => setPdfTitle(e.target.value)}
              sx={{ mt: 2 }}
            />

            <TextField
              fullWidth
              size="small"
              label={langConstant.AUTHOR}
              value={pdfAuthorName}
              onChange={(e) => setPdfAuthorName(e.target.value)}
              sx={{ mt: 2 }}
            />

            <TextField
              fullWidth
              size="small"
              label={langConstant.VERSION_PDF}
              value={pdfVersion}
              onChange={(e) => setPdfVersion(e.target.value)}
              sx={{ mt: 2 }}
            />

            <Box display="flex" alignItems="center" gap={2}>
              <Checkbox
                checked={docVersionPosition}
                onChange={(e) => setDocVersionPosition(!docVersionPosition)}
                color="success"
              />
              <span>{langConstant.DOCUMENT_CONTROL}</span>

              <Button
                onClick={() => setLogoPhotoView(true)}
                variant="contained"
                color="primary"
                sx={{ cursor: "pointer" }}
                size="small"
              >
                Upload PDF Image
              </Button>

              {logoImage && (
                <img
                  src={`data:image/jpeg;base64,${logoImage}`}
                  alt="Preview"
                  style={{
                    width: 80,
                    height: 80,
                    objectFit: "contain",
                    borderRadius: 5,
                  }}
                />
              )}
            </Box>

            <UploadPhoto
              show={logoPhotoView}
              setShow={setLogoPhotoView}
              updateImage={updateLogoPhoto}
            />

            {/* Header Section */}
            <Box display="flex" alignItems="center" gap={2}>
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <InputLabel>Header Text Position</InputLabel>
                <Select
                  sx={{ mt: 1 }}
                  size="small"
                  value={headerPosition}
                  onChange={(e) => {
                    setHeaderPosition(e.target.value);
                    setHeaderImagePosition("");
                  }}
                >
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="center">Center</MenuItem>
                  <MenuItem value="right">Right</MenuItem>
                </Select>
              </FormControl>

              <TextField
                sx={{ mt: 1 }}
                label="Custom Header"
                size="small"
                fullWidth
                value={customHeader}
                onChange={(e) => setCustomHeader(e.target.value)}
              />
            </Box>

            <Box display="flex" alignItems="center" gap={1}>
              <Checkbox
                checked={pageNumbering}
                onChange={(e) => {
                  setPageNumbering(!pageNumbering);
                  setFooterPosition("");
                }}
                color="success"
              />
              <span>Add Page Numbers</span>
            </Box>

            {/* Header Image Upload */}
            <Box display="flex" alignItems="center" gap={2}>
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <InputLabel>Header Image Position</InputLabel>
                <Select
                  sx={{ mt: 1 }}
                  size="small"
                  value={headerImagePosition}
                  onChange={(e) => setHeaderImagePosition(e.target.value)}
                >
                  {getHeaderImagePositionOptions().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                onClick={() => setHeaderPhotoView(true)}
                sx={{ cursor: "pointer", mt: 1 }}
                variant="contained"
                color="primary"
                size="small"
              >
                Upload Header Image
              </Button>

              {hederImage && (
                <img
                  src={`data:image/jpeg;base64,${hederImage}`}
                  alt="Header Preview"
                  style={{
                    width: 80,
                    height: 80,
                    objectFit: "contain",
                    borderRadius: 5,
                  }}
                />
              )}
            </Box>

            <UploadPhoto
              show={headerPhotoView}
              setShow={setHeaderPhotoView}
              updateImage={updateHeaderPhoto}
            />

            {/* Footer Section */}
            <Box display="flex" alignItems="center" gap={2} xs={{ mt: 1 }}>
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <InputLabel>Footer Text Position</InputLabel>
                <Select
                  sx={{ mt: 1 }}
                  value={footerPosition}
                  onChange={(e) => setFooterPosition(e.target.value)}
                >
                  {getFooterPositionOptions().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                sx={{ mt: 1 }}
                size="small"
                label="Custom Footer"
                fullWidth
                value={customFooter}
                onChange={(e) => setCustomFooter(e.target.value)}
              />
            </Box>

            {/* Footer Image Upload */}
            <Box display="flex" alignItems="center" gap={2} xs={{ mt: 1 }}>
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <InputLabel>Footer Image Position</InputLabel>
                <Select
                  sx={{ mt: 1 }}
                  value={footerImagePosition}
                  onChange={(e) => setFooterImagePosition(e.target.value)}
                >
                  {getFooterImagePositionOptions()?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                onClick={() => setFooterPhotoView(true)}
                sx={{ cursor: "pointer", mt: 1 }}
                variant="contained"
                color="primary"
                size="small"
              >
                Upload Footer Image
              </Button>

              {footerImage && (
                <img
                  src={`data:image/jpeg;base64,${footerImage}`}
                  alt="Footer Preview"
                  style={{
                    width: 80,
                    height: 80,
                    objectFit: "contain",
                    borderRadius: 5,
                  }}
                />
              )}
            </Box>

            <UploadPhoto
              show={footerPhotoView}
              setShow={setFooterPhotoView}
              updateImage={updateFooterPhoto}
            />

            <TextField
              xs={{ mt: 1 }}
              size="small"
              fullWidth
              label="Version Description"
              value={versionDescription}
              onChange={(e) => setVersionDescription(e.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                generatePDF("preview");
              }}
              variant="contained"
              color="primary"
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for Preview */}
        <Dialog
          open={isPreviewDialogOpen}
          onClose={() => setPreviewDialogOpen(false)}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>
            PDF Preview
            <IconButton
              onClick={() => setPreviewDialogOpen(false)}
              sx={{ position: "absolute", right: 10, top: 10 }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {pdfUrl ? (
              <iframe
                src={pdfUrl}
                title="PDF Preview"
                style={{ width: "100%", height: "80vh", border: "none" }}
              />
            ) : (
              <Typography>Loading PDF...</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Tooltip title="Save">
              <IconButton
                color="primary"
                onClick={() => {
                  policyUpdated();
                }}
              >
                <Save sx={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                color="secondary"
                onClick={() => {
                  generatePDF("download");
                }}
              >
                <Download sx={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </Box>
      {/* Render all headers */}
      <Box sx={{ display: "flex", position: "relative" }}>
        {loaderData.length > 0 && (
          <Box
            id="scrollableBox"
            ref={scrollableRef}
            sx={{
              width: "100%",
              height: "544px",
              overflow: "auto",
              borderRadius: "4px",
              padding: "8px",
              transition: "margin-right 0.6s ease",
              marginRight: isNavigationOpen ? "20px" : "0%",
            }}
          >
            <InfiniteScroll
              dataLength={loaderData.length}
              next={loadMoreHeaders}
              hasMore={hasMore}
              // loader={<h4>Loading...</h4>}
              // endMessage={<p>No more data to load</p>}
              scrollThreshold={0.9}
              scrollableTarget="scrollableBox"
            >
              <Box>{renderHeaders(loaderData)}</Box>
              {loaderData.length < 5 && (
                <Box
                  sx={{
                    height: "300px", // Adjust height based on your needs
                    backgroundColor: "transparent",
                  }}
                ></Box>
              )}
            </InfiniteScroll>
          </Box>
        )}

        {loaderData.length > 0 && (
          <IconButton
            onClick={() => setIsNavigationOpen(!isNavigationOpen)}
            sx={{
              position: "absolute",
              top: "calc(50% - 20px)",
              right: isNavigationOpen ? "340px" : "-15px",
              zIndex: 1000,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              transform: isNavigationOpen
                ? "translateX(0)"
                : "translateX(-100%)",
              transition: "left 0.6s ease",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          >
            {isNavigationOpen ? (
              <ArrowForward color="primary" sx={{ fontSize: "18px" }} />
            ) : (
              <ArrowBack color="primary" sx={{ fontSize: "18px" }} />
            )}
          </IconButton>
        )}

        {isNavigationOpen && data.length > 0 && (
          <Box
            sx={{
              width: "20%",
              height: "544px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              overflow: "auto",
              padding: "5px",
              marginLeft: "5px",
              backgroundColor: "#fff",
            }}
          >
            <Typography variant="h6" textAlign="center" gutterBottom>
              Navigation
            </Typography>
            {renderNavHeaders(data)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

// PolicyGenerasion.propTypes = {};

export default PolicyGenerasion;
