/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";
import constant from "./constants";
import apiService from "_services/api.service";
import { alertActions, loadingActions } from "_actions";
import langConstant from "_lang";
import { decodeB64ToJsonObject } from "_helpers/utils";

const getIndividualQuestionDetails = (qId, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionDetails", {
        i_QUESTION_ID: qId,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-2"]) {
            dispatch({
              type: constant.guidanceTypes_GET_SUCCESS,
              data: response["#result-set-2"],
            });
          } else {
            dispatch({
              type: constant.guidanceTypes_GET_FAILURE,
              data: null,
            });
            dispatch(alertActions.error("Could not fetch guidance type"));
          }

          if (response && response["#result-set-1"]) {
            dispatch({
              type: constant.individualQuestionDetails_GET_SUCCESS,
              // data: response["#result-set-1"][0],
              data: response["#result-set-1"],
            });
          } else {
            dispatch({
              type: constant.individualQuestionDetails_GET_FAILURE,
              data: null,
            });
            dispatch(alertActions.error("Could not fetch question details"));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
    //dispatch({ type: constant.manageQuestionsConstant_GET, data: draft })
  };
};

const getIndividualQuestionResponses = (qId, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionResponses", {
        i_QUESTION_ID: qId,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constant.individualQuestionResponses_GET_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({
              type: constant.individualQuestionResponses_GET_SUCCESS,
              data: null,
            });
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};

const getResponseTypes = (qId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetResponseType", {
        i_QUESTION_ID: qId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constant.GET_RESPONSE_TYPE_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({
              type: constant.GET_RESPONSE_TYPE_FAILURE,
              data: null,
            });
            dispatch(alertActions.error("Could not fetch question details"));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};

const getIndividualQuestionRules = (qId, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionRules", {
        i_QUESTION_ID: qId,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constant.individualQuestionRules_GET_SUCCESS,
              data: response["#result-set-1"][0].DISPLAY_RULE_FORMAT
                ? decodeB64ToJsonObject(
                    response["#result-set-1"][0].DISPLAY_RULE_FORMAT
                  )
                : [],
            });
          } else {
            dispatch({
              type: constant.individualQuestionRules_GET_FAILURE,
              data: null,
            });
          }

          // const questionDetails =
          //   response && response["#result-set-1"]
          //     ? response["#result-set-1"][0]
          //     : {};
          // dispatch({
          //   type: constant.individualQuestionDetails_GET_SUCCESS,
          //   data: questionDetails,
          // });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};

const getIndividualQuestionEvidenceTypes = (qId, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetEvidenceType_Mapping", {
        i_QUESTION_ID: qId,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constant.individualQuestionEvidenceTypes_GET_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({
              type: constant.individualQuestionEvidenceTypes_GET_FAILURE,
              data: null,
            });
            dispatch(
              alertActions.error("Could not fetch question evidence List")
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};
const getIndividualQuestionEvidenceList = (qId, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetEvidenceList", {
        i_QUESTION_ID: qId,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch({
              type: constant.individualQuestionEvidenceList_GET_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch({
              type: constant.individualQuestionEvidenceList_GET_FAILURE,
              data: null,
            });
            dispatch(
              alertActions.error("Could not fetch question evidence List")
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};
const updateQuestionGuidance = (
  qId,
  guidanceText,
  QuestionTextHtml,
  guidanceType,
  customerID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionGuidance", {
        i_QUESTION_ID: qId,
        i_QUESTION_TEXT: guidanceText,
        i_QUESTION_TEXT_HTML: QuestionTextHtml,
        i_GUIDANCE_TYPE: guidanceType,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          //  if (response["#update-count-1"])

          if (response && response.Status == 500) {
            dispatch(
              alertActions.error(langConstant.QUESTION_GUIDANCE_UPDATE_FAILED)
            );
          } else {
            dispatch(
              alertActions.success(
                langConstant.QUESTION_GUIDANCE_UPDATED_SUCCESSFULLY
              )
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          // dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};
const updateQuestionText = (
  QuestionID,
  QuestionText,
  QuestionTextHtml,
  customerID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionText", {
        i_QUESTION_ID: QuestionID,
        i_QUESTION_TEXT: QuestionText,
        i_QUESTION_TEXT_HTML: QuestionTextHtml,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#update-count-1"]) {
            dispatch(
              alertActions.success(
                langConstant.QUESTION_TEXT_UPDATED_SUCCESSFULLY
              )
            );
          } else {
            dispatch(
              alertActions.error(langConstant.QUESTION_TEXT_UPDATE_FAILED)
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

// update question response

const updateQuestionResponse = (
  updatedResponseData,
  questionId,
  customerID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send(
        "/v1/api/proc/DRegulativDB/UpdateQuestionResponses",
        updatedResponseData
      )
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response["#result-set-1"] &&
            response["#result-set-1"][0]["#update-count-1"]
          ) {
            dispatch(
              alertActions.success(
                langConstant.QUESTION_RESPONSE_UPDATED_SUCCESSFULLY
              )
            );
            dispatch(getIndividualQuestionResponses(questionId, customerID));
          } else {
            dispatch(
              alertActions.error(langConstant.QUESTION_RESPONSE_UPDATE_FAILED)
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

//=---------------------------------------remove question response-------------------------//
const deleteQuestionResponse = (
  questionId,
  responseOptionIds,
  countUpdater,
  customerID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/DeleteQuestionReponses", {
        i_QUESTION_ID: questionId,
        i_RESPONSE_OPTION_ID: responseOptionIds,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response["#result-set-1"]?.length &&
            response["#result-set-1"][0].row_count
          ) {
            dispatch(
              alertActions.success(
                langConstant.QUESTION_RESPONSE_REMOVE_SUCCESSFULLY
              )
            );
            dispatch(getIndividualQuestionResponses(questionId, customerID));
            countUpdater();
          } else {
            dispatch(
              alertActions.error(langConstant.QUESTION_RESPONSE_REMOVE_FAILED)
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

//--------------------------------------------------------------------//

// insert question response
const insertQuestionResponse = (
  insertResponseData,
  questionId,
  countUpdater,
  customerID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send(
        "/v1/api/proc/DRegulativDB/PutQuestionResponses",
        insertResponseData
      )
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response["#result-set-1"]?.length &&
            response["#result-set-1"][0].row_count
          ) {
            dispatch(
              alertActions.success(
                langConstant.QUESTION_RESPONSE_INSERTED_SUCCESSFULLY
              )
            );
            dispatch(getIndividualQuestionResponses(questionId, customerID));
            countUpdater();
          } else {
            dispatch(
              alertActions.error(langConstant.QUESTION_RESPONSE_INSERTED_FAILED)
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
//-------------------------------------------------------------------//
const UpdateQuestionToControlMapping = (
  qId,
  controlId,
  dataAssetAttributeName,
  dataAssetEntityName,
  sourceSystemId,
  flag
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionToControlMapping", {
        i_CISO_CONTROL_ID: controlId,
        i_QUESTION_ID: qId,
        i_SOURCE_SYSTEM_ID: sourceSystemId,
        i_DATA_ASSET_ENTITY_NAME: dataAssetEntityName,
        i_DATA_ASSET_ATTRIBUTE_NAME: dataAssetAttributeName,
        i_FLAG: flag,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#update-count-1"]) {
            if (controlId) {
              dispatch(
                alertActions.success(
                  langConstant.QUESTION_CONTROLS_UPDATED_SUCCESSFULLY
                )
              );
            } else if (dataAssetAttributeName) {
              dispatch(
                alertActions.success(
                  langConstant.QUESTION_DATAPOINTS_UPDATED_SUCCESSFULLY
                )
              );
            }
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          // dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};
const PutEvidenceMapping = (
  qId,
  evidenceTypeId,
  mandatory,
  weightage,
  customerID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutEvidenceMapping", {
        i_QUESTION_ID: qId,
        i_EVIDENCE_TYPE_ID: evidenceTypeId,
        i_MANDATORY: mandatory,
        i_EVIDENCE_WEIGHTAGE: weightage,
        i_START_DATE: null,
        i_END_DATE: null,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          // const questionDetails =
          //   response && response["#result-set-1"]
          //     ? response["#result-set-1"][0]
          //     : {};
          // dispatch({
          //   type: constant.individualQuestionDetails_GET_SUCCESS,
          //   data: questionDetails,
          // });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          // dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};
const UpdateEvidenceMapping = (
  evidenceMappingId,
  mandatory,
  weightage,
  active
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateEvidenceMapping", {
        i_EVIDENCE_MAPPING_ID: evidenceMappingId,
        i_MANDATORY: mandatory,
        i_EVIDENCE_WEIGHTAGE: weightage,
        i_ACTIVE: active,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(alertActions.success("Evidence Updated Successfully"));
          if (response["#update-count-1"]) {
            dispatch(
              alertActions.success(
                langConstant.QUESTION_EVIDENCE_TYPES_UPDATED_SUCCESSFULLY
              )
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          // dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};
const UpdateQuestionNarrative = (qId, narrative, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionNarrative", {
        i_QUESTION_ID: qId,
        i_NARRATIVE_TEMPLATE: narrative,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#update-count-1"]) {
            dispatch(
              alertActions.success(
                langConstant.QUESTION_NARRATIVE_UPDATED_SUCCESSFULLY
              )
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          // dispatch({ type: constant.manageQuestionsConstant_FAILURE });
        }
      );
  };
};

const GetQuestionMappingCount = (questionID, regulationID, customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetQuestionMappingCount", {
      i_QUESTION_ID: questionID,
      i_REGULATION_ID: regulationID,
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetSimilarQuestionsByQuestionID = (
  questionID,
  regulationID,
  customerID
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetSimilarQuestionsByQuestionID", {
      i_QUESTION_ID: questionID,
      i_REGULATION_ID: regulationID,
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetQuestionDataPointMapping = (questionID, customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetQuestionDataPointMapping", {
      i_QUESTION_ID: questionID,
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetQuestionControlsMapping = (questionID, userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetQuestionControlsMapping", {
      i_QUESTION_ID: questionID,
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetQuestionMappingData = (questionID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetQuestionMappingData", {
      i_QUESTION_ID: questionID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const PutCopiedMappings = (dataObj) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutCopiedMappings", dataObj)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getRFIEvidenceAndDataAssetsForQuestion = (
  userID,
  submissionID,
  questionID
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRFIEvidenceAndDataAssetsForQuestion ", {
      i_USER_ID: userID,
      i_SUBMISSION_ID: submissionID,
      i_QUESTION_ID: questionID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetDataAssetsforQuestion = (questionID, customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetDataAssetsforQuestion ", {
      i_QUESTION_ID: questionID,
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetEvidenceForQuestion = (questionID, customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetEvidenceForQuestion ", {
      i_QUESTION_ID: questionID,
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetSampleRuleDecisionForQuestion = (questionID, customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetSampleRuleDecisionForQuestion ", {
      i_QUESTION_ID: questionID,
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetNarrativeTemplateForQuestion = (questionID, customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetNarrativeTemplateForQuestion ", {
      i_QUESTION_ID: questionID,
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const GetQuestionRef = (questionID, userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetQuestionRef ", {
      i_QUESTION_ID: questionID,
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  getIndividualQuestionDetails,
  getIndividualQuestionResponses,
  getIndividualQuestionRules,
  getIndividualQuestionEvidenceTypes,
  getIndividualQuestionEvidenceList,
  updateQuestionGuidance,
  updateQuestionText,
  UpdateQuestionToControlMapping,
  PutEvidenceMapping,
  UpdateEvidenceMapping,
  UpdateQuestionNarrative,
  updateQuestionResponse,
  deleteQuestionResponse,
  insertQuestionResponse,
  getResponseTypes,

  GetQuestionMappingCount,
  GetSimilarQuestionsByQuestionID,
  GetQuestionDataPointMapping,
  GetQuestionControlsMapping,
  GetQuestionMappingData,
  PutCopiedMappings,

  GetDataAssetsforQuestion,
  GetEvidenceForQuestion,
  GetSampleRuleDecisionForQuestion,
  GetNarrativeTemplateForQuestion,
  GetQuestionRef,
  getRFIEvidenceAndDataAssetsForQuestion,
};
