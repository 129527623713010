import {
  Grid,
  TextField,
  Typography,
  Button,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import UserRoleDropdown from "_components/UserRoleDropDown";
import EntitlementDropdown from "_components/EntitlementDropDown";
import Select from "react-select";
import { globalConstants } from "_constants";
import * as Yup from "yup";
import CountryDropdown from "_components/CountryListDropDown";
import UserServices from "../services";
import sessionService from "_services/session.service";
import useStyles from "./styles";
import { alertActions, loadingActions } from "_actions";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import langConstant from "_lang";

const NewUserForm = ({
  userID,
  setCurrentStep,
  userData,
  setUserData,
  mode,
  selectedCustomer,
}) => {
  const formRef = useRef();
  const customerID = sessionService.getCustomerId();
  const classes = useStyles();
  const dispatch = useDispatch();

  const permissionData = useSelector(
    (state) => state.authentication?.userData?.allowedNavItems
  );

  const [infoClassification, setInfoClassification] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  useEffect(() => {
    dispatch(
      UserServices.getCustomerInfoClassification(userID, setInfoClassification)
    );
    if (mode === "admin") {
      dispatch(
        UserServices.getCustomerSupervisorNamesAdmin(
          selectedCustomer.value,
          setSupervisorOptions
        )
      );
    }
  }, []);

  const teamValidationSchema = Yup.object().shape({
    FIRSTNAME: Yup.string()
      .trim()
      .max(50, "Maximum 50 Characters Allowed")
      .required("Name is Required"),
    LASTNAME: Yup.string()
      .trim()
      .max(50, "Maximum 50 Characters Allowed")
      .required("Name is Required"),
    USERNAME: Yup.string()
      .trim()
      .min(3, "Minimum 3 Characters Allowed")
      .max(25, "Maximum 25 Characters Allowed")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .required("UserName is a required field"),
    PASSWORD: userData.USER_ID
      ? Yup.string()
      : Yup.string()
          .trim()
          .required("Password is required")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contains 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
    PHONE_NUMBER: Yup.number().required("Phone Number is Required"),
    EMAIL_ADDRESS: Yup.string()
      .trim()
      .email("Invalid Email Address")
      .required("Email is Required"),
    DEPARTMENT_NAME: Yup.string().required("Department is Required"),
    COUNTRY_CODE: Yup.string().required("Country is Required"),
    USER_ROLE: Yup.number().required("User Role is Required"),
    ENTITLEMENT_ID: Yup.string().required("Entitlement is Required"),
    DATA_CLASSIFICATION: Yup.number().required("Classification is Required"),
    SUPERVISOR_ID: Yup.string().when("IS_SUPERVISOR", {
      is: true,
      then: Yup.string().trim().required("Supervisor Is Required"),
      otherwise: Yup.string().nullable(),
    }),
  });

  const checkUserNameExists = async (customerID, userName) => {
    const response = await UserServices.getCheckUsername(customerID, userName);
    if (response.status === 200) {
      if (response.userAllowed) {
        return {
          responseStatus: 200,
          userAllowed: true,
        };
      } else {
        return {
          responseStatus: 200,
          userAllowed: false,
        };
      }
    } else {
      return {
        responseStatus: 500,
      };
    }
  };

  const checkUserEmailExists = async (customerID, userEmail) => {
    const response = await UserServices.getCheckUserEmail(
      customerID,
      userEmail
    );
    if (response.status === 200) {
      if (response.userAllowed) {
        return {
          responseStatus: 200,
          userAllowed: true,
        };
      } else {
        return {
          responseStatus: 200,
          userAllowed: false,
        };
      }
    } else {
      return {
        responseStatus: 500,
      };
    }
  };

  const submitFormData = async (values) => {
    let customer_id = mode == "user" ? customerID : selectedCustomer.value;
    const userNameRes = await checkUserNameExists(customer_id, values.USERNAME);
    if (userNameRes.responseStatus === 200) {
      if (userNameRes.userAllowed) {
        const userEmailRes = await checkUserEmailExists(
          customer_id,
          values.EMAIL_ADDRESS
        );
        if (userEmailRes.responseStatus === 200) {
          if (userEmailRes.userAllowed) {
            values["CUSTOMER_ID"] = customer_id;
            setUserData((prevState) => ({
              ...prevState,
              basic_information: values,
            }));
            setCurrentStep(2);
          } else {
            formRef.current.setErrors({
              EMAIL_ADDRESS: "This Email is already in use",
            });
          }
        } else {
          dispatch(alertActions.error("Email Exist Service Failed"));
        }
      } else {
        formRef.current.setErrors({ USERNAME: "Username is already in use" });
      }
    } else {
      dispatch(alertActions.error("UserName Exist Service Failed"));
    }
  };

  const updateFormData = async (values) => {
    if (values.USERNAME === userData.basic_information.USERNAME) {
      if (values.EMAIL_ADDRESS == userData.basic_information.EMAIL_ADDRESS) {
        values["CUSTOMER_ID"] = selectedCustomer.value;
        setUserData((prevState) => ({
          ...prevState,
          basic_information: values,
        }));
        setCurrentStep(2);
      } else {
        const userEmailRes = await checkUserEmailExists(
          selectedCustomer.value,
          values.EMAIL_ADDRESS
        );
        if (userEmailRes.responseStatus === 200) {
          if (userEmailRes.userAllowed) {
            values["CUSTOMER_ID"] = selectedCustomer.value;
            setUserData((prevState) => ({
              ...prevState,
              basic_information: values,
            }));
            setCurrentStep(2);
          } else {
            formRef.current.setErrors({
              EMAIL_ADDRESS: "This Email is already in use",
            });
          }
        } else {
          dispatch(alertActions.error("Email Exist Service Failed"));
        }
      }
    } else {
      const userNameRes = await checkUserNameExists(
        selectedCustomer.value,
        values.USERNAME
      );
      if (userNameRes.responseStatus === 200) {
        if (userNameRes.userAllowed) {
          if (
            values.EMAIL_ADDRESS == userData.basic_information.EMAIL_ADDRESS
          ) {
            values["CUSTOMER_ID"] = selectedCustomer.value;
            setUserData((prevState) => ({
              ...prevState,
              basic_information: values,
            }));
            setCurrentStep(2);
          } else {
            const userEmailRes = await checkUserEmailExists(
              selectedCustomer.value,
              values.EMAIL_ADDRESS
            );
            if (userEmailRes.responseStatus === 200) {
              if (userEmailRes.userAllowed) {
                values["CUSTOMER_ID"] = selectedCustomer.value;
                setUserData((prevState) => ({
                  ...prevState,
                  basic_information: values,
                }));
                setCurrentStep(2);
              } else {
                formRef.current.setErrors({
                  EMAIL_ADDRESS: "This Email is already in use",
                });
              }
            } else {
              dispatch(alertActions.error("Email Exist Service Failed"));
            }
          }
        } else {
          formRef.current.setErrors({ USERNAME: "Username is already in use" });
        }
      } else {
        dispatch(alertActions.error("UserName Exist Service Failed"));
      }
    }
  };

  return (
    <Grid>
      <Grid>
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          {langConstant.BASIC_INFORMATION}
        </Typography>
      </Grid>
      <Formik
        enableReinitialize={true}
        initialValues={userData.basic_information}
        validationSchema={teamValidationSchema}
        validateOnChange={false}
        onSubmit={userData.USER_ID ? updateFormData : submitFormData}
        innerRef={formRef}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.FIRSTNAME}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="FIRSTNAME">
                  {() => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={values.FIRSTNAME}
                      onChange={(e) =>
                        setFieldValue("FIRSTNAME", e.target.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="FIRSTNAME">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.LASTNAME}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="LASTNAME">
                  {() => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={values.LASTNAME}
                      onChange={(e) =>
                        setFieldValue("LASTNAME", e.target.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="LASTNAME">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.USERNAME}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="USERNAME">
                  {() => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={values.USERNAME}
                      onChange={(e) =>
                        setFieldValue("USERNAME", e.target.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="USERNAME">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            {!userData.USER_ID && (
              <Grid container className={classes.formFieldWrapper}>
                <Grid item md={3} xs={5}>
                  <Typography className={classes.userFormLabel}>
                    {langConstant.PASSWORD}
                  </Typography>
                </Grid>
                <Grid item md={9} xs={7}>
                  <Field name="PASSWORD">
                    {() => (
                      <TextField
                        size="small"
                        variant="outlined"
                        type="password"
                        fullWidth
                        value={values.PASSWORD}
                        onChange={(e) =>
                          setFieldValue("PASSWORD", e.target.value)
                        }
                      />
                    )}
                  </Field>
                  {errors && errors.PASSWORD && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {errors.PASSWORD}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.PHONE_NUMBER}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="PHONE_NUMBER">
                  {() => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={values.PHONE_NUMBER}
                      onChange={(e) =>
                        setFieldValue("PHONE_NUMBER", e.target.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="PHONE_NUMBER">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.EMAIL_ADDRESS}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="EMAIL_ADDRESS">
                  {() => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={values.EMAIL_ADDRESS}
                      onChange={(e) =>
                        setFieldValue("EMAIL_ADDRESS", e.target.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="EMAIL_ADDRESS">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.DEPARTMENT_NAME}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="DEPARTMENT_NAME">
                  {() => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={values.DEPARTMENT_NAME}
                      onChange={(e) =>
                        setFieldValue("DEPARTMENT_NAME", e.target.value)
                      }
                    />
                    // <DepartmentDropdown onChange={(e) => setFieldValue("DEPARTMENT_NAME", e.value)} placeholder='Select Department'/>
                  )}
                </Field>
                <ErrorMessage name="DEPARTMENT_NAME">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>{" "}
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.COUNTRY}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="COUNTRY_CODE">
                  {() => (
                    <CountryDropdown
                      value={values.COUNTRY_CODE}
                      onChange={(e) => setFieldValue("COUNTRY_CODE", e.value)}
                      placeholder="Select Country"
                    />
                  )}
                </Field>
                <ErrorMessage name="COUNTRY_CODE">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.ROLE}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="USER_ROLE">
                  {() => (
                    <UserRoleDropdown
                      value={values.USER_ROLE}
                      onChange={(e) => setFieldValue("USER_ROLE", e.value)}
                      placeholder="Select Role"
                      customerID={selectedCustomer.value}
                    />
                  )}
                </Field>
                <ErrorMessage name="USER_ROLE">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>{" "}
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.ENTITLEMENT}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Field name="ENTITLEMENT_ID">
                  {() => (
                    <EntitlementDropdown
                      value={values.ENTITLEMENT_ID}
                      onChange={(e) => setFieldValue("ENTITLEMENT_ID", e.value)}
                      placeholder={langConstant.SELECT_ENTITLEMENT}
                    />
                  )}
                </Field>
                <ErrorMessage name="ENTITLEMENT_ID">
                  {(msg) => (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>{" "}
              </Grid>
            </Grid>
            <Grid container className={classes.formFieldWrapper}>
              <Grid item md={3} xs={5}>
                <Typography className={classes.userFormLabel}>
                  {langConstant.CLASSIFICATION}
                </Typography>
              </Grid>
              <Grid item md={9} xs={7}>
                <Typography className={classes.profileMDBasicInfoItem}>
                  <Field style={{ width: "100%" }} name="DATA_CLASSIFICATION">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <Select
                        className={classes.profileMDBasicSelect}
                        options={infoClassification}
                        placeholder="Select Classification"
                        value={infoClassification.find(
                          (classification) => classification.value == value
                        )}
                        onChange={(classification) =>
                          setFieldValue(
                            "DATA_CLASSIFICATION",
                            classification.value
                          )
                        }
                      />
                    )}
                  </Field>
                  <ErrorMessage name="DATA_CLASSIFICATION">
                    {(msg) => (
                      <span
                        style={{
                          color: "red",
                          display: "block",
                          width: "100%",
                          fontSize: "14px",
                          lineHeight: "18px",
                          marginTop: "0px",
                        }}
                      >
                        {msg}
                      </span>
                    )}
                  </ErrorMessage>
                </Typography>
              </Grid>
            </Grid>
            {mode === "admin" &&
              permissionData &&
              permissionData.includes("USER_ADMINISTRATION/TEAM_MANAGER") && (
                <>
                  <Grid container className={classes.formFieldWrapper}>
                    <Grid item md={3} xs={5}>
                      <Typography className={classes.userFormLabel}>
                        {langConstant.SUPERVISOR}
                      </Typography>
                    </Grid>
                    <Grid item md={9} xs={7}>
                      <Field name="IS_SUPERVISOR">
                        {() => (
                          <Checkbox
                            checked={values.IS_SUPERVISOR ? true : false}
                            onClick={(e) =>
                              setFieldValue("IS_SUPERVISOR", e.target.checked)
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formFieldWrapper}>
                    <Grid item md={3} xs={5}>
                      <Typography className={classes.userFormLabel}>
                        {langConstant.SUPERVISOR_NAME}
                      </Typography>
                    </Grid>
                    <Grid item md={9} xs={7}>
                      <Typography className={classes.profileMDBasicInfoItem}>
                        <Field style={{ width: "100%" }} name="SUPERVISOR_ID">
                          {({ field: { value }, form: { setFieldValue } }) => (
                            <Select
                              className={classes.profileMDBasicSelect}
                              options={supervisorOptions}
                              placeholder="Select Supervisor"
                              value={supervisorOptions.find(
                                (supervisor) => supervisor.value == value
                              )}
                              onChange={(supervisor) =>
                                setFieldValue("SUPERVISOR_ID", supervisor.value)
                              }
                              isDisabled={!values.IS_SUPERVISOR}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="SUPERVISOR_ID">
                          {(msg) => (
                            <span
                              style={{
                                color: "red",
                                display: "block",
                                width: "100%",
                                fontSize: "14px",
                                lineHeight: "18px",
                                marginTop: "0px",
                              }}
                            >
                              {msg}
                            </span>
                          )}
                        </ErrorMessage>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.formFieldWrapper}>
                    <Grid item md={3} xs={5}>
                      <Typography className={classes.userFormLabel}>
                        {langConstant.RFI_RECIPIENT}
                      </Typography>
                    </Grid>
                    <Grid item md={9} xs={7}>
                      <Field name="IS_RFI_RECIPIENT">
                        {() => (
                          <Checkbox
                            checked={values.IS_RFI_RECIPIENT ? true : false}
                            onClick={(e) =>
                              setFieldValue(
                                "IS_RFI_RECIPIENT",
                                e.target.checked
                              )
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </>
              )}

            <Grid style={{ textAlign: "right" }}>
              <Button type="submit" variant="contained" color="primary">
                {langConstant.NEXT_CAPS}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

NewUserForm.propTypes = {
  userID: PropTypes.string,
  setCurrentStep: PropTypes.func,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  mode: PropTypes.string,
  selectedCustomer: PropTypes.object,
};

export default NewUserForm;
