import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Slide } from "@mui/material";
import { alertActions } from "_actions";
import IconButton from "@material-ui/core/IconButton";
import DialogBox from "_components/DialogBox";
import PropTypes from "prop-types";
import { Formik, Field, Form, FieldArray } from "formik";
import langConstant from "_lang";
import { ChipInput } from "material-ui-formik-components";
import CloseIcon from "@material-ui/icons/Close";
import UploadFileComponent from "./component/UploadFileComponent";
import UploadServices from "./actions";
import rootStyles from "rootStyles";
import PublishIcon from "@material-ui/icons/Publish";
import evidenceAction from "./actions";
import sessionService from "_services/session.service";
import { UseModalPropType } from "_hooks";
import Loader from "_components/Loader";
import * as Yup from "yup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { GetCustomerInfoClassfication } from "containers/user-administration/ConfigurationManagement/components/EvidenceTypes/services";

const AttachEvidence = ({ modal }) => {
  const questionId = modal
    ? modal.data.selectedRow.data.REGULATION_REFERENCE
    : "";
  const submissionId = modal ? modal.data.submissionID : "";
  const evidenceType = modal ? modal.data.selectedRow.data.ITEM_TYPE : "";
  const dispatch = useDispatch();
  const rootClasses = rootStyles();
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [virusAvailable, setVirusAvailable] = useState(false);
  const user_ID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const [count, setCount] = useState(0);
  const [classificationOptions, setClassificationOptions] = useState([]);
  const userInfo = useSelector((state) => state.authentication.detailedInfo);
  const [isUploading, setIsUploading] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const setPlanActionsDataLength = modal
    ? modal.data.setPlanActionsDataLength
    : "";

  const setKpiData = modal ? modal.data.setKpiData : "";

  const EVIDENCE_TRAINING_FOLDER =
    userInfo?.EVIDENCE_TRAINING || "Evidence_Training";

  const evidenceTypes = useSelector(
    (state) => state.uploadEvidence.evidenceTypes
  );
  const evidenceLocker = useSelector(
    (state) => state.authentication?.detailedInfo?.EVIDENCE_LOCKER
  );
  const loadingFile = useSelector((state) => state.loadingFile);
  let expiryPriodity = useSelector(
    (state) => state.uploadEvidence.evidenceTypes[0]?.EXPIRY_PERIODICITY
  );
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const period = expiryPriodity?.substring(0, expiryPriodity.length - 1);
  const expiryString = expiryPriodity?.charAt(expiryPriodity.length - 1);

  const setExpiryDate = (expiryString) => {
    if (expiryString === "m") {
      return "months";
    } else if (expiryString === "y") {
      return "years";
    } else if (expiryString === "d") {
      return "days";
    }
  };

  const initialValues = {
    evidenceItems: [
      {
        evidenceType: evidenceType,
        evidenceClassification: "select",
        evidenceTag: [],
        evidenceFile: "",
        comments: "",
        expiryDate:
          expiryString && period
            ? moment().add(parseInt(period), setExpiryDate(expiryString))
            : moment(),
        isVirusFile: false,
      },
    ],
  };
  const showAlert = (message, type = "error") => {
    if (!message) return;
    dispatch(alertActions?.[type]?.(message));
  };

  const resetUploadStates = () => {
    const intervalID = setTimeout(() => setIsUploading(false), 2000);

    setProgressMessage("");
  };

  //   const validationSchema = Yup.object().shape({
  //     evidenceItems: Yup.array().of(
  //       Yup.object({
  //         evidenceType: Yup.string().required("Required"),
  //         evidenceClassification: Yup.string().required("Required"),
  //         evidenceTag: Yup.array().min(1, "Required"),
  //         evidenceFile: Yup.mixed().required("Required"),
  //         comments: Yup.string().required("Required"),
  //         expiryDate: Yup.string().required("Required"),
  //       })
  //     )
  // });

  const fileUploadProgress = (event) => {
    setCount(Math.round((100 * event.loaded) / event.total));
  };

  // * Toggles the RFI Filter Dialog box
  const toggleDialog = () => {
    setAttachmentOpen(!attachmentOpen);
  };

  const openDialogAndGetEvidenceTypes = () => {
    dispatch(evidenceAction.getEvidenceType(questionId, customerID));
    toggleDialog();
  };

  const getUserClassificationsByAPI = async () => {
    try {
      const response = await GetCustomerInfoClassfication(user_ID);
      if (response?.status !== 200)
        throw new Error("Unable to get customer classifications");
      setClassificationOptions(
        response?.["#result-set-1"]?.map((item) => ({
          value: item?.INFO_CLASSIFICATION_ID,
          label: item?.DESCRIPTION,
          payload: item,
        }))
      );
    } catch (error) {
      dispatch(
        alertActions.error(
          error?.toString() || "Unable to get customer classifications"
        )
      );
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserClassificationsByAPI();
    openDialogAndGetEvidenceTypes();
  }, []);

  return (
    <React.Fragment>
      <DialogBox
        maxWidth={"sm"}
        title={langConstant.ATTACH_EVIDENCE}
        // open={attachmentOpen}
        // handleClose={toggleDialog}
        onClose={modal?.closeModal}
        open={modal?.isOpen}
      >
        {loadingFile ? (
          <Loader count={count} />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async (values) => {
              let extensionUnsupported = false;
              let classificationNotSelected = false;
              let typeNotSelected = false;
              let data = new FormData();
              let lessThan20 = true;
              let isFile = true;
              let isVirusCheck = false;

              let evidenceTypeId = [];
              let classifications = [];
              let tags = [];
              let files = null;
              let comments = [];
              data.append("submissionId", submissionId);
              data.append("questionId", questionId);
              data.append("evidenceBy", user_ID);
              const validFileTypes = [
                "pdf",
                "jpeg",
                "jpg",
                "xls",
                "xlsx",
                "doc",
                "docx",
                "csv",
                "bmp",
                "png",
              ];

              values.evidenceItems.forEach((evidence, index) => {
                let joinedTags = evidence.evidenceTag.join(";");
                if (!evidence.evidenceFile) {
                  isFile = false;
                  return;
                }

                let extension = evidence.evidenceFile.name.split(".").pop();

                if (evidence.evidenceFile.size > 20400000) {
                  lessThan20 = false;
                }
                if (!validFileTypes.includes(extension.toLowerCase())) {
                  extensionUnsupported = true;
                }

                if (evidence.evidenceClassification == "select") {
                  classificationNotSelected = true;
                }
                if (evidence.evidenceType == "select") {
                  typeNotSelected = true;
                }
                if (evidence.isVirusFile) {
                  isVirusCheck = true;
                }

                const evidenceTypeId = evidenceTypes.find(
                  (type) => type.EVIDENCE_DESCRIPTION == evidence.evidenceType
                ).EVIDENCE_TYPE_ID;

                data.append(`evidenceTypeId`, evidenceTypeId);
                data.append(`tags`, joinedTags);
                data.append(`classifications`, evidence.evidenceClassification);

                data.append(`files`, evidence.evidenceFile);
                data.append(`comments`, evidence.comments);

                data.append(
                  "expiryDate",
                  moment(evidence.expiryDate).format("YYYY-MM-DD HH:mm:ss")
                );
              });

              if (extensionUnsupported) {
                dispatch(
                  alertActions.error(
                    langConstant.EXTENSION_NOT_SUPPORTED_ATTACH_EVIDENCE
                  )
                );
                return;
              } else if (!isFile) {
                dispatch(
                  alertActions.error(langConstant.PLEASE_SELECT_EVIDENCE_FILE)
                );
                return;
              } else if (classificationNotSelected) {
                dispatch(
                  alertActions.error(langConstant.CLASSIFICATION_NOT_SELECTED)
                );
                return;
              } else if (typeNotSelected) {
                dispatch(
                  alertActions.error(langConstant.EVIDENCE_TYPE_NOT_SELECTED)
                );
                return;
              } else if (!lessThan20) {
                dispatch(alertActions.error(langConstant.FILE_LESS_THAN_20));
                return;
              } else if (isVirusCheck) {
                dispatch(
                  alertActions.error(langConstant.PLEASE_REMOVE_VIRUS_FILE)
                );
                return;
              } else {
                const redirectTo = () => {};
                const filePath =
                  customerID.toString() + "/" + evidenceLocker + "/";
                // let filePath = `${customerID}/${EVIDENCE_TRAINING_FOLDER}/`,
                //   fileName = file.name.replaceAll(" ", "_");
                data.append("fileLocation", filePath);
                data.append("bucketName", window.BUCKET_NAME);
                data.append("storageType", docStorageType);
                data.append("preProcessingIP", docStorageIP);
                data.append("preProcessingURL", docStorageURL);
                dispatch(
                  evidenceAction.uploadEvidence(
                    data,
                    { "Content-type": "multipart/form-data" },
                    redirectTo,
                    fileUploadProgress,
                    setPlanActionsDataLength,
                    setKpiData
                  )
                );
              }
            }}
            // validator={() => ({})}
            render={({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <Grid
                    item
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                    }}
                  >
                    <IconButton aria-label="close" onClick={modal?.closeModal}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <FieldArray
                    name="evidenceItems"
                    render={({ insert, remove, push }) => (
                      <div>
                        {values.evidenceItems.length > 0 &&
                          values.evidenceItems.map((item, index) => (
                            <Box
                              key={index}
                              alignItems="center"
                              style={{
                                marginTop: "1px",
                              }}
                            >
                              <Box
                                style={{
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                {/* <Box style={{ marginRight: "25px" }}>
                                  {index + 1}.
                                </Box> */}
                                <Box
                                  style={{
                                    marginRight: "25px",
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        top: "-25px",
                                        left: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.EVIDENCE_TYPE_REQUIRED}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}

                                  <TextField
                                    name={`evidenceItems.${index}.evidenceType`}
                                    variant="outlined"
                                    size="small"
                                    type="text"
                                    defaultValue={evidenceType}
                                    style={{ width: "100%" }}
                                    value={evidenceType}
                                    InputProps={{
                                      style: {
                                        paddingTop: "7.5px",
                                        paddingBottom: "7.5px",
                                        // margin: "0px 10px",
                                        top: "3px",
                                      },
                                    }}
                                  />
                                </Box>
                                {errors.evidenceItems &&
                                  errors.evidenceItems[index] &&
                                  errors.evidenceItems[index].evidenceType &&
                                  touched.evidenceItems &&
                                  touched.evidenceItems[index].evidenceType && (
                                    <div className="field-error">
                                      {errors.evidenceItems[index].evidenceType}
                                    </div>
                                  )}
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  marginTop: "20px",

                                  alignItems: "center",
                                  marginRight: "20px",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        top: "-25px",
                                        left: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_CLASSIFICATION_REQUIRED}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <TextField
                                    name={`evidenceItems.${index}.evidenceClassification`}
                                    placeholder="Select"
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                      width: "100%",
                                      marginLeft: "00px",
                                    }}
                                    select
                                    value={
                                      values.evidenceItems[index]
                                        .evidenceClassification
                                    }
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <MenuItem value="select">
                                      {langConstant.SELECT_CLASSIFICATION}
                                    </MenuItem>
                                    {classificationOptions?.map((item) => (
                                      <MenuItem
                                        key={item?.value}
                                        value={item?.value}
                                      >
                                        {item?.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Box>
                                {errors.evidenceItems &&
                                  errors.evidenceItems[index] &&
                                  errors.evidenceItems[index]
                                    .evidenceClassification &&
                                  touched.evidenceItems &&
                                  touched.evidenceItems[index]
                                    .evidenceClassification && (
                                    <div className="field-error">
                                      {
                                        errors.evidenceItems[index]
                                          .evidenceClassification
                                      }
                                    </div>
                                  )}
                              </Box>
                              <Box style={{ marginTop: "10px", width: "100%" }}>
                                <Box style={{ position: "relative" }}>
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        top: "-7px",
                                        left: "5px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_TAGS_REQUIRED}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <Field
                                    name={`evidenceItems.${index}.evidenceTag`}
                                    variant="outlined"
                                    size="small"
                                    component={ChipInput}
                                    placeholder="Add tags here"
                                    type="text"
                                    defaultValue={
                                      values.evidenceItems[index].evidenceTag
                                    }
                                  />
                                </Box>
                                {errors.evidenceItems &&
                                  errors.evidenceItems[index] &&
                                  errors.evidenceItems[index].evidenceTags &&
                                  touched.evidenceItems &&
                                  touched.evidenceItems[index].evidenceTags && (
                                    <div className="field-error">
                                      {errors.evidenceItems[index].evidenceTags}
                                    </div>
                                  )}
                              </Box>
                              <Box style={{ marginTop: "10px", width: "100%" }}>
                                <Box style={{ position: "relative" }}>
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        top: "-20px",
                                        left: "0px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_COMMENTS}*
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <TextField
                                    name={`evidenceItems.${index}.comments`}
                                    variant="outlined"
                                    size="small"
                                    placeholder={langConstant.COMMENTS}
                                    type="text"
                                    defaultValue={
                                      values.evidenceItems[index].comments
                                    }
                                    style={{ width: "100%" }}
                                    onChange={handleChange}
                                    InputProps={{
                                      style: {
                                        paddingTop: "7.5px",
                                        paddingBottom: "7.5px",
                                        // margin: "0px 10px",
                                        top: "3px",
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box style={{ marginTop: "10px", width: "100%" }}>
                                <Box style={{ position: "relative" }}>
                                  {index == 0 ? (
                                    <Typography
                                      style={{
                                        top: "-20px",
                                        left: "0px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {langConstant.ADD_EXPIRY_DATE}*
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      style={{
                                        marginRight: "1em",
                                        width: "100%",
                                      }}
                                      inputVariant="outlined"
                                      variant="inline"
                                      format="dd-MMM-yy"
                                      autoOk
                                      minDate={moment()}
                                      disablePast
                                      value={
                                        values.evidenceItems[index].expiryDate
                                      }
                                      onChange={(val) =>
                                        setFieldValue(
                                          `evidenceItems.${index}.expiryDate`,
                                          val
                                        )
                                      }
                                      // InputProps={{ readOnly: true }}
                                      // className={classNames(classes.input, classes.dateInput)}
                                      // error={touched.v_start_date && Boolean(errors.v_start_date)}
                                      // helperText={touched.v_start_date && errors.v_start_date}
                                      // disabled={this.state.canEdit}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "20px",
                                  position: "relative",
                                }}
                              >
                                {index == 0 ? (
                                  <Typography
                                    style={{
                                      top: "-40px",
                                      left: "-20px",
                                      fontSize: "14px",
                                      width: "100%",
                                    }}
                                  >
                                    {langConstant.UPLOAD_FILE_REQUIRED}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                                <UploadFileComponent
                                  setFieldValue={setFieldValue}
                                  index={index}
                                  handleChange={handleChange}
                                  values={values}
                                />
                                {/* <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <IconButton
                                    type="button"
                                    className="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon style={{ color: "#F0142F" }} />
                                  </IconButton>
                                </Box> */}
                              </Box>
                            </Box>
                          ))}
                      </div>
                    )}
                  />
                  <br />
                  {/* <button
                  onClick={(event) => {
                    event.preventDefault();
                    handleReset();
                  }}
                >
                  Reset
                </button> */}

                  <Box
                    mt={3}
                    display="flex"
                    style={{ width: "100%", margin: "0 auto" }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="default"
                      size="small"
                      className={rootClasses.greenButton}
                      startIcon={
                        isUploading ? (
                          <CircularProgress size={25} />
                        ) : (
                          <PublishIcon />
                        )
                      }
                    >
                      {langConstant.UPLOAD_FILE}
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ marginLeft: "20px" }}
                      onClick={modal?.closeModal}
                    >
                      {langConstant.CANCEL}
                    </Button>
                  </Box>
                  <Grid style={{ textAlign: "center", p: 1 }} item md={12}>
                    <Slide
                      direction="up"
                      in={Boolean(progressMessage)}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Typography
                        style={{ fontSize: 12 }}
                        variant="body1"
                        color="primary"
                      >
                        {progressMessage}
                      </Typography>
                    </Slide>
                  </Grid>
                </Form>
              );
            }}
          />
        )}
      </DialogBox>
    </React.Fragment>
  );
};

AttachEvidence.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default AttachEvidence;
