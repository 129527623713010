import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { regulationDocMappingSchema } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import sessionService from "_services/session.service";
import getRegulationForDocService from "../../../../../Regulators/services";
import uploadDocumentService from "../../../../../NewRegulation/ManageQuestions/component/UploadDocuments/services";
import { alertActions } from "_actions";

const RegulationDocDialog = ({
  showModal,
  handleClose,
  modalData,
  policyID,
  setFlag,
}) => {
  const [regulationOptions, setRegulationOptions] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const dispatch = useDispatch();

  const isEdit = modalData !== null;

  let initialValues = { regulation: "", documentForRegulation: "" };
  if (isEdit) {
    initialValues = {
      ...modalData,
      documentForRegulation: [],
    };
  }

  const regulationList = useSelector((state) => state.regulationList);

  useEffect(() => {
    if (regulationList.length > 0) {
      setRegulationOptions(
        regulationList.map((item) => {
          return {
            value: item.REGULATION_ID,
            label: item.REGULATION_SHORT_NAME,
          };
        })
      );
    }
    console.log(modalData);
  }, [regulationList]);

  useEffect(async () => {
    if (modalData) {
      const response =
        await getRegulationForDocService.getDocumentsForRegulation(
          modalData?.regulation?.value,
          customerID
        );
      if (response.status === 200) {
        setDocumentOptions(
          response["#result-set-1"].map((item) => {
            return {
              label: item.DOCUMENT_RECOMMENDATION,
              value: item.REGULATIONDOCREQ_UNID,
            };
          })
        );
      }
    }
  }, [modalData]);

  const handleRegulationChange = async (regulationID) => {
    const response = await getRegulationForDocService.getDocumentsForRegulation(
      regulationID,
      customerID
    );
    if (response.status === 200) {
      setDocumentOptions(
        response["#result-set-1"].map((item) => {
          return {
            label: item.DOCUMENT_RECOMMENDATION,
            value: item.REGULATIONDOCREQ_UNID,
          };
        })
      );
    }
  };

  const handleSave = async (values) => {
    console.log(values, "++++");
    values.documentForRegulation.map(async (item) => {
      let response;

      if (isEdit) {
        console.log(modalData.value, "99999");
        response =
          await uploadDocumentService.updateCustomerRegulationPolicyMap(
            customerID,
            values.regulation.value,
            policyID,
            item.value,
            modalData.documentForRegulation.value,
            userID,
            1
          );
        if (response.status !== 200) {
          dispatch(alertActions.error("Something went wrong"));
        }
      } else {
        response =
          await uploadDocumentService.putCustomerRegulationPolicyMapping(
            customerID,
            policyID,
            values.regulation.value,
            userID,
            item.value,
            null
          );
      }
      if (response.status !== 200) {
        dispatch(alertActions.error("Something went wrong"));
      } else {
        setFlag((prev) => !prev);
      }
    });

    handleClose();
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {isEdit
          ? "Edit Regulation Document Mapping"
          : "Add Regulation Document Mapping"}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={regulationDocMappingSchema}
          onSubmit={(values) => handleSave(values)}
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                  <Grid item md={5} xs={12}>
                    <Typography
                      variant="subtitle1"
                      component="label"
                      style={{ marginBottom: "5px" }}
                    >
                      Select Regulation
                    </Typography>
                    <Select
                      options={regulationOptions}
                      placeholder="Select Regulation"
                      value={values.regulation}
                      onChange={(option) => {
                        if (option !== null) {
                          handleRegulationChange(option.value);
                        }
                        setFieldValue("regulation", option);
                      }}
                      isClearable
                    />
                    {errors.regulation && touched.regulation && (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        {errors.regulation}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item md={5} xs={12}>
                    <Typography
                      variant="subtitle1"
                      component="label"
                      style={{ marginBottom: "5px" }}
                    >
                      Select Document for Regulation
                    </Typography>
                    <Select
                      options={documentOptions}
                      placeholder="Select Documents"
                      value={values.documentForRegulation}
                      onChange={(option) =>
                        setFieldValue("documentForRegulation", option)
                      }
                      isMulti
                      isClearable
                    />
                    {errors.documentForRegulation && (
                      <Typography
                        style={{
                          color: "red",
                          marginTop: "5px",
                          fontSize: "0.9rem",
                        }}
                      >
                        {errors.documentForRegulation}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button type="submit" autoFocus>
                    Save
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

RegulationDocDialog.propTypes = {
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  modalData: PropTypes.object,
  policyID: PropTypes.number,
  setFlag: PropTypes.func,
};

export default RegulationDocDialog;
