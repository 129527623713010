// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// regulator list component

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import langConstant from "_lang";
import AppPagination from "_components/AppPagination";
import SearchIcon from "@mui/icons-material/Search";
import FilterDropdown from "_components/FilterDropdown";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RegulatorInfoDialog from "../RegulatorInfoDialog";
import RegulationListDialog from "../RegulationListDialog";
import classNames from "classnames";
import ListIcon from "@mui/icons-material/List";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  acinStatus: {
    color: "#000000",
    border: "1px solid",
    borderRadius: "5px",
    padding: "3px 5px 1px",
    minWidth: "140px",
    textAlign: "center",
    display: "inline-block",
    textTransform: "uppercase",
    fontSize: "13px",
  },
  activeStatus: {
    borderColor: "#aff3bb",
    backgroundColor: "#aff3bb",
  },
  inactiveStatus: {
    borderColor: "#dfdfdf",
    backgroundColor: "#dfdfdf",
  },
  eyeIconCS: {
    width: "28px",
    height: "28px",
    display: "flex",
    padding: "4px",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "rgb(0, 123, 255)",
    marginRight: "5px",
    "& svg": {
      color: "rgb(255, 255, 255)",
      fontSize: "18px !important",
      margin: "0px auto",
    },
  },
  acIconCS: {
    width: "28px",
    height: "28px",
    display: "flex",
    padding: "4px",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "rgb(14, 148, 57)",
    marginRight: "5px",
    "& svg": {
      color: "rgb(255, 255, 255)",
      fontSize: "18px !important",
      margin: "0px auto",
    },
  },
}));

const RegulatorsListTable = ({
  columns,
  rows,
  setRegulatorInfoDialog,
  regulatorInfoDialog,
  regulatorId,
  setRegulatorId,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [regulationDialog, setRegulationDialog] = useState(false);
  const initialFilterParameter = { name: "", country: "", status: "" };
  const [filterParameter, setFilterParameter] = useState(
    initialFilterParameter
  );

  const country = [
    ...new Set(
      rows.map((row) => {
        return row.COUNTRY_SHORT_NAME;
      })
    ),
  ];
  country.sort();
  const countryOptions = country.map((item) => {
    return { value: item, name: item };
  });
  const statusOptions = [
    { value: true, name: "ACTIVE" },
    { value: false, name: "INACTIVE" },
  ];

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };

  const [pageCount, setPageCount] = useState(calculatePageCount(rows));
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onActionClick = (event, data) => {
    event?.preventDefault();
    data && setSelectedRow(data);
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  useEffect(() => {
    let tempRows = [...rows];
    if (filterParameter["name"] !== "") {
      tempRows = tempRows.filter((tempRow) =>
        tempRow.REGULATOR_NAME.toLowerCase().includes(
          filterParameter["name"].toLowerCase()
        )
      );
    }
    if (filterParameter["status"] !== "") {
      tempRows = tempRows.filter(
        (tempRow) => tempRow.ACTIVE == filterParameter["status"]
      );
    }
    if (filterParameter["country"] !== "") {
      tempRows = tempRows.filter(
        (tempRow) => tempRow.COUNTRY_SHORT_NAME == filterParameter["country"]
      );
    }
    tempRows =
      tempRows.length > 0
        ? tempRows.map((item) => {
            let data = item;
            data["ACTIONS"] = (
              <ActionIcon onClick={(e) => onActionClick(e, item)} />
            );

            const StatusLable = (
              <span
                className={classNames(
                  classes.acinStatus,
                  item.ACTIVE ? classes.activeStatus : classes.inactiveStatus
                )}
              >
                {item.ACTIVE ? "ACTIVE" : "INACTIVE"}
              </span>
            );
            data["STATUS"] = StatusLable;
            return data;
          })
        : [];
    setFilteredRows(
      tempRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    );
    setPageCount(calculatePageCount(tempRows));
  }, [page, rows, filterParameter]);

  const onFilterChange = (fieldName, e) => {
    setFilterParameter((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const resetFilter = () => {
    setFilterParameter(initialFilterParameter);
  };

  return (
    <Box>
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          isOpen={openMenu}
          onClose={onActionClick}
          menuItems={[
            {
              icon: RemoveRedEyeIcon,
              tooltipLabel: langConstant.REGULATOR_INFO,
              color: "rgb(0, 123, 255)",
              onClick: () => {
                setRegulatorInfoDialog(true);
                setRegulatorId(selectedRow?.REGULATOR_ID);
              },
            },
            {
              tooltipLabel: langConstant.REGULATION_LIST,
              color: "rgb(14, 148, 57)",
              onClick: () => {
                setRegulationDialog(true);
                setRegulatorId(selectedRow?.REGULATOR_ID);
              },
              icon: ListIcon,
            },
          ]}
        />
      )}
      <Box mb={2} mt={3}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="searchText"
                  label=""
                  name="searchText"
                  placeholder="Filter Table"
                  value={filterParameter["name"]}
                  onChange={(e) =>
                    setFilterParameter((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FilterDropdown
                  options={countryOptions}
                  label={"Country"}
                  fieldName={"country"}
                  value={filterParameter["country"]}
                  onChange={onFilterChange}
                  icon={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FilterDropdown
                  options={statusOptions}
                  label={"Status"}
                  fieldName={"status"}
                  value={filterParameter["status"]}
                  onChange={onFilterChange}
                  icon={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box mx={1}>
              {(filterParameter["name"] ||
                filterParameter["country"] ||
                filterParameter["status"]) && (
                <span onClick={resetFilter} className="linkIcon">
                  Reset All
                </span>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className="data-table">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={classes.stickyColl}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, rowIndex) => (
              <TableRow key={"row_" + rowIndex}>
                {columns.map((column, columnIndex) => {
                  const value = row[column.field];
                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={"column_" + columnIndex + row[columns[0].field]}
                    >
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {filteredRows.length === 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
      {regulatorInfoDialog && (
        <RegulatorInfoDialog
          regulatorId={regulatorId}
          show={regulatorInfoDialog}
          setShow={setRegulatorInfoDialog}
        />
      )}
      {regulatorId && regulationDialog && (
        <RegulationListDialog
          regulatorId={regulatorId}
          selectedRegulatorInfo={selectedRow}
          show={regulationDialog}
          setShow={setRegulationDialog}
        />
      )}
    </Box>
  );
};

RegulatorsListTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  setRegulatorInfoDialog: PropTypes.func,
  regulatorInfoDialog: PropTypes.bool,
  regulatorId: PropTypes.number, // changed from string to number
  setRegulatorId: PropTypes.func,
};

export default RegulatorsListTable;
