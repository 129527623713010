/* eslint-disable react/react-in-jsx-scope */
import apiService from "_services/api.service";
import { alertActions } from "_actions";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import sessionService from "_services/session.service";

export const GetDocumentsForRegulation = (
  i_REGULATION_ID,
  i_CUSTOMER_ID,
  setRequiredFiles,
  setIsLoading
) => {
  return (dispatch) => {
    setIsLoading(true);
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDocumentsForRegulation", {
        i_REGULATION_ID: i_REGULATION_ID,
        i_CUSTOMER_ID: i_CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response?.["#result-set-1"]) {
            setRequiredFiles(() => {
              let num = 0;
              let resultSet2 = [];
              let resultSet1 = [];
              response["#result-set-2"]?.forEach((item, index) => {
                let isAvilable = response["#result-set-1"]?.filter(
                  (res1Item) =>
                    item.GOLD_STANDARD_DOC_NAME ===
                    res1Item.DOCUMENT_RECOMMENDATION
                );
                if (isAvilable.length > 0) {
                  num = num + 1;
                  resultSet2.push({
                    ...item,
                    uuid: uuidv4(),
                    uuid2: uuidv4(),
                    no: num,
                    isGoldStandered: true,
                    REGULATIONDOCREQ_UNID: isAvilable[0].REGULATIONDOCREQ_UNID,
                  });
                }
                return;
              });
              response["#result-set-1"]?.forEach((item) => {
                let IsIn = resultSet2.filter(
                  (i) =>
                    i.GOLD_STANDARD_DOC_NAME === item.DOCUMENT_RECOMMENDATION
                );
                if (IsIn.length == 0) {
                  num += 1;
                  resultSet1.push({
                    ...item,
                    uuid: uuidv4(),
                    uuid2: uuidv4(),
                    no: num,
                    isGoldStandered: false,
                  });
                }
              });
              return [...resultSet2, ...resultSet1];
            });
          }
        },
        (error) => {
          if (error?.response?.data?.message) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
};

const getCustomerRegulationPolicyUploaded = (customerID, regulationID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerRegulationPolicyUploaded", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateCustomerRegulationPolicyMap = (
  customerID,
  regulationID,
  policyID,
  documentRequiredID,
  documentRequiredIDOld,
  userID,
  active
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateCustomerRegulationPolicyMap", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_POLICY_ID: policyID,
      i_DOCUMENT_REQUIRED_ID: documentRequiredID,
      i_OLD_DOCUMENT_REQUIRED_ID: documentRequiredIDOld,
      i_USER_ID: userID,
      i_ACTIVE: active,
      i_POLICY_ID2: null,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCustomerPolicyDocRequirementMap = (customerID, policyID, userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerPolicyDocRequirementMap", {
      i_CUSTOMER_ID: customerID,
      i_POLICY_ID: policyID,
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const fileUpload = (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/upload/fileUpload", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const deleteCustomerRegulationPolicyUpload = (
  customerID,
  regulationID,
  policyID,
  userID,
  userID2
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/DeleteCustomerRegulationPolicyUpload", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_POLICY_ID: policyID,
      i_USER_ID: parseInt(userID2), //login userID
      i_USER_ID2: userID, //Policy userID
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const deleteCustomerRegulationPolicyMapping = (
  customerID,
  policyID,
  regulationID,
  userID,
  documentRequiredID,
  i_POLICY_ID2
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/DeleteCustomerRegulationPolicyMapping", {
      i_CUSTOMER_ID: customerID,
      i_POLICY_ID: policyID,
      i_REGULATION_ID: regulationID,
      i_USER_ID: userID,
      i_DOCUMENT_REQUIRED_ID: documentRequiredID,
      i_POLICY_ID2: i_POLICY_ID2,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putCustomerRegulationPolicyMapping = (
  customerID,
  policyID,
  regulationID,
  userID,
  documentRequiredID,
  i_POLICY_ID2
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutCustomerRegulationPolicyMapping", {
      i_CUSTOMER_ID: customerID,
      i_POLICY_ID: policyID,
      i_REGULATION_ID: regulationID,
      i_USER_ID: userID,
      i_DOCUMENT_REQUIRED_ID: documentRequiredID,
      i_POLICY_ID2: i_POLICY_ID2,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCustomerRegulationPolicyMapping = (
  customerID,
  regulationID,
  userID
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerRegulationPolicyMapping", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const assessmentComparison = (cmpData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${sessionService.getItem("jwtToken")}`,
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(
      "https://gaps-analysis.internal.regulativ.ai/comparison",
      cmpData,
      config
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getAssessmentComparisonStatus = (jobId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${sessionService.getItem("jwtToken")}`,
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(
      `http://ml-api3.internal.regulativ.ai:8089/check_status/${jobId}`,
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getAvailablePolicies = (userId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetAvailablePolicies", {
      i_USER_ID: userId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getProcessingQueueItems = (customerId, queueItemType) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetProcessingQueueItems", {
      i_CUSTOMER_ID: customerId,
      i_USER_ID: null,
      i_QUEUE_ITEM_TYPE: queueItemType,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putPolicyComparisonV2 = (data) => {
  console.log(data);
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutPolicyComparisonV2", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getMlComparisonOutput = (uniqueId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetMlComparisonOutput", {
      i_QUEUE_UNIQUE_ID: uniqueId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  getCustomerRegulationPolicyUploaded,
  getCustomerPolicyDocRequirementMap,
  updateCustomerRegulationPolicyMap,
  fileUpload,
  assessmentComparison,
  getAvailablePolicies,
  deleteCustomerRegulationPolicyUpload,
  deleteCustomerRegulationPolicyMapping,
  putCustomerRegulationPolicyMapping,
  getCustomerRegulationPolicyMapping,
  getAssessmentComparisonStatus,
  getProcessingQueueItems,
  putPolicyComparisonV2,
  getMlComparisonOutput,
};
