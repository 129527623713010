/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  TextField,
  Box,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import langConstant from "_lang";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "../../../../_components/AppPagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import FilterDropdown from "../../../../_components/FilterDropdown";
import approveRegulationAction from "../ApproveRegulation/actions";
import sessionService from "_services/session.service";
import _ from "lodash";
import RuleBookDialogBox from "./RuleBookDialogBox";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";
import newRegulationService from "./services";
import { alertActions } from "_actions";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
}));

const AppDataTable = (props) => {
  const classes = useStyles();

  const rows = props.rows;
  const columns = props.columns;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };

  const [pageCount, setPageCount] = useState(calculatePageCount(rows));
  const emptyRows =
    rowsPerPage -
    Math.round(Math.min(rowsPerPage, rows.length - page * rowsPerPage));

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      filteredRows?.length > 0
        ? Math.round(Math.ceil(filteredRows.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    // scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filteredRows, setFilteredRows] = useState([]);
  const initialFilterObj = {
    COUNTRY_NAME: "",
    STATUS: "",
    REGULATION_DOMAIN: "",
  };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const [searchText, setSearchText] = useState("");
  const [advanceSearchText, setAdvanceSearchText] = useState("");
  const [advanceSearchData, setAdvanceSearchData] = useState([]);
  const [ruleBookPopUp, setRuleBookPopUp] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const userId = sessionService.getUserId();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [advanceSearchPage, setAdvanceSearchPage] = useState(0);
  const [advanceSearchRowsPerPage, setAdvanceSearchRowsPerPage] = useState(5);
  const [filterRegulationName, setFilterRegulationName] = useState("");
  const [filterRegulatorControlGroup, setFilterRegulatorControlGroup] =
    useState("");

  const customerID = sessionService.getCustomerId();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const advanceSearchPageCount = Math.ceil(
    advanceSearchData
      .filter((row) =>
        filterRegulationName
          ? row.REGULATION_NAME.includes(filterRegulationName)
          : true
      )
      .filter((row) =>
        filterRegulatorControlGroup
          ? row.REGULATOR_CONTROL_GROUP?.includes(filterRegulatorControlGroup)
          : true
      ).length / advanceSearchRowsPerPage
  );

  const highlightText = (text, searchText) => {
    if (!searchText || !text) return text;

    const regex = new RegExp(`(${searchText})`, "gi");
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === searchText.toLowerCase() ? (
        <span
          key={index}
          style={{ backgroundColor: "yellow", fontWeight: "bold" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  const handleAdvanceSearchChangePage = (newPage) => {
    setAdvanceSearchPage(newPage);
  };
  useEffect(() => {
    if (advanceSearchPage + 1 <= advanceSearchPageCount) {
      handleAdvanceSearchChangePage(advanceSearchPage);
    } else {
      handleAdvanceSearchChangePage(0);
    }
  }, [filterRegulationName, filterRegulatorControlGroup]);

  const handleAdvanceSearchChangeRowsPerPage = (event) => {
    setAdvanceSearchRowsPerPage(parseInt(event.value, 10));
    setAdvanceSearchPage(0);
  };

  const tableColumns = [
    { field: "QUESTION_ID", headerName: "Question ID", minWidth: 150 },
    { field: "REGULATION_NAME", headerName: "Regulation Name", minWidth: 200 },
    {
      field: "QUESTION_VERSION",
      headerName: "Regulation Version (from MQB)",
      minWidth: 200,
    },
    {
      field: "REGULATOR_CONTROL_GROUP",
      headerName: "Regulatory Control Group",
      minWidth: 200,
    },
    { field: "QUESTION_TEXT", headerName: "Question Text", minWidth: 300 },
    // { field: "SIMILARITY_SCORE", headerName: "Semantic Score", minWidth: 150 },
    // {
    //   field: "SOURCE_QUESTION",
    //   headerName: "Source Question ID",
    //   minWidth: 200,
    // },
  ];

  const onActionClick = (event, data, path, disableLink) => {
    event?.preventDefault();
    setSelectedRow({ data, path, disableLink });
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;

    const filterObj = { ...filterBy, [fieldName]: filterValue };
    setSearchText("");
    setFilterBy(filterObj);

    let filtered_array = rows.filter((item) => {
      if (
        filterObj["COUNTRY_NAME"] &&
        filterObj["STATUS"] &&
        filterObj["REGULATION_DOMAIN"]
      ) {
        return (
          filterObj["COUNTRY_NAME"] == item["COUNTRY_NAME"] &&
          filterObj["STATUS"] == item["STATUS"] &&
          filterObj["REGULATION_DOMAIN"] == item["REGULATION_DOMAIN"]
        );
      } else if (filterObj["COUNTRY_NAME"] && filterObj["STATUS"]) {
        return (
          filterObj["COUNTRY_NAME"] == item["COUNTRY_NAME"] &&
          filterObj["STATUS"] == item["STATUS"]
        );
      } else if (filterObj["COUNTRY_NAME"] && filterObj["REGULATION_DOMAIN"]) {
        return (
          filterObj["COUNTRY_NAME"] == item["COUNTRY_NAME"] &&
          filterObj["REGULATION_DOMAIN"] == item["REGULATION_DOMAIN"]
        );
      } else if (filterObj["STATUS"] && filterObj["REGULATION_DOMAIN"]) {
        return (
          filterObj["STATUS"] == item["STATUS"] &&
          filterObj["REGULATION_DOMAIN"] == item["REGULATION_DOMAIN"]
        );
      } else if (filterObj["COUNTRY_NAME"]) {
        return filterObj["COUNTRY_NAME"] == item["COUNTRY_NAME"];
      } else if (filterObj["STATUS"]) {
        return filterObj["STATUS"] == item["STATUS"];
      } else if (filterObj["REGULATION_DOMAIN"]) {
        return filterObj["REGULATION_DOMAIN"] == item["REGULATION_DOMAIN"];
      } else {
        return true;
      }
    });

    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };
  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    setSearchText("");
    setFilteredRows(rows);
    setPageCount(calculatePageCount(rows));
    setPage(0);
  };
  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);
    setFilterBy({ COUNTRY_NAME: "", STATUS: "", REGULATION_DOMAIN: "" });
    const filtered_array = rows.filter((row) => {
      return (
        row.REGULATION_NAME.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.REGULATOR_NAME.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };

  const advanceSearch = async () => {
    setSearchText("");
    setFilterBy({ COUNTRY_NAME: "", STATUS: "", REGULATION_DOMAIN: "" });

    const response = await newRegulationService.getRuleBookAdvanceSearch({
      i_CUSTOMER_ID: customerID,
      i_USER_ID: userId,
      i_RILEBOOK_SEARCH_STRING: advanceSearchText,
    });
    if (response && response["status"] == 200) {
      setAdvanceSearchData(response["#result-set-1"]);
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      }
    }
  };

  const advanceSearchFilteredRowsForDilog = advanceSearchData
    .filter((row) =>
      filterRegulationName
        ? row.REGULATION_NAME.includes(filterRegulationName)
        : true
    )
    .filter((row) =>
      filterRegulatorControlGroup
        ? row.REGULATOR_CONTROL_GROUP?.includes(filterRegulatorControlGroup)
        : true
    )
    .slice(
      advanceSearchPage * advanceSearchRowsPerPage,
      advanceSearchPage * advanceSearchRowsPerPage + advanceSearchRowsPerPage
    );

  const totalData = advanceSearchData
    .filter((row) =>
      filterRegulationName
        ? row.REGULATION_NAME.includes(filterRegulationName)
        : true
    )
    .filter((row) =>
      filterRegulatorControlGroup
        ? row.REGULATOR_CONTROL_GROUP?.includes(filterRegulatorControlGroup)
        : true
    ).length;

  const updateRegulationStatus = (status, id) => {
    if (
      status == "PENDING_FOR_REVIEW" &&
      (userId == "1004" || userId == "1006")
    ) {
      dispatch(
        approveRegulationAction.updateRegulationStatus(
          id,
          "REVIEW_IN_PROGRESS",
          null,
          langConstant.REGULATION_APPROVED
        )
      );
    } else if (status == "DRAFT") {
      dispatch(
        approveRegulationAction.updateRegulationStatus(
          id,
          "IN_PROGRESS",
          null,
          langConstant.REGULATION_APPROVED
        )
      );
    }
  };
  const downloadCSV = () => {
    const headers = tableColumns.map((col) => col.headerName).join(",");

    // Export **ALL** data, not just filtered data
    const rows = advanceSearchData.map((row) =>
      tableColumns.map((col) => `"${row[col.field] || ""}"`).join(",")
    );

    const csvContent = `data:text/csv;charset=utf-8,${[headers, ...rows].join(
      "\n"
    )}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "AllRegulationsData.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    const updateRows =
      rows.length > 0
        ? rows.map((item) => {
            let data = item;
            let link = "";
            let disableLink = true;
            if (props.pageSlug == "manage-questions") {
              if (item.STANDARD_STATUS != "AVAILABLE") {
                link = props.pageSlug;
                disableLink = true;
              } else {
                link = `/${props.pageSlug}/`;
                disableLink = false;
              }
            } else {
              if (
                item.STANDARD_STATUS == "LOADING" ||
                item.STANDARD_STATUS == "DRAFT" ||
                item.STANDARD_STATUS == "IN_PROGRESS" ||
                item.STANDARD_STATUS == "AVAILABLE" ||
                item.STANDARD_STATUS == null
              ) {
                link = "/new-regulation/";
                disableLink = false;
              } else if (
                item.STANDARD_STATUS == "PENDING_FOR_REVIEW" ||
                item.STANDARD_STATUS == "REVIEW_IN_PROGRESS"
              ) {
                link = "/approve-regulation/";
                disableLink = false;
              } else {
                link = "";
                disableLink = true;
                // TODO Enabled it for now, need to disable later
                // link = "/new-regulation/";
                // disableLink = false;
              }
            }
            data["Status_label"] = item.STATUS ? (
              <span className={item.STANDARD_STATUS + " status-badge"}>
                {item.STATUS}
              </span>
            ) : (
              item.STATUS
            );

            data["action"] = (
              <ActionIcon
                onClick={(e) => onActionClick(e, item, link, disableLink)}
              />
            );

            return data;
          })
        : [];
    setFilteredRows(updateRows);
    setPageCount(calculatePageCount(updateRows));
  }, [rows, props.pageSlug]);

  const createFilterDropdown = (fieldName, label) => {
    let options = _.reject(_.uniq(_.map(rows, fieldName)).sort(), _.isNull);
    if (options.length > 0 && options[0]) {
      options = options.map((item) => {
        return { value: item, name: item };
      });
    }
    if (options.length > 0) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName}
          value={filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  return (
    <div>
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          onClose={onActionClick}
          isOpen={openMenu}
          menuItems={[
            {
              tooltipLabel: langConstant.VIEW,
              icon: VisibilityIcon,
              isDisabled: selectedRow?.disableLink,
              linkTo: {
                pathname: selectedRow?.path + selectedRow?.data?.REGULATION_ID,
                state: {
                  regulatorId: selectedRow?.data?.REGULATOR_ID,
                  countryCode: selectedRow?.data?.COUNTRY_CODE,
                },
              },
            },
          ]}
        />
      )}
      <Box mb={2} mt={3}>
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>{langConstant.ADVANCE_SEARCH}</DialogTitle>
          <DialogContent>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 1,
                  borderRadius: 2,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={5}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="searchText"
                      size="small"
                      name="searchText"
                      placeholder="Advanced Search"
                      value={advanceSearchText}
                      onChange={(e) => {
                        setAdvanceSearchText(e.target.value);
                        setAdvanceSearchPage(0);
                      }}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2} md={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={advanceSearch}
                      fullWidth
                      disabled={advanceSearchText == ""}
                      size="small"
                    >
                      {langConstant.ADVANCE_SEARCH}
                    </Button>
                  </Grid>

                  <Grid item xs={6} sm={2} md={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={downloadCSV}
                      fullWidth
                      size="small"
                      disabled={advanceSearchData.length == 0}
                    >
                      Download CSV
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    textAlign="center"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <strong>{totalData} records found with match</strong>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  p: 1,
                  borderRadius: 2,
                  boxShadow: 1,
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      select
                      label="Filter Regulation Name"
                      value={filterRegulationName}
                      onChange={(e) => setFilterRegulationName(e.target.value)}
                      variant="outlined"
                      size="small"
                      fullWidth
                    >
                      <MenuItem value="">All</MenuItem>
                      {[
                        ...new Set(
                          advanceSearchData.map((item) => item.REGULATION_NAME)
                        ),
                      ].map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      select
                      label="Filter Regulatory Control Group"
                      value={filterRegulatorControlGroup}
                      onChange={(e) =>
                        setFilterRegulatorControlGroup(e.target.value)
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                    >
                      <MenuItem value="">All</MenuItem>
                      {[
                        ...new Set(
                          advanceSearchData.map(
                            (item) => item.REGULATOR_CONTROL_GROUP
                          )
                        ),
                      ].map((group) => (
                        <MenuItem key={group} value={group}>
                          {group || "N/A"}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setFilterRegulationName("");
                        setFilterRegulatorControlGroup("");
                      }}
                      fullWidth
                      size="small"
                    >
                      Reset All
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              {/* Table */}
              <TableContainer component={Paper}>
                <Table aria-label="Regulation List">
                  <TableHead>
                    <TableRow>
                      {tableColumns.map((column, index) => (
                        <TableCell
                          key={"column_" + index + column.field}
                          style={{
                            minWidth: column.minWidth,
                            fontWeight: "bold",
                          }}
                        >
                          {column.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {advanceSearchFilteredRowsForDilog.length > 0 ? (
                      advanceSearchFilteredRowsForDilog.map((row) => (
                        <TableRow key={row.QUESTION_ID}>
                          {tableColumns.map((column, index) => (
                            <TableCell
                              key={"column_" + index + row.QUESTION_ID || index}
                            >
                              {column.field === "QUESTION_TEXT" ? (
                                <span>
                                  {highlightText(
                                    row[column.field],
                                    advanceSearchText
                                  )}
                                </span>
                              ) : row[column.field] !== null ? (
                                row[column.field]
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow style={{ height: 53 }}>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={tableColumns.length}
                        >
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination Controls */}
              {advanceSearchPageCount > 1 && (
                <Box my={2}>
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <SelectPageRange
                      selectedRange={advanceSearchRowsPerPage}
                      handleChange={handleAdvanceSearchChangeRowsPerPage}
                    />
                    <AppPagination
                      count={advanceSearchPageCount}
                      rowsPerPage={advanceSearchRowsPerPage}
                      page={advanceSearchPage}
                      onChangePage={handleAdvanceSearchChangePage}
                      onChangeRowsPerPage={handleAdvanceSearchChangeRowsPerPage}
                    />
                  </Grid>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="searchText"
                  label=""
                  name="searchText"
                  placeholder="Filter Table"
                  value={searchText}
                  onChange={(e) => onSearch(e)}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {createFilterDropdown("REGULATION_DOMAIN", "Domain Name")}
              </Grid>
              <Grid item xs={12} md={2}>
                {createFilterDropdown("COUNTRY_NAME", "Country")}
              </Grid>
              <Grid item xs={12} md={2}>
                {createFilterDropdown("STATUS", "Status")}
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOpen}
                >
                  {langConstant.ADVANCE_SEARCH}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={3} style={{ alignItems: "center" }}>
            <Grid item md={6} xs={6}>
              <Box mx={1}>
                {(searchText ||
                  filterBy["COUNTRY_NAME"] ||
                  filterBy["REGULATION_DOMAIN"] ||
                  filterBy["STATUS"]) && (
                  <span onClick={resetFilter} className="linkIcon">
                    {langConstant.RESET_ALL}
                  </span>
                )}
              </Box>
            </Grid>
            <Grid container item md={6} xs={6} justify="flex-end">
              <Button
                color="primary"
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={() => setRuleBookPopUp(true)}
              >
                {langConstant.ADD_NEW_RULE_BOOK}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className={props.className} aria-label="Regulation List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={item.sticky && classes.stickyColl}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row) => (
              <TableRow key={row[columns[0].field]}>
                {columns.map((column, index) => {
                  //const Comp = column.component && column.component
                  const value = row[column.field];
                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={"column_" + index + row[columns[0].field]}
                      className={column.sticky && classes.stickyColl}
                    >
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />

            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
      {ruleBookPopUp && (
        <RuleBookDialogBox
          open={ruleBookPopUp}
          setOpen={setRuleBookPopUp}
          data={rows}
        />
      )}
    </div>
  );
};

AppDataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  pageSlug: PropTypes.string,
};

export default AppDataTable;
