/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Badge,
  Menu,
  MenuItem,
  MenuList,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BuildIcon from "@mui/icons-material/Build";
import SupportIcon from "@mui/icons-material/Support";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import MoreIcon from "@material-ui/icons/MoreVert";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import logo from "../../_assets/new_regulativ_purple.png";
import userActions from "../../containers/LoginPage/actions";
import { confirmationDialogActions } from "../../_actions/confirmationDialog.action";
import langConstant from "_lang";
import sessionService from "../../_services/session.service";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useModal } from "_hooks";
import Notifications from "./NotificationsPopup/index.jsx";
import MyProfilePopup from "./MyProfilePopup/index";
import FeatureDialog from "./FeatureDialog";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import styles from "./styles";
import TaskList from "./TaskListPopup";
import { getThemeDetails } from "containers/LoginPage/reducers";
import ServiceRequestDialog from "./TaskListPopup/components/serviceRequestPopup";

const PrimarySearchAppBar = (props) => {
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);
  // const themeColor = window.THEME_COLOR;
  // const fontColor = window.THEME_FONT_COLOR;
  const classes = styles({
    themeColor: themeDetails?.TOPBAR_BGROUND_COLOUR,
    fontColor: window.THEME_FONT_COLOR,
  });
  const getBackgroundStyle = () => {
    if (themeDetails.TOPBAR_TYPE === "color") {
      return { background: themeDetails.TOPBAR_DATA };
    } else if (themeDetails.TOPBAR_TYPE === "gradient") {
      return { background: themeDetails.TOPBAR_DATA };
    } else if (themeDetails.TOPBAR_TYPE === "image") {
      return {
        backgroundImage: `url(${themeDetails.TOPBAR_DATA})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "white",
      };
    }
    return {};
  };

  const history = useHistory();

  const serviceRequestModal = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [editProfilePopUp, setEditProfilePopUp] = useState(false);
  const [openFeatureDialog, setOpenFeatureDialog] = useState(false);
  const [twoFADetail, setTwoFADetail] = useState([]);
  const [notificationPreferences, setNotificationPreferences] = useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  let requestType = null;
  const userData = useSelector((state) => state.authentication.userData);

  const eventCount = useSelector(
    (state) => state.authentication.detailedInfo?.EVENT_COUNT
  );
  const FeatureList = useSelector(
    (state) => state.authentication.detailedInfo?.FEATURE_LIST
  );
  const [featureCount, setFeatureCount] = useState(FeatureList?.length);
  const userName = useSelector(
    (state) => state.authentication.detailedInfo?.Name
  );
  const customerName = useSelector(
    (state) => state.authentication.detailedInfo?.CUSTOMER_NAME
  );
  const userEmail = useSelector(
    (state) => state.authentication.detailedInfo?.EMAIL_ADDRESS
  );

  let view_service_request = false;
  let view_support_request = false;

  if (
    userData &&
    userData.allowedNavItems &&
    userData.allowedNavItems.length > 0 &&
    userData.allowedNavItems.indexOf("NEW_SERVICE_REQUEST") > -1
  ) {
    view_service_request = true;
  }

  if (
    userData &&
    userData.allowedNavItems &&
    userData.allowedNavItems.length > 0 &&
    userData.allowedNavItems.indexOf("SUPPORT_REQUEST") > -1
  ) {
    view_support_request = true;
  }

  useEffect(() => {
    setFeatureCount(FeatureList?.length);
  }, [FeatureList]);

  const userID = sessionService.getUserId();

  const onOpenServiceRequestDialog = () => {
    requestType = "SERVICE";
    serviceRequestModal?.setData({
      userName,
      customerName,
      userEmail,
      requestType,
    });
    serviceRequestModal?.openModal();
  };

  const onOpenSupportRequestDialog = () => {
    serviceRequestModal?.setData({
      userName,
      customerName,
      userEmail,
      requestType,
    });
    serviceRequestModal?.openModal();
  };

  const handleClickOpenCalendar = () => {
    history.push("/calendar");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logout = (userID) => {
    dispatch(userActions.logout(userID));
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(false);
    }
  };

  const handleClickOpenFeature = () => {
    setOpenFeatureDialog(!openFeatureDialog);
  };

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Popper
      open={isMenuOpen}
      id={menuId}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <MenuItem
                  className={
                    classes.profileMenuItem + " " + classes.borderBottom
                  }
                  onClick={() => {
                    handleMenuClose();
                    setEditProfilePopUp(true);
                  }}
                >
                  <PersonIcon className={classes.profileMenuIcon} /> My Profile
                </MenuItem>
                <MenuItem
                  className={
                    classes.profileMenuItem + " " + classes.borderBottom
                  }
                  onClick={() => {
                    sessionService.setItem(
                      "tempLogin",
                      JSON.stringify({ tempLogin: true })
                    );
                    props.setTempLogin(true);
                    history.push("/reset-password");
                  }}
                >
                  <SwapHorizIcon className={classes.profileMenuIcon} /> Change
                  Password
                </MenuItem>
                {view_service_request && (
                  <MenuItem
                    className={
                      classes.profileMenuItem + " " + classes.borderBottom
                    }
                    onClick={() => {
                      handleMenuClose();
                      onOpenServiceRequestDialog();
                    }}
                  >
                    <BuildIcon className={classes.profileMenuIcon} /> Service
                    Request
                  </MenuItem>
                )}

                {view_support_request && (
                  <MenuItem
                    className={
                      classes.profileMenuItem + " " + classes.borderBottom
                    }
                    onClick={() => {
                      handleMenuClose();
                      onOpenSupportRequestDialog();
                    }}
                  >
                    <SupportIcon className={classes.profileMenuIcon} /> Support
                    Request
                  </MenuItem>
                )}
                <MenuItem
                  className={
                    classes.profileMenuItem + " " + classes.borderBottom
                  }
                  onClick={() => {
                    history.push("/getting-started");
                    handleMenuClose();
                  }}
                >
                  <AutoAwesomeIcon className={classes.profileMenuIcon} />
                  Getting Started
                </MenuItem>

                <MenuItem
                  className={
                    classes.profileMenuItem + " " + classes.borderBottom
                  }
                  onClick={() => {
                    history.push("/help-section");
                    handleMenuClose();
                  }}
                >
                  <QuestionMarkIcon className={classes.profileMenuIcon} /> Help
                  Portal
                </MenuItem>

                <MenuItem
                  className={classes.profileMenuItem}
                  onClick={() => {
                    dispatch(
                      confirmationDialogActions.open({
                        message: langConstant.LOGOUT_MESSAGE_FN(),

                        onConfirm: () => {
                          logout(userID);
                        },
                        title: "LOGOUT CONFIRMATION",
                        option1: langConstant.YES_PLEASE,
                        option2: langConstant.NO_THANKS,
                      })
                    );
                  }}
                >
                  <ReplyAllIcon className={classes.profileMenuIcon} /> Log Out
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.sectionMobile}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem
        className={classes.profileMenuItem + " " + classes.borderBottom}
        onClick={() => {
          handleMenuClose();
          setEditProfilePopUp(true);
        }}
      >
        <PersonIcon className={classes.profileMenuIcon} /> My Profile
      </MenuItem>
      <MenuItem
        className={classes.profileMenuItem + " " + classes.borderBottom}
        onClick={() => {
          sessionService.setItem(
            "tempLogin",
            JSON.stringify({ tempLogin: true })
          );
          props.setTempLogin(true);
          history.push("/reset-password");
        }}
      >
        <SwapHorizIcon className={classes.profileMenuIcon} /> Change Password
      </MenuItem>
      {view_service_request && (
        <MenuItem
          className={classes.profileMenuItem + " " + classes.borderBottom}
          onClick={() => {
            handleMenuClose();
            onOpenServiceRequestDialog();
          }}
        >
          <BuildIcon className={classes.profileMenuIcon} /> Service Request
        </MenuItem>
      )}

      {view_support_request && (
        <MenuItem
          className={classes.profileMenuItem + " " + classes.borderBottom}
          onClick={() => {
            handleMenuClose();
            onOpenSupportRequestDialog();
          }}
        >
          <SupportIcon className={classes.profileMenuIcon} />
          Support Request
        </MenuItem>
      )}
      <MenuItem
        className={classes.profileMenuItem + " " + classes.borderBottom}
        onClick={() => {
          history.push("/getting-started");
          handleMenuClose();
        }}
      >
        <AutoAwesomeIcon className={classes.profileMenuIcon} />
        Getting Started
      </MenuItem>

      <MenuItem
        className={classes.profileMenuItem + " " + classes.borderBottom}
        onClick={() => {
          history.push("/help-section");
          handleMenuClose();
        }}
      >
        <QuestionMarkIcon className={classes.profileMenuIcon} />
        Help Portal
      </MenuItem>

      <MenuItem
        className={classes.profileMenuItem}
        onClick={() => {
          dispatch(
            confirmationDialogActions.open({
              message: langConstant.LOGOUT_MESSAGE_FN(),

              onConfirm: () => {
                logout(userID);
              },
              title: "LOGOUT CONFIRMATION",
              option1: langConstant.YES_PLEASE,
              option2: langConstant.NO_THANKS,
            })
          );
        }}
      >
        <ReplyAllIcon className={classes.profileMenuIcon} /> Log out
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      {themeDetails && (
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{
            ...getBackgroundStyle(),
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
            {props.primaryLogo && <img src={props.primaryLogo} />}
            <div className={classes.grow} />
            <div className={classes.search}>
              {/* <div className={classes.searchIcon}>
                <SearchIcon />
              </div> */}
              {/* <InputBase
                placeholder="AI Assisted Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              /> */}
            </div>
            <div className={classes.grow2} />
            <div className={classes.sectionDesktop}>
              <IconButton
                onClick={handleClickOpenFeature}
                className={classes.iconBtn}
              >
                {featureCount > 0 && (
                  <Grid className={classes.redBadge}>{featureCount}</Grid>
                )}
                <FiberNewIcon />
              </IconButton>
              <TaskList />
              <IconButton
                onClick={handleClickOpenCalendar}
                className={classes.iconBtn}
              >
                {eventCount > 0 && (
                  <Grid className={classes.redBadge}>{eventCount}</Grid>
                )}
                <CalendarTodayOutlined />
              </IconButton>

              <Notifications />
              <List className={classes.profileDropDownList}>
                <ListItem onClick={handleProfileMenuOpen}>
                  <ListItemAvatar>
                    <Avatar
                      className={classes.profileAvatar}
                      src={props.userAvatar}
                      alt={props.userData && props.userData.userName}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ className: classes.primaryText }}
                    secondaryTypographyProps={{
                      className: classes.secondaryText,
                    }}
                    primary={props.userData && props.userData.userName}
                    secondary={props.userData && props.userData.userRole}
                  />
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <ExpandMore
                      aria-label={props.userData && props.userData.userName}
                    />
                  </IconButton>
                </ListItem>
              </List>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          {renderMenu}
          {renderMobileMenu}
          {serviceRequestModal?.isOpen && (
            <ServiceRequestDialog modal={serviceRequestModal} />
          )}

          {editProfilePopUp && (
            <MyProfilePopup
              user_ID={parseInt(userID)}
              show={editProfilePopUp}
              setShow={setEditProfilePopUp}
              isTeamView={false}
              twoFADetail={twoFADetail}
              setTwoFADetail={setTwoFADetail}
              notificationPreferences={notificationPreferences}
              setNotificationPreferences={setNotificationPreferences}
            />
          )}
          {openFeatureDialog && (
            <FeatureDialog
              FeatureList={FeatureList}
              userID={parseInt(userID)}
              show={openFeatureDialog}
              setOpenFeatureDialog={setOpenFeatureDialog}
              setFeatureCount={setFeatureCount}
            />
          )}
        </AppBar>
      )}
    </React.Fragment>
  );
};

PrimarySearchAppBar.propTypes = {
  handleSidebarOpen: PropTypes.func,
  userData: PropTypes.object,
  userAvatar: PropTypes.string,
  primaryLogo: PropTypes.string,
  setTempLogin: PropTypes.func,
};
export default PrimarySearchAppBar;
