import { Grid } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import useStyles from "../styles";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AnalysisTable from "./analysistable";
import langConstant from "_lang";
import DocumentViewer from "_components/DocumentViewer";

const DocumentDrawer = memo(
  ({ show, setShow, metaData, setNum, policyMetaData, num }) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    let gapData = [];

    if (Array.isArray(metaData) && metaData.length > 0) {
      const groupedByLevel = metaData.reduce((acc, item) => {
        if (!acc[item.SECTION_ORDER]) {
          acc[item.SECTION_ORDER] = [];
        }
        acc[item.SECTION_ORDER].push(item);
        return acc;
      }, {});
      Object.values(groupedByLevel).forEach((element, otIndex) => {
        element.forEach((innerEle, index) => {
          if (innerEle.GAP_DESCRIPTION) {
            gapData.push({
              header: element[0].SECTION_NAME,
              gap_identified: innerEle.GAP_DESCRIPTION.trim(),
              gapKey: `${innerEle.SECTION_ORDER}-${innerEle.SENTENCE_INDEX}`,
              index: [otIndex, index],
              status: innerEle.GAP_ANALYSIS_STATUS,
            });
          }
        });
      });
    }
    const handleChange = async (event, newValue) => {
      setTabValue(newValue);
    };
    const baseFile = policyMetaData?.BASE_FILE[0];
    const targetFile = policyMetaData?.TARGET_FILE[0];
    const baseExtension = "pdf";

    return (
      <Drawer
        anchor={"right"}
        open={show}
        onClose={() => setShow(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "700px !important",
          },
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          TabIndicatorProps={{ style: { padding: 2 } }}
          orientation="horizontal"
          variant="scrollable"
        >
          <Tab aria-orientation="horizontal" label={"Recommendations"} />
          <Tab
            aria-orientation="horizontal"
            label={langConstant.BASE_DOCUMENT}
          />
          <Tab
            aria-orientation="horizontal"
            label={langConstant.TARGET_DOCUMENT}
          />
        </Tabs>
        <Grid className={classes.docViewer} style={{ height: "100vh" }}>
          {tabValue === 0 && (
            <AnalysisTable
              analyseData={gapData.length > 0 ? gapData : []}
              setNum={setNum}
              num={num}
            />
          )}
          {tabValue === 1 && (
            <DocumentViewer
              documentClass={{
                "& #header-bar": {
                  display: "none !important",
                },
                "& #pdf-download": {
                  display: "none !important",
                },
                "& #proxy-renderer": {
                  overflow: "hidden",
                },
              }}
              height="calc(100vh - 20px)"
              filePath={baseFile?.STORAGE_FOLDER_URL}
              fileName={baseFile?.POLICY_FILE_NAME}
              fileType={baseExtension}
              docName={true}
            />
          )}
          {tabValue === 2 && (
            <DocumentViewer
              documentClass={{
                "& #header-bar": {
                  display: "none !important",
                },
                "& #pdf-download": {
                  display: "none !important",
                },
                "& #proxy-renderer": {
                  overflow: "hidden",
                },
              }}
              height="calc(100vh - 20px)"
              filePath={targetFile?.STORAGE_FOLDER_URL}
              fileName={targetFile?.POLICY_FILE_NAME}
              fileType={baseExtension}
              docName={true}
            />
          )}
        </Grid>
      </Drawer>
    );
  }
);

DocumentDrawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  metaData: PropTypes.array,
  setNum: PropTypes.func,
  policyMetaData: PropTypes.object,
  num: PropTypes.number,
};
export default DocumentDrawer;
