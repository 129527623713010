import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import FilledIconsPreview from "_components/MaterialIconsPreview/FilledIcons";
import OutlinedIconsPreview from "_components/MaterialIconsPreview/OutlinedIcons";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import AdminUtils from "../utils";
import useStyles from "../styles";
import langConstant from "_lang";
import Select from "react-select";
import PropTypes from "prop-types";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import AdminServices from "../services";
import { useDispatch } from "react-redux";
import OptionComponent from "containers/manage-regulation/NewRegulation/ManageQuestions/component/OptionComponent";
import * as Icons from "@mui/icons-material";
import AlertDialog from "_components/AlertDialog";

const EntitlementForm = ({
  selectedEntitlement,
  entitlementLables,
  setEntitlementLabels,
  setSelectedEntitlement,
  isEditView,
  setIsEditView,
  selectedCustomer,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeIconsBox, setActiveIconsBox] = useState(false);
  const [inActiveIconsBox, setInActiveIconsBox] = useState(false);
  const [entitlementList, setEntitlementList] = useState([]);
  const [removeDialog, setRemoveDialog] = useState(false);

  const initialValues = selectedEntitlement
    ? entitlementLables.find(
        (entitlement) =>
          entitlement.ENTITLEMENT_LABEL_ID ==
          selectedEntitlement.ENTITLEMENT_LABEL_ID
      )
    : AdminUtils.initialEntitlementValues;

  useEffect(() => {
    dispatch(AdminServices.getEntitlementsList(setEntitlementList));
  }, []);

  const renderIcon = (iconName) => {
    const SelectedIcon = Icons[iconName];
    if (SelectedIcon && iconName) {
      return <SelectedIcon className={classes.icon} />;
    } else {
      return <Typography variant="caption">Icon not available</Typography>;
    }
  };

  const submitFormData = (values) => {
    values["CUSTOMER_ID"] = selectedCustomer.value;
    dispatch(
      AdminServices.putOrUpdateEntitlementLabel(
        values,
        entitlementLables,
        setEntitlementLabels,
        setSelectedEntitlement,
        setIsEditView
      )
    );
  };

  const removeEntitlementLabel = () => {
    setRemoveDialog(false);
    dispatch(
      AdminServices.removeEntitlementLabel(
        selectedEntitlement.ENTITLEMENT_LABEL_ID,
        entitlementLables,
        setEntitlementLabels,
        setSelectedEntitlement,
        setIsEditView
      )
    );
  };

  return (
    <Grid style={{ padding: "20px" }}>
      {isEditView.mode === "edit" && (
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Typography
            className={classes.customerFormLabel}
            style={{ marginRight: "10px" }}
          >
            {langConstant.SELECTED_ENTITLEMENT}
          </Typography>
          <Select
            className={classes.entitlementSelectForm}
            options={entitlementLables}
            components={{ Option: OptionComponent }}
            closeMenuOnSelect={true}
            close
            hideSelectedOptions={false}
            value={selectedEntitlement}
            placeholder={langConstant.SELECT_ENTITLEMENT}
            onChange={(entitlement) => {
              entitlement["ENTITLEMENT_ICON_PREVIEW"] = entitlement[
                "ENTITLEMENT_ICON"
              ]
                ? JSON.parse(entitlement["ENTITLEMENT_ICON"])
                : { active: "", inactive: "" };
              setSelectedEntitlement(entitlement);
            }}
          />
        </Grid>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={AdminUtils.entitlementValidationSchema}
        validateOnChange={true}
        onSubmit={submitFormData}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.ENTITLEMENT_NAME}
                </Typography>
                <Field
                  style={{ width: "100%", margin: "10px" }}
                  name="ENTITLEMENT_NAME"
                >
                  {() => (
                    <Select
                      className={classes.customerSelectForm}
                      options={entitlementList}
                      value={entitlementList.find(
                        (entitlement) =>
                          entitlement.value === values.ENTITLEMENT_NAME
                      )}
                      placeholder={langConstant.SELECT_ENTITLEMENT_NAME}
                      onChange={(entitlement) =>
                        setFieldValue("ENTITLEMENT_NAME", entitlement.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="ENTITLEMENT_NAME">
                  {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.ENTITLEMENT_LABEL}
                </Typography>
                <Field
                  style={{ width: "100%", margin: "10px" }}
                  name="ENTITLEMENT_LABEL"
                >
                  {() => (
                    <TextField
                      style={{ width: "80%", paddingBottom: "10px" }}
                      value={values.ENTITLEMENT_LABEL}
                      onChange={(e) =>
                        setFieldValue("ENTITLEMENT_LABEL", e.target.value)
                      }
                      placeholder={langConstant.ENTITLEMENT_LABEL}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.inputField,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="ENTITLEMENT_LABEL">
                  {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.ENTITLEMENT_LABEL_LEVEL}
                </Typography>
                <Field
                  style={{ width: "100%", margin: "10px" }}
                  name="ENTITLEMENT_LABEL_LEVEL"
                >
                  {() => (
                    <Select
                      className={classes.customerSelectForm}
                      value={AdminUtils.labelLevelOptions.find(
                        (labelLevel) =>
                          labelLevel.value ===
                          parseInt(values.ENTITLEMENT_LABEL_LEVEL)
                      )}
                      options={AdminUtils.labelLevelOptions}
                      placeholder={langConstant.ENTITLEMENT_LABEL_LEVEL}
                      onChange={(type) =>
                        setFieldValue("ENTITLEMENT_LABEL_LEVEL", type.value)
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.ENTITLEMENT_PARENT}
                </Typography>
                <Field
                  style={{ width: "100%", margin: "10px" }}
                  name="ENTITLEMENT_PARENT"
                >
                  {() => (
                    <Select
                      className={classes.customerSelectForm}
                      options={entitlementList}
                      value={entitlementList.find(
                        (entitlement) =>
                          entitlement.value === values.ENTITLEMENT_PARENT
                      )}
                      placeholder={langConstant.SELECT_ENTITLEMENT_PARENT}
                      onChange={(entitlement) =>
                        setFieldValue("ENTITLEMENT_PARENT", entitlement.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="ENTITLEMENT_PARENT">
                  {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.ENTITLEMENT_PATH}
                </Typography>
                <Field
                  style={{ width: "100%", margin: "10px" }}
                  name="ENTITLEMENT_PATH"
                >
                  {() => (
                    <TextField
                      style={{ width: "80%", paddingBottom: "10px" }}
                      value={values.ENTITLEMENT_PATH}
                      onChange={(e) =>
                        setFieldValue("ENTITLEMENT_PATH", e.target.value)
                      }
                      placeholder={langConstant.ENTITLEMENT_PATH}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.inputField,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="ENTITLEMENT_PATH">
                  {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.ENTITLEMENT_TOOLTIP}
                </Typography>
                <Field
                  style={{ width: "100%", margin: "10px" }}
                  name="ENTITLEMENT_TOOLTIP"
                >
                  {() => (
                    <TextField
                      style={{ width: "80%", paddingBottom: "10px" }}
                      value={values.ENTITLEMENT_TOOLTIP}
                      onChange={(e) =>
                        setFieldValue("ENTITLEMENT_TOOLTIP", e.target.value)
                      }
                      placeholder={langConstant.ENTITLEMENT_TOOLTIP}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.inputField,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="ENTITLEMENT_TOOLTIP">
                  {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                </ErrorMessage>
              </Grid>
              <Grid item md={12} xs={12} container>
                <Grid item md={6} xs={12}>
                  <Grid
                    style={{
                      display: "inline-flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography className={classes.customerFormLabel}>
                      {langConstant.ACTIVE_ICON}
                    </Typography>
                    <ChangeCircleIcon
                      onClick={() => setActiveIconsBox(true)}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                  <Typography>
                    {renderIcon(values.ENTITLEMENT_ICON_PREVIEW.active)}
                  </Typography>
                  <ErrorMessage name="ENTITLEMENT_ICON_PREVIEW.active">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                  {activeIconsBox && (
                    <FilledIconsPreview
                      show={activeIconsBox}
                      setShow={setActiveIconsBox}
                      handleChange={setFieldValue}
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid
                    style={{
                      display: "inline-flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography className={classes.customerFormLabel}>
                      {langConstant.INACTIVE_ICON}
                    </Typography>
                    <ChangeCircleIcon
                      onClick={() => setInActiveIconsBox(true)}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                  <Typography>
                    {renderIcon(values.ENTITLEMENT_ICON_PREVIEW.inactive)}
                  </Typography>
                  <ErrorMessage name="ENTITLEMENT_ICON_PREVIEW.inactive">
                    {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                  </ErrorMessage>
                  {inActiveIconsBox && (
                    <OutlinedIconsPreview
                      show={inActiveIconsBox}
                      setShow={setInActiveIconsBox}
                      handleChange={setFieldValue}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.CUSTOMER_DEFAULT}
                </Typography>
                <Field style={{ width: "100%" }} name="CUSTOMER_DEFAULT">
                  {() => (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={parseInt(values.CUSTOMER_DEFAULT)}
                      onChange={(e) =>
                        setFieldValue("CUSTOMER_DEFAULT", e.target.value)
                      }
                      style={{
                        display: "inline",
                      }}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  )}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className={classes.customerFormLabel}>
                  {langConstant.ENTITLEMENT_TYPE}
                </Typography>
                <Field
                  style={{ width: "100%", margin: "10px" }}
                  name="ENTITLEMENT_TYPE"
                >
                  {() => (
                    <Select
                      className={classes.customerSelectForm}
                      options={AdminUtils.entitlementTypeOptions}
                      value={AdminUtils.entitlementTypeOptions?.find(
                        (entitlementType) =>
                          entitlementType.value === values.ENTITLEMENT_TYPE
                      )}
                      placeholder={langConstant.SELECT_ENTITLEMENT_TYPE}
                      onChange={(entitlementType) =>
                        setFieldValue("ENTITLEMENT_TYPE", entitlementType.value)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage name="ENTITLEMENT_TYPE">
                  {(msg) => <span className={classes.errorTxt}>{msg}</span>}
                </ErrorMessage>
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              {isEditView.mode === "create"
                ? langConstant.CREATE_ENTITLEMENT_LABEL
                : langConstant.UPDATE_ENTITLEMENT_LABEL}
            </Button>
            {isEditView.mode !== "create" && (
              <Button
                type="button"
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px", marginLeft: "10px" }}
                onClick={() => setRemoveDialog(true)}
              >
                {langConstant.REMOVE}
              </Button>
            )}
          </Form>
        )}
      </Formik>
      {isEditView.mode !== "create" && (
        <AlertDialog
          open={removeDialog}
          handleClose={() => setRemoveDialog(false)}
          buttonText={"DELETE"}
          onButtonClick={removeEntitlementLabel}
          dialogText={"Are You Sure Want To Remove The Entitlement Label ?"}
        />
      )}
    </Grid>
  );
};

EntitlementForm.propTypes = {
  entitlementLables: PropTypes.array,
  setEntitlementLabels: PropTypes.func,
  selectedEntitlement: PropTypes.any,
  setSelectedEntitlement: PropTypes.func,
  isEditView: PropTypes.object,
  setIsEditView: PropTypes.func,
  selectedCustomer: PropTypes.oneOfType([() => null, PropTypes.object]),
};

export default EntitlementForm;
