import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import AdminServices from "../services";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import useStyles from "../styles";
import langConstant from "_lang";
import EntitlementForm from "./EntitlementForm";
import EntitlementTable from "./EntitlementTable";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

const EntitlementLabels = ({ selectedCustomer }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [entitlementLables, setEntitlementLabels] = useState([]);
  const [selectedEntitlement, setSelectedEntitlement] = useState(null);
  const [isEditView, setIsEditView] = useState({ mode: "", view: false });

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.value) {
      dispatch(
        AdminServices.getCustomerEntitlementLabels(
          selectedCustomer.value,
          setEntitlementLabels
        )
      );
    }
    setSelectedEntitlement(null);
  }, [selectedCustomer]);

  return (
    <Grid style={{ height: "calc( 100vh - 300px )" }}>
      <Grid className={classes.entitlementWrapper}>
        <Grid
          style={{
            display: "flex",
            marginBottom: "5px",
            alignItems: "center",
          }}
          container
        >
          <Grid item md={2} xs={3} style={{ textAlign: "left" }}>
            {isEditView.view && (
              <Tooltip title={langConstant.BACK_TO_ENTITLEMENT_LABELS}>
                <IconButton
                  type="button"
                  color="primary"
                  onClick={() => {
                    setIsEditView({ mode: "", view: false });
                    setSelectedEntitlement(null);
                  }}
                >
                  <ArrowBackIosRoundedIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item md={8} xs={6} style={{ textAlign: "center" }}>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginRight: "15px",
              }}
            >
              {langConstant.ENTITLEMENT_LABELS}
            </Typography>
          </Grid>
          <Grid item md={2} xs={3} style={{ textAlign: "right" }}>
            {selectedCustomer &&
              selectedCustomer.value &&
              isEditView.mode !== "create" && (
                <Tooltip title={langConstant.ADD_ENTITLEMENT_LABEL}>
                  <IconButton
                    type="button"
                    color="primary"
                    onClick={() => {
                      setIsEditView({ mode: "create", view: true });
                      setSelectedEntitlement(null);
                    }}
                  >
                    <AddBoxRoundedIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              )}
          </Grid>
        </Grid>
       
        {!isEditView.view ? (
          <EntitlementTable
            entitlementLables={entitlementLables}
            setSelectedEntitlement={setSelectedEntitlement}
            selectedEntitlement={selectedEntitlement}
            setIsEditView={setIsEditView}
          />
        ) : (
          <EntitlementForm
            entitlementLables={entitlementLables}
            setEntitlementLabels={setEntitlementLabels}
            selectedEntitlement={selectedEntitlement}
            setSelectedEntitlement={setSelectedEntitlement}
            isEditView={isEditView}
            setIsEditView={setIsEditView}
            selectedCustomer={selectedCustomer}
          />
        )}
      </Grid>
    </Grid>
  );
};

EntitlementLabels.propTypes = {
  selectedCustomer: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

export default EntitlementLabels;
