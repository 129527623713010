import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import React from "react";
import searchData from "./index.jsx";

const getPowerBIResponse = () => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("user")
      .send(`/getPowerBIResponse`)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerSetupStatus = (
  customerID,
  setSetupQuestionList,
  setQueCount
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerSetupStatus", {
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const tempQuestionList = [];
          if (
            response &&
            response["#result-set-1"] &&
            response["#result-set-2"]
          ) {
            setQueCount(response["#result-set-1"].length);
            response["#result-set-1"].forEach((question) => {
              const temp = response["#result-set-2"].filter(
                (item) => item.QUESTION_ID == question.QUESTION_ID
              );

              if (
                temp[0].RESPONSE_TYPE_ID == 1 ||
                temp[0].RESPONSE_TYPE_ID == 8
              ) {
                const tempOptions = [];
                const seen = new Set();

                temp.forEach((element) => {
                  const optionValue = element.RESPONSE_OPTION_ID;

                  if (!seen.has(optionValue)) {
                    tempOptions.push({
                      value: optionValue,
                      label: element.RESPONSE_OPTION,
                    });
                    seen.add(optionValue);
                  }
                });

                const tempQuestion = {
                  ...question,
                  RESPONSE_TYPE_ID: temp[0].RESPONSE_TYPE_ID,
                  optionList: tempOptions,
                };
                tempQuestionList.push(tempQuestion);
              } else if (temp[0].RESPONSE_TYPE_ID == 3) {
                const tempQuestion = {
                  ...question,
                  RESPONSE_OPTION: temp[0].RESPONSE_OPTION,
                  RESPONSE_TYPE_ID: temp[0].RESPONSE_TYPE_ID,
                };
                tempQuestionList.push(tempQuestion);
              }
            });

            setSetupQuestionList(tempQuestionList);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerRegulationSetupQuestions = (
  customerID,
  setRegulationSetupQuestionList,
  setUniqueControlGroup
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerRegulationSetupQuestions", {
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            let uniqueControlGroup = {};
            const sortedItems = [];

            response["#result-set-1"].forEach((item) => {
              const regulatoryControlGroup = item.CISO_CONTROL_NAME.toString()
                .toLowerCase()
                .trim();
              if (!uniqueControlGroup[regulatoryControlGroup]) {
                uniqueControlGroup[regulatoryControlGroup] = [];
              }

              const modifiedItem = {
                ...item,
                answer: "",
              };

              uniqueControlGroup[regulatoryControlGroup].push(modifiedItem);
            });
            const sortedKeys = Object.keys(uniqueControlGroup).sort();
            setUniqueControlGroup(sortedKeys);

            sortedKeys.forEach((key) => {
              sortedItems[key] = [];
              sortedItems[key].push(...uniqueControlGroup[key]);
            });
            uniqueControlGroup = sortedItems;

            setRegulationSetupQuestionList(uniqueControlGroup);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getCustomerRegulationSetupStatus = (
  customerID,
  setCustomerRegulationSetupStatus
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerRegulationSetupStatus", {
        i_CUSTOMER_ID: parseInt(customerID),
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            setCustomerRegulationSetupStatus(response["#result-set-1"]);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putRegulationSetupAnswers = (
  customerID,
  submissionID,
  questionID,
  questionAnswer,
  userID
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRegulationSetupAnswers", {
        i_CUSTOMER_ID: customerID,
        i_SUBMISSION_ID: submissionID,
        i_QUESTION_ID: questionID,
        i_QUESTION_ANSWER: questionAnswer,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            console.log(response["#result-set-1"]);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const putClientSetupAnswers = (
  customerID,
  userID,
  submissionID,
  questionID,
  questionAnswer,
  setSetupQuestionList
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutClientSetupAnswers", {
        i_CUSTOMER_ID: customerID,
        i_USER_ID: userID,
        i_QUESTION_ID: questionID,
        i_QUESTION_ANSWER: questionAnswer,
        i_SUBMISSION_ID: submissionID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            setSetupQuestionList((prevList) =>
              prevList.map((question) =>
                question.QUESTION_ID === questionID
                  ? {
                      ...question,
                      CURRENT_ANSWER_SELECTED_RESPONSE: questionAnswer,
                    }
                  : question
              )
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  getCustomerSetupStatus,
  getCustomerRegulationSetupQuestions,
  getCustomerRegulationSetupStatus,
  putClientSetupAnswers,
  putRegulationSetupAnswers,
  getPowerBIResponse,
};
