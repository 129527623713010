import { Typography } from "@material-ui/core";
import { ArrowBackIos } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { alertActions } from "_actions";
import DashboardView from "_components/MasterAssessmentComp/component/DashboardPanel";
import sessionService from "_services/session.service";
import { GetAssessmentsForMyAssessments } from "containers/cyber-assessment/MyAssessments/NewInitiateMyAssessment/services";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import getActionService from "containers/manage-regulation/Policy/PolicyExplorer/services";
import {
  MasterAssessmentConstants,
  QUESTION_STATUS_TYPES,
} from "_components/MasterAssessmentComp/utils/constants";

const AssessmentDashboardWrapper = () => {
  const dispatch = useDispatch(),
    history = useHistory();
  const { id: SUBMISSION_ID, rid: REGULATION_ID } = useParams(),
    userId = sessionService.getUserId();

  const actions = useSelector((state) => state?.MasterAssessment?.ActionsList),
    assessmentDetails = useSelector(
      (state) => state?.MyAssessmnetReducer?.resultSet2
    )?.[0],
    assessmentKpiData = useSelector(
      (state) => state?.MyAssessmnetReducer?.AssessmentKpiData
    )?.[0],
    approversList = useSelector(
      (state) => state?.MyAssessmnetReducer?.ApproversList
    ),
    regulatorsList = useSelector(
      (state) => state?.MyAssessmnetReducer?.RegulatorsList
    ),
    questionList = useSelector(
      (state) => state.MyAssessmnetReducer.MyAssessmentDetails
    );

  const getPercentage = (data) => {
    if (!data?.length) return 0;
    const total = data?.length || 0;
    const completedQuestions = data?.filter(
        (q) =>
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.COMPLETED ||
          q?.QUESTION_STATUS?.trim() ===
            QUESTION_STATUS_TYPES.COMPLETED_AND_LOCKED
      ),
      completionPercentage =
        (Number(completedQuestions?.length || 0) / total) * 100;
    return completionPercentage?.toFixed(2) || 0;
  };

  const getProgressPercentage = (data) => {
    if (!data?.length) return 0;
    const total = data?.length || 0;
    const completedQuestions = data?.filter(
        (q) =>
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.PENDING_DATA ||
          q?.QUESTION_STATUS?.trim() ===
            QUESTION_STATUS_TYPES.READY_FOR_REVIEW ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_SENT ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_PENDING ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_OVERDUE ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.RFI_RESOLVED ||
          q?.QUESTION_STATUS?.trim() === QUESTION_STATUS_TYPES.SENT_BACK
      ),
      progressPercentage =
        (Number(completedQuestions?.length || 0) / total) * 100;
    // return progressPercentage?.toFixed(2) || 0;
    return completedQuestions?.length || 0;
  };

  const showAlert = (message, type = "error") =>
    dispatch(alertActions?.[type](message?.toString()));

  const getActions = async () => {
    if (!SUBMISSION_ID || !userId) return;
    await getActionService
      ?.getActions({
        i_SUBMISSION_ID: SUBMISSION_ID,
        i_USER_ID: userId,
      })
      .then((response) => {
        if (response?.status !== 200)
          return showAlert(response || "Something went wrong");
        dispatch({
          type: MasterAssessmentConstants.SET_ACTIONS_LIST,
          payload: response?.["#result-set-1"],
        });
      })
      .catch((error) => {
        console.error("error", error);
        showAlert(error);
      });
  };

  useEffect(() => {
    if (isNaN(SUBMISSION_ID)) return;
    dispatch(GetAssessmentsForMyAssessments(SUBMISSION_ID, userId));
    getActions();
  }, []);

  return (
    <React.Fragment>
      <Box height="82vh">
        <DashboardView
          submissionId={SUBMISSION_ID}
          assessmentId={REGULATION_ID}
          actions={actions}
          approversList={approversList}
          assessmentDetails={assessmentDetails}
          assessmentKpiData={assessmentKpiData}
          questionList={questionList}
          regulatorsList={regulatorsList}
          kpiData={{
            completedPercentage: getPercentage(questionList),
            progress: getProgressPercentage(questionList),
          }}
          getActions={getActions}
        />
      </Box>
    </React.Fragment>
  );
};

export default AssessmentDashboardWrapper;
