/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect } from "react";
import {
  Link as RouterLink,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Route } from "react-router-dom";
import { makeStyles, Typography, Box, Breadcrumbs } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import manageQuestionsAction from "../containers/manage-regulation/NewRegulation/ManageQuestions/actions";
import Stepper from "_components/Stepper/Stepper";
import sessionService from "_services/session.service";
import { getNavigationPathList } from "containers/LoginPage/reducers";

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    fontSize: "0.875rem",
  },
  link: {
    color: "#007BFF",
    textDecoration: "none",
  },
  filedTitle: {
    fontWeight: "500",
    padding: "0 5px",
  },
}));

const ShowStepperArray = [
  "/my-audits/:rid/:sid",
  "/my-assessments/:id/:sid",
  "/policy-explorer",
  "/vendor-assessment/:id/:rid",
];

const AppBreadcrumbs = () => {
  const isMatch = useRouteMatch(ShowStepperArray);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const customerID = sessionService.getCustomerId();
  const userName = useSelector(
    (state) => state.authentication.detailedInfo?.Name
  );
  const lastLogin = useSelector(
    (state) => state.authentication.detailedInfo?.LOGIN_DATETIME
  );
  const [showGreeting, setShowGreeting] = useState(true);

  const manageQuestions = useSelector((state) => state.manageQuestions);
  const steps = useSelector((state) => state.stepper.steps);
  const entitlementList = useSelector(getNavigationPathList);
  const [hideMessage, setHideMessage] = useState(false);
  const routesMap = entitlementList?.reduce((acc, item) => {
    if (
      (item?.ENTITLEMENT_LABEL_LEVEL == 2 ||
        item?.ENTITLEMENT_LABEL_LEVEL == 1) &&
      item?.ENTITLEMENT_PATH
    ) {
      let slug = item?.ENTITLEMENT_PATH?.replace("/", ""),
        parentPage =
          entitlementList?.find(
            (i) => i?.ENTITLEMENT_NAME == item?.ENTITLEMENT_PARENT
          )?.ENTITLEMENT_LABEL || item?.ENTITLEMENT_PARENT;
      acc[slug] = {
        parentPage,
        title: item?.ENTITLEMENT_SLUG || item?.ENTITLEMENT_LABEL,
      };
    }
    return acc;
  }, {});

  const userData = useSelector((state) => state.authentication.userData);
  const NavigationDetails = useSelector(
    (state) => state.authentication?.detailedInfo?.NAVIGATION_DETAILS
  );

  const systemMessage = useSelector(
    (state) => state.authentication?.detailedInfo?.SYSTEM_MESSAGE
  );
  const currentRequestedNavDetails = NavigationDetails?.find(
    (entitlement) => entitlement?.ENTITLEMENT_PATH === location.pathname
  );

  const isAllowed =
    userData?.allowedNavItems?.indexOf(
      currentRequestedNavDetails?.ENTITLEMENT_NAME
    ) > -1;

  const hideBreadcrumb = ["/dashboard-view"];

  const detailPages = {
    "manage-questions":
      manageQuestions.regulationDetail &&
      manageQuestions.regulationDetail.REGULATION_NAME
        ? manageQuestions.regulationDetail.REGULATION_NAME
        : "",
  };

  const getFirstName = (fullName) => {
    const name = fullName.split(" ");
    return name[0];
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    // Get day with suffix
    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th to 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Get month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];

    const year = date.getFullYear();

    // Format time with AM/PM
    let hours = date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

    // Construct the final formatted date
    const formattedDate = `${day}${daySuffix(
      day
    )} ${month}, ${year} ${hours}:${minutes}${ampm}`;

    return formattedDate;
  };

  const getGreetingMessage = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 0 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 16) {
      return "Good Afternoon";
    } else if (currentHour >= 16 && currentHour < 24) {
      return "Good Evening";
    } else {
      return "Good Night";
    }
  };

  const getRouteBasedTitle = () => {
    const matchingEntitlements = entitlementList?.filter(
      (i) =>
        i?.ENTITLEMENT_PATH?.includes(location.pathname) &&
        location.pathname !== "/"
    );

    return location?.pathname === "/"
      ? matchingEntitlements?.find((i) => i?.ENTITLEMENT_PATH === "/")
          ?.ENTITLEMENT_LABEL || ""
      : matchingEntitlements?.find(
          (i) => i?.ENTITLEMENT_LABEL_LEVEL != 1 && i?.ENTITLEMENT_PARENT
        )?.ENTITLEMENT_LABEL ||
          matchingEntitlements?.find(
            (i) => i?.ENTITLEMENT_PATH === location.pathname
          )?.ENTITLEMENT_LABEL ||
          "";
  };

  const getPathsToShowGreetings = () => {
    const matchingEntitlements = entitlementList
      ?.filter(
        (i) =>
          i.ENTITLEMENT_NAME == "LANDING_PAGE" ||
          i.ENTITLEMENT_NAME == "HOME_PAGE"
      )
      .map((item) => item.ENTITLEMENT_PATH);

    return matchingEntitlements
      ? matchingEntitlements?.includes(location?.pathname)
      : false;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGreeting(false);
    }, 50000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route>
      {({ location }) => {
        const AssessmentName = location?.state?.AssessmentName;
        const pathnames = location.pathname.split("/")?.filter((x) => x);
        const pageSlug = pathnames[0];
        // const parentPage = routesMap?.[pageSlug]
        //   ? routesMap?.[pageSlug]?.parentPage
        //   : "Dashboard";

        // Calling action so that breadcrumb is not empty
        if (
          pageSlug == "manage-questions" &&
          !isNaN(pathnames[1]) &&
          pathnames.length > 2 &&
          manageQuestions.regulationDetail &&
          !manageQuestions.regulationDetail.REGULATION_NAME
        ) {
          dispatch(
            manageQuestionsAction.getMQBAssessments(pathnames[1], customerID)
          );
        }

        const idTitle =
          detailPages[pageSlug] &&
          !isNaN(pathnames[pathnames.length - 1]) &&
          detailPages[pageSlug];

        if (location.pathname.includes(hideBreadcrumb)) return null;

        return (
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "0px" }}

            // borderBottom="1px solid #0000001f"
          >
            <Box
              mb={1}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0px",
              }}
              flex={1}
            >
              <Breadcrumbs
                aria-label="Breadcrumb"
                className={classes.breadcrumbs}
              >
                {/* <Typography variant="inherit" color="textPrimary" key={1}>
                {parentPage}
              </Typography> */}
                {pathnames?.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                  let title = routesMap?.[value]?.title || value;
                  // Adding condition so that Regulation number doesn't show in manage questions page
                  if (
                    pageSlug == "manage-questions" &&
                    !last &&
                    !isNaN(value)
                  ) {
                    return "";
                  } else {
                    return last ? (
                      <Typography
                        variant="inherit"
                        color="textPrimary"
                        key={to}
                      >
                        {idTitle ? idTitle : title}
                      </Typography>
                    ) : (
                      <RouterLink
                        color="inherit"
                        className={classes.link}
                        to={to}
                        key={to}
                      >
                        {title}
                      </RouterLink>
                    );
                  }
                })}
              </Breadcrumbs>
              <Box
                style={{
                  minWidth: "240px",
                  alignSelf: "flex-end",
                  marginTop: "0px",
                }}
              >
                {isMatch?.isExact && AssessmentName && (
                  <Typography className={classes.filedTitle}>
                    Assessment Name : {AssessmentName}
                  </Typography>
                )}
              </Box>
              <Box
                style={{
                  height: "5px",
                  width: "26%",
                  minWidth: "240px",
                  alignSelf: "flex-end",
                  marginTop: "0px",
                }}
              >
                {/* {isMatch?.isExact && steps?.length > 0 && <Stepper />} */}
              </Box>
            </Box>

            {getPathsToShowGreetings() && lastLogin && showGreeting && (
              <>
                <Grid container style={{ marginTop: "0px" }}>
                  <Grid item md={6} xs={6}>
                    <h3 style={{ color: "#3c0292" }}>
                      {getGreetingMessage()},{" "}
                      <span>&nbsp;{getFirstName(userName)}.</span>
                      <span>
                        &nbsp;&nbsp;
                        {"  "}
                        Your last login was {"  "}
                        {formatTimestamp(lastLogin)}{" "}
                      </span>
                    </h3>
                  </Grid>
                  {systemMessage &&
                    !hideMessage &&
                    systemMessage.length > 0 && (
                      <Grid
                        container
                        item
                        md={6}
                        xs={6}
                        style={{
                          color: "red",
                          backgroundColor: "#cefad0",
                          padding: "5px",
                          position: "relative",
                          borderRadius: "10px",
                        }}
                      >
                        <Grid
                          item
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                        >
                          <IconButton
                            aria-label="close"
                            onClick={() => setHideMessage((prev) => !prev)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>

                        {/* Heading - Top Left */}
                        {/* <Grid>
                          <h4 style={{ margin: "10px 0", paddingLeft: "5px" }}>
                            System Message Panel:
                          </h4>
                        </Grid> */}

                        {/* Message Descriptions */}

                        <Grid style={{ marginTop: "10px" }}>
                          {!hideMessage &&
                            systemMessage?.length > 0 &&
                            systemMessage.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "2px",
                                }}
                              >
                                {item.MESSAGE_DESCRIPTION}
                              </div>
                            ))}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </>
            )}
            {getRouteBasedTitle() && isAllowed && (
              <h1 className="page-title" style={{ marginTop: "0px" }}>
                {getRouteBasedTitle()}
              </h1>
            )}
          </Box>
        );
      }}
    </Route>
  );
};

export default AppBreadcrumbs;
