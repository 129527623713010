// OP#2801 UJL3 - UI Dev - Manage User Profile
// file stands for user profile pop up (edit profile pop up)

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Typography } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import langConstant from "_lang";
import sessionService from "_services/session.service";
import useStyles from "./styles";
import NewUserForm from "./NewUserForm";
import SecurityFormComponent from "./SecurityForm";
import NotificationForm from "./NotificationForm";
import Reports from "_components/Layout/MyProfilePopup/component/Reports";

const CreateUser = ({
  setAddUserPopUp,
  addUserPopUp,
  selectedUserData = null,
  setSelectedUserData = null,
  mode = "user",
  selectedCustomer = null,
}) => {
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({
    USER_ID: null,
    basic_information: {
      FIRSTNAME: "",
      LASTNAME: "",
      USERNAME: "",
      PHONE_NUMBER: "",
      EMAIL_ADDRESS: "",
      DEPARTMENT_NAME: "",
      COUNTRY_CODE: "",
      USER_ROLE: null,
      PASSWORD: "",
      ENTITLEMENT_ID: null,
      DATA_CLASSIFICATION: null,
      IS_SUPERVISOR: false,
      SUPERVISOR_ID: null,
      IS_RFI_RECIPIENT: false,
    },
    security: {
      isEnabled: false,
      authenticationType: "EMAIL OTP",
    },
    team: "",
    notification_pref: [],
  });

  useEffect(() => {
    if (selectedUserData) {
      setUserData({
        USER_ID: selectedUserData.USER_ID,
        basic_information: {
          FIRSTNAME: selectedUserData.FIRST_NAME,
          LASTNAME: selectedUserData.LAST_NAME,
          USERNAME: selectedUserData.USER_NAME,
          PHONE_NUMBER: selectedUserData.PHONE_NUMBER,
          EMAIL_ADDRESS: selectedUserData.EMAIL_ADDRESS,
          DEPARTMENT_NAME: selectedUserData.DEPARTMENT,
          COUNTRY_CODE: selectedUserData.LOCATION,
          USER_ROLE: selectedUserData.ROLE_ID,
          ENTITLEMENT_ID: selectedUserData.ENTITLEMENT_ID,
          DATA_CLASSIFICATION: selectedUserData.DATA_CLASSIFICATION,
          IS_SUPERVISOR: selectedUserData.SUPERVISOR_ID ? true : false,
          SUPERVISOR_ID: selectedUserData.SUPERVISOR_ID,
          IS_RFI_RECIPIENT: selectedUserData.RFI_RECIPIENT_ID ? true : false,
        },
        security: {
          isEnabled: selectedUserData["2FA_ENABLED"],
          authenticationType: selectedUserData["2FA_TYPE"],
        },
        team: selectedUserData.TEAM_ID,
        notification_pref: [],
      });
    }
  }, []);

  const handleClose = () => {
    setAddUserPopUp(false);
    if (mode == "admin") {
      setSelectedUserData(null);
    }
  };

  return (
    <Drawer anchor={"right"} open={addUserPopUp} onClose={handleClose}>
      <Grid className={classes.profileMDTopHedaerCardFX}>
        <Grid
          container
          style={{ marginBottom: "20px", borderBottom: "2px solid #7f7878" }}
        >
          <Grid item md={10} xs={9}>
            <Typography className={classes.formHeader}>
              {userData.USER_ID
                ? langConstant.UPDATE_USER
                : langConstant.CREATE_USER}
            </Typography>
          </Grid>
          <Grid item md={2} xs={3} style={{ textAlign: "right" }}>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </Grid>
        </Grid>
        {currentStep === 1 && (
          <NewUserForm
            userID={userID}
            setCurrentStep={setCurrentStep}
            userData={userData}
            setUserData={setUserData}
            mode={mode}
            selectedCustomer={selectedCustomer}
          />
        )}
        {currentStep === 2 && (
          <SecurityFormComponent
            setCurrentStep={setCurrentStep}
            userData={userData}
            mode={mode}
            setUserData={setUserData}
            handleClose={handleClose}
            selectedCustomer={selectedCustomer}
          />
        )}
        {currentStep === 3 && (
          <NotificationForm
            userData={userData}
            setUserData={setUserData}
            handleClose={handleClose}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 4 && (
          <Reports
            userData={userData}
            selectedCustomer={selectedCustomer}
            mode={mode}
            setCurrentStep={setCurrentStep}
          />
        )}
      </Grid>
    </Drawer>
  );
};

CreateUser.propTypes = {
  setAddUserPopUp: PropTypes.func,
  addUserPopUp: PropTypes.bool,
  selectedUserData: PropTypes.object,
  setSelectedUserData: PropTypes.func,
  mode: PropTypes.string,
  selectedCustomer: PropTypes.object,
};

export default CreateUser;
