import React, { useEffect, useState } from "react";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";
import sessionService from "_services/session.service";
import useStyles from "../styles";

const Summary = ({ setupQuestionList, answers }) => {
  const classes = useStyles();
  const user_Name = sessionService.getUserName();

  const answeredCount = setupQuestionList.filter(
    (que) =>
      answers[que.QUESTION_ID] ||
      (que.CURRENT_ANSWER_SELECTED_RESPONSE &&
        que.CURRENT_ANSWER_SELECTED_RESPONSE.trim() !== "")
  ).length;

  const formatTimestamp = () => {
    const date = new Date();

    // Get day with suffix
    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th to 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Get month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];

    const year = date.getFullYear();

    // Format time with AM/PM
    let hours = date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

    // Construct the final formatted date
    const formattedDate = `${day}${daySuffix(
      day
    )} ${month}, ${year} ${hours}:${minutes}${ampm}`;

    return formattedDate;
  };
  const columns = [
    { field: "Question", headerName: "Question", minWidth: "100%" },
    {
      field: "ResponseProvided",
      headerName: "Response Provided",
      minWidth: "100%",
    },
  ];

  return (
    <>
      <Typography className={classes.formFieldLabel}>
        Questions answered <span style={{ fontsize: "24px" }}>-</span>{" "}
        {answeredCount} out of {setupQuestionList.length}
      </Typography>
      <Typography className={classes.formFieldLabel}>
        Responses provided by {user_Name} on {formatTimestamp()}
      </Typography>
      <Typography className={classes.formFieldLabel}>
        Summary of information you provided.
      </Typography>
      <TableContainer
        component={Paper}
        style={{
          marginTop: 10,
          width: "80%",
          maxHeight:
            setupQuestionList.length > 5 ? "calc(100vh - 150px)" : "auto", // Dynamically adjusts height
          minHeight: "auto", // Prevents unnecessary empty space
          overflowY: setupQuestionList.length > 5 ? "auto" : "visible", // Enables scrolling only when needed
        }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 600,
            maxWidth: 600,
            width: "50%",
            borderSpacing: "0px",
            border: "none",
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  key={"column_" + index + item.field}
                  className={classes.stickyColl}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {setupQuestionList.length > 0 &&
              setupQuestionList.map((que, index) => (
                <TableRow key={index} style={{ border: "none" }}>
                  <TableCell>{que.QUESTION_TEXT || ""}</TableCell>
                  <TableCell>
                    {que.RESPONSE_TYPE_ID === 8 || que.RESPONSE_TYPE_ID === 1
                      ? que.optionList
                          ?.filter((option) => {
                            let selectedValues =
                              answers[que.QUESTION_ID] ||
                              que.CURRENT_ANSWER_SELECTED_RESPONSE ||
                              "";

                            if (que.RESPONSE_TYPE_ID === 1) {
                              return (
                                selectedValues.toString() ===
                                option.value.toString()
                              );
                            }

                            selectedValues = selectedValues
                              .split(",")
                              .map((val) => val.trim());

                            return selectedValues.includes(
                              option.value.toString()
                            );
                          })
                          .map((option) => option.label)
                          .join(", ")
                      : answers[que.QUESTION_ID] ||
                        que.CURRENT_ANSWER_SELECTED_RESPONSE ||
                        ""}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

Summary.propTypes = {
  setupQuestionList: PropTypes.array,
  answers: PropTypes.object,
};

export default Summary;
