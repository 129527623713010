import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Step1 from "./component/step1.jsx";
import Step2 from "./component/step2.jsx";
import Step3 from "./component/step3.jsx";
import langConstant from "_lang";
import { useDispatch } from "react-redux";
import { resetStepper, setSteps } from "_components/Stepper/action.js";
import { clearData, setStep1Values, updateReRunMode } from "./actions.js";
import PolicyServices from "../../services.js";
import sessionService from "_services/session.service.js";

const PolicyUpload = ({ setShow, metaData, policyReRun, setPolicyReRun }) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();

  const [currentStep, setCurrentStep] = useState(1);
  const [uploadOnly, setUploadOnly] = useState(false);

  const steps = [
    { stepname: langConstant.STEP_DOCUMENT_DETAILS, compID: 1 },
    { stepname: langConstant.STEP_EXTRACTION_DETAILS, compID: 2 },
    { stepname: langConstant.STEP_PREVIEW_AND_SAVE, compID: 3 },
  ];

  useEffect(() => {
    dispatch(setSteps(steps));
    dispatch(clearData());
    if (policyReRun) {
      dispatch(updateReRunMode(true));
      dispatch(setStep1Values(metaData));
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    dispatch(setSteps([]));
    dispatch(clearData());
    dispatch(resetStepper());
    dispatch(PolicyServices.getAvailablePolicies(userID));
    setPolicyReRun(false);
  };

  return (
    <>
      {currentStep == 1 && (
        <Step1
          handleClose={handleClose}
          uploadOnly={uploadOnly}
          setUploadOnly={setUploadOnly}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep == 2 && (
        <Step2
          closeDocumentUploadPopUp={handleClose}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep == 3 && <Step3 stepperClose={handleClose} />}
    </>
  );
};

PolicyUpload.propTypes = {
  setShow: PropTypes.func,
  metaData: PropTypes.object,
  policyReRun: PropTypes.bool,
  setPolicyReRun: PropTypes.func,
};

export default PolicyUpload;
