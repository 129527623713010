import React, { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { UseModalPropType } from "_hooks";
import { Button } from "@material-ui/core";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Help } from "@mui/icons-material";
import {
  EditableFilenameRow,
  getFileNameWithoutExtension,
} from "./EditableFilenameRow";
import { v4 as uuidv4 } from "uuid";
import uploadDocumentService from "./services";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import PolicyUploadService from "containers/manage-regulation/Policy/PolicyExplorer/component/NewPolicyUpload/services";
import moment from "moment";
import { alertActions } from "_actions";
import Select from "react-select";
import PolicyServices from "../../../../../manage-regulation/Policy/PolicyExplorer/services";
import OptionComponent from "./OptionComponent";
const BatchUploadDialog = ({
  modal,
  setFilesForUpload,
  filesForUpload = [],
  regulationID,
}) => {
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [fileStatus, setFileStatus] = React.useState([]);
  const [duplicateFiles, setIsDuplicateFiles] = React.useState();
  const [avilablePolicies, SetAvilablePolicies] = React.useState([]);
  // const [selectedOptions, setSelectedOptions] = React.useState([]);

  const [documentTypes, setDocumentTypes] = React.useState([
    { value: "all", name: "All" },
  ]);
  let rows = useSelector((state) => state.policyReducer.policies);

  const dispatch = useDispatch();

  const onSelectFiles = (event) => {
    if (!event?.target?.files) return;
    let newFiles = Object.values(event?.target?.files)?.map(
      (file) =>
        // Object.defineProperties(file, "document_name", {
        //   writable: true,
        //   value: getFileNameWithoutExtension(file?.name),
        // }) || []
        Object.defineProperties(file, {
          document_name: {
            writable: true,
            value: getFileNameWithoutExtension(file?.name),
          },
          uuid: {
            value: uuidv4(),
          },
        }) || []
    );
    const existingFileNames =
      filesForUpload?.map((f) => (f?.name ? f?.name : f?.POLICY_NAME)) || [];
    const allFiles = newFiles
      ?.filter(
        (f) =>
          !existingFileNames?.includes(getFileNameWithoutExtension(f?.name))
      )
      ?.concat(filesForUpload);
    let duplicates = newFiles?.filter((f) =>
      existingFileNames?.includes(getFileNameWithoutExtension(f?.name))
    );
    setIsDuplicateFiles(duplicates?.map((f) => f?.name));

    newFiles.forEach((item) =>
      setFileStatus((prev) => [...prev, { id: item.uuid, status: "init" }])
    );
    setSelectedFiles(allFiles);
  };

  const onFileNameUpdate = (file, input) => {
    if (!input) return;
    Object.defineProperty(file, "document_name", {
      writable: true,
      value: input,
    });
    setSelectedFiles((pre) => [...pre]);
  };

  const customerID = sessionService.getCustomerId();
  const userID = sessionService.getUserId();
  const userDetails = useSelector(
    (state) => state?.authentication?.detailedInfo
  );
  const docStorageIP = userDetails?.DOC_STORAGE_IP,
    docStorageURL = userDetails?.DOC_STORAGE_URL,
    docStorageType = userDetails?.DOC_STORAGE_TYPE,
    uploadPath = userDetails?.POLICY_MANAGEMENT_EXTRACTION;

  const onDeleteFile = (file) => {
    if (!file) return;
    setSelectedFiles(selectedFiles?.filter((f) => f?.uuid !== file?.uuid));
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onConfirm = async () => {
    const uploadFile = async (item, index) => {
      const tempFile = fileStatus?.find((f) => f.id === item.uuid);

      if (tempFile && tempFile.status === "completed") return;
      if (!item.name) return;

      if (tempFile) {
        tempFile.status = "running";
        setFileStatus((prev) => [
          ...prev?.filter((f) => f.id !== item.uuid),
          tempFile,
        ]);
      }

      // await sleep(index * 1000);
      // const fileData = item.name.split(".");
      // const data = {
      //   document_name: item.document_name,
      //   policy_metadata: {},
      //   fileName: item.name,
      //   document_extension:
      //     fileData.length >= 2 ? fileData[fileData.length - 1] : "",
      //   customerID: customerID,
      //   document_owner: null,
      //   document_tags: {},
      //   document_type: {},
      //   userID: userID,
      //   currentDateTime: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      //   document_language: { value: null },
      // };

      try {
        //   console.log(selectedFiles);
        //   const response = await PolicyUploadService.putPolicy(data);
        //   if (response?.status !== 200)
        //     throw new Error("Failed to create Policy");
        //   let policyId = response?.["#result-set-1"]?.[0]?.["new_id"];
        //   const filepath = `${customerID}/${uploadPath}/${policyId}`;
        const updateResponse =
          await uploadDocumentService.putCustomerRegulationPolicyMapping(
            customerID,
            item.POLICY_ID,
            regulationID,
            userID,
            null,
            null
          );
        if (updateResponse?.status !== 200) {
          throw new Error("updatePolicyExtraction SP Failed");
        } else {
          modal?.closeModal();
          dispatch(alertActions.success("Successfully maped RegulationPolicy"));
        }
        // await sleep(index * 1000);
        // const formData = new FormData();
        // formData.append("document_obj", item);
        // formData.append(
        //   "fileLocation",
        //   `${customerID}/${uploadPath}/${policyId}`
        // );
        // formData.append("storageType", docStorageType.toLowerCase());
        // formData.append("preProcessingIP", docStorageIP);
        // formData.append("preProcessingURL", docStorageURL);

        // const s3Response = await uploadDocumentService.fileUpload(formData);
        // if (s3Response.status !== 200) throw new Error("Something went wrong");

        if (tempFile) {
          tempFile.status = "completed";
          setFileStatus((prev) => [
            ...prev?.filter((f) => f.id !== item.uuid),
            tempFile,
          ]);
        }
      } catch (error) {
        dispatch(alertActions.error(error.message));
        if (tempFile) {
          tempFile.status = "failed";
          setFileStatus((prev) => [
            ...prev?.filter((f) => f.id !== item.uuid),
            tempFile,
          ]);
        }
      }
    };

    await Promise.all(
      selectedFiles.map((item, index) => uploadFile(item, index))
    );

    let newUploadedFiles = selectedFiles?.map((i) => {
      if ("uuid" in i) {
        console.log("1");
        setFilesForUpload((prev) => [
          ...prev,
          {
            ...i,
            children: [],
          },
        ]);
        return { ...i, children: [] };
      } else {
        let uuid = uuidv4();
        setFilesForUpload((prev) => [
          ...prev,
          {
            ...i,
            uuid: uuid,
            children: [],
          },
        ]);
        return {
          ...i,
          uuid: uuid,
          children: [],
        };
      }
    });
  };
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    modal?.closeModal();
  };

  const getDocumentControlData = async () => {
    dispatch(
      PolicyServices.getDocumentControlInformation(
        userID,
        true,
        "",
        setDocumentTypes,
        ""
      )
    );
  };
  useEffect(() => {
    (async () => {
      await getDocumentControlData();
    })();
  }, []);
  const policiesData = async () => {
    const res = await uploadDocumentService.getAvailablePolicies(userID);
    return res;
  };
  const formatDateTime = (date) => {
    const d = new Date(date);

    const month = d.toLocaleString("default", { month: "long" });
    const day = d.getDate();
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    return `${month} ${day}, ${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const getDateLabel = (date) => {
    const now = new Date();
    const inputDate = new Date(date);

    const isToday = (date) => {
      return date.toDateString() === now.toDateString();
    };

    const isYesterday = (date) => {
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      return date.toDateString() === yesterday.toDateString();
    };

    if (isToday(inputDate)) {
      return `Today at ${inputDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`;
    } else if (isYesterday(inputDate)) {
      return `Yesterday at ${inputDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`;
    } else {
      return formatDateTime(inputDate);
    }
  };
  useEffect(async () => {
    const policies = await policiesData();
    let optionData = {};
    policies["#result-set-1"]?.forEach((element) => {
      if (
        element.DOCUMENT_TYPE &&
        JSON.parse(element.DOCUMENT_TYPE).length > 0 &&
        JSON.parse(element.DOCUMENT_TYPE)[0]["label"] !== undefined
      ) {
        if (
          !Object.keys(optionData).includes(
            JSON.parse(element.DOCUMENT_TYPE)[0]["label"]
          )
        ) {
          optionData[JSON.parse(element.DOCUMENT_TYPE)[0]["label"]] = [];
        }
        if (
          element.EXTRACTION_FILE_NAME !== null &&
          element.Extraction_Status == "success"
        ) {
          optionData[JSON.parse(element.DOCUMENT_TYPE)[0]["label"]].push({
            value: element?.POLICY_ID,
            label: element?.POLICY_FILE_NAME,
            name: element?.POLICY_NAME,
            ...element,
            uuid: uuidv4(),
          });
        }
      }
    });
    let opt = Object.keys(optionData);
    let allData = opt.map((ele) => ({
      label: ele,
      options: optionData[ele],
    }));
    SetAvilablePolicies(allData);
  }, []);

  const handleChange = (selected) => {
    setSelectedFiles(selected || []);
  };
  const filterOption = (option, searchText) => {
    const { data } = option;
    const searchLower = searchText.toLowerCase();
    const idStr = data?.value.toString().toLowerCase();
    return (
      data?.label.toLowerCase().includes(searchLower) ||
      idStr.includes(searchLower) ||
      data?.name.toLowerCase().includes(searchLower)
    );
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={handleClose}
      open={modal?.isOpen}
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle sx={{ m: 0, p: 1.5 }}>Batch Upload Files</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={modal?.closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <DialogContent
        sx={{
          display: "flex",
          gap: 2,
          flex: "auto",
          p: 1.5,
          flexDirection: "column",
        }}
        dividers
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            {filesForUpload?.length ? "Add More Documents" : "Upload Document"}
            <Tooltip
              placement="right"
              title={
                filesForUpload?.length
                  ? "Add Files to upload"
                  : "Select files to be uploaded"
              }
            >
              <IconButton>
                <Help style={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
          </Typography>
          <Select
            isMulti
            value={selectedFiles}
            components={{ Option: OptionComponent }}
            options={
              avilablePolicies &&
              avilablePolicies.map((group) => ({
                label: group.label,
                options: group?.options.map((option) => ({
                  date: getDateLabel(option?.CREATE_DATETIME),
                  ...option,
                })),
              }))
            }
            onChange={handleChange}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            filterOption={filterOption}
            placeholder="Upload Files"
            // menuIsOpen={true}
            menuPortalTarget={document.body}
          />

          {/* <Box
            sx={{
              border: "1px solid #0000001f",
              borderRadius: 1,
              p: 1,
              display: "inline-flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "inline-flex", gap: 1, minWidth: "30%" }}>
              <input
                onChange={onSelectFiles}
                type="file"
                multiple
                id="select-files"
                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                hidden
              />
              <Typography
                component="label"
                htmlFor="select-files"
                sx={{
                  p: 0.25,
                  px: 2,
                  border: "1px solid gray",
                  borderRadius: 1,
                  background: "#F0F4F8",
                  fontSize: 12,
                  cursor: "pointer",
                }}
              >
                {filesForUpload?.length ? "Add More Files" : "Select Files"}
              </Typography>
              <Fade
                in={Boolean(selectedFiles?.length)}
                unmountOnExit
                mountOnEnter
              >
                <span>{`${selectedFiles?.length} Files chosen`}</span>
              </Fade>
            </Box>
            <Fade
              in={Boolean(duplicateFiles?.length)}
              unmountOnExit
              mountOnEnter
            >
              <Box fontSize={12}>
                {duplicateFiles?.length} Files already exists &nbsp;
                <Typography fontSize={12} color="red">
                  {duplicateFiles?.join(", ")}
                </Typography>
              </Box>
            </Fade>
          </Box> */}
        </Box>
        <Typography>Selected Files ({selectedFiles?.length || 0})</Typography>
        <Box
          sx={{
            background: "#F0F4F8",
            borderRadius: 1,
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignSelf: "stretch",
            overflowY: "auto",
          }}
        >
          {selectedFiles?.length ? (
            selectedFiles?.map((file, index) => (
              <EditableFilenameRow
                readOnly={false}
                key={`file_${file?.name}_${index}`}
                file={Object.assign(file, { id: `${index + 1}.` })}
                onFileNameUpdate={onFileNameUpdate}
                onDeleteFile={onDeleteFile}
                fileStatus={fileStatus}
                regulationID={regulationID}
              />
            ))
          ) : (
            <Typography>No files selected</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 1.5, justifyContent: "flex-start", gap: 3 }}>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          disabled={Boolean(!selectedFiles?.length)}
        >
          Confirm
        </Button>
        <Button onClick={modal?.closeModal} variant="contained" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BatchUploadDialog.propTypes = {
  modal: UseModalPropType.isRequired,
  setFilesForUpload: PropTypes.func,
  filesForUpload: PropTypes.array,
  regulationID: PropTypes.number,
};

export default BatchUploadDialog;
