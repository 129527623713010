import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import propTypes from "prop-types";
import { Circle, Tune } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";
import services from "./services";

export const FilterMenu = ({
  dataList,
  filterOptions,
  tooltipTitle,
  setDataList,
  icon,
  activeFilters,
  dataVariable,
  setActiveFilters,
  filesForUpload,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const [filterList, setFilterList] = useState(filterOptions);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const isFilterOpen = Boolean(filterAnchorEl);

  const onFilterToggle = (e) => {
    setFilterAnchorEl(e?.currentTarget || null);
    setActiveFilters?.((pre) => [...(pre ?? []), dataVariable]);
  };

  const onClearAll = () => {
    onStatusFilterChange(null, true);
    onFilterToggle();
  };

  const onStatusFilterChange = (item, clearAll) => {
    if (clearAll) {
      // Resetting filters and questions
      setDataList(dataList);
      setSelectedFilters(null);
      return;
    }

    const isSelected = selectedFilters?.find((i) => i?.value === item?.value);

    // Updating selected filters
    let updatedFilters = isSelected
      ? selectedFilters?.filter((i) => i?.value !== item?.value)
      : [...(selectedFilters || []), item];

    setSelectedFilters(updatedFilters);

    // Filtering based on selected filters and active filters
    const filteredData = dataList?.filter((dataItem) => {
      return activeFilters?.some((key) => {
        return updatedFilters?.some((filter) => {
          return filter?.value === dataItem[key]?.trim()?.toLowerCase();
        });
      });
    });

    // Updating filtered data
    const newData = updatedFilters?.length ? filteredData : dataList;
    const tempData = newData.filter((newDataObject) => {
      // Check if any object in filesForUpload has children with the same uuid
      return !filesForUpload.some((uploadObject) => {
        // Check if any child object in uploadObject has the same uuid as newDataObject
        return uploadObject.children?.some(
          (child) => child.uuid === newDataObject.uuid
        );
      });
    });
    setDataList(tempData);
  };

  useEffect(() => {
    setFilterList(filterOptions);
  }, [filterOptions]);

  return (
    <React.Fragment>
      <Menu
        anchorEl={filterAnchorEl}
        open={isFilterOpen}
        onClose={() => onFilterToggle()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          disabled={Boolean(!selectedFilters?.length)}
          onClick={onClearAll}
          sx={{ py: 0 }}
        >
          <Typography
            sx={{
              display: "inline-flex",
              color: "red",
              fontSize: 12,
              py: 0.75,
            }}
          >
            Clear All
          </Typography>
        </MenuItem>
        <Divider flexItem />
        {filterList?.map((item, index) => {
          return (
            <MenuItem
              onClick={() => onStatusFilterChange(item)}
              key={index}
              sx={{ py: 0.25 }}
            >
              <Typography
                sx={{
                  display: "inline-flex",
                  fontWeight: "bold",
                  fontSize: "12px",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                {item?.label}
              </Typography>
              <Checkbox
                id={item?.value}
                checked={Boolean(
                  selectedFilters?.some((i) => i?.value === item?.value)
                )}
                sx={{
                  "&.Mui-checked": {
                    color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                  },
                  "& .MuiSvgIcon-root": { fontSize: 20 },
                }}
              />
            </MenuItem>
          );
        })}
      </Menu>

      <Box
        display="flex"
        flex={0.6}
        flexDirection="column"
        borderRadius="4px"
        gap={1}
        bgcolor="white"
        alignSelf="stretch"
      >
        <Box display="flex" gap={2} maxWidth="fit-content">
          <Tooltip title={tooltipTitle}>
            <IconButton onClick={onFilterToggle} component="span">
              {selectedFilters?.length > 0 && (
                <Circle
                  sx={{
                    fontSize: 12,
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "red",
                  }}
                />
              )}
              {icon ? icon : <Tune />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </React.Fragment>
  );
};

FilterMenu.propTypes = {
  dataList: propTypes.array,
  filterOptions: propTypes.array,
  tooltipTitle: propTypes.string,
  setDataList: propTypes.func,
  dataVariable: propTypes.string,
  icon: propTypes.any,
  activeFilters: propTypes.bool,
  setActiveFilters: propTypes.func,
  filesForUpload: propTypes.any,
};
