import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  IconButton,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import AssessmentServices from "_components/MasterAssessmentComp/steps/services";
import initiateAssessmentAction from "../actions";

import { useParams } from "react-router";
import { alertActions } from "_actions";
import langConstant from "_lang";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AppPagination from "_components/AppPagination";
import CloseIcon from "@mui/icons-material/Close";
import sessionService from "_services/session.service";
import { GetEmailNotificationTemplateForUseCase } from "containers/cyber-assessment/ManageAssessments/NewAssignAssessment/services";
import AwsEmailService from "_services/AwsEmailService";
import { useHistory } from "react-router-dom";
import { updateAssessmentStatus } from "_components/MasterAssessmentComp/utils/actions";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  updateBtn: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
  },
  sendEmailTxt: {
    fontSize: "15px",
    margin: "0 10px",
  },
  checkboxBtn: {
    height: "20px",
    width: "20px",
    accentColor: ({ actionButtonColor }) => `${actionButtonColor} !important`,
  },
}));

const AprroversDialog = ({ TotalFinalStatusQuestions, status }) => {
  const { id, sid, rid } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [delegatorsList, setDelegatorsList] = useState([]);
  const [selectedDelegator, setSelectedDelegator] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => setIsDialogOpen(false);

  const userId = sessionService.getUserId();
  let AWSAccessKey = useSelector(
    (state) => state.authentication.detailedInfo?.REACT_APP_AWS_ACCESS_KEY_ID
  );
  let AWSSecretKey = useSelector(
    (state) =>
      state.authentication.detailedInfo?.REACT_APP_AWS_SECRET_ACCESS_KEY
  );

  AWSAccessKey = window.atob(AWSAccessKey ? AWSAccessKey : "");
  AWSSecretKey = window.atob(AWSSecretKey ? AWSSecretKey : "");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(0);

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToVRMAssessment = () => {
    history.push("/vendor-assessment");
  };
  const updateAssignedStatus = () => {
    const assignedByUserID =
      TotalFinalStatusQuestions[0].assessmentObj[0].USER_ID;
    dispatch(
      initiateAssessmentAction.updateAssignedStatus(
        id,
        rid,
        assignedByUserID,
        "PENDING_FOR_REVIEW",
        goToVRMAssessment
      )
    );
  };
  const OnrejectRedirectFunc = () => {
    history.push("/vendor-assessment");
  };

  const columns = [
    {
      field: "SEQUENCE_ID",
      headerName: langConstant.APPROVAL_ORDER,
      minWidth: 50,
    },
    {
      field: "PRIMARY_APPROVER",
      headerName: langConstant.PRIMARY_APPROVER,
      format: (value) =>
        value["ROLE_NAME"]
          ? value["ROLE_NAME"] + " - " + value["PRIMARY_APPROVER"]
          : value["PRIMARY_APPROVER"],
      minWidth: 100,
    },
    {
      field: "DELAGATED_APPROVER",
      headerName: langConstant.DELEGATE_APPROVER,
      minWidth: 100,
    },
    {
      field: "APPROVAL_STATUS",
      headerName: langConstant.APPROVAL_STATUS,
      format: (value) =>
        value["APPROVAL_STATUS"] ? value["APPROVAL_STATUS"] : "Not Approved",
      minWidth: 100,
    },
    {
      field: "APPROVAL_DATE",
      headerName: langConstant.APPROVAL_DATE,
      format: (value) =>
        value["APPROVAL_DATE"]
          ? moment(value["APPROVAL_DATE"]).format("DD-MMM-YY")
          : "-",
      minWidth: 100,
    },
    {
      field: "INTERNAL_EXTERNAL_FLAG",
      headerName: langConstant.INTERNAL_OR_EXTERNAL,
      format: (value) =>
        value["INTERNAL_EXTERNAL_FLAG"] == "I"
          ? "Internal"
          : "External - " + value["EXT_COMPANY_NAME"],
      minWidth: 100,
    },
  ];

  useEffect(() => {
    (async () => {
      const response = await AssessmentServices.getApproversForAssessment(
        sid || TotalFinalStatusQuestions[0]["SUBMISSION_ID"]
      );
      if (response.status == 200) {
        if (response["#result-set-1"].length > 0) {
          setIsDialogOpen(true);
          setDelegatorsList(response["#result-set-1"]);
          setPageCount(calculatePageCount(response["#result-set-1"]));
        }
        // else {
        //   updateAssignedStatus();
        // }
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    })();
  }, []);

  const updateApproverForAssessment = async () => {
    if (selectedDelegator && Object.keys(selectedDelegator).length > 0) {
      if (sendEmail) {
        const emailTemplateResponse =
          await GetEmailNotificationTemplateForUseCase(
            2,
            "Assessment_Assigned"
          );

        let templateData = JSON.parse(
          emailTemplateResponse["#result-set-1"][0].NOTIFICATION_TEMPLATE
        );
        let templateName =
          emailTemplateResponse["#result-set-1"][0].NOTIFICATION_TEMPLATE;
        let sourceEmailAddress =
          emailTemplateResponse["#result-set-1"][0].OUTBOUND_EMAIL;

        let EmailObj = {
          EmailTo: [selectedDelegator["PRIMARY_EMAIL"]],
          EmailTitle: "Assessment Assigned",
          EmailBody: `Assessment : ${selectedDelegator["REGULATION_SHORT_NAME"]} - has been assigned to you for your review.  Please login into the platform to undertake the review.`,
          EmailCC: selectedDelegator["DELEGATOR_EMAIL"]
            ? [selectedDelegator["DELEGATOR_EMAIL"]]
            : null,
          EmailBCC: [],
          EmailSourceAddress: sourceEmailAddress,
        };

        let UserDataObj = {
          UserName: selectedDelegator["PRIMARY_APPROVER"],
        };

        await AwsEmailService(
          AWSSecretKey,
          AWSAccessKey,
          EmailObj,
          UserDataObj,
          templateData,
          templateName,
          dispatch
        );
      }
      const payload = {
        submissionID: sid || TotalFinalStatusQuestions[0]["SUBMISSION_ID"],
        toUserId: selectedDelegator["USER_ID"],
        userId: userId,
        status: status,
      };

      const response = await AssessmentServices.updateApproverForAssessment(
        payload
      );
      if (response.status == 200) {
        dispatch(
          alertActions.success(
            langConstant.ASSESSMENT_APPROVER_HAS_BEEN_ASSIGNED_SUCCESSFULLY
          )
        );
        handleClose();
        OnrejectRedirectFunc();
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    } else {
      dispatch(alertActions.error("Please select One Delegate"));
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={isDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle>
        <Grid container>
          <Grid item md={9} xs={6}>
            {langConstant.SELECT_APPROVER_FROM_LIST}
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
            container
            justify="flex-end"
            alignItems="center"
          >
            <input
              type="checkbox"
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
              className={classes.checkboxBtn}
            />
            <span className={classes.sendEmailTxt}>
              {langConstant.SEND_EMAIL}
            </span>
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={{ marginRight: "10px" }}
              onClick={updateApproverForAssessment}
            >
              {langConstant.CONFIRM}
            </Button>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TableContainer
          component={Paper}
          style={{
            border: "1px solid rgba(0,0,0,0.1)",
            borderBottomStyle: "none",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderLeftStyle: "solid",
          }}
        >
          <Table className="data-table" aria-label="Regulation List">
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.stickyColl}
                ></TableCell>
                {columns.map((item, index) => (
                  <TableCell
                    component="th"
                    scope="row"
                    key={"column_" + index + item.field}
                    className={classes.stickyColl}
                    style={{ minWidth: item.minWidth }}
                  >
                    {item.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {delegatorsList &&
                delegatorsList.map((row, index) => (
                  <TableRow key={index + row[columns[0].field]}>
                    <TableCell>
                      <input
                        type="checkbox"
                        style={{ accentColor: "#124571" }}
                        disabled={row["APPROVAL_STATUS"] ? true : false}
                        checked={
                          selectedDelegator
                            ? row["APPROVAL_ID"] ===
                              selectedDelegator["APPROVAL_ID"]
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedDelegator(row);
                          } else {
                            setSelectedDelegator(null);
                          }
                        }}
                      />
                    </TableCell>
                    {columns.map((column, index) => {
                      const value = row[column.field];
                      return (
                        <TableCell
                          component="td"
                          scope="row"
                          key={"column_" + index + row[columns[0].field]}
                          className={classes.stickyColl}
                          style={{
                            wordBreak: "break-word",
                            wordWrap: "break-word",
                          }}
                        >
                          {column.format ? column.format(row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              {delegatorsList.length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell
                    style={{ textAlign: "center" }}
                    colSpan={columns.length + 1}
                  >
                    {langConstant.NO_RECORD_FOUND}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

AprroversDialog.propTypes = {
  TotalFinalStatusQuestions: PropTypes.array,
  status: PropTypes.string,
};

export default AprroversDialog;
