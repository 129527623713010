/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  TextField,
  Box,
  InputAdornment,
  Divider,
  Tooltip,
} from "@material-ui/core";
import langConstant from "_lang";
import { makeStyles } from "@material-ui/core";
import FilterDropdown from "_components/FilterDropdown";
import AppPagination from "_components/AppPagination";
import ListItem from "./ListItem";
import _ from "lodash";
import { alertActions } from "_actions";
import QuestionServices from "../../IndividualQuestion/services";
import { useDispatch, useSelector } from "react-redux";
import sessionService from "_services/session.service";
import IconButton from "@mui/material/IconButton";
import NewQuestionDialog from "./NewQuestionDialog";
import { useLocation, useHistory } from "react-router-dom";
import IndividualQuestion from "../../IndividualQuestion";
import PolicyMappingDialog from "./PolicyMappingDialog";
import { getThemeDetails } from "containers/LoginPage/reducers";
import {
  Backup,
  NoteAdd,
  Add as AddIcon,
  Search as SearchIcon,
  AutoGraph as AutoGraphIcon,
} from "@mui/icons-material";
import BallotIcon from "@mui/icons-material/Ballot";
import { useModal } from "_hooks";
import ViewDocsDialog from "./NewPolicyUpload/ViewDocsDialog";
import ResponseTypeWizardDialog from "./ResponseTypeWizardDialog";

const useStyles = makeStyles(() => ({
  viewIcon: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    backgroundColor: ({ actionButtonColor }) =>
      `${actionButtonColor} !important`,
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
  },
  resetWrap: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "0",
    "& .linkIcon": {
      width: "100%",
    },
  },
  iconsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 8,
  },
}));

const QuestionsTable = ({
  rows = [],
  id,
  setQuestionDetailView,
  questionDetailView,
  regulationID,
  // regulationDetail,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const regulatorId = location.state?.regulatorId;
  const countryCode = location.state?.countryCode;
  const SIMILAR_QUESTION = location.state?.SIMILAR_QUESTION;

  const customerID = sessionService.getCustomerId();
  const userID = sessionService.getUserId();

  const tableRef = useRef();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [questionDialog, setQuestionDialog] = useState(false);
  const [policyMappingDialog, setPolicyMappingDialog] = useState(false);
  const [currentQuestionRefrence, setCurrentQuestionRefrence] = useState({
    index: null,
    qid: null,
  });
  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };

  const [pageCount, setPageCount] = useState(calculatePageCount(rows));
  const [questionClassificationOptions, setQuestionClassificationOptions] =
    useState([]);
  const [controlGroupOptions, setControlGroupOptions] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const viewDocsModal = useModal();
  const responseTypeModal = useModal();
  const userData = useSelector((state) => state.authentication.userData);

  let manageRegulation_RegulationAdmin = false;

  if (
    userData &&
    userData.allowedNavItems &&
    userData.allowedNavItems.length > 0 &&
    userData.allowedNavItems.indexOf("MANAGE_REGULATION/REGULATION_ADMIN") > -1
  ) {
    manageRegulation_RegulationAdmin = true;
  }

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filteredRows, setFilteredRows] = useState([]);
  const initialFilterObj = {
    RULE_FLAG: "",
    QUESTION_CLASSIFICATION: "",
    searchText: "",
    REGULATOR_CONTROL_GROUP: "",
    STATUS: "",
  };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const dropDownOption = [
    { value: "automatic", name: langConstant.AUTOMATION },
    { value: "manual", name: langConstant.MANUAL },
  ];

  const statusOptions = [
    { value: "active", name: langConstant.ACTIVE },
    { value: "inactive", name: langConstant.INACTIVE },
  ];

  const onFilterChange = (fieldName, e) => {
    let filterObj = {};
    if (fieldName !== "") {
      const filterValue = e.target.value;
      filterObj = { ...filterBy, [fieldName]: filterValue };
      setFilterBy(filterObj);
    } else {
      filterObj = { ...filterBy };
    }
    let filtered_array = [...rows];

    if (filterObj["searchText"]) {
      filtered_array = filtered_array.filter((row) => {
        return (
          (row.QUESTION_TEXT
            ? row.QUESTION_TEXT.toLowerCase().includes(
                filterObj["searchText"].toLowerCase()
              )
            : false) ||
          (row.REGULATOR_UNIQUE_REFERENCE
            ? row.REGULATOR_UNIQUE_REFERENCE.toLowerCase().includes(
                filterObj["searchText"].toLowerCase()
              )
            : false) ||
          (row.REGULATOR_CONTROL_GROUP
            ? row.REGULATOR_CONTROL_GROUP.toLowerCase().includes(
                filterObj["searchText"].toLowerCase()
              )
            : false)
        );
      });
    }

    if (filterObj["RULE_FLAG"]) {
      filtered_array = filtered_array.filter((item) => {
        if (filterObj["RULE_FLAG"] == "manual") {
          return !item["RULE_FLAG"];
        } else if (filterObj["RULE_FLAG"] == "automatic") {
          return item["RULE_FLAG"];
        } else {
          return true;
        }
      });
    }

    if (filterObj["QUESTION_CLASSIFICATION"]) {
      filtered_array = filtered_array.filter(
        (item) =>
          item.QUESTION_CLASSIFICATION_ID ==
          filterObj["QUESTION_CLASSIFICATION"]
      );
    }

    if (filterObj["REGULATOR_CONTROL_GROUP"]) {
      filtered_array = filtered_array.filter(
        (item) =>
          item.REGULATOR_CONTROL_GROUP == filterObj["REGULATOR_CONTROL_GROUP"]
      );
    }

    if (filterObj["STATUS"] !== "") {
      const status = filterObj["STATUS"] === "active" ? true : false;
      filtered_array = filtered_array.filter((item) => item.ACTIVE == status);
    }

    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };

  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    setFilteredRows(rows);
    setPageCount(calculatePageCount(rows));
    setPage(0);
  };

  const setCurrentIndex = (questionRef) => {
    const refIndex = filteredRows.findIndex(
      (item) => item.QUESTION_ID === questionRef
    );
    if (refIndex != "-1") {
      setCurrentQuestionRefrence({ index: refIndex, qid: questionRef });
      setQuestionDetailView(true);
    } else {
      setQuestionDetailView(false);
      setCurrentQuestionRefrence({ index: null, qid: null });
      dispatch(
        alertActions.error(langConstant.NOT_ABLE_TO_FIND_QUESTION_REFRENCE)
      );
    }
  };

  useEffect(() => {
    if (
      SIMILAR_QUESTION &&
      filteredRows.length > 0 &&
      filteredRows[0]["REGULATION_ID"] == regulationID
    ) {
      setCurrentIndex(SIMILAR_QUESTION);
      setTabValue(0);
      history.replace({ pathname: history.location.pathname, state: {} });
    }
  }, [filteredRows, SIMILAR_QUESTION, regulationID]);

  useEffect(() => {
    if (rows.length > 0) {
      (async () => {
        const response = await QuestionServices.getQuestionClassification(
          customerID
        );
        if (response.status == 200) {
          const options = [];
          response["#result-set-1"].forEach((element) => {
            options.push({
              value: element.QUESTION_CLASSIFICATION_ID,
              name: element.QUESTION_CLASSIFICATION_NAME,
            });
          });
          setQuestionClassificationOptions(options);
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      })();
      const uniqueControlGroup = [
        ...new Set(rows.map((item) => item.REGULATOR_CONTROL_GROUP)),
      ];
      const controlOptions = [];
      uniqueControlGroup.forEach((item) =>
        controlOptions.push({ name: item, value: item })
      );
      setControlGroupOptions(controlOptions);
      setFilteredRows(rows);
      onFilterChange("", "");
      setPageCount(calculatePageCount(rows));
      setPage(0);
    }
  }, [rows]);

  const onQuestionRefChange = (type) => {
    if (type === "next") {
      const nextQuestionRef = filteredRows[currentQuestionRefrence.index + 1];
      setCurrentQuestionRefrence({
        index: currentQuestionRefrence.index + 1,
        qid: nextQuestionRef["QUESTION_ID"],
      });
    } else {
      const prevQuestionRef = filteredRows[currentQuestionRefrence.index - 1];
      setCurrentQuestionRefrence({
        index: currentQuestionRefrence.index - 1,
        qid: prevQuestionRef["QUESTION_ID"],
      });
    }
  };
  const onOpenResponseTypeDialog = () => {
    responseTypeModal?.setData(regulationID);
    responseTypeModal?.openModal();
  };

  // const onOpenFileUploadDialog = () => {
  //   viewDocsModal?.setData(regulationDetail);
  //   viewDocsModal?.openModal();
  // };

  return (
    <div>
      {/* {viewDocsModal?.isOpen && <ViewDocsDialog modal={viewDocsModal} />} */}
      {responseTypeModal?.isOpen && (
        <ResponseTypeWizardDialog modal={responseTypeModal} />
      )}

      {questionDetailView ? (
        <IndividualQuestion
          id={id}
          qid={currentQuestionRefrence.qid}
          setQuestionDetailView={setQuestionDetailView}
          onQuestionRefChange={onQuestionRefChange}
          currentQuestionRefrence={currentQuestionRefrence}
          recordLength={filteredRows.length}
          value={tabValue}
          setValue={setTabValue}
        />
      ) : (
        <>
          <Box mb={2} mt={3}>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} md={10}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={2}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="searchText"
                      label=""
                      name="searchText"
                      placeholder={langConstant.SEARCH_QUESTION}
                      value={filterBy["searchText"]}
                      onChange={(e) => onFilterChange("searchText", e)}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start">
                            {" "}
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FilterDropdown
                      options={dropDownOption}
                      label={langConstant.RULE_TYPE}
                      fieldName={"RULE_FLAG"}
                      value={filterBy["RULE_FLAG"]}
                      onChange={onFilterChange}
                      icon={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FilterDropdown
                      options={questionClassificationOptions}
                      label={langConstant.SELECT_QUESTION_CLASSIFICATION}
                      fieldName={"QUESTION_CLASSIFICATION"}
                      value={filterBy["QUESTION_CLASSIFICATION"]}
                      onChange={onFilterChange}
                      icon={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FilterDropdown
                      options={controlGroupOptions}
                      label={langConstant.SELECT_REGULATOR_CONTROL_GROUP}
                      fieldName={"REGULATOR_CONTROL_GROUP"}
                      value={filterBy["REGULATOR_CONTROL_GROUP"]}
                      onChange={onFilterChange}
                      icon={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FilterDropdown
                      options={statusOptions}
                      label={langConstant.SELECT_STATUS}
                      fieldName={"STATUS"}
                      value={filterBy["STATUS"]}
                      onChange={onFilterChange}
                      icon={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={2}>
                <Box mx={1} mt={1} className={classes.resetWrap}>
                  {(filterBy["STATUS"] ||
                    filterBy["RULE_FLAG"] ||
                    filterBy["QUESTION_CLASSIFICATION"] ||
                    filterBy["searchText"] ||
                    filterBy["REGULATOR_CONTROL_GROUP"]) && (
                    <span onClick={resetFilter} className="linkIcon">
                      {langConstant.RESET_ALL}
                    </span>
                  )}
                  {regulatorId && countryCode && (
                    <Grid container className={classes.iconsWrapper}>
                      {manageRegulation_RegulationAdmin && (
                        <IconButton
                          onClick={onOpenResponseTypeDialog}
                          className={classes.iconButton}
                        >
                          <Tooltip title="Response Type Wizard">
                            <BallotIcon sx={{ color: "#fff" }} />
                          </Tooltip>
                        </IconButton>
                      )}
                      <IconButton className={classes.iconButton}>
                        <Tooltip title={langConstant.NETWORK_GRAPH}>
                          <AutoGraphIcon
                            onClick={() =>
                              history.push(
                                `/manage-questions/${id}/network-graph`
                              )
                            }
                            style={{ color: "white" }}
                          />
                        </Tooltip>
                      </IconButton>
                      <IconButton className={classes.iconButton}>
                        <Tooltip title={langConstant.POLICY_MAPPING}>
                          <NoteAdd
                            onClick={() => setPolicyMappingDialog(true)}
                            style={{ color: "white" }}
                          />
                        </Tooltip>
                      </IconButton>
                      <IconButton className={classes.iconButton}>
                        <Tooltip title={langConstant.ADD_NEW_QUESTION}>
                          <AddIcon
                            onClick={() => setQuestionDialog(true)}
                            style={{ color: "white" }}
                          />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid
            style={{
              color: "#707070",
              fontWeight: "500",
              background: "#eeeeee",
              borderRadius: "5px 5px 0 0",
              textTransform: "uppercase",
            }}
            ref={tableRef}
          >
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 0 3px 0",
                width: "100%",
                margin: "0 ",
              }}
            >
              <Grid item md={2} style={{ textAlign: "center" }}>
                {langConstant.REF_NO}
              </Grid>
              <Grid item md={7}>
                {langConstant.QUESTION}
              </Grid>
              <Grid item md={1} style={{ textAlign: "center" }}>
                {langConstant.EVIDENCE_COUNT}
              </Grid>
              <Grid item md={1} style={{ textAlign: "center" }}>
                {langConstant.POLICY_MAPS}
              </Grid>
              <Grid item md style={{ textAlign: "center" }}>
                {langConstant.ACTIONS}
              </Grid>
            </Grid>
          </Grid>
          <Card style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid container spacing={2} alignItems="stretch">
              {(rowsPerPage > 0
                ? filteredRows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows
              ).map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      key={item.QUESTION_ID}
                      item={item}
                      viewIconClassName={classes.viewIcon}
                      setCurrentIndex={setCurrentIndex}
                    />
                    {(rowsPerPage > 0
                      ? filteredRows.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : filteredRows
                    ).length -
                      1 >
                      index && (
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Box>
                          <Divider />
                        </Box>
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
            </Grid>

            {filteredRows.length == 0 && (
              <Box my={3} align="center">
                {langConstant.NO_RECORD_FOUND}
              </Box>
            )}
          </Card>
          {pageCount > 1 && (
            <Box my={2}>
              <Grid container justify="flex-end">
                <AppPagination
                  colSpan={3}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  tableRef={tableRef}
                />
              </Grid>
            </Box>
          )}
        </>
      )}
      {questionDialog && (
        <NewQuestionDialog
          open={questionDialog}
          setOpen={setQuestionDialog}
          customerID={customerID}
          regulatorId={regulatorId}
          countryCode={countryCode}
        />
      )}
      {policyMappingDialog && (
        <PolicyMappingDialog
          open={policyMappingDialog}
          setOpen={setPolicyMappingDialog}
          customerID={customerID}
          userID={userID}
          regulationID={regulationID}
        />
      )}
    </div>
  );
};

QuestionsTable.propTypes = {
  rows: PropTypes.array,
  id: PropTypes.string,
  setQuestionDetailView: PropTypes.func,
  questionDetailView: PropTypes.bool,
  regulationID: PropTypes.string,
  // regulationDetail: PropTypes.object,
};

export default QuestionsTable;
