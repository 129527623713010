import React, { memo, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import UndoIcon from "@mui/icons-material/Undo";
import CloseIcon from "@mui/icons-material/Close";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import putStatusService from "./../../../services";
import {
  Grid,
  Box,
  Popover,
  Typography,
  Tooltip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NoteIcon from "@mui/icons-material/Note";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { AddNoteDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AddNoteDialog";
import { useModal } from "_hooks";
import { AddEditActionTabMenu } from "_components/ActionList/AddEditActionTabMenu";
import { linkTypes } from "containers/cyber-assessment/AuditActions/constants";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import sessionService from "_services/session.service";
import { makeStyles } from "@material-ui/core";

const EditorComponent = memo(
  ({ metaData, searchTerm, cmpID, setComparisonMetaData }) => {
    const [grpData, setGrpData] = useState([]);
    const [editingSectionIndex, setEditingSectionIndex] = useState(null);
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [rowEditorState, setRowEditorState] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editingSectionNameIndex, setEditingSectionNameIndex] =
      useState(null);
    const [sectionNameEditorState, setSectionNameEditorState] = useState(null);
    const [menuPosition, setMenuPosition] = useState(null);
    const [actionList, setActionList] = useState(null);
    const [gapID, setGapId] = useState(null);
    const addNoteModal = useModal();
    const addActionModal = useModal();
    const dispatch = useDispatch();
    const handleRightClick = (event, hasAccess, gapID) => {
      setGapId(gapID);
      if (hasAccess) {
        event.preventDefault();
        setGapId(gapID);
        setMenuPosition({
          mouseX: event.clientX,
          mouseY: event.clientY,
        });
      }
    };
    const handleClose = () => {
      setGapId(null);
      setMenuPosition(null);
    };

    const userID = sessionService.getUserId();
    const handleApprove = async (event, decision) => {
      const response = await putStatusService.putGapAnalysisDecision({
        i_GAP_ANALYSIS_ID: gapID,
        i_USER_ID: userID,
        i_DECISION: decision,
      });
      if (response.status) {
        if (response.status == 200) {
          setComparisonMetaData((prevData) => {
            event.preventDefault();
            return prevData.map((item) => {
              if (item.GAP_ANALYSIS_ID == gapID) {
                return { ...item, GAP_ANALYSIS_STATUS: decision };
              } else {
                return { ...item };
              }
            });
          });
          handleClose();
          dispatch(alertActions.success("Successfully Added Gap Decision"));
        } else {
          handleClose();
          dispatch(alertActions.error(response.message));
        }
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          handleClose();
          dispatch(alertActions.error("something went wrong"));
        }
      }
    };

    const useStyles = makeStyles(() => ({
      diffClassName: {
        fontSize: "14px !important",
      },
      diffHeader: {
        fontFamily: "Fira Code , monospace !important",
        fontSize: "15px !important",
      },
    }));
    const classes = useStyles();
    useEffect(() => {
      if (Array.isArray(metaData) && metaData.length > 0) {
        const groupedByLevel = metaData.reduce((acc, item) => {
          if (!acc[item.SECTION_ORDER]) {
            acc[item.SECTION_ORDER] = [];
          }
          acc[item.SECTION_ORDER].push(item);
          return acc;
        }, {});
        setGrpData(Object.values(groupedByLevel));
      }
    }, [metaData]);
    const [cumulativeData, setCumulativeData] = useState([]);

    const handleEditRowClick = (sectionIndex, rowIndex, text) => {
      setEditingSectionIndex(sectionIndex);
      setEditingRowIndex(rowIndex);
      const contentState = ContentState.createFromText(text || "");
      setRowEditorState(EditorState.createWithContent(contentState));
    };

    const handleEditSectionNameClick = (sectionIndex, text) => {
      setEditingSectionNameIndex(sectionIndex);
      const contentState = ContentState.createFromText(text || "");
      setSectionNameEditorState(EditorState.createWithContent(contentState));
    };

    const handleSaveRowClick = (sectionIndex, rowIndex) => {
      if (rowEditorState) {
        const updatedGrpData = [...grpData];
        updatedGrpData[sectionIndex][rowIndex].modified_sentence_copy =
          rowEditorState.getCurrentContent().getPlainText();
        setGrpData(updatedGrpData);
      }
      setEditingSectionIndex(null);
      setEditingRowIndex(null);
    };

    const handleSaveSectionNameClick = (sectionIndex) => {
      const updatedGrpData = [...grpData];
      if (sectionNameEditorState) {
        updatedGrpData[sectionIndex][0].SECTION_NAME = sectionNameEditorState
          .getCurrentContent()
          .getPlainText();
        setGrpData(updatedGrpData);
      }
      setComparisonMetaData((prev) => {
        return prev.map((ele) => {
          if (
            ele.GAP_ANALYSIS_ID ==
            updatedGrpData[sectionIndex][0].GAP_ANALYSIS_ID
          ) {
            return {
              ...ele,
              SECTION_NAME: sectionNameEditorState
                .getCurrentContent()
                .getPlainText(),
            };
          } else {
            return { ...ele };
          }
        });
      });
      setEditingSectionNameIndex(null);
    };

    const handleUndoRowClick = (sectionIndex, rowIndex) => {
      const updatedGrpData = [...grpData];
      const item = updatedGrpData[sectionIndex][rowIndex];
      item.modified_sentence_copy = item.MODIFIED_TEXT || item.ORIGINAL_TEXT;
      setGrpData(updatedGrpData);

      const contentState = ContentState.createFromText(
        item.modified_sentence_copy || ""
      );
      setRowEditorState(EditorState.createWithContent(contentState));
    };

    const handleResetToOriginalClick = (sectionIndex, rowIndex) => {
      const updatedGrpData = [...grpData];
      updatedGrpData[sectionIndex][rowIndex].modified_sentence_copy =
        updatedGrpData[sectionIndex][rowIndex].ORIGINAL_TEXT;
      setGrpData(updatedGrpData);

      const contentState = ContentState.createFromText(
        updatedGrpData[sectionIndex][rowIndex].ORIGINAL_TEXT || ""
      );
      setRowEditorState(EditorState.createWithContent(contentState));
    };

    const handleCancelSectionNameEdit = () => {
      setEditingSectionNameIndex(null);
    };

    const handleInfoClick = (event, item) => {
      if (item.RECOMMENDATION || item.GAP_DESCRIPTION) {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
      }
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
      setSelectedItem(null);
    };

    const highlightText = (text) => {
      if (!searchTerm || !text) return text;

      // Escape special characters in the search term
      const escapedSearchTerm = searchTerm.replace(
        /[-/\\^$*+?.()|[\]{}]/g,
        "\\$&"
      );

      // Highlight matches with a span
      const regex = new RegExp(`(${escapedSearchTerm})`, "gi");
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ backgroundColor: "yellow" }}>
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    const renderContent = (line) => {
      return <span>{highlightText(line)}</span>;
    };

    const open = Boolean(anchorEl);

    return (
      <Grid container spacing={3}>
        {Array.isArray(grpData) && grpData.length > 0 ? (
          grpData.map((section, sectionIndex) => (
            <Grid item xs={12} key={sectionIndex}>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  padding: 1,
                  borderRadius: 1,
                  boxShadow: 2,
                  marginBottom: 1,
                }}
              >
                <Box display="flex" alignItems="center" mb={1}>
                  {editingSectionNameIndex === sectionIndex ? (
                    <Box width="100%">
                      <Box display="flex" justifyContent="flex-end">
                        <IconButton
                          onClick={() =>
                            handleSaveSectionNameClick(sectionIndex)
                          }
                          size="small"
                          sx={{ color: "green" }}
                        >
                          <CheckIcon sx={{ fontSize: 17 }} />
                        </IconButton>
                        <IconButton
                          onClick={handleCancelSectionNameEdit}
                          size="small"
                          sx={{ color: "red" }}
                        >
                          <CloseIcon sx={{ fontSize: 17 }} />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #ddd",
                          padding: 1,
                          borderRadius: 1,
                        }}
                      >
                        <Editor
                          editorState={sectionNameEditorState}
                          onEditorStateChange={(newState) =>
                            setSectionNameEditorState(newState)
                          }
                          // toolbarHidden={true}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      gap={"3px"}
                      // justifyContent="space-between"
                      // width="100%"
                    >
                      <Typography className={classes.diffHeader}>
                        {highlightText(section[0].SECTION_NAME, searchTerm)}
                      </Typography>
                      <Tooltip title="Edit Section Name">
                        <IconButton
                          onClick={() =>
                            handleEditSectionNameClick(
                              sectionIndex,
                              section[0].SECTION_NAME
                            )
                          }
                          size="small"
                          color="primary"
                        >
                          <EditIcon sx={{ fontSize: 17 }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>

                {section.map((item, itemIndex) => (
                  <Grid
                    container
                    spacing={1}
                    key={itemIndex}
                    alignItems="center"
                    mt={1}
                  >
                    {editingSectionIndex === sectionIndex &&
                    editingRowIndex === itemIndex ? (
                      <>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="flex-end" mt={1}>
                            <IconButton
                              onClick={() =>
                                handleSaveRowClick(sectionIndex, itemIndex)
                              }
                              size="small"
                              sx={{ color: "green" }}
                            >
                              <CheckIcon sx={{ fontSize: 17 }} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleUndoRowClick(sectionIndex, itemIndex)
                              }
                              size="small"
                              color="primary"
                            >
                              <UndoIcon sx={{ fontSize: 17 }} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleResetToOriginalClick(
                                  sectionIndex,
                                  itemIndex
                                )
                              }
                              size="small"
                              color="primary"
                            >
                              <RefreshIcon sx={{ fontSize: 17 }} />
                            </IconButton>
                            <IconButton
                              onClick={() => setEditingSectionIndex(null)}
                              size="small"
                              sx={{ color: "red" }}
                            >
                              <CloseIcon sx={{ fontSize: 17 }} />
                            </IconButton>
                          </Box>

                          <Box
                            sx={{
                              border: "1px solid #ddd",
                              padding: 2,
                              borderRadius: 1,
                            }}
                          >
                            <div style={{ backgroundColor: "#F5D2E6" }}>
                              {item.ORIGINAL_TEXT}
                            </div>
                            <Editor
                              editorState={rowEditorState}
                              onEditorStateChange={(newState) =>
                                setRowEditorState(newState)
                              }
                              toolbarHidden={true}
                            />
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={11}
                          onContextMenu={
                            item.RECOMMENDATION || item.GAP_DESCRIPTION
                              ? (e) =>
                                  handleRightClick(
                                    e,
                                    true,
                                    item.GAP_ANALYSIS_ID
                                  )
                              : (e) =>
                                  handleRightClick(
                                    e,
                                    false,
                                    item.GAP_ANALYSIS_ID
                                  )
                          }
                        >
                          <div className={classes.diffClassName}>
                            <ReactDiffViewer
                              oldValue={
                                item.GAP_TYPE === null
                                  ? item.ORIGINAL_TEXT
                                  : item.ORIGINAL_TEXT || ""
                              }
                              newValue={item.modified_sentence_copy || ""}
                              splitView={false}
                              showDiffOnly={false}
                              hideLineNumbers={true}
                              compareMethod={DiffMethod.WORDS}
                              renderContent={renderContent}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <Box display="flex" justifyContent="flex-start">
                            <Tooltip title="Edit Row">
                              <IconButton
                                onClick={() =>
                                  handleEditRowClick(
                                    sectionIndex,
                                    itemIndex,
                                    item.modified_sentence_copy
                                  )
                                }
                                size="small"
                                color="primary"
                              >
                                <EditIcon sx={{ fontSize: 17 }} />
                              </IconButton>
                            </Tooltip>

                            {(item.RECOMMENDATION || item.GAP_DESCRIPTION) && (
                              <div
                                id={`${item.SECTION_ORDER}-${item.SENTENCE_INDEX}`}
                              >
                                <Tooltip
                                  title="More Info"
                                  // id={`${item.SECTION_ORDER}-${item.SENTENCE_INDEX}`}
                                >
                                  <IconButton
                                    onClick={(e) => handleInfoClick(e, item)}
                                    size="small"
                                    color="primary"
                                  >
                                    <InfoIcon sx={{ fontSize: 17 }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            )}
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                ))}
              </Box>
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" xs={12}>
            No data available.
          </Typography>
        )}
        <Box>
          <Menu
            open={!!menuPosition}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              menuPosition
                ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                : undefined
            }
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Tooltip title="Approve">
                <IconButton
                  color="success"
                  onClick={(event) => {
                    handleApprove(event, "Accept");
                  }}
                >
                  <CheckCircleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton
                  color="error"
                  onClick={(event) => {
                    handleApprove(event, "Reject");
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <MenuItem onClick={addActionModal?.openModal}>
              <ListItemIcon>
                <PlaylistAddIcon />
              </ListItemIcon>
              <ListItemText primary="Add Action" />
            </MenuItem>
            <MenuItem onClick={addNoteModal?.openModal}>
              <ListItemIcon>
                <NoteIcon />
              </ListItemIcon>
              <ListItemText primary="Add Note" />
            </MenuItem>
          </Menu>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              boxShadow: "none",
            }}
          >
            <Box p={2} minWidth={250}>
              <Typography variant="body2">
                <strong>Recommendation:</strong>{" "}
                {selectedItem?.RECOMMENDATION || "No recommendation available"}
              </Typography>
              <Typography variant="body2" mt={1}>
                <strong>Gap Description:</strong>{" "}
                {selectedItem?.GAP_DESCRIPTION ||
                  "No gap description available"}
              </Typography>
            </Box>
          </Popover>
        </Box>
        {addNoteModal?.isOpen && (
          <AddNoteDialog
            modal={addNoteModal}
            action={{ i_POLICYCOMPARISONID: cmpID }}
          />
        )}
        {addActionModal?.isOpen && (
          <AddEditActionTabMenu
            actionList={actionList}
            setActionList={setActionList}
            modal={addActionModal}
            linkedId={`${cmpID}`}
            actionListType={linkTypes[2]}
          />
        )}
      </Grid>
    );
  }
);

EditorComponent.propTypes = {
  metaData: PropTypes.array,
  searchTerm: PropTypes.any,
  cmpID: PropTypes.number,
  setComparisonMetaData: PropTypes.func,
};

export default EditorComponent;
