/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import { globalConstants } from "../../_constants";
import { authConstants } from "./constants";
import sessionService from "./../../_services/session.service";
let authData = JSON.parse(sessionService.getItem("authData"));
const themeDetails = {
  TOPBAR_BGROUND_COLOUR: window.TOPBAR_BGROUND_COLOUR,
  MENUBAR_BGROUND_COLOUR: window.MENUBAR_BGROUND_COLOUR,
  ACTIONBUTTON_BGROUND_COLOUR: window.ACTIONBUTTON_BGROUND_COLOUR,
  TABLE_HEADER_COLOUR: window.TABLE_HEADER_COLOUR,
  FONT_TYPEFACE_BOLD: window.FONT_TYPEFACE_BOLD,
  FONT_TYPEFACE_LIGHT: window.FONT_TYPEFACE_LIGHT,
  FONT_COLOUR_LIGHT: window.FONT_COLOUR_LIGHT,
  FONT_COLOUR_DARK: window.FONT_COLOUR_DARK,
};
const initialState = authData
  ? {
      loggedIn: true,
      authData,
      detailedInfo: {
        DEFAULT_DOC_URL: "",
        AVATAR_IMAGE: "",
        PRIMARY_LOGO: "",
        REACT_APP_AWS_ACCESS_KEY_ID: "",
        REACT_APP_AWS_SECRET_ACCESS_KEY: "",
        FEATURE_LIST: [],
        EVENT_COUNT: 0,
        PRIVACY_DATALIST: [],
        ASSISTANT_DATA: [],
        userData: {
          allowedNavItems: [],
        },
      },
      themeDetails,
    }
  : { themeDetails };

const updateUserRole = (state, role) => {
  let tempUserData = { ...state.userData };
  tempUserData["userRole"] = role.name;
  tempUserData["userRoleId"] = role.id;
  return {
    ...state,
    userData: tempUserData,
  };
};

const updateUserName = (state, data) => {
  let tempUserData = { ...state.userData };
  tempUserData["userName"] = data;
  return {
    ...state,
    userData: tempUserData,
  };
};

const updateUserAvatar = (state, data) => {
  let tempDetailedInfo = { ...state.detailedInfo };
  tempDetailedInfo.AVATAR_IMAGE = data;
  return {
    ...state,
    detailedInfo: tempDetailedInfo,
  };
};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        authData: action.user,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        authData: action.user,
      };
    case authConstants.LOGIN_FAILURE:
      return {};
    case authConstants.LOGOUT:
      return {};
    case globalConstants.USER_DATA_ADD:
      return {
        ...state,
        userData: { ...state.userData, ...action.data },
      };
    case globalConstants.UPDATE_USER_ROLE:
      return updateUserRole(state, action.data);
    case globalConstants.UPDTAE_USER_NAME:
      return updateUserName(state, action.data);
    case globalConstants.USER_AVATAR_ADD:
      return updateUserAvatar(state, action.data);
    case globalConstants.ADD_USER_DETAILED_INFO:
      return {
        ...state,
        themeDetails: {
          TOPBAR_TYPE: action?.data?.TOPBAR_TYPE || window.TOPBAR_TYPE,
          TOPBAR_DATA: action?.data?.TOPBAR_VALUE || window.TOPBAR_VALUE,
          TOPBAR_BGROUND_COLOUR:
            action?.data?.TOPBAR_BGROUND_COLOUR || window.TOPBAR_BGROUND_COLOUR,
          MENUBAR_BGROUND_COLOUR:
            action?.data?.MENUBAR_BGROUND_COLOUR ||
            window.MENUBAR_BGROUND_COLOUR,
          ACTIONBUTTON_BGROUND_COLOUR:
            action?.data?.ACTIONBUTTON_BGROUND_COLOUR ||
            window.ACTIONBUTTON_BGROUND_COLOUR,
          TABLE_HEADER_COLOUR:
            action?.data?.TABLE_HEADER_COLOUR || window.TABLE_HEADER_COLOUR,
          FONT_TYPEFACE_BOLD:
            action?.data?.FONT_TYPEFACE_BOLD || window.FONT_TYPEFACE_BOLD,
          FONT_TYPEFACE_LIGHT:
            action?.data?.FONT_TYPEFACE_LIGHT || window.FONT_TYPEFACE_LIGHT,
          FONT_COLOUR_LIGHT:
            action?.data?.FONT_COLOUR_LIGHT || window.FONT_COLOUR_LIGHT,
          FONT_COLOUR_DARK:
            action?.data?.FONT_COLOUR_DARK || window.FONT_COLOUR_DARK,
        },
        detailedInfo: action.data,
        userData: {
          ...state.userData,
          allowedNavItems: action?.data?.ALLOWED_NAV_ITEMS,
          userName: action?.data?.userName,
          userRole: action?.data?.userRole,
          userRoleId: action?.data?.userRoleId,
          userImageUrl: action?.data?.userImageUrl,
        },
      };
    case globalConstants.RESET_FEATURE_LIST:
      return {
        ...state,
        detailedInfo: {
          ...state.detailedInfo,
          FEATURE_LIST: [],
        },
      };
    default:
      return state;
  }
}

export const getThemeDetails = (state) => state?.authentication?.themeDetails;
export const getNavigationPathList = (state) =>
  state?.authentication?.detailedInfo?.NAVIGATION_DETAILS;
