/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import constant from "./constants";
import apiService from "../../../../_services/api.service";
import { alertActions, loadingActions } from "../../../../_actions";
import langConstant from "_lang";
import sessionService from "_services/session.service";
const userId = sessionService.getUserId();
const checkAssignmentStatus = (id, status, goBackOnError) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    if (status == "ASSIGNED") {
      apiService
        .apiCall("regulationservice")
        .send("/createNewAssessment", { regulationId: id })
        .then(apiService.handleResponse)
        .then(
          (response) => {
            if (response.statusUpdated) {
              dispatch(getSQMAssessmentById(id, status, goBackOnError));
            } else {
              dispatch(loadingActions.end());
              goBackOnError();
              dispatch(
                alertActions.error(
                  langConstant.ERROR_LISTING_ASSESSMENT_QUESTIONS
                )
              );
            }
          },
          (error) => {
            dispatch(loadingActions.end());
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              dispatch(
                alertActions.error(
                  error.response.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              dispatch(alertActions.error(error.toString()));
            }
            goBackOnError();
          }
        );
    } else {
      dispatch(getSQMAssessmentById(id, status, goBackOnError));
    }
  };
};
const getSQMAssessmentById = (id, status, goBackOnError) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetTPSRAssessments", {
        i_SUBMISSION_ID: id,
        i_USER_ID: userId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let payload = [];
          const functionWithPromise = (item) => {
            return Promise.resolve(item);
          };
          const anAsyncFunction = async (item) => {
            return functionWithPromise(item);
          };

          try {
            payload = response["#result-set-1"];
            if (payload == 0) {
              goBackOnError();
              dispatch({ type: constant.GET_TPSR_ASSESMENT_FAILURE });
              dispatch(alertActions.error("Data not found"));
              return;
            }
            const assessmentObj = response["#result-set-2"]
              ? response["#result-set-2"]
              : {};
            if (response["#result-set-5"]) {
              dispatch({
                type: constant.SET_SUPPLIER_DATA,
                data: response["#result-set-5"][0],
              });
            }
            const getData = async () => {
              return Promise.all(
                payload.map((item, index) => {
                  const matches = [];
                  const historyAnswers = {};
                  if (item.CURRENT_ANSWER_SELECTED_RESPONSE) {
                    historyAnswers.currentAnswer = {
                      answer: item.CURRENT_ANSWER_SELECTED_RESPONSE,
                      score: item.CURRENT_ANSWER_MATCH_SCORE,
                    };
                  }
                  if (item.HIST_ANSWER_RESPONSE_01) {
                    historyAnswers.pastAnsOne = {
                      answer: item.HIST_ANSWER_RESPONSE_01,
                      score: item.HIST_ANSWER_MATCH_SCORE_01,
                    };
                    matches.push({
                      answer: item.HIST_ANSWER_RESPONSE_01,
                      score: item.HIST_ANSWER_MATCH_SCORE_01,
                    });
                  }
                  if (item.HIST_ANSWER_RESPONSE_02) {
                    historyAnswers.pastAnsTwo = {
                      answer: item.HIST_ANSWER_RESPONSE_02,
                      score: item.HIST_ANSWER_MATCH_SCORE_02,
                    };
                    matches.push({
                      answer: item.HIST_ANSWER_RESPONSE_02,
                      score: item.HIST_ANSWER_MATCH_SCORE_02,
                    });
                  }
                  if (item.HIST_ANSWER_RESPONSE_03) {
                    historyAnswers.pastAnsThree = {
                      answer: item.HIST_ANSWER_RESPONSE_03,
                      score: item.HIST_ANSWER_MATCH_SCORE_03,
                    };
                    matches.push({
                      answer: item.HIST_ANSWER_RESPONSE_03,
                      score: item.HIST_ANSWER_MATCH_SCORE_03,
                    });
                  }
                  const matchFound = matches.findIndex(
                    (ele) => ele.answer == item.CURRENT_ANSWER_SELECTED_RESPONSE
                  );
                  const selectedAnswer =
                    matchFound > -1 ? "history_" + matchFound : "current";
                  const newItem = {
                    ...item,
                    assessmentCaseId: item.SUBMISSION_ID,
                    question: item.QUESTION_TEXT,
                    status: item.QUESTION_STATUS,
                    id: item.QUESTION_ID,
                    refId: item.REGULATORS_QUESTION_REF,
                    guidance: item.GUIDANCE_TEXT,
                    currentAnswer: item.CURRENT_ANSWER_SELECTED_RESPONSE,
                    currentAnswerScore: item.CURRENT_ANSWER_MATCH_SCORE,
                    matches: matches,
                    historyAnswers: historyAnswers,
                    selectedAnswer: selectedAnswer,
                    questionState:
                      item.QUESTION_STATE && parseInt(item.QUESTION_STATE)
                        ? true
                        : false,
                    assessmentObj: assessmentObj,
                  };
                  return anAsyncFunction(newItem);
                })
              );
            };

            getData().then((data) => {
              dispatch({
                type: constant.GET_TPSR_ASSESMENT_SUCCESS,
                data: data,
              });
            });
            dispatch(loadingActions.end());
          } catch (e) {
            dispatch({ type: constant.GET_TPSR_ASSESMENT_FAILURE });
            dispatch(
              alertActions.error(langConstant.ERROR_GETTING_ASSESSMENT_DETAIL)
            );
            goBackOnError();
            console.log(e, true);
            dispatch(loadingActions.end());
          }

          if (
            response["#result-set-4"] == 0 &&
            response["#result-set-3"] == 0
          ) {
            // If there are no RFIs for the question
            return;
          }

          let rfiPayload = {
            rfiItems: response["#result-set-4"],
            rfiGroups: response["#result-set-3"],
          };
          dispatch({
            type: constant.initiateRfiConstant_GET,
            data: rfiPayload,
          });
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item} </div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          goBackOnError();
        }
      );
  };
};

const updateQuestionStatus = (
  qId,
  submissionId,
  status,
  goBackOnError,
  userID
) => {
  const payload = {
    i_QUESTION_ID: qId,
    i_SUBMISSION_ID: submissionId,
    i_QUESTION_STATUS: status,
    i_USER_ID: userID,
  };
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/updateQuestionStatus", payload)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(getSQMAssessmentById(submissionId, status, goBackOnError));
          dispatch(
            alertActions.success(langConstant.QUESTION_UPDATED_SUCCESSFULLY)
          );
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateSupplierQuestionResponse = (draftButton, attributes, narrative,customerID) => {
  let updateArray = attributes
    ? attributes.map((attr) => {
        return {
          i_CUSTOMER_ID: attr.attrCustId,
          i_SUPPLIER_RESPONSE_LOG_ID: attr.SUPPLIER_RESPONSE_LOG_ID,
          i_QUESTION_ID: narrative.selectedQue.QUESTION_ID,
          i_SUBMISSION_ID: narrative.selectedQue.SUBMISSION_ID,
          i_RESPONSE_RECORD_TYPE: "ATTRIBUTE",
          i_SUPPLIER_RESPONSE_JSON: JSON.stringify({
            DATA_ASSET_ATTRIBUTE_NAME: attr.DATA_ASSET_ATTRIBUTE_NAME,
            SOURCE_DATA_POINT_VALUE: attr.SOURCE_DATA_POINT_VALUE,
          }),
          i_USER_ID: userId,
        };
      })
    : [];

  updateArray.push({
    i_CUSTOMER_ID: narrative.narCustId,
    i_SUPPLIER_RESPONSE_LOG_ID: narrative.narLogId,
    i_QUESTION_ID: narrative.selectedQue.QUESTION_ID,
    i_SUBMISSION_ID: narrative.selectedQue.SUBMISSION_ID,
    i_RESPONSE_RECORD_TYPE: "NARRATIVE",
    i_SUPPLIER_RESPONSE_JSON: JSON.stringify({
      NARRATIVE: narrative.NARRATIVE,
    }),
    i_USER_ID: userId,
  });
  return (dispatch) => {
    Promise.all(
      updateArray.map((updateItem) => {
        return apiService
          .apiCall("dbapi")
          .send(
            "/v1/api/proc/DRegulativDB/UpdateSupplierQuestionResponse",
            updateItem
          )
          .then(apiService.handleResponse);
      })
    ).then(
      (response) => {
        if (draftButton) {
          dispatch(
            getSupplierQuestionResponses(
              narrative.selectedQue.QUESTION_ID,
              narrative.selectedQue.SUBMISSION_ID,
              customerID
            )
          );
        }
        dispatch(
          alertActions.success(langConstant.DATA_ASSET_UPDATED_SUCCESSFULLY)
        );
      },
      (error) => {
        dispatch(loadingActions.end());
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(
            alertActions.error(
              error.response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };
};
const putSupplierQuestionResponse = (draftButton, attributes, narrative,customerID) => {
  let updateArray = attributes
    ? attributes.map((attr) => {
        return {
          i_QUESTION_ID: narrative.selectedQue.QUESTION_ID,
          i_SUBMISSION_ID: narrative.selectedQue.SUBMISSION_ID,
          i_RESPONSE_RECORD_TYPE: "ATTRIBUTE",
          i_SUPPLIER_RESPONSE_JSON: JSON.stringify({
            DATA_ASSET_ATTRIBUTE_NAME: attr.DATA_ASSET_ATTRIBUTE_NAME,
            SOURCE_DATA_POINT_VALUE: attr.SOURCE_DATA_POINT_VALUE,
          }),
          i_USER_ID: userId,
        };
      })
    : [];

  updateArray.push({
    i_QUESTION_ID: narrative.selectedQue.QUESTION_ID,
    i_SUBMISSION_ID: narrative.selectedQue.SUBMISSION_ID,
    i_RESPONSE_RECORD_TYPE: "NARRATIVE",
    i_SUPPLIER_RESPONSE_JSON: JSON.stringify({
      NARRATIVE: narrative.NARRATIVE,
    }),
    i_USER_ID: userId,
  });
  return (dispatch) => {
    Promise.all(
      updateArray.map((updateItem) => {
        return apiService
          .apiCall("dbapi")
          .send(
            "/v1/api/proc/DRegulativDB/PutSupplierQuestionResponse",
            updateItem
          )
          .then(apiService.handleResponse);
      })
    ).then(
      (response) => {
        if (draftButton) {
          dispatch(
            getSupplierQuestionResponses(
              narrative.selectedQue.QUESTION_ID,
              narrative.selectedQue.SUBMISSION_ID,
              customerID
            )
          );
        }
        dispatch(
          alertActions.success(langConstant.DATA_ASSET_UPDATED_SUCCESSFULLY)
        );
      },
      (error) => {
        dispatch(loadingActions.end());
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(
            alertActions.error(
              error.response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };
};

const updateAssignedStatus = (id, rid, assignedByUserID, status, callback) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAssignedStatusTPRS", {
        i_SUBMISSION_ID: id,
        i_REGULATION_ID: rid,
        i_USER_ID: assignedByUserID,
        i_STATUS: status,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (status == "PENDING_FOR_REVIEW") {
            dispatch(
              alertActions.success(
                langConstant.ASSESSMENT_SUBMITTED_FOR_REVIEW_SUCCESSFULLY
              )
            );
            callback();
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const getQuestionDQProfile = (id) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionDQProfile", {
        i_QUESTION_ID: id,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({
            type: constant.initiateAssessmentConstant_Add_DQ_PROFILE,
            data: response["#result-set-1"],
          });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getDataAssestsList = (id, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDataAssestsForQuestion", {
        i_QUESTION_ID: id,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({
            type: constant.GET_DATA_ASSET_LIST_SUCCESS,
            data: response["#result-set-1"],
          });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const getSupplierQuestionResponses = (id, submissionId, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetSupplierQuestionResponses", {
        i_QUESTION_ID: id,
        i_SUBMISSION_ID: submissionId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#result-set-1"]?.length) {
            dispatch({
              type: constant.GET_SUPPLIER_QUE_RESPONSES_LIST_SUCCESS,
              data: response["#result-set-1"],
            });
          } else {
            dispatch(getDataAssestsList(id, customerID));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
const handleDataEntryChange = (type, id, queData, value) => {
  return (dispatch) => {
    dispatch({
      type: constant.DATA_ENTRY_UPDATE,
      data: {
        type: type,
        id: id,
        queData: queData,
        value: value,
      },
    });
  };
};

const setSelectedQuestion = (queData) => {
  return (dispatch) => {
    dispatch({
      type: constant.SELECTED_QUESTION,
      data: queData,
    });
  };
};
export default {
  setSelectedQuestion,
  checkAssignmentStatus,
  getSQMAssessmentById,
  updateAssignedStatus,
  getQuestionDQProfile,
  updateQuestionStatus,
  getDataAssestsList,
  putSupplierQuestionResponse,
  updateSupplierQuestionResponse,
  getSupplierQuestionResponses,
  handleDataEntryChange,
};
