import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { getThemeDetails } from "containers/LoginPage/reducers";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import { forwardRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import sessionService from "_services/session.service";
import uploadDocumentService from "./services";
import { alertActions } from "_actions";
import { GridCheckCircleIcon } from "@material-ui/data-grid";
import { Error, HourglassEmpty } from "@material-ui/icons";
import { ListItemIcon, MenuItem, makeStyles } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import Checkbox from "@mui/material/Checkbox";
import { Add } from "@mui/icons-material";
import PolicyUploadForm from "containers/manage-regulation/Policy/PolicyExplorer/component/PolicyDataTable/component/policyUploadForm";

const useStyles = makeStyles(() => ({
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  actionButton: {
    color: "#fff",
    fontSize: "18px !important",
    margin: "0 auto",
  },
  iconButton: {
    backgroundColor: "primary !important",
    display: "inline-flex !important",
    borderRadius: "50% !important",
    padding: "10px !important",
    cursor: "pointer !important",
  },
}));
function getStyle({ provided, style, isDragging }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const marginBottom = 8;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
  };
  return withSpacing;
}

export function Item({
  provided,
  item,
  style,
  isDragging,
  setFilteredFiles,
  items,
  file,
  regulationID,
  handleCheckboxChange,
  selected,
}) {
  const classes = useStyles();
  const customerID = sessionService.getCustomerId();
  const userID = sessionService.getUserId();

  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);

  const deleteCustomerRegulationPolicyMapping = async (item) => {
    item.location = "left";
    setFilteredFiles((prev) => [...prev]);
    const tempIndex = items.findIndex((i) => i.uuid === item.uuid);
    items.splice(tempIndex, 1);
    let response =
      await uploadDocumentService.deleteCustomerRegulationPolicyMapping(
        parseInt(customerID),
        file.POLICY_ID,
        regulationID,
        parseInt(userID),
        item.REGULATIONDOCREQ_UNID,
        item.POLICY_ID ? item.POLICY_ID : null
      );
    if (response?.status != 200) {
      item.location = "right";
      items.push(item);
      throw new Error("Failed to delete Policy");
    }
  };

  const history = useHistory();
  const linkHandler = async () => {
    let response;
    if (isEdit) {
      response = await uploadDocumentService.updateCustomerRegulationPolicyMap(
        customerID,
        regulationID,
        file.POLICY_ID,
        item.REGULATIONDOCREQ_UNID,
        userID,
        item.ACTIVE
      );
    } else {
      response = await uploadDocumentService.putCustomerRegulationPolicyMapping(
        customerID,
        file.POLICY_ID,
        regulationID,
        userID,
        item.REGULATIONDOCREQ_UNID,
        item.POLICY_ID ? item.POLICY_ID : null
      );
    }
    if (response.status === 200) {
      dispatch(alertActions.success("Successfully maped RegulationPolicy"));
      setIsEdit(!isEdit);
    } else {
      dispatch(alertActions.error("Something went wrong"));
    }
  };

  return (
    <Box
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging })}
      sx={{
        border: isDragging
          ? `2px solid ${themeDetails?.ACTIONBUTTON_BGROUND_COLOUR}`
          : "1px solid #0000001f",
        borderRadius: 1,
        display: "inline-flex",
        gap: 2,
        alignItems: "center",
        p: 0.5,
        px: 1,
        // my: isDragging ? 2 : 0,
        boxShadow:
          isDragging &&
          `0px 0px 5px 0px ${themeDetails?.ACTIONBUTTON_BGROUND_COLOUR}`,
        transition: "all ease-in-out .3s",
        fontSize: 12,
      }}
    >
      {item?.no && (
        <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
          {item?.no}. &nbsp;
        </Typography>
      )}
      {item?.isGoldStandered ? (
        <Typography fontSize={12} flex={1}>
          {item?.GOLD_STANDARD_DOC_NAME}
        </Typography>
      ) : (
        <Typography fontSize={12} flex={1}>
          {item?.DOCUMENT_RECOMMENDATION}
        </Typography>
      )}

      {item?.isGoldStandered && <StarIcon style={{ color: "gold" }} />}

      {item?.location === "right" && (
        <Box>
          <Tooltip title={"Delete Policy"}>
            <IconButton
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteCustomerRegulationPolicyMapping(item);
              }}
              size="small"
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
          {/* <IconButton
            title="Save Mapping"
            style={{ cursor: "pointer" }}
            onClick={linkHandler}
            size="small"
          > */}
          {/* {isEdit ? <FmdGoodIcon /> : <AddLocationAltIcon />} */}
          {/* <Check color="success" fontSize="small" /> */}
          {/* </IconButton> */}
          {item?.TEMPLATE_FILENAME &&
          item?.TEMPLATE_FOLDER &&
          item?.TEMPLATE_FILENAME != "" &&
          item?.TEMPLATE_FOLDER != "" &&
          item.COMPARISONSTATUS == "FAILED" ? (
            <>
              <IconButton disabled>
                <Error color="error" />
              </IconButton>
              <IconButton>
                <Checkbox
                  checked={selected === item.uuid2}
                  onChange={() => handleCheckboxChange(item.uuid2)}
                />
              </IconButton>
            </>
          ) : item?.TEMPLATE_FILENAME &&
            item?.TEMPLATE_FOLDER &&
            item?.TEMPLATE_FILENAME != "" &&
            item?.TEMPLATE_FOLDER != "" &&
            !("COMPARISONSTATUS" in item) ? (
            <IconButton>
              <Checkbox
                checked={selected === item.uuid2}
                onChange={() => handleCheckboxChange(item.uuid2)}
              />
            </IconButton>
          ) : item?.COMPARISONSTATUS == "COMPLETED" ? (
            <>
              <Tooltip title={"preview data"}>
                <IconButton
                  onClick={() => {
                    // console.log(item.QUEUE_UNIQUE_ID);
                    history.push(`document-analysis/${item.QUEUE_UNIQUE_ID}`);
                  }}
                >
                  <RemoveRedEyeIcon style={{ color: "#3c0292" }} />
                </IconButton>
              </Tooltip>

              <IconButton disabled>
                <GridCheckCircleIcon style={{ color: "green", fontSize: 20 }} />
              </IconButton>
            </>
          ) : item?.COMPARISONSTATUS == "PENDING" ? (
            <IconButton>
              <HourglassEmpty style={{ color: "orange", fontSize: 20 }} />
            </IconButton>
          ) : (
            <span
              style={{ color: "red", fontSize: "0.9em", fontWeight: "bold" }}
            >
              Policy comparison not available
            </span>
          )}
        </Box>
      )}
    </Box>
  );
}

// Recommended react-window performance optimisation: memoize the row render function
// Things are still pretty fast without this
export const Row = React.memo(function Row(props) {
  const { data, index, style } = props;
  const {
    items,
    setFilteredFiles,
    file,
    regulationID,
    selected,
    handleCheckboxChange,
  } = data;
  const item = items[index];
  return (
    <Draggable
      draggableId={item?.uuid}
      index={index}
      key={item?.uuid}
      isDragDisabled={item?.location == "right" ? true : false}
    >
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          items={items}
          style={style}
          index={index}
          setFilteredFiles={setFilteredFiles}
          file={file}
          regulationID={regulationID}
          selected={selected}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </Draggable>
  );
}, areEqual);

// eslint-disable-next-line react/display-name, react/prop-types
export const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
    }}
    {...rest}
    className="innerClass"
  />
));

Row.propTypes = {
  data: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object,
  file: PropTypes.any,
};
Item.propTypes = {
  provided: PropTypes.any,
  item: PropTypes.any,
  style: PropTypes.any,
  isDragging: PropTypes.bool,
  setFilteredFiles: PropTypes.func,
  items: PropTypes.array,
  file: PropTypes.any,
  regulationID: PropTypes.number,
  selected: PropTypes.any,
  handleCheckboxChange: PropTypes.func,
};
