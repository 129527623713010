import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { Grid, Typography, Button } from "@material-ui/core";
import { Save } from "@mui/icons-material";
import LoupeIcon from "@material-ui/icons/Loupe";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import sessionService from "_services/session.service";
import ProfileServices from "../services";
import langConstant from "_lang";
import useStyles from "./styles";

const Reports = ({ user_ID, selectedCustomer, mode, setCurrentStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [entitlementList, setEntitlementList] = useState([]);
  const [entitlementLabels, setEntitlementLabels] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [list, setList] = useState([]);

  const customerID = selectedCustomer?.value || sessionService.getCustomerId();
  const userID = sessionService.getUserId();

  const initialValues = {
    menuList: list,
  };

  const validationSchema = yup.object().shape({
    menuList: yup
      .array()
      .of(
        yup.object().shape({
          ENTITLEMENT_LABEL_ID: yup
            .number()
            .nullable()
            .required("Menu item is required.")
            .test(
              "is-valid-entitlement-label",
              "Menu item is not permitted.",
              function (value) {
                return entitlementLabels.some((label) => label.value === value);
              }
            ),
          REPORT_ID: yup
            .number()
            .nullable()
            .required("Report name is required."),
        })
      )
      .test(
        "unique-entitlement-label",
        "Menu items must be unique.",
        function (menuList) {
          if (!menuList) return true;

          const duplicates = menuList
            .map((item, index) => ({
              index,
              value: item.ENTITLEMENT_LABEL_ID,
            }))
            .filter(
              (item, idx, arr) =>
                item.value &&
                arr.findIndex((i) => i.value === item.value) !== idx
            );

          // Assign errors to specific fields for duplicates
          if (duplicates.length > 0) {
            const errors = {};
            duplicates.forEach((dup) => {
              if (!errors[dup.index]) {
                errors[dup.index] = {
                  ENTITLEMENT_LABEL_ID: "Menu items must be unique.",
                };
              }
            });
            throw this.createError({ path: "menuList", message: errors });
          }

          return true;
        }
      ),
  });

  // API: Update Reporting Preference
  const updateReportingPreference = async (payload) => {
    dispatch(loadingActions.start());
    const response = await ProfileServices.updateUserReportingPreference(
      payload
    );
    dispatch(loadingActions.end());

    if (response?.status === 200) {
      const successMessage = payload.i_IS_DELETE
        ? langConstant.REPORTING_PREFERENCE_DELETED
        : langConstant.REPORTING_PREFERENCE_ADDED;
      dispatch(alertActions.success(successMessage));
    } else {
      const errorMessage =
        response?.data?.message?.join("\n") ||
        langConstant.SOMETHING_WENT_WRONG;
      dispatch(alertActions.error(errorMessage));
    }
  };

  // Fetch report list
  const getReportList = async () => {
    const response =
      mode === "admin"
        ? await ProfileServices.getCustomerReportsForAdmin(customerID)
        : await ProfileServices.getCustomerReports(customerID);

    if (response?.status === 200) {
      setReportList(
        response["#result-set-1"].map((item) => ({
          label: item.REPORT_NAME,
          value: item.REPORT_ID,
        }))
      );
    }
  };

  // Fetch customer reports menu items
  const getCustomerReportsMenuItems = async () => {
    const response =
      mode === "admin"
        ? await ProfileServices.getCustomerReportsMenuItemsForAdmin(
            customerID,
            userID
          )
        : await ProfileServices.getCustomerReportsMenuItems(customerID, userID);

    if (response?.status === 200) {
      setList(response["#result-set-1"]);
    }
  };

  useEffect(() => {
    if (customerID) {
      dispatch(
        ProfileServices.getCustomerReportingEntitlements(
          customerID,
          setEntitlementLabels,
          setEntitlementList
        )
      );
    }
  }, [selectedCustomer]);

  useEffect(() => {
    getReportList();
    getCustomerReportsMenuItems();
  }, [userID]);

  const onSubmit = async (values) => {
    values.menuList.forEach((item, index) => {
      const isNewOrUpdated =
        index >= list.length ||
        item.ENTITLEMENT_LABEL_ID !== list[index].ENTITLEMENT_LABEL_ID ||
        item.REPORT_ID !== list[index].REPORT_ID;

      if (isNewOrUpdated) {
        const payload = {
          i_USER_ID: userID,
          i_ENTITLEMENT_NAME:
            entitlementList.find(
              (i) => i.ENTITLEMENT_LABEL_ID === item.ENTITLEMENT_LABEL_ID
            )?.ENTITLEMENT_NAME ||
            list.find(
              (e) =>
                e.ENTITLEMENT_LABEL_ID ===
                values.menuList[index].ENTITLEMENT_LABEL_ID
            )?.ENTITLEMENT_NAME,
          i_REPORT_ID: item.REPORT_ID,
          i_IS_DELETE: 0,
        };
        updateReportingPreference(payload);
      }
    });
  };

  const goToPrevMenu = () => setCurrentStep(2);

  return (
    <Grid className={classes.reportWrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  {langConstant.REPORTING_PREFERENCES}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
              style={{ marginTop: 20 }}
            >
              <Grid item md={6} xs={12} style={{ textAlign: "left" }}>
                {mode === "admin" && (
                  <Button
                    variant="outlined"
                    onClick={goToPrevMenu}
                    style={{ marginRight: 10 }} // Optional margin for spacing
                  >
                    {langConstant.BACK}
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Save />}
                >
                  {langConstant.SAVE}
                </Button>
              </Grid>
              <Grid item md={5} xs={12} style={{ textAlign: "right" }}>
                <Button
                  type="button"
                  onClick={() =>
                    setFieldValue("menuList", [
                      { ENTITLEMENT_LABEL_ID: null, REPORT_ID: null },
                      ...values.menuList,
                    ])
                  }
                  color="primary"
                >
                  <LoupeIcon /> {langConstant.ADD_REPORT_ITEM}
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ display: "flex", marginTop: "10px" }}>
              <Grid item md={6} xs={6}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {langConstant.MENU_ITEM_NAME}
                </Typography>{" "}
              </Grid>

              <Grid item md={6} xs={6}>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {langConstant.REPORT_NAME}
                </Typography>
              </Grid>
            </Grid>

            <FieldArray name="menuList">
              {({ remove }) =>
                values.menuList.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={2}
                    style={{ marginTop: 5 }}
                  >
                    <Grid item md={5} xs={12}>
                      <Select
                        options={[
                          ...(!entitlementLabels.some(
                            (e) => e.value === item.ENTITLEMENT_LABEL_ID
                          )
                            ? [
                                {
                                  value: item.ENTITLEMENT_LABEL_ID,
                                  label: item.ENTITLEMENT_LABEL,
                                },
                              ]
                            : []),
                          ...entitlementLabels,
                        ]}
                        placeholder={langConstant.SELECT_MENU_ITEM_NAME}
                        value={
                          entitlementLabels.find(
                            (e) => e.value === item.ENTITLEMENT_LABEL_ID
                          ) ||
                          (item.ENTITLEMENT_LABEL_ID
                            ? {
                                value: item.ENTITLEMENT_LABEL_ID,
                                label: item.ENTITLEMENT_LABEL,
                              }
                            : null)
                        }
                        onChange={(e) =>
                          setFieldValue(
                            `menuList[${index}].ENTITLEMENT_LABEL_ID`,
                            e.value
                          )
                        }
                      />

                      <ErrorMessage
                        name={`menuList[${index}].ENTITLEMENT_LABEL_ID`}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <Select
                        options={reportList}
                        placeholder={langConstant.SELECT_REPORT}
                        value={reportList.find(
                          (r) => r.value === item.REPORT_ID
                        )}
                        onChange={(report) =>
                          setFieldValue(
                            `menuList[${index}].REPORT_ID`,
                            report.value
                          )
                        }
                      />
                      <ErrorMessage
                        name={`menuList[${index}].REPORT_ID`}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Grid>
                    <Grid item md={2} xs={2} style={{ textAlign: "center" }}>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => {
                          if (item.ENTITLEMENT_LABEL_ID && item.REPORT_ID) {
                            dispatch(
                              confirmationDialogActions.open({
                                message:
                                  langConstant.CONFIRMATION_TO_DELETE_REPORT,
                                onConfirm: () => {
                                  const payload = {
                                    i_USER_ID: userID,
                                    i_ENTITLEMENT_NAME: list.find(
                                      (e) =>
                                        e.ENTITLEMENT_LABEL_ID ===
                                        values.menuList[index]
                                          .ENTITLEMENT_LABEL_ID
                                    )?.ENTITLEMENT_NAME,
                                    i_REPORT_ID:
                                      values.menuList[index].REPORT_ID,
                                    i_IS_DELETE: 1,
                                  };
                                  updateReportingPreference(payload);
                                  remove(index);
                                },
                                title: "",
                                option1: langConstant.YES,
                                option2: langConstant.NO,
                              })
                            );
                          } else {
                            remove(index);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </Button>
                    </Grid>
                  </Grid>
                ))
              }
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

Reports.propTypes = {
  user_ID: PropTypes.string,
  selectedCustomer: PropTypes.object,
  mode: PropTypes.string,
  setCurrentStep: PropTypes.func,
};

export default Reports;
