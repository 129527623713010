import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  paper: {
    padding: "2px 2px",
    textAlign: "left",
    color: "red",
    minHeight: "400px",
    width: "100%",
    border: "0px",
  },
  title: { display: "flex", alignItems: "center", padding: ".75rem" },

  wideColumn: {
    width: "100%",
  },
  regColumn: {
    width: "14%",
  },
  normalColumn: {
    width: "9%",
  },
  smallColumn: {
    width: "2%",
  },
  stickyColl: {
    position: "sticky",
    right: 0,
  },

  searchbar: {
    textAlign: "left",
    color: "gray",
    height: "40px",
    width: "80%",
  },

  textField: {
    width: "400px",
    height: "20px",
  },
  inputField: {
    // padding: "5px 5px !important",
    width: "80%",
    color: "#000",
    "& .MuiOutlinedInput-root": {
      // padding: "5px 5px !important",
      width: "100%",
      color: "#000",
      borderRadius: "0px !important", // Removes the curved border
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderTop: "none !important",
      borderLeft: "none !important",
      borderRight: "none !important",
      borderBottom: "1px solid #aaa !important", // Lighter bottom border (gray shade)
      borderRadius: "0px !important", // Ensures no border rounding
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderBottom: "1px solid #888 !important", // Slightly darker on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderBottom: "1px solid #666 !important", // Darker on focus for visibility
    },
  },

  formFieldLabel: {
    fontWeight: "bold",
    // fontSize: "14px",
    width: "50%",
    paddingLeft: "5px",
    marginTop: "20px",
  },

  filedTitle: {
    padding: "0 5px",
  },

  backgroundContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
  },
}));

export default useStyles;
