import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import UserServices from "../services";
import { useDispatch } from "react-redux";
import sessionService from "_services/session.service";
import PropTypes from "prop-types";
import langConstant from "_lang";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ServiceConnectionPopUp from "./ServiceConnectionPopUp";
import EditIcon from "@mui/icons-material/Edit";
import useStyles from "_components/ChatBot/styles";

const ServiceConnections = () => {
  const userID = sessionService.getUserId();
  const [serviceConnections, setServiceConnections] = useState([]);
  const [connectionDialog, setConnectionDialog] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const connectionDialogClose = () => {
    setConnectionDialog(false);
    setSelectedConnection(null);
  };

  const columns = [
    {
      field: "CONNECTION_NAME",
      headerName: "Name",
      minWidth: 135,
    },
    {
      field: "SERVICE_URL",
      headerName: "URL",
    },
    {
      field: "ACTIVE",
      headerName: langConstant.ACTIVE,
      format: function formatData(value) {
        if (value["ACTIVE"]) {
          return <TaskAltIcon color="success" />;
        } else {
          return <HighlightOffIcon color="error" />;
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(
      UserServices.getUserServiceConnection(userID, setServiceConnections)
    );
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (serviceConnections && serviceConnections.length > 0) {
      setPageCount(calculatePageCount(serviceConnections));
    }
  }, [serviceConnections]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      serviceConnections?.length > 0
        ? Math.round(Math.ceil(serviceConnections.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  return (
    <Grid className={classes.serviceConnectionWrapper}>
      <Grid style={{ textAlign: "right" }}>
        <IconButton
          type="button"
          color="primary"
          onClick={() => setConnectionDialog(true)}
        >
          <AddBoxIcon />
        </IconButton>
      </Grid>
      <TableContainer component={Paper}>
        <Table className="data-table" aria-label="Entitlement List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                >
                  {item.headerName}
                </TableCell>
              ))}
              <TableCell>{langConstant.ACTIONS}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceConnections ? (
              <React.Fragment>
                {(rowsPerPage > 0
                  ? serviceConnections.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : serviceConnections
                ).map((row, index) => {
                  return (
                    <TableRow key={index + row[columns[0].field]}>
                      {columns.map((column, index) => {
                        const value = row[column.field];
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            key={"column_" + index + row[columns[0].field]}
                            style={{
                              wordBreak: "break-word",
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <EditIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedConnection(row);
                            setConnectionDialog(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {serviceConnections && serviceConnections.length == 0 && (
                  <TableRow style={{ height: 53 }}>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={columns.length + 1}
                    >
                      {langConstant.NO_RECORD_FOUND}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid
          container
          justify="flex-end"
          style={{ display: "flex", alignItems: "center" }}
        >
          <SelectPageRange
            selectedRange={rowsPerPage}
            handleChange={handleSelectPageRange}
          />
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              size="small"
            />
          )}
        </Grid>
      </Box>
      <ServiceConnectionPopUp
        connectionDialog={connectionDialog}
        serviceConnections={serviceConnections}
        setServiceConnections={setServiceConnections}
        selectedConnection={selectedConnection}
        handleClose={connectionDialogClose}
      />
    </Grid>
  );
};

ServiceConnections.propTypes = {};

export default ServiceConnections;
