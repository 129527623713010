import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "_components/DialogBox";
import { Box, Button, makeStyles, IconButton, Grid } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NarrativeTable } from "_components/MasterAssessmentComp/component/NarrativeTable";
import CloseIcon from "@material-ui/icons/Close";
import langConstant from "_lang";
import AssessmentTabs from "./AssessmentTabs";
import { UseModalPropType } from "_hooks";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { EditAnswer } from "./EditAnswer";
import ObservationDialogBox from "_components/MasterAssessmentComp/component/ObservationDialogBox";
import planActionsServices from "../../services";
import EditIcon from "@material-ui/icons/Edit";

import _ from "lodash";
import {
  getEvidenceForMasterAssessment,
  getDataAssestsForMasterAssessment,
  saveSingleQuestion,
} from "_components/MasterAssessmentComp/utils/actions";
import sessionService from "_services/session.service";

import storeProcedure from "_components/MasterAssessmentComp/steps/services";
import { alertActions } from "_actions";
import { useModal } from "_hooks";

import { useParams } from "react-router-dom";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles((theme) => ({
  height100: {
    height: "100%",
  },
  width100: {
    width: "100%",
  },
  marginTop: {
    marginTop: "10px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  statusicon: {
    fontSize: 20,
    marginRight: "5px",

    "&.COMPLETED": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.RFI_SENT": {
      color: "#105BA1",
    },

    "&.RFI_RESOLVED": {
      color: "#1a751a",
    },

    "&.RFI_PENDING": {
      color: "#895514",
    },

    RFI_OVERDUE: {
      color: "#d62000",
    },

    "&.SENT_BACK": {
      color: "rgba(240, 20, 47, 1)",
    },

    "&.FINAL": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.PENDING_DATA": {
      color: "rgba(187, 174, 0, 1)",
    },

    "&.READY_FOR_REVIEW": {
      color: "rgba(1, 148, 25, 1)",
    },

    "&.COMPLETED_AND_LOCKED": {
      color: "rgba(0, 159, 26, 1)",
    },
  },
}));

const BottomTabsArray = [
  { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
  { name: "DATA ASSETS", count: 0, isCount: false, id: 1 },
  { name: "EVIDENCES", count: 0, isCount: false, id: 2 },
  { name: "Responses and narratives", count: 0, isCount: true, id: 3 },
];

const PrepareSummaryAnswer = ({ modal }) => {
  const QuestionList = modal ? modal.data.filteredRows : [];
  const selRow = modal?.data.selRow;
  const IsAudit = false;
  const observationList = [];
  const identifier = "";
  const RfiitemsList = [];
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  const [editAnswer, setEditAnswer] = useState(true);
  const [selectedTab, setSelectedTab] = useState({
    name: "",
    id: 0,
  });
  const setPlanActionsDataLength = modal
    ? modal.data.setPlanActionsDataLength
    : "";

  const setKpiData = modal ? modal.data.setKpiData : "";

  const EvidenceTableHeaders = [
    "EVIDENCE TYPE",
    "Accuracy",
    "Suitability",
    "Classification",
    "Evidence File Name",
    "Evidence Uploaded By",
  ];
  const DataAssetsTableHeaders = [
    "Source System Name",
    "Data Assets Name",
    "Data Assets Value",
    "Entered by",
  ];
  // const [TopSelectedTab, setTopSelectedTab] = useState(TopTabsArray[0]);
  const [BottomTabs, setBottomTabs] = useState(BottomTabsArray);
  const [BottomSelectedTab, setBottomSelectedTab] = useState(
    BottomTabsArray[0]
  );
  const [page, setPage] = useState(0);
  const [filteredQuestionList, setfilteredQuestionList] = useState([]);
  const [ObservationDialog, setObservationDialog] = useState({
    visible: false,
    mode: "",
    ObservationList: {},
  });
  const [rfiNarrative, setRfiNarrative] = useState(null);
  const EvidenceList = useSelector(
    (state) => state.MasterAssessment.EvidencesList
  );
  const DataAssetsList = useSelector(
    (state) => state.MasterAssessment.DataAssetsList
  );

  const [selectedQuestion, setSelectedQuestion] = useState({});

  const [value, setValue] = React.useState(0);

  const themeDetails = useSelector(getThemeDetails);
  const ArrayOfQuestionIDOfObservations = observationList?.map((el) =>
    el.QUESTION_ID.trim()
  );
  const currentRfi = RfiitemsList?.find(
    (rfi) =>
      rfi.REGULATORS_QUESTION_REF === selectedQuestion?.REGULATORS_QUESTION_REF
  );
  const addActionModal = useModal();
  const { sid: SUBMISSION_ID, id: ASSESSMENT_ID } = useParams();

  const actions = useSelector((state) => state?.MasterAssessment?.ActionsList),
    assessmentDetails = useSelector(
      (state) => state?.MyAssessmnetReducer?.resultSet2
    )?.[0],
    assessmentKpiData = useSelector(
      (state) => state?.MyAssessmnetReducer?.AssessmentKpiData
    )?.[0],
    approversList = useSelector(
      (state) => state?.MyAssessmnetReducer?.ApproversList
    ),
    regulatorsList = useSelector(
      (state) => state?.MyAssessmnetReducer?.RegulatorsList
    );
  const saveAnswerFunction = (data) => {
    setEditAnswer(false);
    SaveAnswerFromRichTextEditor(data);
  };
  const getRfiNarrativeByApi = async () => {
    const toNumber = (num) => {
      if (!num) return null;
      return Number(num);
    };
    const payload = {
      i_RFI_ID: toNumber(currentRfi?.RFI_ID),
      i_QUESTION_ID: toNumber(selectedQuestion?.QUESTION_ID),
      i_SUBMISSION_ID: toNumber(selectedQuestion?.SUBMISSION_ID),
    };

    await storeProcedure
      .getRfiNarrative(payload)
      .then((response) => {
        if (response?.status !== 200) return showAlert(response);
        setRfiNarrative(response?.["#result-set-1"]);
      })
      .catch((error) => {
        showAlert(error);
        console.log("error", error);
      });
  };
  const onBottomTabChange = (e, value) => {
    setBottomSelectedTab(BottomTabsArray[value]);
  };

  const CardBox = (identifier, currentQuestion) => {
    return (
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardContent style={{ padding: "5px", height: "100%" }}>
          <PerfectScrollbar>
            {identifier === 1 && (
              <div style={{ display: "flex", fontSize: "15px" }}>
                <div>
                  {" "}
                  <span>
                    {" "}
                    <strong>
                      {currentQuestion?.REGULATORS_QUESTION_REF}
                    </strong>{" "}
                    -
                  </span>
                  &nbsp;{selRow?.QUESTION_TEXT}
                </div>
              </div>
            )}

            {identifier === 3 && (
              <div style={{ fontSize: "1rem", padding: "1rem" }}>
                {BottomSelectedTab.id === 1 &&
                  TableRenderer(
                    DataAssetsTableHeaders,
                    DataAssetsList,
                    BottomSelectedTab.id
                  )}
                {BottomSelectedTab.id === 2 &&
                  TableRenderer(
                    EvidenceTableHeaders,
                    EvidenceList,
                    BottomSelectedTab.id
                  )}
                {BottomSelectedTab?.id === 3 && (
                  <NarrativeTable rfiNarrativeList={rfiNarrative} />
                )}
                {BottomSelectedTab.id === 0 && (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: "100%",
                      display: "flex",
                      justifyItems: "end",
                    }}
                  >
                    {editAnswer && (
                      <EditAnswer
                        currentQuestion={currentQuestion}
                        currentRfi={currentRfi}
                        onSaveAnswer={saveAnswerFunction}
                        showAlert={showAlert}
                        onClose={modal?.closeModal}
                      />
                    )}
                    {!editAnswer && (
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          paddingLeft: "5px",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
                                ? currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
                                : "",
                          }}
                        ></div>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => setEditAnswer(true)}
                          startIcon={<EditIcon />}
                        >
                          {langConstant.EDIT}
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </div>
            )}
          </PerfectScrollbar>
        </CardContent>
      </Card>
    );
  };

  const UpdateMyAssessmentQuestionArray = (data) => {
    dispatch({ type: "SET_MY_ASSESSMENT_QUESTIONS", payload: data });
  };

  const showAlert = (error, type = "error") => {
    dispatch(
      alertActions?.[type](
        error?.data?.message?.map((item, index) => {
          return <span key={index}>{item}</span>;
        }) ?? error?.toString()
      )
    );
  };

  const TableRenderer = (Headers, Rowdata, id) => {
    const metaDataRender = (MetaData) => {
      let accuracy;
      let suitability;
      let value = MetaData;

      if (MetaData === null) {
        accuracy = "No Data";
        suitability = "No Data";
      } else {
        if (value && value !== "MQ==") {
          let tempData = window.atob(value);
          tempData = tempData.slice(1, -1);
          tempData = tempData.replaceAll("], ", "]&");
          const convertedData = new URLSearchParams(tempData);
          value = JSON.parse(convertedData.get("score"));
        } else if (value === "MQ==") {
          value = [];
        }

        accuracy = value?.length ? value[0] : "No Data";
        suitability = value?.length ? value[1] : "No Data";
      }

      return (
        <>
          <TableCell style={{ padding: "5px" }}>{accuracy}</TableCell>
          <TableCell style={{ padding: "5px" }}>{suitability}</TableCell>
        </>
      );
    };

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {Headers.map((header) => (
                <TableCell
                  key={header}
                  style={{
                    textTransform: "uppercase",
                    padding: "5px",
                    backgroundColor: "white",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rowdata.length > 0 &&
              Rowdata.map((el, i) => {
                switch (id) {
                  case 1:
                    return (
                      <TableRow
                        key={`${el.SOURCE_DATA_ID}${i}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ padding: "5px" }}>
                          {el.SOURCE_SYSTEM_NAME}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el.DATA_ASSET_ATTRIBUTE_NAME}
                        </TableCell>
                        <TableCell style={{ padding: "5px" }}>
                          {el.SOURCE_DATA_POINT_VALUE}
                        </TableCell>
                        <TableCell style={{ padding: "5px" }}>
                          {el.FULLNAME.trim()
                            ? el.FULLNAME.trim()
                            : langConstant.NAME_NOT_PROVIDED}
                        </TableCell>
                      </TableRow>
                    );

                  case 2:
                    return (
                      <TableRow
                        key={`${el.EVIDENCE_LOG_ID}${i}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el.EVIDENCE_SHORTNAME}
                        </TableCell>
                        {metaDataRender(el.METADATA)}
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el?.DESCRIPTION}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {`${el?.FILE_NAME} ${el?.FILE_FORMAT}`}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {`${el.FIRST_NAME} ${el.LAST_NAME}`}
                        </TableCell>
                      </TableRow>
                    );

                  default:
                    break;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const SaveAnswerFromRichTextEditor = (answer) => {
    let currentQuestion = {
      ...selectedQuestion,
      SUBMISSION_ID: selRow.SUBMISSION_ID,
    };
    currentQuestion.CURRENT_ANSWER_SELECTED_RESPONSE = answer;

    const RichTextCallback = () => {
      setSelectedQuestion({
        ...selectedQuestion,
        CURRENT_ANSWER_SELECTED_RESPONSE: answer,
      });
      setPlanActionsDataLength((length) => length - 1);
      setKpiData((prev) => ({
        ...prev,
        answersToPrepare: prev.answersToPrepare - 1,
      }));

      // const data = [...QuestionList].map((que) => {
      //   if (
      //     `${que.REGULATION_REFERENCE}` === `${selectedQuestion.QUESTION_ID}`
      //   ) {
      //     return { ...currentQuestion };
      //   }

      //   return que;
      // });

      // UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(saveSingleQuestion(currentQuestion, RichTextCallback));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await planActionsServices.GetSubmissionQuestionDetails(
          selRow.REGULATION_REFERENCE,
          selRow.SUBMISSION_ID
        );
        setSelectedQuestion({
          ...selRow,
          QUESTION_ID: selRow?.REGULATION_REFERENCE,
        });
        if (response?.status !== 200) throw new Error("Unable to fetch data");
        else if (response && response?.["#result-set-1"][0]) {
          const tempSelectedQuestion = response?.["#result-set-1"][0];
          setSelectedQuestion(tempSelectedQuestion);
        }
      } catch (error) {
        console.error("error", error);
      }
    })();

    const index = _.findIndex(filteredQuestionList, (e) => {
      return e.REGULATION_REFERENCE == selRow.QUESTION_ID;
    });

    if (filteredQuestionList.length > 0) {
      setSelectedQuestion(filteredQuestionList[index > -1 ? index : page]);

      dispatch(
        getEvidenceForMasterAssessment(
          selRow.REGULATION_REFERENCE,
          selRow.SUBMISSION_ID
        )
      );
      dispatch(
        getDataAssestsForMasterAssessment(
          selRow.REGULATION_REFERENCE,
          customerID
        )
      );
      selRow.REGULATION_REFERENCE &&
        currentRfi?.RFI_ID &&
        getRfiNarrativeByApi();
    }
  }, [page, filteredQuestionList, currentRfi]);

  // useEffect(() => {
  //   setfilteredQuestionList(QuestionList);
  // }, [QuestionList]);

  useEffect(() => {
    let tempArr = [
      { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },

      {
        name: "DATA ASSETS",
        count: DataAssetsList?.length,
        isCount: false,
        id: 1,
      },
      {
        name: "EVIDENCES",
        count: EvidenceList?.length,
        isCount: false,
        id: 2,
      },
      {
        name: "Responses and narratives",
        count: rfiNarrative?.length,
        isCount: false,
        id: 3,
      },
    ];
    setBottomTabs(tempArr);
  }, [EvidenceList, DataAssetsList, rfiNarrative]);

  return (
    <DialogBox
      fullWidth
      maxWidth="md"
      title={selectedQuestion?.QUESTION_ID}
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableEnforceFocus
      disableAutoFocus
      PaperProps={{
        style: {
          height: "80vh",
          zIndex: 1,
        },
      }}
    >
      <Grid
        item
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <IconButton aria-label="close" onClick={modal?.closeModal}>
          <CloseIcon />
        </IconButton>
      </Grid>

      <Box
        style={{
          overflowY: "auto",
          // maxHeight: "65vh",
          // padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "18vh",
          }}
        >
          <Box style={{ width: "99%" }}>{CardBox(1, selectedQuestion)}</Box>
        </Box>

        <AssessmentTabs
          selectedTab={BottomSelectedTab}
          onChange={onBottomTabChange}
          tabs={BottomTabs}
          bottomTabs
          currentQuestion={selectedQuestion}
        />

        <Box style={{ height: "53vh", overflow: "auto" }}>
          <Box style={{ width: "100%", height: "100%" }}>
            {CardBox(3, selectedQuestion)}
          </Box>
        </Box>

        {ObservationDialog.visible &&
          (identifier === "audit" || identifier === "myassessment") && (
            <ObservationDialogBox
              Controls={selectedQuestion?.CISO_CONTROL_NAME}
              submissionID={selectedQuestion?.SUBMISSION_ID}
              questionID={selectedQuestion?.QUESTION_ID}
              questionText={selectedQuestion?.QUESTION_TEXT}
              miscData={ObservationDialog}
              setObservationDialog={setObservationDialog}
              TotalObservationList={observationList}
            />
          )}
      </Box>
    </DialogBox>
  );
};

PrepareSummaryAnswer.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default PrepareSummaryAnswer;
