import React, { useState } from "react";
import { Box } from "@material-ui/core";
import langConstant from "_lang";
import PropTypes from "prop-types";
import DialogBox from "_components/DialogBox";
import RfiFilterTable from "_components/RfiFilterTable";
import AttachEvidence from "_components/AttachEvidence/index";
import EvidenceLocker from "_components/EvidenceLocker/index";
import { useSelector } from "react-redux";
import moment from "moment";

const styles = {
  evidenceLocker: {
    marginLeft: "10px",
    color: "#FF1030",
  },
  infoTextLabel: {
    marginRight: "10px",
    color: "#959595",
  },
};

const ToolBar = ({ selectedQuestion, isFormDisabled, submissionID }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const rfiRows = useSelector(
    (state) => state.MasterAssessment.RfiFilterDetails
  );

  const columns = [
    {
      field: "REGULATORS_QUESTION_REF",
      headerName: "QUESTION REF.",
      minWidth: 135,
    },
    { field: "Status_label", headerName: "STATUS" },
    {
      field: "RFI_DESCRIPTION",
      headerName: "COVER NOTE",
      minWidth: 350,
    },
    {
      field: "RFI_DUE_DATE",
      headerName: "DUE DATE",
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
      // format: (value) => (value ? value.substring(0, 10) : ""),
      minWidth: 120,
    },
  ];

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <Box display="flex" alignItems="center" style={{ height: "18px" }}>
      <DialogBox
        maxWidth={"lg"}
        title={langConstant.RFI_DETAILS}
        open={dialogOpen}
        handleClose={toggleDialog}
      >
        <RfiFilterTable
          rows={rfiRows}
          columns={columns}
          className="data-table"
        />
      </DialogBox>
      <AttachEvidence
        questionId={selectedQuestion ? selectedQuestion.QUESTION_ID : null}
        submissionId={submissionID ? submissionID : null}
        isDisabled={isFormDisabled}
      />
      <EvidenceLocker
        questionId={selectedQuestion ? selectedQuestion.QUESTION_ID : null}
        submissionId={submissionID ? submissionID : null}
      />

      <span style={styles.evidenceLocker}>&#42;</span>
      <span style={styles.infoTextLabel}>
        {langConstant.EVIDENCE_ATTACHMENT_MANDATORY}
      </span>
    </Box>
  );
};

ToolBar.propTypes = {
  selectedQuestion: PropTypes.any,
  isFormDisabled: PropTypes.bool,
  submissionID: PropTypes.number,
};

export default ToolBar;
