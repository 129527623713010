import { Grid } from "@mui/material";
import useStyles from "./styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs, Badge } from "@mui/material";
import sessionService from "_services/session.service";
import ClientSetup from "./components/clientSetup";
import PrepareDocument from "./components/prepareDocument";
import langConstant from "_lang";

const GettingStarted = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerID = sessionService.getCustomerId();
  const [tabValue, setTabValue] = useState(0);
  const [queCount, setQueCount] = useState(0);

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid className={classes.backgroundContainer}>
      <Grid>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          TabIndicatorProps={{ style: { padding: 2 } }}
          orientation="horizontal"
          variant="scrollable"
        >
          <Tab
            aria-orientation="horizontal"
            label={
              <Badge
                badgeContent={queCount}
                max={9999}
                sx={{
                  "& .MuiBadge-badge": {
                    transform: "scale(1) translate(16px, -5px)",
                    fontSize: "0.75rem",
                    minWidth: "20px",
                    height: "20px",
                    top: "-10px",
                    background: "#e93434",
                    color: "#fff",
                  },
                }}
              >
                {langConstant.CLIENT_SETUP}
              </Badge>
            }
          />
          <Tab
            aria-orientation="horizontal"
            label={langConstant.PREPARE_DOCUMENT}
            sx={{ pointerEvents: "none", opacity: 0.5 }}
          />
        </Tabs>
      </Grid>
      <Grid>
        {tabValue === 0 && (
          <ClientSetup customerID={customerID} setQueCount={setQueCount} />
        )}

        {tabValue === 1 && <PrepareDocument customerID={customerID} />}
      </Grid>
    </Grid>
  );
};

export default GettingStarted;
