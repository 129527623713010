import apiService from "_services/api.service";

const getTeamList = (customerId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetTeamList", {
      i_CUSTOMER_ID: customerId,
      i_CUSTOMER_ID2: customerId,
    })
    .then(
      (response) => {
        if (response && response.data["#result-set-1"]) {
          return {
            isSuccess: true,
            data: response.data["#result-set-1"],
          };
        } else {
          return {
            isSuccess: false,
            message: "Country list not found",
          };
        }
      },
      (error) => {
        return {
          isSuccess: false,
          message: error.toString(),
        };
      }
    );
};

const getTeamListForAdmin = (customerId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetTeamListForAdmin", {
      i_CUSTOMER_ID: customerId,
      i_CUSTOMER_ID2: customerId,
    })
    .then(
      (response) => {
        if (response && response.data["#result-set-1"]) {
          return {
            isSuccess: true,
            data: response.data["#result-set-1"],
          };
        } else {
          return {
            isSuccess: false,
            message: "Country list not found",
          };
        }
      },
      (error) => {
        return {
          isSuccess: false,
          message: error.toString(),
        };
      }
    );
};

const getRoleDetails = (customerId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRoleDetails", {
      i_CUSTOMER_ID: customerId,
    })
    .then(
      (response) => {
        if (response && response.data["#result-set-1"]) {
          return {
            isSuccess: true,
            data: response.data["#result-set-1"],
          };
        } else {
          return {
            isSuccess: false,
            message: "User Role list not found",
          };
        }
      },
      (error) => {
        return {
          isSuccess: false,
          message: error.toString(),
        };
      }
    );
};

const getEntitlementsDetails = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetEntitlementsV1")
    .then(
      (response) => {
        if (response && response.data["#result-set-1"]) {
          return {
            isSuccess: true,
            data: response.data["#result-set-1"],
          };
        } else {
          return {
            isSuccess: false,
            message: "Entitlement list not found",
          };
        }
      },
      (error) => {
        return {
          isSuccess: false,
          message: error.toString(),
        };
      }
    );
};

export default {
  getTeamList,
  getRoleDetails,
  getEntitlementsDetails,
  getTeamListForAdmin,
};
