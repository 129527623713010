import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import sessionService from "_services/session.service";
import React from "react";

export const getMlComparisonOutput = (uniqueID, setComparisonMetaData) => {
  return (dispatch) => {
    loadingActions.start();
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetMlComparisonOutput", {
        i_QUEUE_UNIQUE_ID: uniqueID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response["#result-set-1"] &&
            response["#result-set-1"].length > 0
          ) {
            setComparisonMetaData(response["#result-set-1"][0]);
          }
        },
        (error) => {
          if (error?.response?.data?.message) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        loadingActions.end();
      });
  };
};

const getPolicyExtractionDetailsByPage = (data) => {
  return apiService
    .apiCall("dbapi")
    .send(`/v1/api/proc/DRegulativDB/GetPolicyExtractionDetailsByPage`, {
      i_POLICY_ID: data.policyId,
      i_PAGE_NUMBER: data.pageNo,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getGapConetent = (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${sessionService.getItem("jwtToken")}`,
      "Content-Type": "application/json",
    },
  };
  return apiService
    .apiCall("dbapi")
    .send(
      `/v1/api/proc/DRegulativDB/GetPolicyExtractionDetailsByPage`,
      {
        template_requirements: "template_requirements",
        recommendations: "recommendations",
        base_file: "Data Security Policy_Extracted_11-33-00.csv",
        input_subURL_base: "",
        input_global_URL: "1004/test/",
        storageType: "aws",
        preProcessingIP: "68.154.41.31",
        preProcessingURL: "windows-storage",
      },
      config
    )
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getGapAnalysis = (
  customerID,
  uniqueID,
  setComparisonMetaData,
  setPolicyMetaData,
  setpolicyMetaDataForDrawer
) => {
  return (dispatch) => {
    loadingActions.start();
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetGapAnalysis", {
        i_CUSTOMER_ID: customerID,
        i_QUEUE_ID: uniqueID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (
            response["#result-set-1"] &&
            response["#result-set-1"].length > 0
          ) {
            response["#result-set-1"].sort(
              (a, b) =>
                a.SECTION_ORDER - b.SECTION_ORDER ||
                a.SENTENCE_INDEX - b.SENTENCE_INDEX
            );
            setComparisonMetaData(response["#result-set-1"]);
            setpolicyMetaDataForDrawer(response["#result-set-1"]);
          }
          if (response["#result-set-2"] && response["#result-set-3"])
            setPolicyMetaData({
              BASE_FILE: response["#result-set-2"],
              TARGET_FILE: response["#result-set-3"],
            });
        },
        (error) => {
          if (error?.response?.data?.message) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        loadingActions.end();
      });
  };
};

const putGapAnalysisDecision = (data) => {
  return apiService
    .apiCall("dbapi")
    .send(`/v1/api/proc/DRegulativDB/PutGapAnalysisDecision`, data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  getMlComparisonOutput,
  getPolicyExtractionDetailsByPage,
  getGapConetent,
  getGapAnalysis,
  putGapAnalysisDecision,
};
