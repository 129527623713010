import React from "react";
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  Box,
} from "@material-ui/core";
import langConstant from "_lang";
import PropTypes from "prop-types";
import DialogBox from "_components/DialogBox";
import { getScoreColorClass, getTrustScoreColorClass } from "_helpers/utils";

const styles = makeStyles((theme) => ({
  td: {
    textAlign: "center",
  },
  highlightColumn: {
    fontSize: "1.250rem",
    fontWeight: 800,
  },
}));

const TrustScoreDialogBox = ({
  DataQualityProfile,
  openPopup,
  setOpenPopup,
}) => {
  const classes = styles();
  const columns = [
    {
      field: "DATA_ASSET_ATTRIBUTE_NAME",
      headerName: "METADATA",
      minWidth: 135,
      textAlign: "left",
    },
    { field: "DATA_ACCURACY_SCORE", headerName: "ACCURACY" },
    { field: "DATA_COMPLETENESS_SCORE", headerName: "COMPLETENESS" },
    { field: "DATA_CONSISTENCY_SCORE", headerName: "CONSISTENCY" },
    {
      field: "TRUST_SCORE",
      headerName: "TRUST SCORE",
      className: classes.highlightColumn,
    },
  ];

  return (
    <DialogBox
      title={langConstant.TRUST_SCORE}
      open={openPopup}
      handleClose={() => {
        setOpenPopup(false);
      }}
      maxWidth={"md"}
    >
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item>
            <Box className={" colorTag highScoreBgColor"}></Box>{" "}
            {langConstant.HIGH}
          </Grid>
          <Grid item>
            <Box ml={2} className={"colorTag mediumScoreBgColor"}></Box>{" "}
            {langConstant.MEDIUM}
          </Grid>
          <Grid item>
            <Box ml={2} className={"colorTag lowScoreBgColor"}></Box>{" "}
            {langConstant.LOW}
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table
          aria-label={langConstant.DATAPOINTS_LIST}
          className="data-table "
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                return (
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.td}
                    key={"column_" + index + column.field}
                    style={{
                      minWidth: column.minWidth,
                      textAlign: column.textAlign,
                    }}
                  >
                    {column.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {DataQualityProfile &&
              DataQualityProfile.length > 0 &&
              DataQualityProfile.map((row, index) => (
                <TableRow key={index + row[columns[0].field]}>
                  {columns.map((column, index) => {
                    const value = row[column.field];
                    return (
                      <TableCell
                        component="td"
                        scope="row"
                        key={"column_" + index + row[columns[0].field]}
                        style={{
                          textAlign: column.textAlign,
                        }}
                        className={
                          classes.td +
                          " " +
                          column.className +
                          " " +
                          (column.field == "TRUST_SCORE"
                            ? getTrustScoreColorClass(value)
                            : null)
                        }
                      >
                        {[
                          "DATA_ACCURACY_SCORE",
                          "DATA_COMPLETENESS_SCORE",
                          "DATA_CONSISTENCY_SCORE",
                        ].indexOf(column.field) > -1 && (
                          <div
                            className={"colorTag " + getScoreColorClass(value)}
                          ></div>
                        )}
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogBox>
  );
};

TrustScoreDialogBox.propTypes = {
  DataQualityProfile: PropTypes.array,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
};

export default TrustScoreDialogBox;
