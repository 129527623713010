import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorComponent from "./components/editorComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchingComponent from "./components/searchingcomponent";
import PDFViewer from "./components/pdfViewer";

const Docular = memo(
  ({
    metaData,
    num,
    cmpID,
    setComparisonMetaData,
    policyMetaDataForDrawer,
  }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
      if (metaData) {
        const updatedData = metaData.map((ele) => ({
          ...ele,
          modified_sentence_copy: ele.MODIFIED_TEXT || ele.ORIGINAL_TEXT || "",
        }));

        setData(updatedData);

        const initialLoadCount = Math.min(num * 2, updatedData.length);
        setItems(updatedData.slice(0, initialLoadCount));
        setHasMore(updatedData.length > initialLoadCount);
      }
    }, [metaData, num]);
    const loadMoreData = () => {
      if (items.length >= data.length) {
        setHasMore(false);
        return;
      }

      const nextBatch = data.slice(items.length, items.length + 5);
      setItems((prev) => [...prev, ...nextBatch]);
      setHasMore(items.length + 5 < data.length);
    };

    useEffect(() => {
      loadMoreData();
    }, [num]);

    useEffect(() => {
      if (metaData) {
        const updatedData = metaData.map((ele) => {
          if (ele.MODIFIED_TEXT) {
            return {
              ...ele,
              modified_sentence_copy: ele.MODIFIED_TEXT,
            };
          } else if (ele.GAP_TYPE == null) {
            return {
              ...ele,
              modified_sentence_copy: ele.ORIGINAL_TEXT,
            };
          } else {
            return {
              ...ele,
              modified_sentence_copy: "",
            };
          }
        });

        setData(updatedData);
        setItems(updatedData.slice(0, num));
      }
    }, [metaData]);

    return (
      <Grid>
        <Box display={"flex"} marginBottom={1} gap={1}>
          <SearchingComponent
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            data={data}
            setData={setData}
            cmpID={cmpID}
            policyMetaDataForDrawer={policyMetaDataForDrawer}
          />
          <Box>{data.length > 0 && <PDFViewer data={data} />}</Box>
        </Box>
        <Grid
          item
          id="scrollableDiv"
          style={{
            height: "75dvh",
            overflowY: "auto",
            minHeight: "150px ",
          }}
        >
          <InfiniteScroll
            dataLength={items.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={<p>No more sections to load.</p>}
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.8}
          >
            <EditorComponent
              setComparisonMetaData={setComparisonMetaData}
              metaData={items}
              searchTerm={searchTerm}
              cmpID={cmpID}
            />
          </InfiniteScroll>
        </Grid>
      </Grid>
    );
  }
);
Docular.displayName = "Docular";

Docular.propTypes = {
  metaData: PropTypes.array,
  num: PropTypes.number,
  cmpID: PropTypes.number,
  setComparisonMetaData: PropTypes.func,
  policyMetaDataForDrawer: PropTypes.any,
};
export default Docular;
