import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import langConstant from "_lang";
import { setPolicyData, setErrorMessages } from "./actions";
import axios from "axios";
import sessionService from "_services/session.service";

const getAvailablePolicies = (userID) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send(`/v1/api/proc/DRegulativDB/GetAvailablePolicies`, {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            let policiesData = response["#result-set-1"].sort((a, b) =>
              a.POLICY_NAME.localeCompare(b.POLICY_NAME)
            );
            dispatch(setPolicyData(policiesData));
          } else {
            dispatch(alertActions.error(langConstant.POLICY_LIST_NOT_FOUND));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getAvailablePolicyComparisons = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetAvailablePolicyComparisons", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const uploadFile = async (formData) => {
  return apiService
    .apiCall("policymanagement")
    .send("/api/v1/policyfileUpload", formData)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const executeComparison = (data, userID) => {
  return apiService
    .apiCall("policymanagement")
    .send("/api/v1/executeComparison", {
      baseFileId: data.basePolicyDetail.id,
      baseFileName: data.basePolicyDetail.name,
      comparisonName: data.comparison_name,
      comparisonType: data.comparison_type,
      distanceCalculator: data.distance_calculator,
      endPage: 10,
      model: "Model1",
      startPage: 0,
      targetFileId: data.targetPolicyDetail.id,
      targetFileName: data.targetPolicyDetail.name,
      thresholdSetting: data.threshold_settings,
      userId: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyComparisonDetails = (policyComparisonId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyComparisonDetails", {
      i_POLICYCOMPARISONID: policyComparisonId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyComparisonRecipeDetails = (policyComparisonId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyComparisonRecipeDetails", {
      i_POLICYCOMPARISONID: policyComparisonId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyComparisonBySentence = (policyId, sentenceId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyComparisonBySentence", {
      i_POLICYCOMPARISONID: policyId,
      i_BASE_DOCUMENT: sentenceId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicySearchDetails = (policyId, text, type) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicySearchDetails", {
      i_POLICY_ID: policyId,
      i_SEARCH_TEXT: text,
      i_BaseORtarget_flag: type,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyComparisonByComparisonId = (comparisonId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyComparisonByComparisonId", {
      i_POLICYCOMPARISONID: comparisonId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyComparisonByHeader = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyComparisonByHeader", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getActionsList = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetActionsList", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const executePolicyComparison = (data) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/compare", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putPolicyComparisonDecision = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutPolicyComparisonDecision", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyComparisonDecisions = (comparisonID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyComparisonDecisions", {
      i_POLICYCOMPARISONID: comparisonID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putAutoPolicyComparisonDecision = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutAutoPolicyComparisonDecision", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getDocumentControlInformation = (
  userID,
  isAllAdded,
  setDocumentOwners = "",
  setDocumentTypes = "",
  setDocumentLanguages = ""
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDocumentControlInformation", {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (isAllAdded) {
            setDocumentOwners &&
              setDocumentOwners([
                { value: "all", name: "All" },
                ...response["#result-set-1"].map((item) => {
                  return { value: item.ORG_UNIT_ID, name: item.ORG_UNIT_OWNER };
                }),
              ]);
            setDocumentTypes &&
              setDocumentTypes([
                { value: "all", name: "All" },
                ...response["#result-set-2"].map((item) => {
                  return {
                    value: item.ID,
                    name: item.DOCUMENT_CLASSIFICATION_NAME,
                  };
                }),
              ]);
            setDocumentLanguages &&
              setDocumentLanguages([
                { value: "all", name: "All" },
                ...response["#result-set-3"].map((item) => {
                  return {
                    value: item.LANGUAGE_CODE,
                    name: item.NATIONAL_LANGUAGE,
                  };
                }),
              ]);
          } else {
            setDocumentOwners &&
              setDocumentOwners([
                ...response["#result-set-1"].map((item) => {
                  return { value: item.ORG_UNIT_ID, name: item.ORG_UNIT_OWNER };
                }),
              ]);
            setDocumentTypes &&
              setDocumentTypes([
                ...response["#result-set-2"].map((item) => {
                  return {
                    value: item.ID,
                    name: item.DOCUMENT_CLASSIFICATION_NAME,
                  };
                }),
              ]);
            setDocumentLanguages &&
              setDocumentLanguages([
                ...response["#result-set-3"].map((item) => {
                  return {
                    value: item.LANGUAGE_CODE,
                    name: item.NATIONAL_LANGUAGE,
                  };
                }),
              ]);
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updatePolicyArchiveStatus = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdatePolicyArchiveStatus", {
      i_USER_ID: data.userID,
      i_POLICY_ID: data.policyID,
      i_ARCHIVE_STATUS: data.status,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updatePolicyComparisonArchiveStatus = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdatePolicyComparisonArchiveStatus", {
      i_USER_ID: data.userID,
      i_POLICYCOMPARISONID: data.policyComparisonID,
      i_ARCHIVE_STATUS: data.status,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateComparisonWorkflowStatus = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateComparisonWorkflowStatus", {
      i_USER_ID: data.userID,
      i_POLICYCOMPARISONID: data.id,
      i_Status_Name: data.status,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getComparisonScore = (id) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetComparisonScore", {
      i_POLICYCOMPARISONID: id,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getAvailableLanguages = (id) => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetAvailableLanguages")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getAllUserErrorMessages = (id) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send(`/v1/api/proc/DRegulativDB/GetAllUserErrorMessages`, {
        i_customer_id: id,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (response && response["#result-set-1"]) {
            dispatch(setErrorMessages(response["#result-set-1"]));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const putUserErrorMessage = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutUserErrorMessage", {
      customer_id: data["CUSTOMER_ID"],
      url: data["URL"],
      technical_error_message: data["TECHNICAL_ERROR_MESSAGE"],
      active: 1,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putErrorLog = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutErrorLog", {
      source_of_error: data["URL"],
      error_message: data["TECHNICAL_ERROR_MESSAGE"],
      status: 1,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getUserProgress = (policyId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetUserProgress", {
      i_id: policyId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putPolicy = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutPolicy", {
      i_POLICY_NAME: data.document_name,
      i_POLICY_META_DATA: JSON.stringify(data.policy_metadata),
      i_POLICY_FILE_NAME: data.document.name,
      i_POLICY_FILE_FORMAT: data.document_extension,
      i_STORAGE_FOLDER_URL:
        data.userID.toString() + "/Policy_Management/Extraction/",
      i_DOCUMENT_OWNER: data.document_owner
        ? JSON.stringify(data.document_owner)
        : data.document_owner,
      i_DOCUMENT_TAGS: data.document_tags,
      i_DOCUMENT_TYPE: data.document_type.length
        ? JSON.stringify(data.document_type)
        : "",
      i_USER_ID: data.userID,
      i_START_DATE: data.currentDateTime,
      i_Language_Code: data.document_language["value"],
      i_END_DATE: "",
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyAdvanceSearch = (searchString, userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyAdvanceSearch", {
      i_USER_ID: userID,
      i_POLICY_SEARCH_STRING: searchString,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getAllEvidencesForCustomer = (customerID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetAllEvidencesForCustomer", {
      i_CUSTOMER_ID: customerID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getPolicyEvidenceMapping = (customerID, policyID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyEvidenceMapping", {
      i_CUSTOMER_ID: customerID,
      i_POLICY_ID: policyID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updatePolicyEvidenceMapping = (customerID, policyID, evidenceTypeID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdatePolicyEvidenceMapping", {
      i_CUSTOMER_ID: customerID,
      i_POLICY_ID: policyID,
      i_EVIDENCE_TYPE_ID: evidenceTypeID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updatePolicyMetaData = (data) => {
  const policyMetaData = {
    aliasName: data.metaData.document_name,
    startPage: data.metaData.pages_extraction_start,
    endPage: data.metaData.pages_extraction_end,
    document_language: data.metaData.document_language,
    document_type: data.metaData.document_type,
    document_owner: data.metaData.document_owner,
    document_tags: data.metaData.document_tags.trim(),
    document_extension: data.metaData.document_extension,
    upload_only: data.metaData.upload_only,
    is_pre_proccesing: data.metaData.is_pre_proccesing,
  };

  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdatePolicyMetaData", {
      i_POLICY_ID: data.policyId,
      i_POLICY_META_DATA: JSON.stringify(policyMetaData),
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getNotes = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetNotes", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const getPolicyChunkData = (PolicyId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetPolicyChunkData", {
      i_POLICY_ID: PolicyId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

const getActions = ({
  i_COMPARISON_ID = null,
  i_SUBMISSION_ID = null,
  i_REGULATION_ID = null,
  i_POLICY_ID = null,
  i_USER_ID = null,
  i_QUESTION_ID = null,
}) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetActions", {
      i_COMPARISON_ID,
      i_SUBMISSION_ID,
      i_REGULATION_ID,
      i_POLICY_ID,
      i_USER_ID,
      i_QUESTION_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        return error?.response || "Something went wrong";
      }
    );
};

export default {
  getAvailablePolicies,
  uploadFile,
  getAvailablePolicyComparisons,
  executeComparison,
  getPolicyComparisonDetails,
  getPolicyComparisonBySentence,
  getPolicySearchDetails,
  executePolicyComparison,
  getPolicyComparisonByComparisonId,
  getPolicyComparisonByHeader,
  getActionsList,
  putPolicyComparisonDecision,
  getPolicyComparisonDecisions,
  putAutoPolicyComparisonDecision,
  getDocumentControlInformation,
  updatePolicyArchiveStatus,
  updatePolicyComparisonArchiveStatus,
  updateComparisonWorkflowStatus,
  getComparisonScore,
  getAvailableLanguages,
  getAllUserErrorMessages,
  putUserErrorMessage,
  putErrorLog,
  getUserProgress,
  putPolicy,
  getPolicyAdvanceSearch,
  getPolicyComparisonRecipeDetails,
  updatePolicyMetaData,
  getNotes,
  getActions,
  getAllEvidencesForCustomer,
  getPolicyEvidenceMapping,
  updatePolicyEvidenceMapping,
  getPolicyChunkData,
};
