import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { styles } from "../styles";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Tune } from "@mui/icons-material";
import { GetEvidenceStatusForSubmission } from "../services";
import propTypes from "prop-types";
import { alertActions } from "_actions";
import EvidenceCollected from "./Graphs/EvidenceCollected";
import EvidenceCount from "./Graphs/EvidenceCount";
import EvidenceLoaded from "./Graphs/EvidenceLoaded";
import NoDataCard from "./Graphs/NoDataCard";
import { EvidenceKpis } from "./CenterKpiView";

const graphTypes = [
  "Evidences KPI's",
  "Evidences Collected by Person/Team",
  "Evidences Loaded over Defined Period",
];

export const GraphsContainer = ({ SUBMISSION_ID }) => {
  const dispatch = useDispatch();
  const themeDetails = useSelector(getThemeDetails);
  const [currentGraphIndex, setCurrentGraphIndex] = useState(0);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [graphData, setGraphData] = useState();
  const [evidenceKpiData, setEvidenceKpiData] = useState();
  const isFilterOpen = Boolean(filterAnchorEl),
    currentGraphData = graphData?.[`#result-set-${currentGraphIndex + 1}`];

  const onFilterToggle = (e) => {
    setFilterAnchorEl(e?.currentTarget || null);
  };

  const onGraphTypeChange = (item, index) => {
    if (index !== currentGraphIndex) setCurrentGraphIndex(index);
    onFilterToggle();
  };

  const showAlert = (message) =>
    dispatch(alertActions?.error(message?.toString()));

  const getEvidenceGraphData = async () => {
    if (isNaN(SUBMISSION_ID)) return;
    try {
      const response = await GetEvidenceStatusForSubmission(SUBMISSION_ID);
      if (response?.status !== 200) throw new Error("Unable to fetch data");
      setGraphData(response);
      generateEvidenceKpiData(response);
    } catch (error) {
      console.error("error", error);
      showAlert(error);
    }
  };

  const generateEvidenceKpiData = (response) => {
    if (!response) return;
    let approved = 0,
      recieved = 0;
    response?.["#result-set-1"]?.forEach((item) => {
      recieved += item?.UPLOADED || 0;
      approved += item?.APPROVED || 0;
    });

    setEvidenceKpiData({
      approved,
      recieved,
      requested: response?.["#result-set-4"]?.[0]?.MAPPING_COUNT,
    });
  };

  useEffect(() => {
    getEvidenceGraphData();
  }, []);

  return (
    <Box
      display="flex"
      flex={0.4}
      p={1}
      flexDirection="column"
      overflowY="auto"
      borderRadius="4px"
      gap={1}
      bgcolor="white"
      alignSelf="stretch"
    >
      <Menu
        anchorEl={filterAnchorEl}
        open={isFilterOpen}
        onClose={() => onFilterToggle()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {graphTypes?.map((item, index) => {
          return (
            <MenuItem
              onClick={() => onGraphTypeChange(item, index)}
              key={`graph-type-${index}`}
              sx={{ py: 0.25 }}
              disabled={Boolean(
                currentGraphIndex !== index &&
                  !graphData?.[`#result-set-${index + 1}`]?.length
              )}
            >
              <Typography
                sx={{
                  display: "inline-flex",
                  fontWeight: "bold",
                  fontSize: "12px",
                  flex: 1,
                }}
              >
                {item}
              </Typography>
              <Checkbox
                id={`checkbox-${item}`}
                checked={Boolean(index === currentGraphIndex)}
                sx={{
                  "&.Mui-checked": {
                    color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                  },
                  "& .MuiSvgIcon-root": { fontSize: 20 },
                }}
              />
            </MenuItem>
          );
        })}
      </Menu>

      <Typography fontWeight="500" variant="subtitle1">
        {graphTypes[currentGraphIndex]} &nbsp;
        <Tooltip placement="right" title="Apply Filters">
          <IconButton onClick={onFilterToggle}>
            <Tune />
          </IconButton>
        </Tooltip>
      </Typography>
      <EvidenceKpis {...evidenceKpiData} />

      <Box
        sx={{
          flex: "auto",
          display: "flex",
          gap: 2,
        }}
      >
        {currentGraphData?.length ? (
          <>
            {currentGraphIndex === 0 ? (
              <EvidenceCount data={currentGraphData} />
            ) : currentGraphIndex === 1 ? (
              <EvidenceCollected data={currentGraphData} />
            ) : (
              <EvidenceLoaded data={currentGraphData} />
            )}
          </>
        ) : (
          <NoDataCard />
        )}
      </Box>
    </Box>
  );
};

GraphsContainer.propTypes = {
  SUBMISSION_ID: propTypes.oneOfType([propTypes.number, propTypes.string]),
};
