import {
  Typography,
  makeStyles,
  Button,
  TextField,
  Box,
  FormControl,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import Select from "react-select";
import { InputLabel } from "@mui/material";
import sessionService from "_services/session.service";
import questionServices from "./../services";
import { alertActions } from "_actions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
  widhtforselect: {
    width: "560px !important",
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
}));

const RiskManagement = ({ questionID, regulationID }) => {
  const classes = useStyles();
  const [businessUnit, setBusinessUnit] = useState([]);
  const [processOptions, setProcessOptions] = useState([
    { value: "low", label: "Low" },
  ]);
  const [applicationOptions, setApplication] = useState([]);
  const [riskMetricsImportantOptions, setRiskMetricsImportantOptions] =
    useState([]);
  const [riskMetricsImpactOptions, setRiskMetricsImpactOptions] = useState([]);
  const [riskMetricsProbablityOptions, setRiskMetricsProbablityOptions] =
    useState([]);

  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();

  const handleError = (response) => {
    if (response?.data?.message) {
      dispatch(
        alertActions.error(
          response.data.message.map((item, index) => (
            <div key={index}>{item}</div>
          ))
        )
      );
    } else {
      dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
    }
  };
  let initialValues = {
    description: "",
    businessUnit: [],
    process: [],
    application: [],
    importance: null,
    impact: null,
    probability: null,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [processResponse, businessUnitResponse, applicationResponse] =
          await Promise.all([
            questionServices.getCustomerProcesses({ questionID, customerID }),
            questionServices.getBussinessUnit({ questionID, customerID }),
            questionServices.getCustomerApplications({
              questionID,
              customerID,
            }),
          ]);
        if (
          processResponse?.status === 200 &&
          processResponse["#result-set-1"]
        ) {
          formik.setFieldValue(
            "process",
            processResponse["#result-set-1"].map((ele) => ({
              label: ele.PROCESS_NAME,
              value: ele.RISK_ID,
            }))
          );
        }
        if (
          processResponse?.status === 200 &&
          processResponse["#result-set-2"]
        ) {
          setProcessOptions(
            processResponse["#result-set-2"].map((ele) => ({
              label: ele.PROCESS_NAME,
              value: ele.ORG_PROCESS_ID,
            }))
          );
        } else {
          handleError(processResponse);
        }
        if (
          businessUnitResponse?.status === 200 &&
          businessUnitResponse["#result-set-1"]
        ) {
          formik.setFieldValue(
            "businessUnit",
            businessUnitResponse["#result-set-1"].map((ele) => ({
              label: ele.ORG_UNIT_NAME,
              value: ele.RISK_ID,
            }))
          );
        }

        if (
          businessUnitResponse?.status === 200 &&
          businessUnitResponse["#result-set-2"]
        ) {
          setBusinessUnit(
            businessUnitResponse["#result-set-2"].map((ele) => ({
              label: ele.ORG_UNIT_NAME,
              value: ele.ORG_UNIT_ID,
            }))
          );
        } else {
          handleError(businessUnitResponse);
        }
        if (
          applicationResponse?.status === 200 &&
          applicationResponse["#result-set-1"]
        ) {
          const selectedApplication = applicationResponse["#result-set-1"].map(
            (ele) => ({
              label: ele.APPLICATION_NAME,
              value: ele.RISK_ID,
            })
          );
          console.log(selectedApplication, "++++++++");

          formik.setFieldValue("application", selectedApplication);
        }

        if (
          applicationResponse?.status === 200 &&
          applicationResponse["#result-set-2"]
        ) {
          setApplication(
            applicationResponse["#result-set-2"].map((ele) => ({
              label: ele.APPLICATION_NAME,
              value: ele.APPLICATION_ID,
            }))
          );
        } else {
          handleError(applicationResponse);
        }
      } catch (error) {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    };
    fetchData();

    const fetchRiskMatricsData = async () => {
      try {
        const response = await questionServices.getQuestionRiskMetrics(
          customerID,
          questionID,
          regulationID
        );

        if (response?.status === 200) {
          if (response["#result-set-1"]) {
            let storedData = response["#result-set-1"][0];
            formik.setFieldValue(
              "description",
              storedData.RISK_IMPACT_DESCRIPTION
                ? storedData.RISK_IMPACT_DESCRIPTION
                : ""
            );
            formik.setFieldValue(
              "importance",
              storedData.RISK_IMPORTANCE_VALUE && {
                label: storedData.RISK_IMPORTANCE_VALUE,
                value: storedData.RISK_IMPORTANCE,
              }
            );
            formik.setFieldValue(
              "impact",
              storedData.RISK_IMPACT_VALUE && {
                label: storedData.RISK_IMPACT_VALUE,
                value: storedData.RISK_IMPACT,
              }
            );
            formik.setFieldValue(
              "probability",
              storedData.RISK_PROBABILITY_VALUE && {
                label: storedData.RISK_PROBABILITY_VALUE,
                value: storedData.RISK_PROBABILITY,
              }
            );
          }
          if (response["#result-set-2"]) {
            setRiskMetricsImportantOptions(
              response["#result-set-2"].map((ele) => ({
                label: ele.METRICS_VALUE,
                value: ele.RISK_METRICS_ID,
              }))
            );
          }
          if (response["#result-set-3"]) {
            setRiskMetricsImpactOptions(
              response["#result-set-3"].map((ele) => ({
                label: ele.METRICS_VALUE,
                value: ele.RISK_METRICS_ID,
              }))
            );
          }
          if (response["#result-set-4"]) {
            setRiskMetricsProbablityOptions(
              response["#result-set-4"].map((ele) => ({
                label: ele.METRICS_VALUE,
                value: ele.RISK_METRICS_ID,
              }))
            );
          }
        } else {
          handleError(response);
        }
      } catch (error) {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    };
    fetchRiskMatricsData();
  }, []);

  const validationSchema = Yup.object({
    description: Yup.string().required("Description is required"),
    businessUnit: Yup.array().min(1, "Select at least one business unit"),
    // process: Yup.array().min(1, "Select at least one process"),
    // application: Yup.array().min(1, "Select at least one application"),
    importance: Yup.object().nullable().required("Importance is required"),
    impact: Yup.object().nullable().required("Impact is required"),
    probability: Yup.object().nullable().required("Probability is required"),
  });

  const insertControlFormData = async (values) => {
    try {
      let selectedoptBusiness = "";
      values.businessUnit.forEach(async (element) => {
        selectedoptBusiness += `${element.value},`;
      });
      const businessUnitResponse =
        await questionServices.updateRiskMetricForControlMapping({
          i_CUSTOMER_ID: customerID,
          i_QUESTION_ID: questionID,
          i_RISK_MAP_TYPE: "Unit",
          i_RISK_MAP_TYPE_VALUE: selectedoptBusiness,
        });
      if (businessUnitResponse.status !== 200) {
        handleError(businessUnitResponse);
      }

      let selectedoptProcess = "";
      values.process.forEach(async (element) => {
        selectedoptProcess += `${element.value},`;
      });

      const responseProcess =
        await questionServices.updateRiskMetricForControlMapping({
          i_CUSTOMER_ID: customerID,
          i_QUESTION_ID: questionID,
          i_RISK_MAP_TYPE: "Process",
          i_RISK_MAP_TYPE_VALUE: selectedoptProcess,
        });
      if (responseProcess.status !== 200) {
        handleError(responseProcess);
      }
      let selectedoptApplication = "";
      values.application.forEach(async (element) => {
        selectedoptApplication += `${element.value},`;
      });
      const responseApplication =
        await questionServices.updateRiskMetricForControlMapping({
          i_CUSTOMER_ID: customerID,
          i_QUESTION_ID: questionID,
          i_RISK_MAP_TYPE: "Application",
          i_RISK_MAP_TYPE_VALUE: selectedoptApplication,
        });
      if (responseApplication.status !== 200) {
        handleError(responseApplication);
      }

      const response = await questionServices.updateRiskMetricForControl({
        i_CUSTOMER_ID: customerID,
        i_REGULATION_ID: regulationID,
        i_QUESTION_ID: questionID,
        i_RISK_IMPACT: values.impact.value,
        i_RISK_IMPORTANCE: values.importance.value,
        i_RISK_PROBABILITY: values.probability.value,
        i_RISK_IMPACT_DESCRIPTION: values.description,
      });
      if (response.status === 200) {
        dispatch(
          alertActions.success("RiskmetricsForControl Updated Successfully!")
        );
      } else {
        handleError(response);
      }
    } catch (error) {
      dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: insertControlFormData,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ p: 3 }} border={1} borderColor={"grey.500"}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ mt: 2, width: "100%", p: 1 }}
          // padding={5}
        >
          <Box width="60%">
            {/* <InputLabel sx={{ mt: 2 }} shrink>
              Describe the Risk and its Impact
            </InputLabel> */}
            <Typography className={classes.filedTitle}>
              Describe the Risk and its Impact
            </Typography>
            <TextField
              variant="outlined"
              multiline
              rows={8}
              fullWidth
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                formik.touched.description && formik.errors.description ? (
                  <Typography color="error">
                    {formik.errors.description}
                  </Typography>
                ) : null
              }
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
            />
          </Box>

          <Box width="35%" display="flex" flexDirection="column" gap={3}>
            {[
              { label: "Importance", name: "importance" },
              { label: "Impact", name: "impact" },
              { label: "Probability", name: "probability" },
            ].map((field) => (
              <FormControl
                key={field.name}
                fullWidth
                error={
                  formik.touched[field.name] &&
                  Boolean(formik.errors[field.name])
                }
              >
                {/* <InputLabel sx={{ mt: 2 }} shrink>
                  {field.label}
                </InputLabel> */}
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ mt: 2 }} className={classes.filedTitle}>
                    {field.label}
                  </Typography>
                  <Select
                    options={
                      field.label == "Importance"
                        ? riskMetricsImportantOptions
                        : field.label == "Impact"
                        ? riskMetricsImpactOptions
                        : riskMetricsProbablityOptions
                    }
                    value={formik.values[field.name]}
                    onChange={(selected) =>
                      formik.setFieldValue(field.name, selected)
                    }
                    onBlur={() => formik.setFieldTouched(field.name, true)}
                    placeholder="Pick List value"
                  />
                  {formik.touched[field.name] && formik.errors[field.name] && (
                    <Typography color="error">
                      {formik.errors[field.name]}
                    </Typography>
                  )}
                </Box>
              </FormControl>
            ))}
          </Box>
        </Box>
        <Box display="flex" sx={{ mt: 3, p: 1 }}>
          {[
            {
              label: "Business Unit",
              name: "businessUnit",
              options: businessUnit,
            },
            { label: "Process", name: "process", options: processOptions },
            {
              label: "Application",
              name: "application",
              options: applicationOptions,
            },
          ].map((field) => (
            <Box
              key={field.name}
              // sx={{ flex: 1 }}
              sx={{
                flex: "0 0 auto",
                marginRight: "8px",
              }}
              display="flex"
              justifyContent="flex-start"
            >
              <FormControl
                className={classes.widhtforselect}
                error={
                  formik.touched[field.name] &&
                  Boolean(formik.errors[field.name])
                }
              >
                {/* <InputLabel shrink>{field.label}</InputLabel> */}
                <Typography className={classes.filedTitle}>
                  {field.label}
                </Typography>
                <Select
                  isMulti
                  options={field.options}
                  value={formik.values[field.name]}
                  onChange={(selected) =>
                    formik.setFieldValue(field.name, selected)
                  }
                  onBlur={() => formik.setFieldTouched(field.name, true)}
                  placeholder={`Select ${field.label}`}
                />
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <Typography color="error">
                    {formik.errors[field.name]}
                  </Typography>
                )}
              </FormControl>
            </Box>
          ))}
        </Box>

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Box>
    </form>
  );
};

RiskManagement.propTypes = {
  questionID: PropTypes.string,
  regulationID: PropTypes.string,
};

export default RiskManagement;
