import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import CloseIcon from "@mui/icons-material/Close";
import { rowData } from "./utils";
import ExtractionService from "../NewPolicyUpload/services";
import PolicyService from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AppPagination from "_components/AppPagination";
import Select from "react-select";
import { updatePolicyRecord } from "../../actions";
import useStyles from "./styles";
import sessionService from "_services/session.service";
import { uniqBy } from "lodash";
import SplittedDataList from "../PolicyDataTable/ChatBot/splittedDataList";
// import CloseIcon from "@mui/icons-material/Close";
import ListAltIcon from "@mui/icons-material/ListAlt"; // For Headers
import InfoIcon from "@mui/icons-material/Info"; // For Details
import AssignmentIcon from "@mui/icons-material/Assignment"; // For Evidence Mapping
import DataUsageIcon from "@mui/icons-material/DataUsage"; // For LLM Segments
import { IconButton, Tooltip } from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AddIcon from "@mui/icons-material/Add";
import RegulatorServices from "../../../../../manage-regulation/Regulators/services";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadDocumentService from "../../../../NewRegulation/ManageQuestions/component/UploadDocuments/services";

const PolicyPreview = ({
  policyPreview,
  setPolicyPreview,
  userID,
  setShowModal,
  setModalData,
  flag,
  setFlag,
}) => {
  const dispatch = useDispatch();
  const customerID = sessionService.getCustomerId();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [policyDetails, setPolicyDetails] = useState({});
  const [detailView, setDetailView] = useState({
    isDetailView: false,
    extractionID: "",
  });
  const [page, setPage] = useState(0);
  const [regPage, setRegPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerRegPage, setRowsPerRegPage] = useState(10);
  const [isEditView, setIsEditView] = useState(false);
  const [documentClassification, setDocumentClassification] = useState({
    DOCUMENT_OWNER: [],
    DOCUMENT_TYPE: [],
  });
  const [errors, setErrors] = useState({ POLICY_NAME: "" });
  const [policyFormDetils, setPolicyFormDetails] = useState({});
  const [allEvidencesList, setAllEvidenceList] = useState([]);
  const [evidenceTypes, setEvidenceTypes] = useState([]);
  const [defaultEvidence, setDefaultEvidence] = useState([]);
  const [splittedDataList, setSplittedDataList] = useState([]);
  const [selectedEvidenceMappingID, setSelectedEvidenceMappingID] = useState(
    []
  );
  const [selectedIcon, setSelectedIcon] = useState(0);
  const [selectedRegulatoryDomain, setSelectedRegulatoryDomain] = useState([]);
  const filteredRegulatoryDomains =
    uniqBy(allEvidencesList ?? [], "REGULATORY_DOMAIN_ID") ?? [];

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const [pageCount, setPageCount] = useState(calculatePageCount([]));
  const [regPageCount, setRegPageCount] = useState(calculatePageCount([]));

  const regulationList = useSelector((state) => state.regulationList);
  const [regulationData, setRegulationData] = useState([]);

  useEffect(() => {
    (async () => {
      setRegPageCount(calculatePageCount(regulationData));

      const basePolicyResponse =
        await ExtractionService.getPolicyExtractionDetailsByPage({
          policyId: policyPreview.data.POLICY_ID,
          pageNo: null,
        });
      if (basePolicyResponse.status == 200) {
        setPolicyDetails(basePolicyResponse["#result-set-1"]);
        setPageCount(calculatePageCount(basePolicyResponse["#result-set-1"]));
      } else {
        dispatch(alertActions.error("Policy Extraction Details SP Failed."));
      }

      const splitedResponse = await PolicyService.getPolicyChunkData(
        policyPreview.data.POLICY_ID
      );
      if (splitedResponse.status == 200) {
        splitedResponse["#result-set-1"].forEach((element) => {
          setSplittedDataList((prevstate) => [
            ...prevstate,
            element.CHUNK_TEXT,
          ]);
        });
      } else {
        dispatch(alertActions.error("Policy Chunk Data SP Failed."));
      }

      const allEvidencesResp = await PolicyService.getAllEvidencesForCustomer(
        customerID
      );

      if (allEvidencesResp?.status && allEvidencesResp?.status == 200) {
        setAllEvidenceList(allEvidencesResp?.["#result-set-1"] ?? []);
      } else {
        if (allEvidencesResp?.data && allEvidencesResp?.data.message) {
          dispatch(
            alertActions.error(
              allEvidencesResp?.data?.message?.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }

      // use policy id 47 tmp purpose
      const policyEvidenceMappingResp =
        await PolicyService.getPolicyEvidenceMapping(
          customerID,
          policyPreview?.data?.POLICY_ID
        );
      if (
        policyEvidenceMappingResp.status &&
        policyEvidenceMappingResp.status == 200
      ) {
        setDefaultEvidence(policyEvidenceMappingResp?.["#result-set-1"]);
        setSelectedEvidenceMappingID(
          policyEvidenceMappingResp["#result-set-1"]
        );
      } else {
        if (
          policyEvidenceMappingResp.data &&
          policyEvidenceMappingResp.data.message
        ) {
          dispatch(
            alertActions.error(
              policyEvidenceMappingResp.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }

      // const response =
      //   await UploadDocumentService.getCustomerPolicyDocRequirementMap(
      //     customerID,
      //     policyPreview.data.POLICY_ID,
      //     userID
      //   );

      // console.log(response["#result-set-1"]);
      // setRegulationData(response["#result-set-1"]);
      // console.log(regulationData);
    })();

    setIsEditView(false);
  }, [policyPreview]);

  useEffect(async () => {
    const response =
      await UploadDocumentService.getCustomerPolicyDocRequirementMap(
        customerID,
        policyPreview.data.POLICY_ID,
        userID
      );
    setRegulationData(response["#result-set-1"]);
  }, [flag]);

  const onEditHandleClick = async () => {
    setPolicyFormDetails({
      POLICY_NAME: policyPreview.data.POLICY_NAME,
      DOCUMENT_OWNER: policyPreview.data.DOCUMENT_OWNER
        ? JSON.parse(policyPreview.data.DOCUMENT_OWNER)
        : "",
      DOCUMENT_TYPE: policyPreview.data.DOCUMENT_TYPE
        ? JSON.parse(policyPreview.data.DOCUMENT_TYPE)
        : "",
      DOCUMENT_TAGS: policyPreview.data.DOCUMENT_TAGS,
    });
    if (!isEditView) {
      const response = await ExtractionService.getDocumentControlInformation(
        userID
      );
      if (response.status === 200) {
        setDocumentClassification({
          DOCUMENT_OWNER: response["#result-set-1"].map((item) => {
            return { value: item.ORG_UNIT_ID, label: item.ORG_UNIT_OWNER };
          }),
          DOCUMENT_TYPE: response["#result-set-2"].map((item) => {
            return { value: item.ID, label: item.DOCUMENT_CLASSIFICATION_NAME };
          }),
        });
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    } else {
      setErrors({ POLICY_NAME: "" });
    }
    setIsEditView(!isEditView);
  };

  const handleDomainChange = (data) => {
    setSelectedRegulatoryDomain(data);
    setSelectedEvidenceMappingID([]);
    const tempEvidenceTypes = allEvidencesList?.filter(
      (item) => item?.REGULATORY_DOMAIN_ID === data?.REGULATORY_DOMAIN_ID
    );
    setEvidenceTypes(tempEvidenceTypes);
  };

  const handleClose = () => setPolicyPreview({ isOpen: false, data: {} });

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setTabValue(newValue);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRegPage = (event) => {
    setRegPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hnadleChangeRowsPerRegPage = (event) => {
    setRowsPerRegPage(parseInt(event.target.value, 10));
    setRegPage(0);
  };

  const handleSaveEvidenceTypes = async () => {
    if (selectedEvidenceMappingID?.length < 1) {
      setErrors((prevState) => ({
        ...prevState,
        evidenceTypes: "Evidence Types are required",
      }));
      return;
    } else if (selectedRegulatoryDomain?.length < 1) {
      setErrors((prevState) => ({
        ...prevState,
        domains: "Regulatory Domain is required",
      }));
      return;
    }

    const evidenceTypesString = selectedEvidenceMappingID
      ?.map((item) => item?.EVIDENCE_TYPE_ID)
      ?.toString();

    dispatch(loadingActions.start());
    const response = await PolicyService.updatePolicyEvidenceMapping(
      customerID,
      policyPreview?.data?.POLICY_ID,
      evidenceTypesString
    );
    if (response.status != 200) {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
    dispatch(loadingActions.end());
    handleClose();
  };

  const handleSave = async () => {
    const policy_metadata = policyPreview.data.POLICY_META_DATA
      ? JSON.parse(window.atob(policyPreview.data.POLICY_META_DATA))
      : {};
    const new_policy_metadata = {
      ...policy_metadata,
      aliasName: policyFormDetils.POLICY_NAME.trim(),
      document_owner: policyFormDetils.DOCUMENT_OWNER,
      document_type: policyFormDetils.DOCUMENT_TYPE,
      document_tags: policyFormDetils.DOCUMENT_TAGS.trim()
        ? policyFormDetils.DOCUMENT_TAGS.trim()
        : "",
    };
    const payload = {
      policy_id: policyPreview.data.POLICY_ID,
      document_name: policyFormDetils.POLICY_NAME.trim(),
      document_owner: policyFormDetils.DOCUMENT_OWNER,
      document_type: policyFormDetils.DOCUMENT_TYPE,
      document_tags: policyFormDetils.DOCUMENT_TAGS.trim()
        ? policyFormDetils.DOCUMENT_TAGS.trim()
        : "",
      document_language: { value: policyPreview.data.LANGUAGE_CODE },
      policy_metadata: new_policy_metadata,
    };

    dispatch(loadingActions.start());
    const response = await ExtractionService.updatePolicy(payload);
    dispatch(loadingActions.end());
    if (response.status == 200) {
      dispatch(updatePolicyRecord(payload));
      setPolicyPreview((prevState) => ({
        ...prevState,
        POLICY_NAME: policyFormDetils.POLICY_NAME.trim(),
        DOCUMENT_OWNER: policyFormDetils.DOCUMENT_OWNER
          ? JSON.stringify(policyPreview.data.DOCUMENT_OWNER)
          : "",
        DOCUMENT_TYPE: policyFormDetils.DOCUMENT_TYPE.length
          ? JSON.stringify(policyFormDetils.DOCUMENT_TYPE)
          : "",
        DOCUMENT_TAGS: policyFormDetils.DOCUMENT_TAGS.trim(),
      }));
      setIsEditView(false);
    } else {
      dispatch(alertActions.error("Update SP Failed"));
    }
  };

  const deleteHandler = async (regulationID, documentRequiredID) => {
    const response =
      await UploadDocumentService.updateCustomerRegulationPolicyMap(
        customerID,
        regulationID,
        policyPreview.data.POLICY_ID,
        documentRequiredID,
        documentRequiredID,
        userID,
        0
      );
    if (response.status !== 200) {
      dispatch(alertActions.error("Something went wrong"));
    } else {
      setFlag((prev) => !prev);
    }
  };

  return (
    <Grid className={classes.side_offset}>
      <Grid container style={{ alignItems: "center", padding: "20px 0" }}>
        <Grid item md={9} xs={12}>
          <Typography
            style={{ fontWeight: "bold", fontSize: "20px", marginLeft: "10px" }}
          >
            {langConstant.POLICY_PREVIEW}
          </Typography>
        </Grid>
        <Grid item md={3} xs={4} style={{ textAlign: "right" }}>
          <CloseIcon
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Grid>
      </Grid>
      <Grid container style={{ padding: "20px 0" }}>
        <Grid item md={2} xs={3} style={{ paddingTop: "20px" }}>
          <Grid container direction="column" alignItems="center">
            <Tooltip title={langConstant.HEADERS}>
              <IconButton
                variant="contained"
                onClick={() => setTabValue(0)}
                color={tabValue === 0 ? "primary" : "default"}
              >
                <ListAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={langConstant.DETAILS}>
              <IconButton
                variant="contained"
                onClick={() => setTabValue(1)}
                color={tabValue === 1 ? "primary" : "default"}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={langConstant.EVIDENCE_MAPPING}>
              <IconButton
                variant="contained"
                onClick={() => setTabValue(2)}
                color={tabValue === 2 ? "primary" : "default"}
              >
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={langConstant.LLM_SEGMENTS}>
              <IconButton
                variant="contained"
                onClick={() => setTabValue(3)}
                color={tabValue === 3 ? "primary" : "default"}
              >
                <DataUsageIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={langConstant.DOCUMENT_MAPPING}>
              <IconButton
                variant="contained"
                onClick={() => setTabValue(4)}
                color={tabValue === 4 ? "primary" : "default"}
              >
                <ReceiptLongIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* <Tabs
        orientation="horizontal"
        value={tabValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        style={{ borderRight: "1px solid #ddd" }} // Optional styling
      >
        <Tab label={langConstant.HEADERS} />
        <Tab label={langConstant.DETAILS} />
        <Tab label={langConstant.EVIDENCE_MAPPING} />
        <Tab label={langConstant.LLM_SEGMENTS} />
      </Tabs> */}
        <Grid item md={10}>
          {tabValue === 0 && (
            <Grid>
              <Grid
                container
                justify="flex-end"
                style={{ paddingBottom: "10px" }}
              >
                {isEditView && (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave()}
                    style={{ fontWeight: "bold", marginRight: "10px" }}
                  >
                    {langConstant.SAVE}
                  </Button>
                )}
                <Button
                  size="small"
                  variant={isEditView ? "outlined" : "contained"}
                  onClick={onEditHandleClick}
                  style={{ fontWeight: "bold" }}
                >
                  {isEditView ? langConstant.CANCEL : langConstant.EDIT}
                </Button>
              </Grid>
              <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                  <TableBody>
                    {rowData.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            <span style={{ fontWeight: "bold" }}>
                              {row.header}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {!isEditView || !row.editAvailable
                              ? row.format
                                ? row.format(policyPreview.data)
                                : policyPreview.data[row.value]
                                ? policyPreview.data[row.value]
                                : "-"
                              : ""}
                            {row.editAvailable &&
                              isEditView &&
                              (row.type === "input" ? (
                                <>
                                  <TextField
                                    className={classes.textFieldWid}
                                    variant="outlined"
                                    value={
                                      row.format
                                        ? row.format(policyFormDetils)
                                        : policyFormDetils[row.value]
                                    }
                                    onChange={(e) => {
                                      setPolicyFormDetails((prevState) => ({
                                        ...prevState,
                                        [row.key]: e.target.value,
                                      }));
                                      e.target.value.trim() === "" &&
                                      row.isRequired
                                        ? setErrors((prevState) => ({
                                            ...prevState,
                                            [row.key]: row.requiredMessage,
                                          }))
                                        : setErrors((prevState) => ({
                                            ...prevState,
                                            [row.key]: "",
                                          }));
                                    }}
                                    InputProps={{
                                      classes: {
                                        input: classes.inputWrapper,
                                      },
                                    }}
                                  />
                                  {errors[row.value] && row.isRequired && (
                                    <Typography>{errors[row.value]}</Typography>
                                  )}
                                </>
                              ) : (
                                <Select
                                  options={documentClassification[row.key]}
                                  placeholder={row.header}
                                  value={policyFormDetils[row.key]}
                                  onChange={(data) => {
                                    setPolicyFormDetails((prevState) => ({
                                      ...prevState,
                                      [row.key]: data,
                                    }));
                                  }}
                                  menuPortalTarget={document.body}
                                  isMulti={row.isMulti}
                                  isClearable={true}
                                />
                              ))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {tabValue === 1 && (
            <Grid style={{ marginTop: "15px" }}>
              {pageCount > 1 && (
                <Box my={2}>
                  <Grid container justify="flex-end">
                    <AppPagination
                      colSpan={3}
                      count={pageCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Grid>
                </Box>
              )}
              <TableContainer component={Paper}>
                <Table className="data-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: "center" }}
                      >
                        {langConstant.HEADERS}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? policyDetails.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : policyDetails
                    ).map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            component="td"
                            scope="row"
                            style={{ paddingLeft: "20px", width: "50%" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                paddingBottom: "10px",
                                display: "inline-flex",
                              }}
                            >
                              <div style={{}}> {item["NAME"]}</div>
                              <div style={{}}>
                                {detailView.isDetailView &&
                                detailView.extractionID ==
                                  item["POLICY_EXTRACTION_DETAILS_ID"] ? (
                                  <VisibilityOffIcon
                                    fontSize="small"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() => {
                                      setDetailView({
                                        isDetailView: false,
                                        extractionID: "",
                                      });
                                    }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    fontSize="small"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() => {
                                      setDetailView({
                                        isDetailView: true,
                                        extractionID:
                                          item["POLICY_EXTRACTION_DETAILS_ID"],
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </Typography>
                            {detailView.isDetailView &&
                              detailView.extractionID ==
                                item["POLICY_EXTRACTION_DETAILS_ID"] && (
                                <Typography
                                  style={{
                                    fontSize: "0.9rem",
                                    overflowY: "auto",
                                    maxHeight: "300px",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {item["ITEM_BODY_CONTENT"]
                                    ? item["ITEM_BODY_CONTENT"]
                                    : langConstant.NOT_AVAILABLE}
                                </Typography>
                              )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {policyDetails.length === 0 && (
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          {langConstant.NO_RECORD_FOUND}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {tabValue == 2 && (
            <Grid style={{ marginTop: "15px", minHeight: 300 }}>
              <Grid
                container
                justify="flex-end"
                style={{ paddingBottom: "10px" }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ fontWeight: "bold", marginRight: "10px" }}
                  onClick={handleSaveEvidenceTypes}
                >
                  {langConstant.SAVE}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setSelectedEvidenceMappingID(defaultEvidence);
                    setSelectedRegulatoryDomain([]);
                  }}
                  style={{ fontWeight: "bold" }}
                >
                  UNDO
                </Button>
              </Grid>
              <TableContainer component={Paper} style={{ minHeight: 250 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell padding="0" component="th" scope="row">
                        <span style={{ fontWeight: "bold" }}>
                          {langConstant.DOMAIN}
                        </span>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Select
                          options={filteredRegulatoryDomains}
                          placeholder="Select Regulatory Domain"
                          isClearable={true}
                          onChange={(data) => handleDomainChange(data)}
                          getOptionLabel={(option) => option?.DOMAIN_NAME}
                          getOptionValue={(option) =>
                            option?.REGULATORY_DOMAIN_ID
                          }
                          value={selectedRegulatoryDomain}
                        />
                        {errors["domains"] && (
                          <Typography style={{ color: "red", fontSize: 12 }}>
                            {errors["domains"]}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <span style={{ fontWeight: "bold" }}>
                          {langConstant.EVIDENCE_TYPES}
                        </span>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Select
                          defaultValue={selectedEvidenceMappingID}
                          value={selectedEvidenceMappingID}
                          options={evidenceTypes}
                          placeholder={langConstant.EVIDENCE_TYPES}
                          isMulti={true}
                          isClearable={true}
                          getOptionLabel={(option) => option.EVIDENCE_SHORTNAME}
                          getOptionValue={(option) => option.EVIDENCE_TYPE_ID}
                          onChange={(options) =>
                            setSelectedEvidenceMappingID(options)
                          }
                        />
                        {errors["evidenceTypes"] && (
                          <Typography style={{ color: "red", fontSize: 12 }}>
                            {errors["evidenceTypes"]}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {tabValue == 3 && (
            <Grid style={{ marginTop: "15px", minHeight: 300 }}>
              {splittedDataList.length > 0 && (
                <Grid>
                  <SplittedDataList splittedDataList={splittedDataList} />
                </Grid>
              )}
            </Grid>
          )}
          {tabValue == 4 && (
            <Grid style={{ marginTop: "15px", minHeight: 300 }}>
              <Grid
                container
                justify="flex-end"
                style={{ paddingBottom: "10px" }}
              >
                <IconButton
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ fontWeight: "bold", marginRight: "10px" }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              <TableContainer component={Paper} style={{ minHeight: 250 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Regulation</TableCell>
                      <TableCell>Document Required</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerRegPage > 0
                      ? regulationData.slice(
                          regPage * rowsPerRegPage,
                          regPage * rowsPerRegPage + rowsPerRegPage
                        )
                      : regulationData
                    ).map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell padding="0" component="th" scope="row">
                            {item.REGULATION_SHORT_NAME}
                          </TableCell>
                          <TableCell padding="0" component="th" scope="row">
                            {item.DOCUMENT_RECOMMENDATION}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setModalData({
                                  regulation: {
                                    label: item.REGULATION_SHORT_NAME,
                                    value: item.REGULATION_ID,
                                  },
                                  documentForRegulation: {
                                    label: item.DOCUMENT_RECOMMENDATION,
                                    value: item.REGULATIONDOCREQ_UNID,
                                  },
                                });
                                setShowModal(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                deleteHandler(
                                  item.REGULATION_ID,
                                  item.REGULATIONDOCREQ_UNID
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {regulationData.length === 0 && (
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          {langConstant.NO_RECORD_FOUND}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {regPageCount > 1 && (
                <Box my={2}>
                  <Grid container justify="flex-end">
                    <AppPagination
                      colSpan={3}
                      count={regPageCount}
                      rowsPerPage={rowsPerRegPage}
                      page={regPage}
                      onChangePage={handleChangeRegPage}
                      onChangeRowsPerPage={hnadleChangeRowsPerRegPage}
                    />
                  </Grid>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

PolicyPreview.propTypes = {
  policyPreview: PropTypes.object,
  setPolicyPreview: PropTypes.func,
  userID: PropTypes.string,
  setShowModal: PropTypes.func,
  setModalData: PropTypes.func,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
};

export default PolicyPreview;
