/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { sectorsListActions } from "../_actions";
import Select from "react-select";

const SectorsDropDown = (props) => {
  const sectors = useSelector((state) => state.sectorsList);
  const [sectorsOptions, setSectorsOptions] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (sectors.length == 0) {
      dispatch(sectorsListActions.getSectorsList());
    } else {
      const tempOptions = [];
      sectors.forEach((element) => {
        if (element.ACTIVE == 1) {
          tempOptions.push({
            value: element.ISO_SECTOR_CODE,
            label: element.ISO_SECTOR_TITLE,
          });
        }
      });
      setSectorsOptions(tempOptions);
    }
  }, [sectors]);

  return (
    <Select
      value={sectorsOptions.find((sector) => sector.value == props.value)}
      options={sectorsOptions}
      onChange={props.onChange}
      placeholder={props.placeholder}
      menuPortalTarget={document.body}
    />
  );
};
SectorsDropDown.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
export default SectorsDropDown;
