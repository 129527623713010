/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import constant from "./constants";
import apiService from "../../../../_services/api.service";
import { alertActions, loadingActions } from "../../../../_actions";
import langConstant from "_lang";
import { useDispatch } from "react-redux";

const checkAssignmentStatus = (
  id,
  status,
  goBackOnError,
  SUBMISSION_ID,
  userID
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    if (status == "ASSIGNED") {
      apiService
        .apiCall("regulationservice")
        .send("/createNewAssessment", { regulationId: id })
        .then(apiService.handleResponse)
        .then(
          (response) => {
            if (response.statusUpdated) {
              dispatch(
                getAssessmentById(
                  id,
                  status,
                  goBackOnError,
                  SUBMISSION_ID,
                  userID
                )
              );
            } else {
              dispatch(loadingActions.end());
              goBackOnError();
              dispatch(
                alertActions.error(
                  langConstant.ERROR_LISTING_ASSESSMENT_QUESTIONS
                )
              );
            }
          },
          (error) => {
            dispatch(loadingActions.end());
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              dispatch(
                alertActions.error(
                  error.response.data.message.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })
                )
              );
            } else {
              dispatch(alertActions.error(error.toString()));
            }
            goBackOnError();
          }
        );
    } else {
      dispatch(
        getAssessmentById(id, status, goBackOnError, SUBMISSION_ID, userID)
      );
    }
  };
};
const getAssessmentById = (
  id,
  status,
  goBackOnError,
  SUBMISSION_ID,
  userID
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAssessments", {
        // i_REGULATION_ID: id,
        i_SUBMISSION_ID: SUBMISSION_ID,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let payload = [];
          const functionWithPromise = (item) => {
            return Promise.resolve(item);
          };
          const anAsyncFunction = async (item) => {
            return functionWithPromise(item);
          };

          try {
            payload = response["#result-set-1"];
            if (payload == 0) {
              goBackOnError();
              dispatch({ type: constant.initiateAssessmentConstant_FAILURE });
              return;
            }
            const assessmentObj = response["#result-set-2"]
              ? response["#result-set-2"]
              : {};
            const getData = async () => {
              return Promise.all(
                payload.map((item, index) => {
                  const matches = [];
                  const historyAnswers = {};
                  if (item.CURRENT_ANSWER_SELECTED_RESPONSE) {
                    historyAnswers.currentAnswer = {
                      answer: item.CURRENT_ANSWER_SELECTED_RESPONSE,
                      score: item.CURRENT_ANSWER_MATCH_SCORE,
                    };
                  }
                  if (item.HIST_ANSWER_RESPONSE_01) {
                    historyAnswers.pastAnsOne = {
                      answer: item.HIST_ANSWER_RESPONSE_01,
                      score: item.HIST_ANSWER_MATCH_SCORE_01,
                    };
                    matches.push({
                      answer: item.HIST_ANSWER_RESPONSE_01,
                      score: item.HIST_ANSWER_MATCH_SCORE_01,
                    });
                  }
                  if (item.HIST_ANSWER_RESPONSE_02) {
                    historyAnswers.pastAnsTwo = {
                      answer: item.HIST_ANSWER_RESPONSE_02,
                      score: item.HIST_ANSWER_MATCH_SCORE_02,
                    };
                    matches.push({
                      answer: item.HIST_ANSWER_RESPONSE_02,
                      score: item.HIST_ANSWER_MATCH_SCORE_02,
                    });
                  }
                  if (item.HIST_ANSWER_RESPONSE_03) {
                    historyAnswers.pastAnsThree = {
                      answer: item.HIST_ANSWER_RESPONSE_03,
                      score: item.HIST_ANSWER_MATCH_SCORE_03,
                    };
                    matches.push({
                      answer: item.HIST_ANSWER_RESPONSE_03,
                      score: item.HIST_ANSWER_MATCH_SCORE_03,
                    });
                  }
                  const matchFound = matches.findIndex(
                    (ele) => ele.answer == item.CURRENT_ANSWER_SELECTED_RESPONSE
                  );
                  const selectedAnswer =
                    matchFound > -1 ? "history_" + matchFound : "current";
                  const newItem = {
                    ...item,
                    assessmentCaseId: item.SUBMISSION_ID,
                    question: item.QUESTION_TEXT,
                    status: item.QUESTION_STATUS,
                    id: item.QUESTION_ID,
                    refId: item.REGULATORS_QUESTION_REF,
                    guidance: item.GUIDANCE_TEXT,
                    currentAnswer: item.CURRENT_ANSWER_SELECTED_RESPONSE,
                    currentAnswerScore: item.CURRENT_ANSWER_MATCH_SCORE,
                    matches: matches,
                    historyAnswers: historyAnswers,
                    selectedAnswer: selectedAnswer,
                    questionState:
                      item.QUESTION_STATE && parseInt(item.QUESTION_STATE)
                        ? true
                        : false,
                    assessmentObj: assessmentObj,
                  };
                  return anAsyncFunction(newItem);
                })
              );
            };

            getData().then((data) => {
              dispatch({
                type: constant.initiateAssessmentConstant_GET,
                data: data,
              });
            });
            dispatch(loadingActions.end());
          } catch (e) {
            dispatch({ type: constant.initiateAssessmentConstant_FAILURE });
            dispatch(alertActions.error("Error in Assessment Questions"));
            goBackOnError();
            console.log(e, true);
            dispatch(loadingActions.end());
          }

          if (
            response["#result-set-4"] == 0 &&
            response["#result-set-3"] == 0
          ) {
            // If there are no RFIs for the question
            return;
          }

          let rfiPayload = {
            rfiItems: response["#result-set-4"],
            rfiGroups: response["#result-set-3"],
          };
          dispatch({
            type: constant.initiateRfiConstant_GET,
            data: rfiPayload,
          });
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item} </div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          goBackOnError();
        }
      );
    //dispatch({ type: constant.initiateAssessmentConstant_GET, data: draft })
  };
};
const saveSingleQuestion = (data, id, SUBMISSION_ID, userID) => {
  const payload = {
    i_QUESTION_ID: data.QUESTION_ID,
    i_CURRENT_ANSWER_SELECTED_RESPONSE: data.CURRENT_ANSWER_SELECTED_RESPONSE,
    i_CURRENT_ANSWER_JUSTIFICATION_DETAILS:
      data.CURRENT_ANSWER_JUSTIFICATION_DETAILS
        ? data.CURRENT_ANSWER_JUSTIFICATION_DETAILS
        : "",
    i_CURRENT_ANSWER_EVIDENCE_DETAILS: data.CURRENT_ANSWER_EVIDENCE_DETAILS
      ? data.CURRENT_ANSWER_EVIDENCE_DETAILS
      : "",
    i_CURRENT_ANSWER_MATCH_SCORE: data.CURRENT_ANSWER_MATCH_SCORE
      ? data.CURRENT_ANSWER_MATCH_SCORE
      : "",
    i_QUESTION_STATE: data.QUESTION_STATE ? 1 : 0,
    i_SUBMISSION_ID: SUBMISSION_ID,
  };
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAssessments", payload)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({
            type: constant.initiateAssessmentConstant_UPDATE_SINGLE_ITEM,
            data: data,
          });
          dispatch(
            alertActions.success(langConstant.QUESTION_UPDATED_SUCCESSFULLY)
          );
          if (id) {
            dispatch(getAssessmentById(id, null, null, SUBMISSION_ID, userID));
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
    //dispatch(loadingActions.start());
  };
};
const updateSingleItemInDraft = (obj) => {
  return (dispatch) => {
    //dispatch(loadingActions.start());
    dispatch({
      type: constant.initiateAssessmentConstant_UPDATE_SINGLE_ITEM,
      data: obj,
    });
    //dispatch(alertActions.success('Draft updated successfully!'));
  };
};
const updateAssignedStatus = (
  id,
  submissionID,
  assignedByUserID,
  status,
  callback
) => {
  //let data = new Blob(JSON.stringify(draft) );

  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAssignedStatus", {
        i_REGULATION_ID: id,
        i_SUBMISSION_ID: submissionID,
        i_USER_ID: assignedByUserID,
        i_STATUS: status,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (status == "PENDING_FOR_REVIEW") {
            dispatch(
              alertActions.success(
                langConstant.ASSESSMENT_SUBMITTED_FOR_REVIEW_SUCCESSFULLY
              )
            );
            callback();
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
    //dispatch(loadingActions.start());
  };
};
const getQuestionDQProfile = (id) => {
  //let data = new Blob(JSON.stringify(draft) );
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionDQProfile", {
        i_QUESTION_ID: id,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({
            type: constant.initiateAssessmentConstant_Add_DQ_PROFILE,
            data: { id: id, profile: response["#result-set-1"] },
          });
        },
        (error) => {
          //dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
    //dispatch(loadingActions.start());
  };
};

const createRFI = (
  groupName,
  SUBMISSION_ID,
  QUESTION_ID,
  REGULATORS_QUESTION_REF,
  coverNote,
  dueDate
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRFI", {
        i_NAME: groupName,
        i_ASSESSMENT_ID: SUBMISSION_ID,
        i_QUESTION_ID: QUESTION_ID,
        i_RFI_DESCRIPTION: coverNote,
        i_RFI_DUE_DATE: dueDate,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          //*  Successfully added the RFI to backend

          //* Changes question status locally
          dispatch({
            type: constant.changeQuestionStatus,
            data: { REGULATORS_QUESTION_REF, QUESTION_STATUS: "RFI_SENT" },
          });

          //* Creates RFI locally
          dispatch({
            type: constant.createRfi,
            data: {
              REGULATORS_QUESTION_REF,
              RFI_DESCRIPTION: coverNote,
              RFI_DUE_DATE: dueDate,
              RFI_STATUS: "RFI_SENT",
              STATUS: "RFI SENT",
            },
          });

          //* display success alert prompt
          dispatch(
            alertActions.success(
              langConstant.CREATE_RFI_ALERT_PROMPT_SUCCESS(
                REGULATORS_QUESTION_REF
              )
            )
          );
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateRFI = (
  SUBMISSION_ID,
  QUESTION_ID,
  REGULATORS_QUESTION_REF,
  newDueDate,
  active,
  newStatus,
  rfiResolvedDate = null
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateRFI", {
        i_ASSESSMENT_ID: SUBMISSION_ID,
        i_QUESTION_ID: QUESTION_ID,
        i_RFI_DUE_DATE: newDueDate,
        i_ACTIVE: active,
        i_RFI_STATUS: newStatus,
        i_RFI_RESOLVED_DATE: rfiResolvedDate,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());

          if (active != null) {
            dispatch({
              type: constant.deleteRfi,
              data: REGULATORS_QUESTION_REF,
            });
            dispatch({
              type: constant.changeQuestionStatus,
              data: { REGULATORS_QUESTION_REF, QUESTION_STATUS: "DRAFT" },
            });

            dispatch(
              alertActions.success(
                langConstant.RFI_CANCELLED_ALERT_PROMPT(REGULATORS_QUESTION_REF)
              )
            );
          } else if (newStatus != null) {
            dispatch({
              type: constant.changeRfiStatus,
              data: {
                REGULATORS_QUESTION_REF,
                RFI_STATUS: "RFI_RESOLVED",
                STATUS: "RFI RESOLVED",
              },
            });
            dispatch({
              type: constant.changeQuestionStatus,
              data: {
                REGULATORS_QUESTION_REF,
                QUESTION_STATUS: "RFI_RESOLVED",
              },
            });

            //* display resolved alert prompt
            dispatch(
              alertActions.success(
                langConstant.RESOLVED_RFI_ALERT_PROMPT(REGULATORS_QUESTION_REF)
              )
            );
          } else if (newDueDate != null) {
            dispatch({
              type: constant.changeRfiDueDate,
              data: {
                REGULATORS_QUESTION_REF,
                RFI_DUE_DATE: newDueDate,
              },
            });
            dispatch(
              alertActions.success(
                langConstant.DUE_DATE_CHANGED_RFI_ALERT_PROMPT(
                  REGULATORS_QUESTION_REF
                )
              )
            );
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateQuestionStatus = (QUESTION_ID, SUBMISSION_ID, STATUS, userID) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionStatus", {
        i_QUESTION_ID: QUESTION_ID,
        i_SUBMISSION_ID: SUBMISSION_ID,
        i_QUESTION_STATUS: STATUS,
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());

          //* Successfully changed the question status
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getDataAssestsList = (id, customerID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDataAssestsForQuestion", {
        i_QUESTION_ID: id,
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({
            type: constant.GET_DATA_ASSET_LIST_SUCCESSC,
            data: response["#result-set-1"],
          });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateComplianceDecision = (
  qId,
  option,
  regulationID,
  SUBMISSION_ID,
  userID
) => {
  const payload = {
    i_QUESTION_ID: qId,
    i_Compliance_Decision: option,
    i_SUBMISSION_ID: SUBMISSION_ID,
  };
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateComplianceDecision", payload)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (regulationID) {
            dispatch(
              getAssessmentById(regulationID, null, null, SUBMISSION_ID, userID)
            );
          }
          dispatch(
            alertActions.success(langConstant.COMPLIANCE_UPDATED_SUCCESSFULLY)
          );
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const GetRFIRecipients = (userid) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRFIRecipients", { i_USER_ID: userid })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#result-set-1"]) {
            dispatch({
              type: constant.GetRFIRecipients,
              data: response["#result-set-1"],
            });
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const GetRFIEvidenceAndDataAssetsForQuestion = (
  UserID,
  SubmissionID,
  QuestionID
) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send(
        "/v1/api/proc/DRegulativDB/GetRFIEvidenceAndDataAssetsForQuestion",
        {
          i_USER_ID: UserID,
          i_SUBMISSION_ID: SubmissionID,
          i_QUESTION_ID: QuestionID,
          // i_USER_ID: 1, i_SUBMISSION_ID: 3, i_QUESTION_ID: 100004
        }
      )
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#result-set-1"]) {
            dispatch({
              type: constant.GetEvidences,
              data: response["#result-set-1"],
            });
          }

          if (response["#result-set-2"]) {
            dispatch({
              type: constant.GetDataAsset,
              data: response["#result-set-2"],
            });
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const PutMultiRFI = (obj) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutMultiRFI", obj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(alertActions.success("RFi has been successfully assigned"));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const UpdateMultiRFI = (obj, identifier) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateMultiRFI", obj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let word;
          if (identifier === "delete") {
            word = "Deleted";
          } else {
            word = "Updated";
          }
          dispatch(alertActions.success("RFi has been successfully " + word));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const GetRFIList = (questionID, submissionID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRFIList", {
      i_QUESTION_ID: questionID,
      i_SUBMISSION_ID: submissionID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  checkAssignmentStatus,
  getAssessmentById,
  saveSingleQuestion,
  updateSingleItemInDraft,
  updateAssignedStatus,
  getQuestionDQProfile,
  createRFI,
  updateRFI,
  updateQuestionStatus,
  getDataAssestsList,
  updateComplianceDecision,
  GetRFIRecipients,
  GetRFIEvidenceAndDataAssetsForQuestion,
  PutMultiRFI,
  UpdateMultiRFI,
  GetRFIList,
};
