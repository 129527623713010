import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  docViewer: {
    padding: "10px",
    height: "600px",
  },
  docScannerBtn: {
    right: 0,
    position: "absolute !important",
    top: "50%",
    transform: "translateY(-50%)",
    background: "red !important",
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
    padding: "5px !important",
    minWidth: "30px !important",
  },
  contentEditor: {
    height: "67vh",
    border: "1px solid",
    borderRadius: "5px",
    padding: "10px",
    display: "block",
  },
  contentToolBar: {
    background: "#80808038",
    borderRadius: "10px",
  },
  documentClass: {
    "& #header-bar": {
      display: "none !important",
    },
    "& #pdf-download": {
      display: "none !important",
    },
    "& #proxy-renderer": {
      overflow: "hidden",
    },
  },
}));

export default useStyles;
