import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import PropTypes from "prop-types";
import useStyles from "./styles";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import langConstant from "_lang";
import UserServices from "../services";
import SaveIcon from "@mui/icons-material/Save";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DnsIcon from "@mui/icons-material/Dns";
import { useDispatch } from "react-redux";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const BasicInformation = ({
  userData,
  updateUserService,
  isTeamView,
  isEditMode,
  setIsEditMode,
  countryData,
  domainList,
  setEntitlementPopUp,
  user_ID,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [infoClassification, setInfoClassification] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);

  useEffect(() => {
    dispatch(
      UserServices.getCustomerInfoClassification(user_ID, setInfoClassification)
    );
  }, []);

  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .max(50, "Maximum 50 Characters Allowed")
      .required("Name is Required"),
    PHONE_NUMBER: Yup.string()
      .max(20, "Maximum 20 Numbers Allowed")
      .required("Phone Number is Required"),
    EMAIL_ADDRESS: Yup.string()
      .email("Invalid Email Address")
      .required("Email is Required"),
    DEPARTMENT_NAME: Yup.string().required("Department is Required"),
    COUNTRY_CODE: Yup.string().required("Country is Required"),
    // REGULATORY_DOMAIN_ID: Yup.string().required("Domain Name is Required"),
    REGULATORY_DOMAIN_ID: Yup.array().required("Domain Name is Required"),
    DATA_CLASSIFICATION: Yup.number().required("Classification is Required"),
  });

  const teamValidationSchema = Yup.object().shape({
    Name: Yup.string()
      .max(50, "Maximum 50 Characters Allowed")
      .required("Name is Required"),
    PHONE_NUMBER: Yup.string()
      .max(20, "Maximum 20 Numbers Allowed")
      .required("Phone Number is Required"),
    EMAIL_ADDRESS: Yup.string()
      .email("Invalid Email Address")
      .required("Email is Required"),
    DEPARTMENT_NAME: Yup.string().required("Department is Required"),
    COUNTRY_CODE: Yup.string().required("Country is Required"),
    DATA_CLASSIFICATION: Yup.number().required("Classification is Required"),
  });

  const updateUserDetail = async (user) => {
    let name = user.Name;
    let nameList = [];
    name = name.split(" ");
    nameList.push(name.shift());
    nameList.push(name.join(" "));
    const payload = {
      i_USER_ID: user_ID,
      i_USER_ID2: user_ID,
      i_User_firstname: nameList[0],
      i_User_lastname: nameList[1],
      i_PhoneNumber: user.PHONE_NUMBER,
      i_departmentName: user.DEPARTMENT_NAME,
      i_EmailAddress: user.EMAIL_ADDRESS,
      i_location: user.COUNTRY_CODE,
      i_Active: userData.ACTIVE,
      i_DataClassification: user.DATA_CLASSIFICATION,
    };

    const selectedDomainIdList = [];
    selectedDomains.forEach((selectedDomain) => {
      selectedDomainIdList.push(selectedDomain.value);
    });
    const selectedDomainStr = selectedDomainIdList.join(",");

    const domainData = {
      i_USER_REGULATORY_DOMAIN_ID: userData.USER_REGULATORY_DOMAIN_ID,
      i_USER_ID: user_ID,
      i_REGULATORY_DOMAIN_ID: selectedDomainStr,
    };

    // const domainData = {
    //   i_USER_REGULATORY_DOMAIN_ID: userData.USER_REGULATORY_DOMAIN_ID,
    //   i_USER_ID: user_ID,
    //   i_REGULATORY_DOMAIN_ID: user.REGULATORY_DOMAIN_ID,
    // };

    await updateUserService(
      payload,
      langConstant.USER_DETAILS_SUCCESSFULLY_UPDATED,
      "userEdit",
      domainData
    );
  };

  return (
    <Grid className={classes.reportWrapper}>
      <Formik
        enableReinitialize={true}
        initialValues={userData}
        validationSchema={isTeamView ? teamValidationSchema : validationSchema}
        onSubmit={(value) => updateUserDetail(value)}
      >
        {({ resetForm, handleSubmit }) => (
          <Form>
            <Grid className={classes.profileButtonWrapper}>
              {isEditMode ? (
                <>
                  <Button
                    color="primary"
                    type="button"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    <SaveIcon className={classes.editBTNProfileIcon} />
                    <span className={classes.editBTNProfileText}>
                      {langConstant.SAVE}
                    </span>
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                      setIsEditMode(false);
                      resetForm();
                    }}
                  >
                    {langConstant.CANCEL}
                  </Button>
                </>
              ) : (
                <>
                  {userData.ACTIVE && (
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        setIsEditMode(true);

                        if (selectedDomains.length == 0) {
                          const selectedDomainIdList =
                            userData.REGULATORY_DOMAIN_ID;

                          if (selectedDomainIdList) {
                            selectedDomainIdList.forEach((selectedDomainId) => {
                              const userDomain = domainList.find(
                                (domain) => domain.value == selectedDomainId
                              );
                              if (userDomain)
                                setSelectedDomains((prevState) => [
                                  ...prevState,
                                  userDomain,
                                ]);
                            });
                          }
                        }
                      }}
                      variant="outlined"
                    >
                      <CreateOutlinedIcon
                        className={classes.editBTNProfileIcon}
                      />
                      <span className={classes.editBTNProfileText}>EDIT</span>
                    </Button>
                  )}
                </>
              )}
            </Grid>
            <Grid className={classes.profileMDBasicInfo}>
              <Typography className={classes.profileMDBasicInfoItem}>
                <Field style={{ width: "100%" }} name="Name">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <TextField
                      className={classes.inputProfileField}
                      value={value}
                      disabled={!isEditMode}
                      onChange={(e) => setFieldValue("Name", e.target.value)}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.profileMDBasicInfoIcon}
                            />
                          </InputAdornment>
                        ),
                        classes: {
                          input: classes.inputProfileField,
                          notchedOutline: isEditMode ? "" : classes.borderNone,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="Name">
                  {(msg) => (
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginTop: "0px",
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </Typography>
              <Typography className={classes.profileMDBasicInfoItem}>
                <TextField
                  className={classes.inputProfileField}
                  value={userData.USER_NAME}
                  disabled={true}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon
                          className={classes.profileMDBasicInfoIcon}
                        />
                      </InputAdornment>
                    ),
                    classes: {
                      input: classes.inputProfileField,
                      notchedOutline: classes.borderNone,
                    },
                  }}
                />
              </Typography>
              <Typography className={classes.profileMDBasicInfoItem}>
                <Field style={{ width: "100%" }} name="EMAIL_ADDRESS">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <TextField
                      className={classes.inputProfileField}
                      value={value}
                      disabled={!isEditMode}
                      onChange={(e) =>
                        setFieldValue("EMAIL_ADDRESS", e.target.value)
                      }
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon
                              className={classes.profileMDBasicInfoIcon}
                            />
                          </InputAdornment>
                        ),
                        classes: {
                          input: classes.inputProfileField,
                          notchedOutline: isEditMode ? "" : classes.borderNone,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="EMAIL_ADDRESS">
                  {(msg) => (
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginTop: "0px",
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </Typography>
              <Typography className={classes.profileMDBasicInfoItem}>
                <Field style={{ width: "100%" }} name="DEPARTMENT_NAME">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <TextField
                      className={classes.inputProfileField}
                      value={value}
                      disabled={!isEditMode}
                      onChange={(e) =>
                        setFieldValue("DEPARTMENT_NAME", e.target.value)
                      }
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountTreeIcon
                              className={classes.profileMDBasicInfoIcon}
                            />
                          </InputAdornment>
                        ),
                        classes: {
                          input: classes.inputProfileField,
                          notchedOutline: isEditMode ? "" : classes.borderNone,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="DEPARTMENT_NAME">
                  {(msg) => (
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginTop: "0px",
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </Typography>
              <Typography className={classes.profileMDBasicInfoItem}>
                <Field style={{ width: "100%" }} name="PHONE_NUMBER">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <TextField
                      className={classes.inputProfileField}
                      value={value}
                      disabled={!isEditMode}
                      onChange={(e) =>
                        setFieldValue("PHONE_NUMBER", e.target.value)
                      }
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalPhoneIcon
                              className={classes.profileMDBasicInfoIcon}
                            />
                          </InputAdornment>
                        ),
                        classes: {
                          input: classes.inputProfileField,
                          notchedOutline: isEditMode ? "" : classes.borderNone,
                        },
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="PHONE_NUMBER">
                  {(msg) => (
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginTop: "0px",
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </Typography>
              <Typography className={classes.profileMDBasicInfoItem}>
                {isEditMode ? (
                  <Field style={{ width: "100%" }} name="COUNTRY_CODE">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <Select
                        className={classes.profileMDBasicSelect}
                        options={countryData}
                        placeholder="Select Country"
                        value={countryData.find(
                          (country) => country.value === value
                        )}
                        onChange={(country) =>
                          setFieldValue("COUNTRY_CODE", country.value)
                        }
                        isDisabled={!isEditMode}
                      />
                    )}
                  </Field>
                ) : (
                  <TextField
                    className={classes.inputProfileField}
                    value={userData.COUNTRY_SHORT_NAME}
                    disabled={true}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FlagCircleIcon
                            className={classes.profileMDBasicInfoIcon}
                          />
                        </InputAdornment>
                      ),
                      classes: {
                        input: classes.inputProfileField,
                        notchedOutline: isEditMode ? "" : classes.borderNone,
                      },
                    }}
                  />
                )}
                <ErrorMessage name="COUNTRY_CODE">
                  {(msg) => (
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginTop: "0px",
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </Typography>
              <Typography className={classes.profileMDBasicInfoItem}>
                <Grid container>
                  <Grid item xs={7}>
                    <TextField
                      className={classes.inputProfileField}
                      value={userData.ROLE_NAME}
                      disabled={true}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineIcon
                              className={classes.profileMDBasicInfoIcon}
                            />
                          </InputAdornment>
                        ),
                        classes: {
                          input: classes.inputProfileField,
                          notchedOutline: classes.borderNone,
                        },
                      }}
                    />
                  </Grid>
                  {isTeamView && (
                    <Grid item xs={5}>
                      <Button
                        color="primary"
                        variant="text"
                        disabled={!userData.ACTIVE}
                        onClick={() => setEntitlementPopUp(true)}
                      >
                        {langConstant.CHANGE_ENTITLEMENT}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Typography>
              {!isTeamView && (
                <>
                  <Typography className={classes.profileMDBasicInfoItem}>
                    {isEditMode ? (
                      <Field
                        style={{ width: "100%" }}
                        name="REGULATORY_DOMAIN_ID"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <Select
                            className={classes.profileMDBasicSelect}
                            options={domainList}
                            placeholder="Select Domain"
                            // value={domainList.find(
                            //   (domain) => domain.value == value
                            // )}
                            value={selectedDomains}
                            // onChange={(domain) =>
                            //   setFieldValue("REGULATORY_DOMAIN_ID", domain.value)
                            // }
                            onChange={(domains) => {
                              setSelectedDomains(domains);
                              setFieldValue("REGULATORY_DOMAIN_ID", domains);
                            }}
                            isDisabled={!isEditMode}
                            isMulti
                          />
                        )}
                      </Field>
                    ) : (
                      <TextField
                        className={classes.inputProfileField}
                        value={userData.REGULATION_DOMAIN || "-"}
                        disabled={true}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DnsIcon
                                className={classes.profileMDBasicInfoIcon}
                              />
                            </InputAdornment>
                          ),
                          classes: {
                            input: classes.inputProfileField,
                            notchedOutline: isEditMode
                              ? ""
                              : classes.borderNone,
                          },
                        }}
                      />
                    )}
                    <ErrorMessage name="REGULATORY_DOMAIN_ID">
                      {(msg) => (
                        <span
                          style={{
                            color: "red",
                            display: "block",
                            width: "100%",
                            fontSize: "14px",
                            lineHeight: "18px",
                            marginTop: "0px",
                          }}
                        >
                          {msg}
                        </span>
                      )}
                    </ErrorMessage>
                  </Typography>
                </>
              )}
              <Typography className={classes.profileMDBasicInfoItem}>
                {isEditMode ? (
                  <Field style={{ width: "100%" }} name="DATA_CLASSIFICATION">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <Select
                        className={classes.profileMDBasicSelect}
                        options={infoClassification}
                        placeholder="Select Classification"
                        value={infoClassification.find(
                          (classification) => classification.value == value
                        )}
                        onChange={(classification) =>
                          setFieldValue(
                            "DATA_CLASSIFICATION",
                            classification.value
                          )
                        }
                        isDisabled={!isEditMode}
                      />
                    )}
                  </Field>
                ) : (
                  <TextField
                    className={classes.inputProfileField}
                    value={
                      userData.DATA_CLASSIFICATION
                        ? infoClassification.find(
                            (classification) =>
                              classification.value ==
                              userData.DATA_CLASSIFICATION
                          )?.label
                        : "-"
                    }
                    disabled={true}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ManageAccountsIcon
                            className={classes.profileMDBasicInfoIcon}
                          />
                        </InputAdornment>
                      ),
                      classes: {
                        input: classes.inputProfileField,
                        notchedOutline: isEditMode ? "" : classes.borderNone,
                      },
                    }}
                  />
                )}
                <ErrorMessage name="DATA_CLASSIFICATION">
                  {(msg) => (
                    <span
                      style={{
                        color: "red",
                        display: "block",
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginTop: "0px",
                      }}
                    >
                      {msg}
                    </span>
                  )}
                </ErrorMessage>
              </Typography>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

BasicInformation.propTypes = {
  userData: PropTypes.object,
  updateUserService: PropTypes.func,
  isTeamView: PropTypes.bool,
  isEditMode: PropTypes.bool,
  setIsEditMode: PropTypes.func,
  countryData: PropTypes.array,
  domainList: PropTypes.array,
  setEntitlementPopUp: PropTypes.func,
  user_ID: PropTypes.number,
};

export default BasicInformation;
