import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import sessionService from "_services/session.service";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import { InsertLink } from "@mui/icons-material";
import questionApiService from "_components/MasterAssessmentComp/steps/services";
import individualQuestionAction from "containers/manage-regulation/NewRegulation/IndividualQuestion/actions";
import ReactDomServer from "react-dom/server";
import moment from "moment";

import RichTextEditor from "_components/RichTextEditor/RichTextEditor";
import langConstant from "_lang";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardContent } from "@mui/material";

const ShowTabContent = ({ selectedTab, saveAnswer, currentQuestion }) => {
  const [editAnswer, setEditAnswer] = useState(false);
  const [ApiDataObj, setApiDataObj] = useState({});
  const [narrativeTemplate, setNarrativeTemplate] = useState(null);
  const questionID = currentQuestion?.QUESTION_ID,
    customerID = sessionService.getCustomerId();
  const { id } = useParams();
  const dispatch = useDispatch();
  const saveAnswerFunction = (data) => {
    saveAnswer(data);
    setEditAnswer(false);
  };

  const renderRulesHtmlText = () => {
    let responsedata = ApiDataObj["Rule Decision Prefix Text"]
      ? ApiDataObj["Rule Decision Prefix Text"][0]?.RESPONSE_VALUE
      : "";
    let data = responsedata?.replace("@RuleDecision", responsedata);
    return data;
  };

  const createNarativeTemplate = (stringArr) => {
    let template = [];
    stringArr?.forEach?.((i) => {
      switch (i?.toLowerCase()) {
        case "@questiontext":
          template.push(
            ReactDomServer.renderToString(
              <p>
                {currentQuestion?.QUESTION_TEXT}{" "}
                {currentQuestion?.REGULATORS_QUESTION_REF} -{" "}
                <strong>{currentQuestion?.QUESTION_TEXT_HTML}</strong>
              </p>
            )
          );
          break;
        case "@auditinfo":
          template.push(
            ReactDomServer.renderToString(
              <p>
                This answer was generated on{" "}
                {moment().utc().format("MMMM DD, YYYY")} at{" "}
                {moment().utc().format("h.mm A")}
              </p>
            )
          );
          break;
        case "@ruledecisionprefixtext":
          template.push(
            ReactDomServer.renderToString(<p>{renderRulesHtmlText()}</p>)
          );
          break;
        case "@ruledecision":
          template.push(
            ReactDomServer.renderToString(
              <p>
                <strong>
                  {ApiDataObj["Rule Decision"]
                    ? ApiDataObj["Rule Decision"][0]?.RESPONSE_VALUE
                    : ""}
                </strong>
              </p>
            )
          );
          break;
        case "@dataassets":
          template.push(
            ReactDomServer.renderToString(
              <p>
                The data attributes available and their values are :
                {ApiDataObj["Data Assets"]?.map((el, i) => (
                  <div
                    key={`${el.DATA_ASSET_ATTRIBUTE_NAME}_${el.SOURCE_DATA_POINT_VALUE}_${i}`}
                  >
                    {el.DATA_ASSET_ATTRIBUTE_NAME} ={" "}
                    {el.SOURCE_DATA_POINT_VALUE}
                  </div>
                ))}
              </p>
            )
          );
          break;
        case "@combinedevidencescore":
          template.push(
            ReactDomServer.renderToString(
              <p>
                The evidences provided are :
                {ApiDataObj["Combined Evidence Score"]?.map((el, i) => (
                  <div key={`${el.EVIDENCE_SHORTNAME}}_${i}`}>
                    {el.EVIDENCE_SHORTNAME}
                  </div>
                ))}
              </p>
            )
          );
      }
    });
    return template?.join("\n");
  };

  const showAlert = (error, type = "error") => {
    dispatch(
      alertActions?.[type](
        error?.data?.message?.map((item, index) => {
          return <span key={index}>{item}</span>;
        }) ?? error?.toString()
      )
    );
  };

  const getNarrativeTemplateByApi = async () => {
    if (!currentQuestion?.QUESTION_ID) return;
    await Apicaller();
    await questionApiService
      .getQuestionNarrativeTemplate({
        i_QUESTION_ID: currentQuestion?.QUESTION_ID,
        i_CUSTOMER_ID: customerID,
        i_REGULATION_ID: id,
      })
      .then((response) => {
        if (response?.status !== 200) return showAlert?.(response);
        const template = response?.["#result-set-1"]?.[0];
        setNarrativeTemplate(template);
        if (!template?.NARRATIVE_TEMPLATE)
          showAlert?.("No Templates available for insertion");
      })
      .catch((error) => {
        showAlert?.(error);
        console.error("error", error);
      });
  };

  const Apicaller = async () => {
    // setIsLoading(true);

    if (!ApiDataObj?.["Data Assets"]?.length) {
      const data = await individualQuestionAction.GetDataAssetsforQuestion(
        questionID,
        customerID
      );
      if (data["#result-set-1"]) {
        setApiDataObj((prev) => ({
          ...prev,
          "Data Assets": data["#result-set-1"],
        }));
      }
    }

    if (!ApiDataObj?.["Combined Evidence Score"]?.length) {
      const data2 = await individualQuestionAction.GetEvidenceForQuestion(
        questionID,
        customerID
      );
      if (data2["#result-set-1"]) {
        setApiDataObj((prev) => ({
          ...prev,
          "Combined Evidence Score": data2["#result-set-1"],
        }));
      }
    }

    if (
      !ApiDataObj?.["Rule Decision Prefix Text"]?.length &&
      !ApiDataObj?.["Rule Decision"]?.length
    ) {
      const data3 =
        await individualQuestionAction.GetSampleRuleDecisionForQuestion(
          questionID,
          customerID
        );
      if (data3["#result-set-1"]) {
        setApiDataObj((prev) => ({
          ...prev,
          "Rule Decision Prefix Text": data3["#result-set-1"],
          "Rule Decision": data3["#result-set-1"],
        }));
      }
    }

    // getNarrativeTemplate();

    // setIsLoading(false);
  };

  return (
    // <div style={{ fontSize: '1rem', padding: '1rem' }}>
    <Card variant="outlined" style={{ height: "100%" }}>
      <CardContent style={{ padding: "5px", height: "100%" }}>
        <PerfectScrollbar>
          {selectedTab.id === 4 || selectedTab.id === 5 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    {selectedTab?.data.headers?.length &&
                      selectedTab?.data.headers.map((header) => (
                        <TableCell
                          key={header}
                          style={{ textTransform: "uppercase", padding: "5px" }}
                        >
                          {header}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTab?.data.rowData?.length &&
                    selectedTab?.data.rowData.map((el, i) => {
                      switch (selectedTab.id) {
                        case 4:
                          return (
                            <TableRow
                              key={`${el.SOURCE_DATA_ID}${i}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ padding: "5px" }}
                              >
                                {el.SOURCE_SYSTEM_NAME}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ padding: "5px" }}
                              >
                                {el.DATA_ASSET_ATTRIBUTE_NAME}
                              </TableCell>
                              <TableCell style={{ padding: "5px" }}>
                                {el.SOURCE_DATA_POINT_VALUE}
                              </TableCell>
                              <TableCell style={{ padding: "5px" }}>
                                {el.FULLNAME.trim()
                                  ? el.FULLNAME.trim()
                                  : langConstant.NAME_NOT_PROVIDED}
                              </TableCell>
                            </TableRow>
                          );

                        case 5:
                          return (
                            <TableRow
                              key={`${el.EVIDENCE_LOG_ID}${i}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ padding: "5px" }}
                              >
                                {el.EVIDENCE_SHORTNAME}
                              </TableCell>
                              <TableCell style={{ padding: "5px" }}>
                                {el.accuracy}
                              </TableCell>
                              <TableCell style={{ padding: "5px" }}>
                                {el.suitability}
                              </TableCell>
                              <TableCell style={{ padding: "5px" }}>
                                {el?.DESCRIPTION}
                              </TableCell>
                            </TableRow>
                          );

                        default:
                          break;
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : selectedTab.id === 0 ? (
            <Box>
              {!editAnswer && (
                <Box style={{ display: "flex" }}>
                  <Box style={{ width: "100%" }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedTab?.data }}
                    ></div>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => setEditAnswer(true)}
                      startIcon={<EditIcon />}
                    >
                      {langConstant.EDIT}
                    </Button>
                  </Box>
                </Box>
              )}
              {editAnswer && (
                <Box>
                  <RichTextEditor
                    data={
                      narrativeTemplate?.NARRATIVE_TEMPLATE
                        ? createNarativeTemplate(
                            narrativeTemplate?.NARRATIVE_TEMPLATE?.split(";")
                          )
                        : selectedTab?.data
                        ? selectedTab.data
                        : ""
                    }
                    onCancelClick={setEditAnswer}
                    onSaveClick={saveAnswerFunction}
                    customButtons={
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        startIcon={<InsertLink />}
                        onClick={getNarrativeTemplateByApi}
                      >
                        {langConstant.INSERT_NARRATIVE_TEMPLATE}
                      </Button>
                    }
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Box>{selectedTab?.data}</Box>
          )}
        </PerfectScrollbar>
      </CardContent>
    </Card>
    // </div>
  );
};
ShowTabContent.propTypes = {
  selectedTab: PropTypes.any,
  saveAnswer: PropTypes.func,
  currentQuestion: PropTypes.any,
};
export default ShowTabContent;
