import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography, TextField, Button } from "@material-ui/core";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useStyles from "../styles";
import GettingStartedServices from "../services";
import sessionService from "_services/session.service";

export const RegulationQuestions = ({
  regulatoryControlGroup,
  questionList,
  answers,
  setAnswers,
  index,
  totalRegulatoryControlGroup,
  setIndex,
}) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const QUESTIONS_PER_PAGE = 5;
  const totalPages = Math.ceil(questionList?.length / QUESTIONS_PER_PAGE);
  const customerID = sessionService.getCustomerId();
  const userID = sessionService.getUserId();

  // Dynamically calculate the start index
  const startIndex = currentPage * QUESTIONS_PER_PAGE;
  const currentQuestions = questionList?.slice(
    startIndex,
    startIndex + QUESTIONS_PER_PAGE
  );

  // Handle answer changes
  const handleAnswerChange = (id, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: value,
    }));
  };

  // Submit the form when finished
  const handleSubmit = () => {
    console.log("User Answers:", answers);
  };

  // Move to the next set of questions
  const handleNext = () => {
   
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (currentPage == totalPages - 1) {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Move back to the previous set of questions
  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else if (currentPage == 0 && index > 0) {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };
  const capitalizeFirstLetter = (groupName) => {
    return groupName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const saveAnswers = () => {
    // GettingStartedServices.putRegulationSetupAnswers(
    //   customerID,
    //   submissionID,
    //   questionID,
    //   questionAnswer,
    //   userID
    // );
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [questionList]);

  return (
    <Box
      sx={{
        padding: 2,
        marginRight: "100px",
        marginLeft: "100px",
        marginTop: "20px",
      }}
    >
      <Typography variant="h4" style={{ marginBottom: "50px" }}>
        {capitalizeFirstLetter(regulatoryControlGroup?.toString())}
      </Typography>

      {currentQuestions.map((q, index) => (
        <Box key={q.QUESTION_ID} sx={{ marginBottom: 2, marginTop: "20px" }}>
          <Typography
            variant="h6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
              {startIndex + index + 1}
            </span>
            <ArrowForwardIcon
              style={{ fontSize: "1rem", margin: "0 8px 0 0px" }}
            />
            <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>
              {q.QUESTION_TEXT}
            </span>
          </Typography>

          <TextField
            variant="outlined"
            multiline={q.type === "longText"}
            rows={q.type === "longText" ? 4 : 1}
            className={classes.inputField}
            value={
              answers[q.QUESTION_ID] || q.CURRENT_ANSWER_SELECTED_RESPONSE || ""
            }
            onChange={(e) => handleAnswerChange(q.QUESTION_ID, e.target.value)}
          />
        </Box>
      ))}

      {questionList.length > 0 && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
            padding: "10px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {(currentPage > 0 || index > 0) && (
            <Grid style={{ marginRight: "10px" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                sx={{
                  marginRight: "10px",
                  transition: "transform 0.2s",
                  "&:hover .MuiButton-startIcon": {
                    transform: "translateX(-5px)",
                  },
                }}
              >
                Back
              </Button>
            </Grid>
          )}

          {currentPage < totalPages - 1 ||
          index !== totalRegulatoryControlGroup - 1 ? (
            <Grid style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                // startIcon={<ArrowForwardIcon />}
                endIcon={
                  <ArrowForwardIcon
                    sx={{
                      transition: "transform 0.2s",
                      "&:hover": { transform: "translateX(5px)" },
                    }}
                  />
                }
              >
                Next
              </Button>
            </Grid>
          ) : (
            <Grid style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit Answers
              </Button>
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

RegulationQuestions.propTypes = {
  regulatoryControlGroup: PropTypes.string,
  questionList: PropTypes.array.isRequired,
  answers: PropTypes.object,
  setAnswers: PropTypes.func,
  index: PropTypes.number,
  totalRegulatoryControlGroup: PropTypes.number,
  setIndex: PropTypes.func,
};

export default RegulationQuestions;
