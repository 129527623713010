/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button, Typography, TextField } from "@material-ui/core";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import LoupeIcon from "@material-ui/icons/Loupe";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import * as yup from "yup";

import langConstant from "_lang";
import rootStyles from "../../../../../rootStyles";

const RegulatorResponseCriteria = ({
  isDataPresent,
  responseType,
  responseOptionIds,
  insertResponseData,
  qid,
}) => {
  const rootClasses = rootStyles();
  var initialValues = {
    questionRespons: [],
  };
  const validationSchema = yup.object().shape({
    questionRespons: yup.array().of(
      yup.object().shape({
        v_response_option: yup.string().required("Header is a required field."),
      })
    ),
  });
  const responseTypeId = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <>
      <Box>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            const ids = responseOptionIds?.length
              ? responseTypeId.filter((id) => !responseOptionIds.includes(id))
              : responseTypeId;
            values.questionRespons.forEach((responseData, index) => {
              responseData.v_response_option_id = ids[index];
              insertResponseData(responseData);
            });
            resetForm();
          }}
          validationSchema={validationSchema}
          render={({ values, handleReset, handleChange }) => {
            return (
              <Form>
                <Box>
                  <Box style={{ marginRight: "30px" }}>
                    <FieldArray
                      name="questionRespons"
                      render={({ insert, remove, push }) => (
                        <Box>
                          {values.questionRespons &&
                            values.questionRespons.map((item, index) => (
                              <Box
                                key={index}
                                display="flex"
                                style={{
                                  marginTop: "10px",
                                  marginRight: "7rem",
                                }}
                              >
                                <Box
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <Typography style={{ marginBottom: "10px" }}>
                                    {langConstant.ADD_HEADER}
                                  </Typography>
                                  <TextField
                                    name={`questionRespons.${index}.v_response_option`}
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    value={item.v_response_option}
                                    onChange={(e) => {
                                      // if (
                                      //   e.target.value.match(/^[a-zA-Z0-9\s]*$/)
                                      // ) {
                                      handleChange(e);
                                      // }
                                    }}
                                    // onPaste={(e) => e.preventDefault()}
                                  ></TextField>
                                  <ErrorMessage
                                    name={`questionRespons[${index}].v_response_option`}
                                  >
                                    {(msg) => (
                                      <div style={{ color: "red" }}>{msg}</div>
                                    )}
                                  </ErrorMessage>
                                  <Typography style={{ marginBottom: "10px" }}>
                                    {langConstant.ADD_DESCRIPTION}
                                  </Typography>
                                  <TextField
                                    name={`questionRespons.${index}.v_response_value`}
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    value={item.v_response_value}
                                    onChange={(e) => {
                                      // if (
                                      //   e.target.value.match(/^[a-zA-Z0-9\s]*$/)
                                      // ) {
                                      handleChange(e);
                                      // }
                                    }}
                                    // onPaste={(e) => e.preventDefault()}
                                  ></TextField>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "end",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="secondary"
                                    style={{
                                      minWidth: "30px",
                                      lineHeight: "18px",
                                    }}
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteOutlineIcon
                                      style={{
                                        color: "#FF7070",
                                        fontSize: "28px",
                                      }}
                                    />{" "}
                                  </Button>
                                </Box>
                              </Box>
                            ))}
                          {isDataPresent < 0 && (
                            <Typography>
                              {langConstant.NO_RESPONSE_MAPPED_TO_QUESTION}
                            </Typography>
                          )}
                          <Button
                            type="button"
                            onClick={() =>
                              push({
                                v_question_id: qid,
                                v_response_type_id: "",
                                v_response_option: "",
                                v_response_option_id: "",
                                v_response_value: "",
                              })
                            }
                            style={{
                              color:
                                values.questionRespons.length +
                                  isDataPresent !==
                                responseTypeId.length
                                  ? "#007BFF"
                                  : "gray",
                              fontWeight: "400",
                              fontSize: "14px",
                              margin: "20px 0 0 0px",
                            }}
                            disabled={
                              values.questionRespons.length + isDataPresent ==
                              responseTypeId.length
                            }
                          >
                            <LoupeIcon style={{ marginRight: "10px" }} />
                            {langConstant.ADD_MORE_RESPONSE_CRITERIA}
                          </Button>
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
                <Box mt={3} display="flex">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="medium"
                    // className={rootClasses.blueButton}
                    startIcon={<SaveOutlinedIcon />}
                    disabled={!responseType.responseTypeValue?.length}
                  >
                    {langConstant.SAVE}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={(event) => {
                      event.preventDefault();
                      handleReset();
                    }}
                    style={{ marginLeft: "30px" }}
                  >
                    {langConstant.CANCEL}
                  </Button>
                </Box>
              </Form>
            );
          }}
        />
      </Box>
    </>
  );
};

RegulatorResponseCriteria.propTypes = {
  questionResponses: PropTypes.array,
  isDataPresent: PropTypes.any,
  insertResponseData: PropTypes.func,
  responseType: PropTypes.object,
  responseOptionIds: PropTypes.any,
  qid: PropTypes.string,
};
export default RegulatorResponseCriteria;
