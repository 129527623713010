import { makeStyles } from "@material-ui/core";
import { Padding } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  checkboxStyle: {
    marginLeft: "auto",
    width: "3%",
  },
  labelStyle2: {
    flex: "1",
    padding: "0 10px",
    borderRight: "2px solid rgba(0 0 0 / 0.3)",
  },
  optionsStyle: {
    display: "flex !important",
    alignItems: "center",
  },
  labelStyle: {
    borderRight: "2px solid rgba(0 0 0 / 0.3)",
    flex: "0 0 50px",
    padding: "0 10px",
  },
  labelStyle3: {
    borderRight: "2px solid rgba(0 0 0 / 0.3)",
    flex: "0 0 200px",
    padding: "0 10px",
    color: "gray",
  },
  labelStyle4: {
    borderRight: "2px solid rgba(0 0 0 / 0.3)",
    padding: "0 10px",
    flex: "0 0 250px",
    color: "gray",
  },
}));

export default useStyles;
