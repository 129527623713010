import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Button, FormControlLabel, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import langConstant from "_lang";
import { useDispatch } from "react-redux";
import { Box, FormControl, Radio, RadioGroup } from "@mui/material";
import { SketchPicker } from "react-color";
import ConfigurationsServices from "./services";

const HeaderCustomisation = ({ customerData, setCustomerData }) => {
  const [selectedType, setSelectedType] = useState(
    customerData ? customerData?.TOPBAR_TYPE : "color"
  );
  const [color, setColor] = useState("#ffffff");
  const [gradient, setGradient] = useState({
    angle: 90,
    colors: [
      { offset: "0%", color: "#ff0000" },
      { offset: "100%", color: "#0000ff" },
    ],
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerData && customerData.TOPBAR_TYPE) {
      setSelectedType(customerData.TOPBAR_TYPE);
      if (customerData.TOPBAR_TYPE == "image" && customerData.TOPBAR_VALUE) {
        setImagePreview(customerData.TOPBAR_VALUE);
      }
    }
  }, [customerData?.TOPBAR_TYPE]);

  const handleUpdate = async () => {
    let newBackground;

    if (selectedType === "color") {
      newBackground = {
        type: "color",
        value: color,
        CUSTOMER_ID: customerData.CUSTOMER_ID,
      };
    } else if (selectedType === "gradient") {
      const gradientCSS =
        gradient.colors && Array.isArray(gradient.colors)
          ? `linear-gradient(${gradient.angle}deg, ${gradient.colors
              .map((c) => `${c.color} ${c.offset}`)
              .join(", ")})`
          : "";

      newBackground = {
        type: "gradient",
        value: gradientCSS,
        CUSTOMER_ID: customerData.CUSTOMER_ID,
      };
    } else if (selectedType === "image" && imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = async () => {
        const base64Image = reader.result;
        newBackground = {
          type: "image",
          value: base64Image,
          CUSTOMER_ID: customerData.CUSTOMER_ID,
        };
        dispatch(
          ConfigurationsServices.updateCustomerTopBarConfiguration(
            newBackground,
            setCustomerData
          )
        );
      };
      return; // Wait for FileReader to complete
    }
    dispatch(
      ConfigurationsServices.updateCustomerTopBarConfiguration(
        newBackground,
        setCustomerData
      )
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <Grid>
      <Grid className={classes.configurationLabelWrapper}>
        <Typography className={classes.configurationLabel}>
          {langConstant.TOPBAR_CUSTOMISATION}
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormControl>
          <RadioGroup
            row
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <FormControlLabel
              value="color"
              control={<Radio />}
              label="Color"
              sx={{ color: "#fff" }}
            />
            <FormControlLabel
              value="gradient"
              control={<Radio />}
              label="Gradient"
              sx={{ color: "#fff" }}
            />
            <FormControlLabel
              value="image"
              control={<Radio />}
              label="Image"
              sx={{ color: "#fff" }}
            />
          </RadioGroup>
        </FormControl>
        {selectedType === "color" && (
          <Box sx={{ marginBottom: "10px", display: "flex" }}>
            <SketchPicker
              color={
                customerData &&
                customerData.TOPBAR_TYPE == "color" &&
                customerData.TOPBAR_VALUE
                  ? customerData.TOPBAR_VALUE
                  : color
              }
              onChangeComplete={(newColor) => setColor(newColor.hex)}
              style={{ marginTop: "20px" }}
            />

            <Box
              sx={{
                width: "40px",
                height: "20px",
                backgroundColor:
                  customerData &&
                  customerData.TOPBAR_TYPE == "color" &&
                  customerData.TOPBAR_VALUE
                    ? customerData.TOPBAR_VALUE
                    : color,
                marginLeft: "10px",
                borderRadius: "5px",
                border: "1px solid black",
              }}
            />
          </Box>
        )}
        {selectedType === "gradient" && (
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "20px",
                background: customerData.TOPBAR_VALUE,
                borderRadius: "5px",
                marginBottom: "10px",
                border: "1px solid black",
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: "1px",
              }}
            >
              <SketchPicker
                color={gradient.colors[0].color}
                onChangeComplete={(newColor) => {
                  const updatedGradient = { ...gradient };
                  updatedGradient.colors[0].color = newColor.hex;
                  setGradient(updatedGradient);
                  setCustomerData((prevState) => ({
                    ...prevState,
                    TOPBAR_TYPE: "gradient",
                    TOPBAR_VALUE: `linear-gradient(${
                      updatedGradient.angle
                    }deg, ${updatedGradient.colors
                      .map((c) => `${c.color} ${c.offset}`)
                      .join(", ")})`,
                  }));
                }}
              />
              <SketchPicker
                color={gradient.colors[1].color}
                onChangeComplete={(newColor) => {
                  const updatedGradient = { ...gradient };
                  updatedGradient.colors[1].color = newColor.hex;
                  setGradient(updatedGradient);
                  setCustomerData((prevState) => ({
                    ...prevState,
                    TOPBAR_TYPE: "gradient",
                    TOPBAR_VALUE: `linear-gradient(${
                      updatedGradient.angle
                    }deg, ${updatedGradient.colors
                      .map((c) => `${c.color} ${c.offset}`)
                      .join(", ")})`,
                  }));
                }}
              />
            </Box>
          </Box>
        )}
        {selectedType === "image" && (
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {imagePreview && (
              <Box
                sx={{
                  width: "200px",
                  height: "100px",
                  backgroundImage: `url(${imagePreview})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  border: "1px solid #ccc",
                }}
              />
            )}
          </Box>
        )}
      </Box>
      <Grid style={{ textAlign: "right", marginTop: "20px" }}>
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

HeaderCustomisation.propTypes = {
  customerData: PropTypes.oneOfType([() => null, PropTypes.object]),
  setCustomerData: PropTypes.func,
};

export default HeaderCustomisation;
