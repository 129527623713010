import { FormLabel } from "@material-ui/core";
import React, { useEffect } from "react";
import propTypes from "prop-types";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import {
  ACTION_STATUS_LIST,
  priorities,
  linkTypes,
} from "containers/cyber-assessment/AuditActions/constants";
import {
  GetTeamDetails,
  PutActions,
} from "containers/cyber-assessment/AuditActions/services";

import { actionValidation } from "./contants";
import { AddActionForm } from "./AddActionForm";

const intialValues = {
  actionShortDesc: "",
  actionFullDesc: "",
  assignedTo: null,
  // assignedBy: "",
  priority: priorities[0],
  status: ACTION_STATUS_LIST[0].value,
  closureDate: "",
  dueDate: "",
};
const validationSchema = actionValidation.addAction;

const Label = ({ label, htmlFor, ...rest }) => (
  <FormLabel required htmlFor={htmlFor} {...rest}>
    {label}
  </FormLabel>
);

const getAttachedItem = (actionList) => {
  if (!actionList?.ACTIONS_LIST_LINK_ID || !actionList?.ACTIONS_LIST_TYPE)
    return null;
  const mapping = {
    [linkTypes[0]]: "i_SUBMISSION_ID",
    [linkTypes[1]]: "i_POLICY_ID",
    [linkTypes[2]]: "i_POLICYCOMPARISON_ID",
    [linkTypes[3]]: "i_REGULATION_ID",
  };
  return {
    [mapping[actionList?.ACTIONS_LIST_TYPE]]: actionList?.ACTIONS_LIST_LINK_ID,
  };
};

export const AddAction = ({ addActionModal, successCallback, actionList }) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId(),
    customerID = sessionService.getCustomerId();
  const userList = useSelector((state) => state?.auditActions?.userList);

  const onSubmit = async (values, actions) => {
    actions?.setSubmitting(true);
    const payload = {
      i_ACTIONS_SHORT_DESCRIPTION: values?.actionShortDesc,
      i_ACTIONS_FULL_DESCRIPTION: values?.actionFullDesc,
      i_ASSIGNED_TO: values?.assignedTo?.value,
      i_ASSIGNED_BY: userID,
      i_ACTIONS_PRIORITY: values?.priority,
      i_ACTIONS_STATUS: values?.status,
      i_DUE_DATE: values?.dueDate,
      i_CLOSURE_DATE: values?.closureDate,
      i_ACTIONS_ID: null,
      i_SUBMISSION_ID: addActionModal
        ? addActionModal.data.SUBMISSION_ID
        : null,
      i_QUESTION_ID: addActionModal ? addActionModal.data.QUESTION_ID : null,
      i_RFI_ID: null,
      i_POLICY_ID: null,
      i_POLICYCOMPARISON_ID: null,
      i_POLICY_COMPARISON_DETAILS_ID: null,
      i_ACTIONS_LIST_ID: actionList?.ACTIONS_LIST_ID,
      ...getAttachedItem(actionList),
    };


    await PutActions(payload)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(alertActions.success("Action successfully added"));

          setTimeout(() => {
            successCallback?.();
            addActionModal?.closeModal();
          }, 300);
        } else {
          dispatch(
            alertActions.error(
              response?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) || "Something went wrong"
            )
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(alertActions.error(err?.toString() || "Something went wrong"));
      })
      .finally(() => actions?.setSubmitting(false));
  };

  // if user list is not availlable fetch users
  useEffect(() => {
    if (userList?.length) return;
    dispatch(GetTeamDetails(userID));
  }, []);

  return (
    <React.Fragment>
      <AddActionForm
        intialValues={intialValues}
        onClose={addActionModal?.closeModal}
        onSubmit={onSubmit}
        userList={userList}
        validationSchema={validationSchema}
      />
    </React.Fragment>
  );
};

AddAction.propTypes = {
  addActionModal: propTypes.object,
  actionList: propTypes.object,
  successCallback: propTypes.func,
};
Label.propTypes = {
  label: propTypes.string,
  htmlFor: propTypes.string,
};
