import { Grid, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import langConstant from "_lang";
import useStyles from "../../NewPolicyUpload/styles";
import ExtractedContent from "./components/extractedContent";
import PropTypes from "prop-types";
import DocumentViewer from "_components/DocumentViewer";
import CompareIcon from "@mui/icons-material/Compare";
import { useDispatch, useSelector } from "react-redux";
import Stepper2ProgressDrawer from "./components/stepper2ProgressDrawer";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ReplayIcon from "@mui/icons-material/Replay";
import PredictionServices from "../services";
import { alertActions, loadingActions } from "_actions";
import {
  setOriginalPredictedtextValues,
  setProcessedPredictedtextValues,
} from "../actions";
import sessionService from "_services/session.service";

const Step2 = ({ closeDocumentUploadPopUp, setCurrentStep }) => {
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();

  const documentsInfo = useSelector(
    (state) => state.uploadPolicyReducer.documentInfo
  );

  const step1Data = useSelector((state) => state.uploadPolicyReducer.step1);

  const isReRunMode = useSelector(
    (state) => state.uploadPolicyReducer.isReRunMode
  );
  const startPage = useSelector(
    (state) => state.uploadPolicyReducer.step1.pages_extraction_start
  );
  const endPage = useSelector(
    (state) => state.uploadPolicyReducer.step1.pages_extraction_end
  );

  const [compareFileDialog, setCompareFileDialog] = useState(false);
  const [predictionFromProcessed, setPredictionFromProcessed] = useState(true);
  const [currentUrl, setCurrentUrl] = useState({
    filePath: "",
    fileName: "",
    fileType: "",
  });
  const [progressLogsData, setProgressLogsData] = useState([]);
  const [isProgressView, setIsProgressView] = useState(false);
  const [isProgressDialog, setIsProgressDialog] = useState(false);

  useEffect(() => {
    if (documentsInfo.isPreProcessed && predictionFromProcessed) {
      setCurrentUrl({
        filePath:
          customerID.toString() +
          "/Policy_Management/Extraction/" +
          documentsInfo.policyId +
          "/",
        fileName: documentsInfo.processed.name,
        fileType: documentsInfo.processed.extension,
      });
    } else {
      setPredictionFromProcessed(false);
      setCurrentUrl({
        filePath:
          customerID.toString() +
          "/Policy_Management/Extraction/" +
          documentsInfo.policyId +
          "/",
        fileName: documentsInfo.original.name,
        fileType: documentsInfo.original.extension,
      });
    }
  }, [documentsInfo]);

  const reRunPrediction = async () => {
    const payload = {
      docName: documentsInfo.isPreProcessed
        ? documentsInfo.processed.name
        : documentsInfo.original.name,
      fileId: documentsInfo.policyId,
      startPage: startPage,
      endPage: endPage,
      docType: documentsInfo.isPreProcessed ? "processed" : "original",
      stepper: "2",
      customerID: customerID,
      version_1_prediction: step1Data.version_1_prediction,
      language: step1Data.document_language.label,
      languageCode: step1Data.document_language.value,
    };
    dispatch(loadingActions.start());
    dispatch(
      loadingActions.setLoaderText(
        langConstant.DOCUMENT_CLASSIFICATION_INPROGRESS
      )
    );
    const response = await PredictionServices.getPrediction(payload);
    dispatch(loadingActions.end());
    dispatch(loadingActions.setLoaderText(null));
    if (response.status) {
      if (response.status === 200 && response.data.length > 0) {
        documentsInfo.isPreProcessed
          ? dispatch(setProcessedPredictedtextValues(response.data))
          : dispatch(setOriginalPredictedtextValues(response.data));
      } else {
        if (response.message) {
          dispatch(alertActions.error(response.message));
        }
      }
    } else {
      if (response.data && response.data.message) {
        dispatch(
          alertActions.error(
            response.data.message.map((item, index) => {
              return <div key={index}>{item}</div>;
            })
          )
        );
      } else {
        dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
      }
    }
  };

  return (
    <Grid>
      {isProgressView && (
        <Button
          className={classes.progressBtn}
          onClick={() => setIsProgressDialog(true)}
        >
          <HistoryToggleOffIcon />
        </Button>
      )}
      <Typography variant="h6" component="h6" className={classes.step2Header}>
        {langConstant.DOCUMENT_CONTENT_IDENTIFIER}
      </Typography>
      <Grid container spacing={5}>
        <Grid item md={5} xs={12}>
          <Grid className={classes.docViewer} style={{ height: "640px" }}>
            {!compareFileDialog && (
              <DocumentViewer
                documentClass={classes.documentClass}
                height="620px"
                fileName={currentUrl.fileName}
                filePath={currentUrl.filePath}
                fileType={currentUrl.fileType}
              />
            )}
          </Grid>
          {!isReRunMode && documentsInfo.isPreProcessed && (
            <Button
              startIcon={<CompareIcon />}
              onClick={() => setCompareFileDialog(true)}
              className={classes.compareIcon}
            >
              {predictionFromProcessed
                ? langConstant.COMPARE_WITH_ORIGINAL
                : langConstant.COMPARE_WITH_PROCESSED_FILE}
            </Button>
          )}
          {isReRunMode && (
            <Button
              startIcon={<ReplayIcon />}
              onClick={() => reRunPrediction()}
              className={classes.compareIcon}
            >
              {langConstant.RERUN_CLASSIFICATION}
            </Button>
          )}
        </Grid>
        <Grid item md={7} xs={12}>
          <ExtractedContent
            predictionFromProcessed={predictionFromProcessed}
            closeDocumentUploadPopUp={closeDocumentUploadPopUp}
            setCurrentStep={setCurrentStep}
            compareFileDialog={compareFileDialog}
            setCompareFileDialog={setCompareFileDialog}
            setPredictionFromProcessed={setPredictionFromProcessed}
            setCurrentUrl={setCurrentUrl}
            isProgressView={isProgressView}
            setProgressLogsData={setProgressLogsData}
            setIsProgressView={setIsProgressView}
            setIsProgressDialog={setIsProgressDialog}
          />
        </Grid>
      </Grid>
      {isProgressDialog && (
        <Stepper2ProgressDrawer
          show={isProgressDialog}
          setShow={setIsProgressDialog}
          progressLogsData={progressLogsData}
        />
      )}
    </Grid>
  );
};

Step2.propTypes = {
  closeDocumentUploadPopUp: PropTypes.func,
  setCurrentStep: PropTypes.func,
};

export default Step2;
