import React, { memo, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  makeStyles,
  Paper,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import AppPagination from "_components/AppPagination";
import PropTypes from "prop-types";
import langConstant from "_lang";
import { AccessAlarm, Check, Close, PendingActions } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));

const columns = [
  {
    field: "NO",
    headerName: "No",
    minWidth: 40,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 40,
  },
  {
    field: "header",
    headerName: "Section",
    minWidth: 60,
  },
  {
    field: "gap_identified",
    headerName: "Recommendations",
    minWidth: 350,
  },
];

const AnalysisTable = memo(({ analyseData, setNum }) => {
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState([]);
  const classes = useStyles();
  const maxLength = 45;

  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  useEffect(() => {
    setFilteredRows(analyseData);
    setPageCount(calculatePageCount(analyseData));
  }, []);

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [target, setTarget] = useState("");
  const handleClick = (value) => {
    setTarget(value);
    setNum((prev) => prev + 5);
  };

  useEffect(() => {
    if (!target) return;

    const checkElementAndScroll = async () => {
      const element = document.getElementById(target);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else {
        while (!document.getElementById(target)) {
          setNum((prev) => prev + 1);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        const element = document.getElementById(target);
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    checkElementAndScroll();
  }, [target, analyseData]);

  return (
    <Grid>
      {pageCount > 1 && (
        <Box my={2}>
          <Grid container justify="flex-end">
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      )}
      <TableContainer
        component={Paper}
        style={{
          border: "1px solid rgba(0,0,0,0.1)",
          borderBottomStyle: "none",
          borderTopStyle: "solid",
          borderRightStyle: "solid",
          borderLeftStyle: "solid",
        }}
      >
        <Table className="analysis-table" aria-label="Regulation List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={item.sticky && classes.stickyColl}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, outerindex) => (
              <TableRow key={`row_${outerindex}_${row[columns[0].field]}`}>
                {columns.map((column, index) => {
                  const value =
                    (column.field == "gap_identified" ||
                      column.field == "header") &&
                    row[column.field].length > maxLength ? (
                      <span
                        style={{ textDecoration: "underline", color: "blue" }}
                      >
                        {row[column.field].slice(0, maxLength) + "..."}
                      </span>
                    ) : (
                      row[column.field]
                    );

                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={"col_" + index + row[columns[0].field]}
                      className={column.sticky && classes.stickyColl}
                      style={{
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      {column.field == "NO" &&
                        `${outerindex + 1 + page * rowsPerPage}`}
                      {/*{column.field == "status2" && "pending"}*/}
                      {/* {column.field == "header" && value} */}
                      {column.field == "status" &&
                        (value == "Accept" ? (
                          <Check sx={{ color: "green" }} />
                        ) : value == "Reject" ? (
                          <Close sx={{ color: "red" }} />
                        ) : (
                          <AccessAlarm sx={{ color: "orange" }} />
                        ))}
                      {column.field == "header" && value}
                      {column.field == "gap_identified" && (
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleClick(row.gapKey);
                          }}
                        >
                          {value}
                        </Box>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  {langConstant.NO_RECORD_FOUND}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
});
AnalysisTable.displayName = "AnalysisTable";

AnalysisTable.propTypes = {
  analyseData: PropTypes.array,
  setNum: PropTypes.func,
};
export default AnalysisTable;
