import React, { useState } from "react";
import useStyles from "./styles";
import { Button, FormControlLabel, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import langConstant from "_lang";
import { Form, Formik } from "formik";
import ConfigurationsUtils from "./utils";
import ColorPicker from "./components/ColorPicker";
import { useDispatch } from "react-redux";
import ConfigurationsServices from "./services";
import HeaderCustomisation from "./HeaderCustomisation";

const ThemeCustomisation = ({ customerData, setCustomerData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(
      ConfigurationsServices.updateCustomerThemeConfiguration(
        values,
        setCustomerData
      )
    );
  };

  return (
    <Grid className={classes.configurationWrapper}>
      <Grid className={classes.configurationLabelWrapper}>
        <Typography className={classes.configurationLabel}>
          {langConstant.THEME_CUSTOMISATION}
        </Typography>
      </Grid>
      <Grid>
        <Formik
          initialValues={
            customerData ? customerData : ConfigurationsUtils.ThemeInitialData
          }
          validationSchema={ConfigurationsUtils.ThemeValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={false}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {/* <ColorPicker
                fieldLabel={langConstant.TOPBAR_BACKGROUND}
                hexColor={values.TOPBAR_BGROUND_COLOUR}
                field={"TOPBAR_BGROUND_COLOUR"}
                setFieldValue={setFieldValue}
                errors={errors}
              /> */}
              <ColorPicker
                fieldLabel={langConstant.MENUBAR_BACKGROUND}
                hexColor={values.MENUBAR_BGROUND_COLOUR}
                field={"MENUBAR_BGROUND_COLOUR"}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <ColorPicker
                fieldLabel={langConstant.ACTIONBUTTON_BACKGROUND}
                hexColor={values.ACTIONBUTTON_BGROUND_COLOUR}
                field={"ACTIONBUTTON_BGROUND_COLOUR"}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <ColorPicker
                fieldLabel={langConstant.TABLE_HEADER}
                hexColor={values.TABLE_HEADER_COLOUR}
                field={"TABLE_HEADER_COLOUR"}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <ColorPicker
                fieldLabel={langConstant.FONT_COLOUR_LIGHT}
                hexColor={values.FONT_COLOUR_LIGHT}
                field={"FONT_COLOUR_LIGHT"}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <ColorPicker
                fieldLabel={langConstant.FONT_COLOUR_DARK}
                hexColor={values.FONT_COLOUR_DARK}
                field={"FONT_COLOUR_DARK"}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <Grid style={{ textAlign: "right", marginTop: "15px" }}>
                <Button type="submit" variant="contained" color="primary">
                  {langConstant.UPDATE}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid>
        <HeaderCustomisation
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
      </Grid>
    </Grid>
  );
};

ThemeCustomisation.propTypes = {
  customerData: PropTypes.oneOfType([() => null, PropTypes.object]),
  setCustomerData: PropTypes.func,
};

export default ThemeCustomisation;
