import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Typography, TextField, Button, Box } from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import { ViewNotesDialog } from "containers/manage-regulation/Policy/PolicyComparison/ComparisonDetail/CompareTabPanel/component/ViewNotesDialog";
import { ViewActionsDialog } from "containers/manage-regulation/Policy/PolicyComparison/ComparisonDetail/CompareTabPanel/component/ViewActionsDialog";
import sessionService from "_services/session.service";
import { IconButton, Tooltip } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import { ReceiptLong } from "@mui/icons-material";

const SearchingComponent = memo(
  ({
    searchTerm,
    setSearchTerm,
    data,
    setData,
    cmpID,
    policyMetaDataForDrawer,
  }) => {
    const [replaceValue, setReplaceValue] = useState("");
    const [matchCount, setMatchCount] = useState(0);
    const [currentOpenDialog, setCurrentOpenDialog] = useState(null);
    const modalOptions = {
      isOpen: Boolean(currentOpenDialog),
      closeModal: () => setCurrentOpenDialog(null),
    };
    let userID = sessionService.getUserId();
    const handleReplace = () => {
      let replaced = false;
      let sectionOrder = null;

      const newData = data.map((section) => {
        if (replaced) return section;
        if (sectionOrder == null || sectionOrder != section.SECTION_ORDER) {
          sectionOrder = section.SECTION_ORDER;
          if (
            new RegExp(searchTerm, "gi").test(section.SECTION_NAME) &&
            !replaced
          ) {
            section.SECTION_NAME = section.SECTION_NAME.replace(
              new RegExp(searchTerm, "gi"),
              `${replaceValue}`
            );
            replaced = true;
          }
        }

        if (
          new RegExp(searchTerm, "gi").test(section.ORIGINAL_TEXT) &&
          !replaced
        ) {
          if (section.ORIGINAL_TEXT != section.MODIFIED_TEXT) {
            section.ORIGINAL_TEXT = section.ORIGINAL_TEXT.replace(
              new RegExp(searchTerm, "gi"),
              `${replaceValue}`
            );
            replaced = true;
          } else {
            section.MODIFIED_TEXT = section.MODIFIED_TEXT.replace(
              new RegExp(searchTerm, "gi"),
              `${replaceValue}`
            );
            replaced = true;
          }
        }
        if (
          new RegExp(searchTerm, "gi").test(section.modified_sentence_copy) &&
          !replaced
        ) {
          section.modified_sentence_copy =
            section.modified_sentence_copy.replace(
              new RegExp(searchTerm, "gi"),
              `${replaceValue}`
            );
          replaced = true;
        }

        return section;
      });
      setMatchCount(0);
      setData(newData);
    };

    const replaceAllOccurrences = () => {
      const newData = data.map((section) => {
        section.SECTION_NAME = section.SECTION_NAME.replace(
          new RegExp(searchTerm, "gi"),
          `${replaceValue}`
        );
        if (section?.ORIGINAL_TEXT) {
          section.ORIGINAL_TEXT = section.ORIGINAL_TEXT.replace(
            new RegExp(searchTerm, "gi"),
            `${replaceValue}`
          );
        }

        if (section.modified_sentence_copy) {
          section.modified_sentence_copy =
            section.modified_sentence_copy.replace(
              new RegExp(searchTerm, "gi"),
              `${replaceValue}`
            );
        }

        return section;
      });
      setMatchCount(matchCount - 1);
      setData(newData);
    };

    const countSearchTerms = () => {
      let count = 0;

      if (!searchTerm.trim()) {
        return count;
      }

      let sectionOrder = null;
      let referenceSectionMatches = null;
      const escapedSearchTerm = searchTerm.replace(
        /[-/\\^$*+?.()|[\]{}]/g,
        "\\$&"
      );

      data.forEach((section) => {
        if (sectionOrder == null || sectionOrder != section.SECTION_ORDER) {
          sectionOrder = section.SECTION_ORDER;
          referenceSectionMatches = section.SECTION_NAME.match(
            new RegExp(escapedSearchTerm, "gi")
          );
          if (referenceSectionMatches) {
            count += referenceSectionMatches.length;
          }
        }
        let combinedSentences = "";
        if (section?.ORIGINAL_TEXT === section?.modified_sentence_copy) {
          combinedSentences = section?.ORIGINAL_TEXT;
        } else {
          combinedSentences = `${section?.ORIGINAL_TEXT} ${section?.modified_sentence_copy}`;
        }

        const regex = new RegExp(escapedSearchTerm, "gi");
        const matches = combinedSentences.match(regex);

        if (matches) {
          count += matches.length;
        }
      });

      return count;
    };

    const _renderDialogs = () => {
      if (!currentOpenDialog) return <></>;
      const payload = {
        i_POLICYCOMPARISON_ID: cmpID ? cmpID : null,
        i_USER_ID: userID ? Number(userID) : null,
        i_POLICY_ID: null,
        i_COMPARISON_ID: cmpID ? cmpID : null,
      };
      switch (currentOpenDialog) {
        case "viewNotes":
          return <ViewNotesDialog modal={modalOptions} data={payload} />;
        case "viewActions":
          return <ViewActionsDialog modal={modalOptions} data={payload} />;
        default:
          break;
      }
    };
    const [acceptedGap, setAcceptedGap] = useState(0);
    const [totalGap, setTotalGap] = useState(0);
    useEffect(() => {
      const count = countSearchTerms();
      setMatchCount(count);
      if (policyMetaDataForDrawer) {
        setAcceptedGap(
          policyMetaDataForDrawer.filter(
            (item) =>
              item.GAP_ANALYSIS_STATUS === "Accept" &&
              item.RECOMMENDATION !== null
          ).length
        );
        setTotalGap(
          policyMetaDataForDrawer.filter((item) => item.RECOMMENDATION !== null)
            .length
        );
      }
    }, [searchTerm, data, policyMetaDataForDrawer]);

    const progress = (acceptedGap / totalGap) * 100;
    return (
      <React.Fragment>
        {_renderDialogs()}
        <Grid container alignItems="center">
          <Grid container alignItems="center" spacing={1}>
            <Grid item container xs={6} spacing={1} alignItems="center">
              <Grid item>
                <TextField
                  label="Search Text"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Replace Text"
                  variant="outlined"
                  value={replaceValue}
                  onChange={(e) => setReplaceValue(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleReplace()}
                  disabled={!searchTerm || !replaceValue}
                >
                  Replace
                </Button>
              </Grid>
              <Grid item>
                <Typography marginTop={1}>
                  {searchTerm && `${matchCount}`}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => replaceAllOccurrences()}
                  disabled={!searchTerm || !replaceValue}
                >
                  Replace All
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setSearchTerm("");
                    setReplaceValue("");
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>

            {data.length > 0 && (
              <Grid
                item
                xs={6}
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Tooltip
                    title="View Actions"
                    onClick={() => {
                      setCurrentOpenDialog("viewActions");
                    }}
                  >
                    <IconButton color="primary" size="small">
                      <ReceiptLong />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    sx={{ ml: 1 }}
                    title="View Notes"
                    onClick={() => {
                      setCurrentOpenDialog("viewNotes");
                    }}
                  >
                    <IconButton color="primary" size="small">
                      <Description />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <div style={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      variant="determinate"
                      value={progress}
                      size={60}
                      thickness={4}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                      >
                        {`${progress.toFixed(2)}%`}{" "}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
);
SearchingComponent.displayName = "SearchingComponent";

SearchingComponent.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  data: PropTypes.array,
  setData: PropTypes.func,
  cmpID: PropTypes.number,
  policyMetaDataForDrawer: PropTypes.any,
};
export default SearchingComponent;
