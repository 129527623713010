import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import React from "react";

const getCustomerConfigurationByCustomerID = (customerID, setCustomerData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerConfigurationByCustomerID", {
        i_CUSTOMER_ID: customerID,
        i_CUSTOMER_ID2: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setCustomerData(response["#result-set-1"][0]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const viewLicenceKey = (
  licenceKey,
  setViewLicenceKeyInitialValues,
  setOpenDisplayDetailsLicenceKey
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("auth")
      .send("/api/v1/licence/extract", {
        licenceKey: licenceKey,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let customerID = "";
          let customerName = "";
          let licenceExpiryDate = "";
          let licenceType = "";

          if (response) {
            //  const parsedContent = JSON.parse(response);
            const parsedContent = response;

            if (parsedContent) {
              customerID = parsedContent.customerID;
              customerName = parsedContent.customerName;
              licenceExpiryDate = parsedContent.licenceExpiryDate;
              licenceType = parsedContent.licenceType;
              setViewLicenceKeyInitialValues({
                CUSTOMER_ID: customerID,
                CUSTOMER_NAME: customerName,
                LICENCE_EXPIRY_DATE: licenceExpiryDate,
                LICENCE_TYPE: licenceType,
              });
              setOpenDisplayDetailsLicenceKey(true);
            } else {
              dispatch(
                alertActions.error("No data found for the given Licence Key.")
              );
            }
          } else {
            dispatch(
              alertActions.error("No data found for the given Licence Key.")
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const generateLicenceKey = (
  data,
  customerName,
  setNewLicenceKey,
  setOpenNewLicenceKeyPopup
) => {
  const date = data
    ? data.LICENCE_EXPIRY_DATE.split("T")[0] +
      " " +
      data.LICENCE_EXPIRY_DATE.split("T")[1].split(".")[0]
    : "";

  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("auth")
      .send("/api/v1/licence/generate", {
        customerID: data.CUSTOMER_ID,
        customerName: customerName?.CUSTOMER_NAME,
        licenceExpiryDate: date,
        licenceType: data.LICENCE_TYPE,
        uniqueValue: "null",
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const newKey = response ? response.toString().trim() : null;

          setNewLicenceKey(newKey);
          newKey
            ? setOpenNewLicenceKeyPopup(true)
            : setOpenNewLicenceKeyPopup(false);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateCustomerConfiguration = (data, setCustomerData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerConfiguration", {
        i_DEFAULT_LANDING_ZONE: data.DEFAULT_LANDING_ZONE,
        i_DEFAULT_DOC_URL: data.DEFAULT_DOC_URL,
        i_REPORTING_URL: data.REPORTING_URL,
        i_DEFAULT_PM_URL: data.DEFAULT_PM_URL,
        i_PRE_PROCESSING_URL: data.PRE_PROCESSING_URL,
        i_PRE_PROCESSING_IP: data.PRE_PROCESSING_IP,
        i_LICENCE_KEY: data.LICENCE_KEY,
        i_LICENCE_EXPIRY_DATE: data.LICENCE_EXPIRY_DATE,
        i_LICENCE_TYPE: data.LICENCE_TYPE,
        i_LOGIN_TIMEOUT: data.LOGIN_TIMEOUT,
        i_PASSWORD_RESET_EMAIL_ADDRESS: data.PASSWORD_RESET_EMAIL_ADDRESS,
        i_PRIMARY_LOGO: data.PRIMARY_LOGO,
        i_POLLING_TIMER: data.POLLING_TIMER,
        i_WORKFLOW_URL: data.WORKFLOW_URL,
        i_COMP_THRESHOLD_DEFAULT: data.COMP_THRESHOLD_DEFAULT,
        i_REACT_APP_AWS_ACCESS_KEY_ID: data.REACT_APP_AWS_ACCESS_KEY_ID,
        i_REACT_APP_AWS_SECRET_ACCESS_KEY: data.REACT_APP_AWS_SECRET_ACCESS_KEY,
        i_MULTI_PROCESSING: data.MULTI_PROCESSING,
        i_VIRUS_CHECKER: data.VIRUS_CHECKER,
        i_AI_ASSISTANT_AUDITLOG_RETENSION: data.AI_ASSISTANT_AUDITLOG_RETENSION,
        i_AI_ASSISTANT_AUDITLOG_ACTIVE: data.AI_ASSISTANT_AUDITLOG_ACTIVE,
        i_2FA_ENABLED: data["2FA_ENABLED"],
        i_2FA_SERVICE_NAME: data["2FA_SERVICE_NAME"],
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_CUSTOMER_ID2: data.CUSTOMER_ID,
        i_LOGO: data.LOGO,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setCustomerData((prevState) => ({
            ...prevState,
            ...data,
            PRIMARY_LOGO: window.btoa(data.PRIMARY_LOGO),
            LOGO: window.btoa(data.LOGO),
          }));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateCustomerThemeConfiguration = (data, setCustomerData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerThemeConfiguration", {
        i_TOPBAR_BGROUND_COLOUR: data.TOPBAR_BGROUND_COLOUR,
        i_MENUBAR_BGROUND_COLOUR: data.MENUBAR_BGROUND_COLOUR,
        i_ACTIONBUTTON_BGROUND_COLOUR: data.ACTIONBUTTON_BGROUND_COLOUR,
        i_TABLE_HEADER_COLOUR: data.TABLE_HEADER_COLOUR,
        i_FONT_TYPEFACE_BOLD: data.FONT_TYPEFACE_BOLD,
        i_FONT_TYPEFACE_LIGHT: data.FONT_TYPEFACE_LIGHT,
        i_FONT_COLOUR_LIGHT: data.FONT_COLOUR_LIGHT,
        i_FONT_COLOUR_DARK: data.FONT_COLOUR_DARK,
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_CUSTOMER_ID2: data.CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setCustomerData((prevState) => ({ ...prevState, ...data }));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateCustomerTopBarConfiguration = (data, setCustomerData) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateCustomerTopBarConfiguration", {
        i_TOPBAR_TYPE: data.type,
        i_TOPBAR_VALUE: data.value,
        i_CUSTOMER_ID: data.CUSTOMER_ID,
        i_CUSTOMER_ID2: data.CUSTOMER_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setCustomerData((prevState) => ({ ...prevState, ...data }));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  updateCustomerTopBarConfiguration,
  updateCustomerConfiguration,
  getCustomerConfigurationByCustomerID,
  updateCustomerThemeConfiguration,
  generateLicenceKey,
  viewLicenceKey,
};
