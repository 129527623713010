/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";

import _ from "lodash";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useParams } from "react-router";

import langConstant from "_lang";
import individualQuestionAction from "../actions";
import RegulatorResponseCriteria from "./RegulatorResponseCriteria";
import rootStyles from "../../../../../rootStyles";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import PropTypes from "prop-types";
import sessionService from "_services/session.service";
import { Grid, IconButton } from "@mui/material";

const ResponseTab = ({ fetchdata, qid }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const rootClasses = rootStyles();

  const customerID = sessionService.getCustomerId();

  const questionResponses = useSelector(
    (state) => state.manageIndividualQuestion.questionResponses
  );
  const responseOptionIds =
    questionResponses?.length &&
    questionResponses.map((response) => response.RESPONSE_OPTION_ID);
  const responseTypesData = useSelector(
    (state) => state.manageIndividualQuestion.responseTypes
  );
  const [initialValues, setInitialValues] = useState([]);
  const [responseType, setResponseType] = useState({ responseTypeValue: "" });

  // insert and update question response Action calling
  const updateResponseData = (index) => {
    delete initialValues[index].canEdit;
    dispatch(
      individualQuestionAction.updateQuestionResponse(
        initialValues[index],
        qid,
        customerID
      )
    );
  };

  const countUpdater = () => {
    setTimeout(function () {
      fetchdata();
    }, 200);
  };

  const removeResponseData = (index) => {
    dispatch(
      confirmationDialogActions.open({
        message: langConstant.DELETING_RESPONSE_CONFIRMATION_MESSAGE(
          index >= 0 ? initialValues[index].v_response_option : "All Options"
        ),
        onConfirm: () => {
          const responseOptionIds =
            index >= 0
              ? initialValues[index].v_response_option_id
              : initialValues
                  .map((response) => response.v_response_option_id)
                  .toString();
          dispatch(
            individualQuestionAction.deleteQuestionResponse(
              qid,
              responseOptionIds,
              countUpdater,
              customerID
            )
          );
        },
        title: langConstant.REMOVE_RESPONSE_OPTION_CONFIRMATION,
        option1: langConstant.YES_PLEASE,
        option2: langConstant.NO_THANKS,
      })
    );
  };
  const insertResponseData = (responseData) => {
    responseData.v_regulation_id = id;
    responseData.i_CUSTOMER_ID = customerID;
    responseData.v_question_id = qid;
    responseData.v_response_type_id =
      responseTypesData?.length &&
      responseTypesData.filter(
        (type) => type.RESPONSE_TYPE_VALUE === responseType.responseTypeValue
      )[0].RESPONSE_TYPE_ID;
    dispatch(
      individualQuestionAction.insertQuestionResponse(
        responseData,
        qid,
        countUpdater,
        customerID
      )
    );
  };
  //-------------------------------------------------------//
  const handleOnChangeInput = (e, index) => {
    // if (e.target.value.match(/^[a-zA-Z0-9\s]*$/)) {
    const updatednitialValues = [...initialValues];
    updatednitialValues[index][e.target.name] = e.target.value;
    setInitialValues(updatednitialValues);
    // }
  };

  const handleClickToSwitchButton = (index) => {
    const updatednitialValues = [...initialValues];
    updatednitialValues[index].canEdit = !initialValues[index].canEdit;
    setInitialValues(updatednitialValues);
  };

  useEffect(() => {
    if (qid) {
      dispatch(
        individualQuestionAction.getIndividualQuestionResponses(qid, customerID)
      );
    }
  }, [qid]);

  useEffect(() => {
    const newQuestionResponses = [...questionResponses];
    setInitialValues(
      newQuestionResponses?.length > 0 &&
        newQuestionResponses.map((response) => {
          return {
            v_question_id: response.QUESTION_ID,
            v_response_type_id: response.RESPONSE_TYPE_ID,
            v_response_option: response.RESPONSE_OPTION,
            v_response_value: response.RESPONSE_VALUE,
            v_response_option_id: response.RESPONSE_OPTION_ID,
            canEdit: true,
          };
        })
    );
    setResponseType({
      ...responseType,
      responseTypeValue: newQuestionResponses?.length
        ? newQuestionResponses[0].RESPONSE_TYPE_VALUE
        : "",
    });
    dispatch(individualQuestionAction.getResponseTypes(qid));
  }, [questionResponses]);
  return (
    <Box>
      <Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box style={{ width: "30%" }}>
            <Typography
              style={{
                marginTop: "20px",
                fontWeight: "bold",
              }}
            >
              {langConstant.REGULATOR_RESPONSE_CRITERIA}
            </Typography>
          </Box>
          <Box>
            <Typography style={{ marginBottom: "10px" }}>
              {langConstant.RESPONSE_TYPE}
            </Typography>
            <TextField
              size="small"
              name="RESPONSE_TYPE_ID"
              value={responseType.responseTypeValue}
              select
              variant="outlined"
              onChange={(e) =>
                setResponseType({
                  ...responseType,
                  responseTypeValue: e.target.value,
                })
              }
              style={{ width: "400px" }}
              disabled={questionResponses?.length}
            >
              {responseTypesData?.length > 0 &&
                responseTypesData.map((type) => (
                  <MenuItem
                    key={type.RESPONSE_TYPE_ID}
                    value={type.RESPONSE_TYPE_VALUE}
                  >
                    {type.RESPONSE_TYPE_VALUE}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
          <Box style={{ width: "37vw" }}>
            <Button
              style={{ marginTop: "35px", float: "right" }}
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              // className={rootClasses.blueButton}
              startIcon={<DeleteOutlineIcon />}
              disabled={!initialValues?.length}
              onClick={() => removeResponseData()}
            >
              {langConstant.DELETE_ALL}
            </Button>
          </Box>
        </Box>

        <Box>
          <Box>
            <Box style={{ marginRight: "30px", marginTop: "1%" }}>
              <Box sx={{ p: 3 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ justifyContent: "flex-start", flexWrap: "wrap" }}
                >
                  {initialValues?.length > 0 &&
                    initialValues.map((item, index) => (
                      <Grid item xs={12 / 5} key={index}>
                        <Box display="flex" sx={{ mt: 1, mr: "4rem" }}>
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{
                                width: 300,
                                height: 200,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                borderRadius: 10,
                                backgroundColor: "#FAFAFA",
                                cursor: "pointer",
                                gap: 10,
                                // border: "1.5px solid gray",
                                boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.1)",
                                position: "relative",
                                padding: "12px",
                              }}
                            >
                              {/* Top Right Icons */}
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 7,
                                  right: 8,
                                  display: "flex",
                                  gap: 0.8,
                                }}
                              >
                                {item.canEdit ? (
                                  <Box sx={{ display: "flex", gap: 1 }}>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        color="primary"
                                        sx={{ p: 0.5 }}
                                        onClick={() =>
                                          handleClickToSwitchButton(index)
                                        }
                                      >
                                        <CreateIcon
                                          sx={{
                                            color: "#43a047",
                                            fontSize: "16px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                      <IconButton
                                        sx={{ p: 0.5 }}
                                        onClick={() =>
                                          removeResponseData(index)
                                        }
                                      >
                                        <DeleteOutlineIcon
                                          sx={{
                                            color: "#FF7070",
                                            fontSize: "22px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                ) : (
                                  <Tooltip title="Confirm">
                                    <IconButton
                                      color="primary"
                                      sx={{ p: 0.5, mb: 2 }}
                                      onClick={() => updateResponseData(index)}
                                    >
                                      <CheckIcon
                                        sx={{
                                          color: "#43a047",
                                          fontSize: "18px",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>

                              {item.canEdit ? (
                                <>
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    sx={{ color: "#333" }}
                                  >
                                    {item.v_response_option || "Header"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#666" }}
                                  >
                                    {item.v_response_value || "Description"}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <TextField
                                    label="Header"
                                    name="v_response_option"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={item.v_response_option}
                                    onChange={(e) =>
                                      handleOnChangeInput(e, index)
                                    }
                                  />
                                  <TextField
                                    label="Description"
                                    name="v_response_value"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={item.v_response_value}
                                    onChange={(e) =>
                                      handleOnChangeInput(e, index)
                                    }
                                  />
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {questionResponses && (
        <RegulatorResponseCriteria
          isDataPresent={questionResponses.length}
          responseType={responseType}
          responseOptionIds={responseOptionIds}
          qid={qid}
          insertResponseData={insertResponseData}
          fetchdata={fetchdata}
        ></RegulatorResponseCriteria>
      )}
    </Box>
  );
};

ResponseTab.propTypes = {
  fetchdata: PropTypes.func,
  qid: PropTypes.string,
};

export default ResponseTab;
