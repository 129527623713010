import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "_components/DialogBox";
import {
  Typography,
  Box,
  Button,
  makeStyles,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NarrativeTable } from "_components/MasterAssessmentComp/component/NarrativeTable";
import TextField from "@mui/material/TextField";
import AssessmentTabs from "./AssessmentTabs";
import { UseModalPropType } from "_hooks";
import PropTypes from "prop-types";
import langConstant from "_lang";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import planActionsServices from "../../services";
import "react-perfect-scrollbar/dist/css/styles.css";
import ToolBar from "_components/MasterAssessmentComp/component/ToolBar";
import ObservationDialogBox from "_components/MasterAssessmentComp/component/ObservationDialogBox";
import { EditAnswer } from "_components/MasterAssessmentComp/component/EditAnswer";
import Checkbox from "@mui/material/Checkbox";
import SaveIcon from "@material-ui/icons/Save";
import _ from "lodash";
import {
  getEvidenceForMasterAssessment,
  getDataAssestsForMasterAssessment,
  getCommentsList,
  GetQuestionResponsesForAssessments,
  saveSingleQuestion,
  updateComplianceDecision,
} from "_components/MasterAssessmentComp/utils/actions";
import sessionService from "_services/session.service";
import moment from "moment";
import MailIcon from "@material-ui/icons/Mail";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import LockIcon from "@material-ui/icons/Lock";
import RestorePageIcon from "@material-ui/icons/RestorePage";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Autocomplete from "@mui/material/Autocomplete";
import storeProcedure from "_components/MasterAssessmentComp/steps/services";
import { alertActions } from "_actions";
import { useModal } from "_hooks";
// import PlanView from "Comp/components/Plan";
import {
  MasterAssessmentConstants,
  QUESTION_STATUS_TYPES,
} from "_components/MasterAssessmentComp/utils/constants";
import getActionService from "containers/manage-regulation/Policy/PolicyExplorer/services";
import { useParams } from "react-router-dom";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles((theme) => ({
  height100: {
    height: "100%",
  },
  width100: {
    width: "100%",
  },
  marginTop: {
    marginTop: "10px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  statusicon: {
    fontSize: 20,
    marginRight: "5px",

    "&.COMPLETED": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.RFI_SENT": {
      color: "#105BA1",
    },

    "&.RFI_RESOLVED": {
      color: "#1a751a",
    },

    "&.RFI_PENDING": {
      color: "#895514",
    },

    RFI_OVERDUE: {
      color: "#d62000",
    },

    "&.SENT_BACK": {
      color: "rgba(240, 20, 47, 1)",
    },

    "&.FINAL": {
      color: "rgba(0, 159, 26, 1)",
    },

    "&.PENDING_DATA": {
      color: "rgba(187, 174, 0, 1)",
    },

    "&.READY_FOR_REVIEW": {
      color: "rgba(1, 148, 25, 1)",
    },

    "&.COMPLETED_AND_LOCKED": {
      color: "rgba(0, 159, 26, 1)",
    },
  },
}));

const BottomTabsArray = [
  { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },
  { name: "DATA ASSETS", count: 0, isCount: false, id: 1 },
  { name: "EVIDENCES", count: 0, isCount: false, id: 2 },
  { name: "Responses and narratives", count: 0, isCount: true, id: 3 },
];

const PrepareDecisionAction = ({ modal }) => {
  const QuestionList = modal?.data.filteredRows;
  const selRow = modal?.data.selRow;
  const IsAudit = modal?.data.IsAudit;
  const filteredRows = modal ? modal.data.filteredRows : [];

  const setPlanActionsDataLength = modal
    ? modal.data.setPlanActionsDataLength
    : "";
  const setKpiData = modal ? modal.data.setKpiData : "";
  const setFilteredRows = modal ? modal.data.setFilteredRows : "";

  const observationList = [];
  const identifier = "";
  const RfiitemsList = [];
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();
  const dispatch = useDispatch();
  // const [TopSelectedTab, setTopSelectedTab] = useState(TopTabsArray[0]);
  const [BottomTabs, setBottomTabs] = useState(BottomTabsArray);
  const [BottomSelectedTab, setBottomSelectedTab] = useState(
    BottomTabsArray[0]
  );
  const [filteredQuestionList, setfilteredQuestionList] = useState([]);
  const [page, setPage] = useState(0);
  const [ObservationDialog, setObservationDialog] = useState({
    visible: false,
    mode: "",
    ObservationList: {},
  });
  const [selectedQuestion, setSelectedQuestion] = useState({
    ...selRow,
    QUESTION_ID: selRow.REGULATION_REFERENCE,
  });

  const [QuestionResponses, setQuestionResponses] = useState([]);
  const [DecisionSelectedOption, setDecisionSelectedOption] = useState(null);
  const [DecisionSelectedOption2, setDecisionSelectedOption2] = useState(null);
  const [complianceDecision, setComplianceDecision] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(
    DecisionSelectedOption?.RESPONSE_OPTION ===
      selectedQuestion?.QUESTION_COMPLIANCE_DECISION ||
      DecisionSelectedOption === null
  );

  const [rfiNarrative, setRfiNarrative] = useState(null);

  const [value, setValue] = React.useState(0);
  const commentsList = useSelector(
    (state) => state.MasterAssessment.AssessmentComments
  );
  const DataPointsList = useSelector(
    (state) => state.MasterAssessment.QuestionDataPoints
  );
  const EvidenceList = useSelector(
    (state) => state.MasterAssessment.EvidencesList
  );
  const DataAssetsList = useSelector(
    (state) => state.MasterAssessment.DataAssetsList
  );

  const StatusTagObject = {
    RFI_SENT: {
      statusBackground: "rgba(123, 192, 255, 1)",
      statusTag: langConstant.RFI_SENT,
      icon: <MailIcon className={`${classes.statusicon} RFI_SENT`} />,
    },
    RFI_PENDING: {
      statusBackground: "rgba(255, 248, 156, 1)",
      statusTag: langConstant.RFI_PENDING,
      icon: <HelpOutlineIcon className={`${classes.statusicon} RFI_PENDING`} />,
    },
    RFI_OVERDUE: {
      statusBackground: "rgba(255, 188, 188, 1)",
      statusTag: langConstant.RFI_OVERDUE,
      icon: (
        <ReportProblemIcon className={`${classes.statusicon} RFI_OVERDUE`} />
      ),
    },
    RFI_RESOLVED: {
      statusBackground: "rgba(175, 243, 187, 0.8)",
      statusTag: langConstant.RFI_RESOLVED,
      icon: (
        <CheckOutlinedIcon className={`${classes.statusicon} RFI_RESOLVED`} />
      ),
    },
    SENT_BACK: {
      statusBackground: "rgba(255, 188, 188, 1)",
      statusTag: langConstant.SENT_BACK,
      icon: <ReplyAllIcon className={`${classes.statusicon} SENT_BACK`} />,
    },
    FINAL: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.FINAL,
      icon: <ThumbUpAltIcon className={`${classes.statusicon} FINAL`} />,
    },
    PENDING_DATA: {
      statusBackground: "rgba(255, 248, 156, 1)",
      statusTag: langConstant.PENDING_DATA,
      icon: (
        <RestorePageIcon className={`${classes.statusicon} PENDING_DATA`} />
      ),
    },
    READY_FOR_REVIEW: {
      statusBackground: "rgba(175, 243, 187, 0.7)",
      statusTag: langConstant.READY_FOR_REVIEW,
      icon: (
        <FlashOnIcon className={`${classes.statusicon} READY_FOR_REVIEW`} />
      ),
    },
    COMPLETED_AND_LOCKED: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.COMPLETED_AND_LOCKED,
      icon: (
        <LockIcon className={`${classes.statusicon} COMPLETED_AND_LOCKED`} />
      ),
    },
    COMPLETED: {
      statusBackground: "rgba(175, 243, 187, 1)",
      statusTag: langConstant.COMPLETED,
      icon: <LockIcon className={`${classes.statusicon} COMPLETED`} />,
    },
  };

  const currentRfi = RfiitemsList?.find(
    (rfi) =>
      rfi.REGULATORS_QUESTION_REF === selectedQuestion?.REGULATORS_QUESTION_REF
  );
  const { sid: SUBMISSION_ID, id: ASSESSMENT_ID } = useParams();

  const actions = useSelector((state) => state?.MasterAssessment?.ActionsList),
    assessmentDetails = useSelector(
      (state) => state?.MyAssessmnetReducer?.resultSet2
    )?.[0],
    assessmentKpiData = useSelector(
      (state) => state?.MyAssessmnetReducer?.AssessmentKpiData
    )?.[0],
    approversList = useSelector(
      (state) => state?.MyAssessmnetReducer?.ApproversList
    ),
    regulatorsList = useSelector(
      (state) => state?.MyAssessmnetReducer?.RegulatorsList
    );

  const EvidenceTableHeaders = [
    "EVIDENCE TYPE",
    "Accuracy",
    "Suitability",
    "Classification",
    "Evidence File Name",
    "Evidence Uploaded By",
  ];
  const DataAssetsTableHeaders = [
    "Source System Name",
    "Data Assets Name",
    "Data Assets Value",
    "Entered by",
  ];
  const MyAssessmentToolbar = () => {
    return (
      <>
        {selectedQuestion.QUESTION_STATUS && (
          <Box
            wrap="nowrap"
            spacing={1}
            display={"flex"}
            justifyContent={"space-between"}
            className={`${classes.width100} ${classes.height100}`}
          >
            <Box display={"flex"}>
              {selectedQuestion.QUESTION_STATUS !== "DRAFT" && (
                <Box
                  style={{
                    backgroundColor:
                      StatusTagObject[selectedQuestion.QUESTION_STATUS]
                        ?.statusBackground,
                    padding: "5px 10px",
                    borderRadius: "4px",
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {StatusTagObject[selectedQuestion.QUESTION_STATUS]?.icon}
                  <Typography variant="body2" style={{ color: "black" }}>
                    {StatusTagObject[
                      selectedQuestion.QUESTION_STATUS
                    ]?.statusTag.toUpperCase()}
                  </Typography>
                </Box>
              )}

              {(selectedQuestion.QUESTION_STATUS === "DRAFT" ||
                selectedQuestion.QUESTION_STATUS === "RFI_RESOLVED" ||
                selectedQuestion.QUESTION_STATUS === "SENT_BACK" ||
                selectedQuestion.QUESTION_STATUS ===
                  "COMPLETED_AND_LOCKED") && (
                <Box
                  style={{
                    width: "190px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    color="success"
                    inputProps={{ "aria-label": "Mark as Completed" }}
                    checked={
                      selectedQuestion.QUESTION_STATE === "1" ? true : false
                    }
                    onClick={(e) => {
                      MyAssessmentCheckboxClick(e.target.checked);
                    }}
                  />
                  <Typography>{langConstant.MARK_AS_COMPLETED}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </>
    );
  };

  const MyAssessmentCheckboxClick = (checked) => {
    let currentQuestion = { ...selectedQuestion };

    if (checked) {
      currentQuestion.QUESTION_STATE = "1";
      currentQuestion.QUESTION_STATUS = "COMPLETED";
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_STATE: "1",
        QUESTION_STATUS: "COMPLETED",
      });
    } else {
      currentQuestion.QUESTION_STATE = "0";
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_STATE: "0",
      });
    }

    const MyAssessmentCallback = () => {
      setPlanActionsDataLength((length) => length - 1);
      setKpiData((prev) => ({
        ...prev,
        questionsToComplete: prev.questionsToComplete - 1,
      }));
      const data = [...QuestionList].map((que) => {
        if (
          `${que.REGULATION_REFERENCE}` === `${selectedQuestion.QUESTION_ID}`
        ) {
          return { ...currentQuestion };
        }
        return que;
      });

      UpdateMyAssessmentQuestionArray(data);
    };

    dispatch(saveSingleQuestion(currentQuestion, MyAssessmentCallback));
  };

  const TableRenderer = (Headers, Rowdata, id) => {
    const metaDataRender = (MetaData) => {
      let accuracy;
      let suitability;
      let value = MetaData;

      if (MetaData === null) {
        accuracy = "No Data";
        suitability = "No Data";
      } else {
        if (value && value !== "MQ==") {
          let tempData = window.atob(value);
          tempData = tempData.slice(1, -1);
          tempData = tempData.replaceAll("], ", "]&");
          const convertedData = new URLSearchParams(tempData);
          value = JSON.parse(convertedData.get("score"));
        } else if (value === "MQ==") {
          value = [];
        }

        accuracy = value?.length ? value[0] : "No Data";
        suitability = value?.length ? value[1] : "No Data";
      }

      return (
        <>
          <TableCell style={{ padding: "5px" }}>{accuracy}</TableCell>
          <TableCell style={{ padding: "5px" }}>{suitability}</TableCell>
        </>
      );
    };

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {Headers.map((header) => (
                <TableCell
                  key={header}
                  style={{
                    textTransform: "uppercase",
                    padding: "5px",
                    backgroundColor: "white",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rowdata.length > 0 &&
              Rowdata.map((el, i) => {
                switch (id) {
                  case 1:
                    return (
                      <TableRow
                        key={`${el.SOURCE_DATA_ID}${i}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ padding: "5px" }}>
                          {el.SOURCE_SYSTEM_NAME}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el.DATA_ASSET_ATTRIBUTE_NAME}
                        </TableCell>
                        <TableCell style={{ padding: "5px" }}>
                          {el.SOURCE_DATA_POINT_VALUE}
                        </TableCell>
                        <TableCell style={{ padding: "5px" }}>
                          {el.FULLNAME.trim()
                            ? el.FULLNAME.trim()
                            : langConstant.NAME_NOT_PROVIDED}
                        </TableCell>
                      </TableRow>
                    );

                  case 2:
                    return (
                      <TableRow
                        key={`${el.EVIDENCE_LOG_ID}${i}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el.EVIDENCE_SHORTNAME}
                        </TableCell>
                        {metaDataRender(el.METADATA)}
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {el?.DESCRIPTION}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {`${el?.FILE_NAME} ${el?.FILE_FORMAT}`}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ padding: "5px" }}
                        >
                          {`${el.FIRST_NAME} ${el.LAST_NAME}`}
                        </TableCell>
                      </TableRow>
                    );

                  default:
                    break;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const onBottomTabChange = (e, value) => {
    setBottomSelectedTab(BottomTabsArray[value]);
  };

  const CardBox = (identifier, currentQuestion) => {
    return (
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardContent style={{ padding: "5px", height: "100%" }}>
          <PerfectScrollbar>
            {identifier === 1 && (
              <div style={{ display: "flex", fontSize: "15px" }}>
                <div>
                  {" "}
                  <span>
                    {" "}
                    <strong>
                      {currentQuestion?.REGULATORS_QUESTION_REF}
                    </strong>{" "}
                    -
                  </span>
                  &nbsp;{selRow?.QUESTION_TEXT}
                </div>
              </div>
            )}

            {identifier === 3 && (
              <div style={{ fontSize: "1rem", padding: "1rem" }}>
                {BottomSelectedTab.id === 1 &&
                  TableRenderer(
                    DataAssetsTableHeaders,
                    DataAssetsList,
                    BottomSelectedTab.id
                  )}
                {BottomSelectedTab.id === 2 &&
                  TableRenderer(
                    EvidenceTableHeaders,
                    EvidenceList,
                    BottomSelectedTab.id
                  )}
                {BottomSelectedTab?.id === 3 && (
                  <NarrativeTable rfiNarrativeList={rfiNarrative} />
                )}
                {BottomSelectedTab.id === 0 && (
                  <Box style={{ width: "100%" }}>
                    <div style={{ fontSize: "15px" }}>
                      <div>
                        {" "}
                        <span>
                          {" "}
                          {currentQuestion?.REGULATORS_QUESTION_REF} -
                        </span>
                        &nbsp; <strong>{currentQuestion?.QUESTION_TEXT}</strong>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
                              ? currentQuestion?.CURRENT_ANSWER_SELECTED_RESPONSE
                              : "",
                        }}
                      ></div>
                    </div>
                  </Box>
                )}
              </div>
            )}
          </PerfectScrollbar>
        </CardContent>
      </Card>
    );
  };
  const saveComplianceDecision = () => {
    const complainceCallback = () => {
      setBottomTabs(BottomTabsArray);
      setSelectedQuestion({
        ...selectedQuestion,
        QUESTION_COMPLIANCE_DECISION: DecisionSelectedOption2
          ? DecisionSelectedOption2
          : DecisionSelectedOption?.RESPONSE_OPTION
          ? DecisionSelectedOption?.RESPONSE_OPTION
          : complianceDecision,
      });

      dispatch(
        alertActions.success(langConstant.COMPLIANCE_UPDATED_SUCCESSFULLY)
      );
      // const data = [...QuestionList].map((que) => {
      //   if (
      //     `${que.REGULATION_REFERENCE}` === `${selRow?.REGULATION_REFERENCE}`
      //   ) {
      //     return {
      //       ...que,
      //       QUESTION_COMPLIANCE_DECISION: complianceDecision
      //         ? complianceDecision
      //         : DecisionSelectedOption?.RESPONSE_OPTION,
      //     };
      //   }
      //   return que;
      // });

      // UpdateMyAssessmentQuestionArray(data);
    };
    dispatch(
      updateComplianceDecision(
        selectedQuestion,
        DecisionSelectedOption2
          ? DecisionSelectedOption2
          : DecisionSelectedOption?.RESPONSE_OPTION
          ? DecisionSelectedOption?.RESPONSE_OPTION
          : complianceDecision,
        complainceCallback
      )
    );
  };
  const getSelectedQuestionDetails = async () => {
    setBottomTabs(BottomTabsArray);

    try {
      const response = await planActionsServices.GetSubmissionQuestionDetails(
        selRow.REGULATION_REFERENCE,
        selRow.SUBMISSION_ID
      );
      if (response?.status !== 200) throw new Error("Unable to fetch data");
      else if (response && response?.["#result-set-1"][0]) {
        let tempSelectedQuestion = response["#result-set-1"][0];
        tempSelectedQuestion = {
          ...tempSelectedQuestion,
          SUBMISSION_ID: selRow?.SUBMISSION_ID,
        };
        setSelectedQuestion(tempSelectedQuestion);

        if (tempSelectedQuestion) {
          getQuestionResponse(tempSelectedQuestion);
        }
        setComplianceDecision(
          tempSelectedQuestion.QUESTION_COMPLIANCE_DECISION
            ? tempSelectedQuestion.QUESTION_COMPLIANCE_DECISION
            : "No Decision Selected"
        );
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getRfiNarrativeByApi = async () => {
    const toNumber = (num) => {
      if (!num) return null;
      return Number(num);
    };
    const payload = {
      i_RFI_ID: toNumber(currentRfi?.RFI_ID),
      i_QUESTION_ID: toNumber(selectedQuestion?.QUESTION_ID),
      i_SUBMISSION_ID: toNumber(selectedQuestion?.SUBMISSION_ID),
    };
    await storeProcedure
      .getRfiNarrative(payload)
      .then((response) => {
        if (response?.status !== 200) return showAlert(response);
        setRfiNarrative(response?.["#result-set-1"]);
      })
      .catch((error) => {
        showAlert(error);
        console.log("error", error);
      });
  };

  const UpdateMyAssessmentQuestionArray = (data) => {
    dispatch({ type: "SET_MY_ASSESSMENT_QUESTIONS", payload: data });
  };

  const showAlert = (error, type = "error") => {
    dispatch(
      alertActions?.[type](
        error?.data?.message?.map((item, index) => {
          return <span key={index}>{item}</span>;
        }) ?? error?.toString()
      )
    );
  };

  const getQuestionResponse = async (question) => {
    const data = await dispatch(
      GetQuestionResponsesForAssessments(
        selRow?.REGULATION_REFERENCE,
        customerID
      )
    );

    if (data?.length > 0) {
      setQuestionResponses(data);

      const tempdata = data.find(
        (el) =>
          el.RESPONSE_OPTION?.trim() ===
          question?.QUESTION_COMPLIANCE_DECISION?.trim()
      );
      if (tempdata) {
        setDecisionSelectedOption(tempdata);
      } else {
        const temp = {
          ACTIVE: 1,

          QUESTION_ID: selRow?.REGULATION_REFERENCE,
          RESPONSE_OPTION: question?.QUESTION_COMPLIANCE_DECISION?.trim(),
        };
        setComplianceDecision(question?.QUESTION_COMPLIANCE_DECISION?.trim());
        setDecisionSelectedOption(temp);
      }
    } else {
      setQuestionResponses([]);
    }
  };

  useEffect(() => {
    setDecisionSelectedOption(null);
    setDecisionSelectedOption2(null);
    setQuestionResponses([]);
    setBottomTabs(BottomTabsArray);

    getSelectedQuestionDetails();

    dispatch(
      getEvidenceForMasterAssessment(
        selRow.REGULATION_REFERENCE,
        selRow.SUBMISSION_ID
      )
    );
    dispatch(
      getDataAssestsForMasterAssessment(selRow.REGULATION_REFERENCE, customerID)
    );
    selRow.REGULATION_REFERENCE && currentRfi?.RFI_ID && getRfiNarrativeByApi();
  }, [selRow]);

  useEffect(() => {
    setBottomTabs(BottomTabsArray);

    let tempArr = [
      { name: "CURRENT ANS", count: 0, isCount: true, id: 0 },

      {
        name: "DATA ASSETS",
        count: DataAssetsList.length,
        isCount: false,
        id: 1,
      },
      {
        name: "EVIDENCES",
        count: EvidenceList.length,
        isCount: false,
        id: 2,
      },
      {
        name: "Responses and narratives",
        count: rfiNarrative?.length,
        isCount: false,
        id: 3,
      },
    ];
    setBottomTabs(tempArr);
  }, [EvidenceList, DataAssetsList, rfiNarrative]);

  return (
    <DialogBox
      fullWidth
      maxWidth="md"
      title={selectedQuestion?.QUESTION_ID}
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableEnforceFocus
      disableAutoFocus
      PaperProps={{
        style: {
          height: "80vh",
        },
      }}
    >
      <Grid
        item
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <IconButton aria-label="close" onClick={modal?.closeModal}>
          <CloseIcon />
        </IconButton>
      </Grid>

      <Box
        style={{
          overflowY: "auto",
          maxHeight: "80vh",
          // padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "160px",
          }}
        >
          <Box style={{ width: "99%" }}>{CardBox(1, selectedQuestion)}</Box>
        </Box>
        <Grid container>
          <Grid
            item
            md={6}
            sx={12}
            flex={2}
            display="flex"
            alignItems="center"
            style={{
              display: "flex",
              marginTop: "5px",
            }}
          >
            {MyAssessmentToolbar()}
          </Grid>

          <Grid
            item
            md={6}
            sx={12}
            flex={2}
            style={{
              display: "flex",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <Typography>Decision: </Typography>
            <Grid style={{ marginLeft: "5px", width: "60%" }}>
              {(IsAudit || QuestionResponses.length == 0) && (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setIsSaveDisabled(false);
                    setComplianceDecision(e.target.value);
                  }}
                  value={complianceDecision}
                  className={classes.width100}
                  inputProps={{ readOnly: false }}
                />
              )}
              {!IsAudit && QuestionResponses.length > 0 && (
                <Autocomplete
                  freeSolo
                  size="small"
                  className={`${classes.width100}`}
                  autoHighlight
                  openOnFocus
                  id="checkboxes-tags-demo"
                  options={QuestionResponses}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search"
                      onChange={(e) => {
                        setIsSaveDisabled(false);
                        setDecisionSelectedOption2(e.target.value);
                      }}
                    />
                  )}
                  getOptionLabel={(option) =>
                    option.RESPONSE_OPTION ? option.RESPONSE_OPTION : ""
                  }
                  onChange={(e, val) => {
                    setIsSaveDisabled(false);
                    setDecisionSelectedOption(val);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.RESPONSE_OPTION?.trim() ===
                    value?.RESPONSE_OPTION?.trim()
                  }
                  value={DecisionSelectedOption}
                  disabled={
                    selectedQuestion?.QUESTION_STATUS === "COMPLETED_AND_LOCKED"
                  }
                />
              )}
            </Grid>
            <Grid style={{ width: "20%", marginLeft: "5px" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                onClick={saveComplianceDecision}
                disabled={isSaveDisabled}
              >
                {langConstant.SAVE}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AssessmentTabs
          selectedTab={BottomSelectedTab}
          onChange={onBottomTabChange}
          tabs={BottomTabs}
          bottomTabs
          currentQuestion={selectedQuestion}
        />

        <Box style={{ height: "calc(100vh - 611px)", overflow: "auto" }}>
          <Box style={{ width: "100%", height: "100%" }}>
            {CardBox(3, selectedQuestion)}
          </Box>
        </Box>

        {ObservationDialog.visible &&
          (identifier === "audit" || identifier === "myassessment") && (
            <ObservationDialogBox
              Controls={selectedQuestion?.CISO_CONTROL_NAME}
              submissionID={selectedQuestion?.SUBMISSION_ID}
              questionID={selectedQuestion?.QUESTION_ID}
              questionText={selectedQuestion?.QUESTION_TEXT}
              miscData={ObservationDialog}
              setObservationDialog={setObservationDialog}
              TotalObservationList={observationList}
            />
          )}
      </Box>
    </DialogBox>
  );
};

PrepareDecisionAction.propTypes = {
  TopTabsArray: PropTypes.array,
  QuestionList: PropTypes.array,
  observationList: PropTypes.array,
  identifier: PropTypes.string,
  RfiitemsList: PropTypes.array,
  modal: UseModalPropType.isRequired,
};

export default PrepareDecisionAction;
