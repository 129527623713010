import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SendIcon from "@mui/icons-material/Send";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import sessionService from "_services/session.service";
import moment from "moment";
import * as yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { postComment } from "_components/MasterAssessmentComp/utils/actions";
import commentsAction from "_components/CommentSection/actions";

import CircularProgress from "@mui/material/CircularProgress";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  height100: {
    height: "100%",
  },
  flexGrow: {
    flexGrow: 1,
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
  },
  profileSec: {
    width: "46px",
    alignSelf: "center",
  },
  commentdialog: {
    alignItems: "center",
    flexGrow: 1,
    display: "flex",
    background: "#e5e5ea",
    color: "#000",
    borderRadius: "1.15rem",
    lineHeight: 1.25,
    padding: "0.5rem .875rem",
    wordWrap: "break-word",
    width: "calc(100% - 110px)",
  },
  dateTime: {
    fontSize: "11px",
    minWidth: "63px",
    alignSelf: "center",
    marginLeft: "5px",
    display: "flex",
    flexDirection: "column",
    textAlign: "end",
  },
  senderMessage: {
    "& .MuiBox-root:nth-child(1)": {
      order: "3",
      "& .MuiAvatar-root": {
        marginRight: "0px",
        marginLeft: "5px",
      },
    },
    "& .MuiBox-root:nth-child(2)": {
      order: "2",
      backgroundColor: "#007bff1a",
    },
    "& .MuiBox-root:nth-child(3)": {
      order: "-1",
      marginRight: "5px",
      marginLeft: "0px",
      textAlign: "left !important",
    },
  },
}));

const CommentPanel = ({ commentsList, submissionID, questionID, page }) => {
  const classes = useStyles();
  const userID = sessionService.getUserId();
  const dispatch = useDispatch();
  const Loader = useSelector((state) => state.MasterAssessment.CommentLoader);
  const [FormInitialValues, setFormInitialValues] = useState({ COMMENT: "" });

  const validationSchema = yup.object({
    COMMENT: yup.string().trim().required("required"),
  });

  const CommentListItem = () => {
    return (
      <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
        {commentsList?.length ? (
          commentsList?.map((comment, index) => {
            return (
              <Box
                className={
                  comment.USER_ID.toString().trim() === userID.toString().trim()
                    ? classes.senderMessage
                    : ""
                }
                style={{ display: "flex", padding: "5px" }}
                key={index}
              >
                <Box className={classes.profileSec}>
                  <ListItemAvatar style={{ minWidth: "46px" }}>
                    <Tooltip placement="left" title={comment?.NAME || ""}>
                      <Avatar
                        alt={`Avatar n°`}
                        src={
                          comment.AVATAR_IMAGE !== null
                            ? "data:image/png;base64," +
                              window.atob(comment.AVATAR_IMAGE)
                            : ""
                        }
                      />
                    </Tooltip>
                  </ListItemAvatar>
                </Box>

                <Box className={classes.commentdialog}>
                  <Box style={{ flexGrow: 1 }}>{comment.COMMENT}</Box>
                </Box>

                <Box className={classes.dateTime}>
                  <div>{moment(comment.CREATE_DATE).format("DD-MMM-YY")}</div>
                  <div>{moment(comment.CREATE_DATE).format("h:mm a")}</div>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box>
            <Typography style={{ textAlign: "center", marginTop: "1rem" }}>
              Comments not found
            </Typography>
          </Box>
        )}
      </List>
    );
  };

  const SubmitData = async (values, { resetForm }) => {
    await dispatch(
      postComment(questionID, submissionID, userID, values.COMMENT, true)
    );
    await dispatch(commentsAction.getCommentsList(questionID, submissionID));

    resetForm();
  };

  useEffect(() => {
    setFormInitialValues({ COMMENT: "", dummy: page });
  }, [page]);

  return (
    <Card variant="outlined" style={{ height: "100%" }}>
      <CardContent style={{ padding: "5px", height: "100%" }}>
        <Box className={classes.height100}>
          {Loader === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "inherit",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box
              style={{
                height: "85%",
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <PerfectScrollbar>{CommentListItem()}</PerfectScrollbar>
              </Box>
            </Box>
          )}

          <Divider variant="fullWidth" />

          <Formik
            validateOnChange={true}
            initialValues={FormInitialValues}
            validationSchema={validationSchema}
            onSubmit={SubmitData}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, resetForm, touched, errors }) => (
              <Form>
                <Box className={classes.inputBox}>
                  <Box className={classes.flexGrow}>
                    <Field name="COMMENT">
                      {({ field: { value } }) => (
                        <TextField
                          variant="standard"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setFieldValue("COMMENT", e.target.value);
                          }}
                          placeholder="Enter your remarks"
                          multiline
                          rows={3}
                          value={value}
                          error={touched.COMMENT && Boolean(errors.COMMENT)}
                        />
                      )}
                    </Field>
                  </Box>

                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      style={{
                        // borderRadius: "50px",
                        height: "35px",
                      }}
                    >
                      <SendIcon style={{ fontSize: "20px" }} />
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </CardContent>
    </Card>
  );
};

CommentPanel.propTypes = {
  commentsList: PropTypes.array,
  submissionID: PropTypes.any,
  questionID: PropTypes.any,
  page: PropTypes.number,
};

export default CommentPanel;
