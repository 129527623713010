import React, { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useSelector } from "react-redux";
import { fetchFile } from "_helpers/utils";
import { Grid } from "@mui/material";

const DocumentViewer = ({
  height = "650px",
  documentClass,
  fileName,
  filePath,
  fileType,
  docName = false,
}) => {
  
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [docURL, setDocURL] = useState(null);

  const document = [
    {
      uri: docURL,
      fileType: "pdf",
      fileName: fileName,
    },
  ];
  useEffect(() => {
    (async () => {
      if (filePath)
        await fetchFile(
          filePath,
          fileName,
          docStorageType,
          docStorageIP,
          docStorageURL,
          setDocURL
        );
    })();
  }, [filePath]);
  return (
    <>
      {docURL && (
        <>
          {docName && <h3 style={{ marginLeft: "10px" }}>{fileName}</h3>}
          <DocViewer
            style={{ minHeight: height, maxHeight: height, fileName: fileName }}
            documents={document}
            config={{
              header: {
                disableHeader: docName ? true : false,
              },
            }}
            pluginRenderers={DocViewerRenderers}
            className={documentClass}
          />
        </>
      )}
    </>
  );
};

DocumentViewer.propTypes = {
  height: PropTypes.string,
  documentClass: PropTypes.object,
  fileName: PropTypes.string,
  filePath: PropTypes.string,
  fileType: PropTypes.string,
  docName: PropTypes.bool,
};

export default DocumentViewer;
