import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import useStyles from "../styles";
import GettingStartedServices from "../services";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "react-select";
import Summary from "./summary";
import sessionService from "_services/session.service";

const ClientSetup = ({ customerID, setQueCount }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [setupQuestionList, setSetupQuestionList] = useState([]);
  const [answers, setAnswers] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const userID = sessionService.getUserId();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const QUESTIONS_PER_PAGE = 5;
  const totalPages = Math.ceil(setupQuestionList.length / QUESTIONS_PER_PAGE);
  const startIndex = currentPage * QUESTIONS_PER_PAGE;
  const currentQuestions = setupQuestionList?.slice(
    startIndex,
    startIndex + QUESTIONS_PER_PAGE
  );

  const saveAnswers = (submissionID, questionID, questionAnswer) => {
    dispatch(
      GettingStartedServices.putClientSetupAnswers(
        customerID,
        userID,
        submissionID,
        questionID,
        questionAnswer,
        setSetupQuestionList
      )
    );
  };

  const handleAnswerChange = (id, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: value,
    }));
  };
  const handleSubmit = () => {
    currentQuestions.forEach((item) => {
      if (
        answers[item.QUESTION_ID] !== undefined &&
        answers[item.QUESTION_ID] != item.CURRENT_ANSWER_SELECTED_RESPONSE
      ) {
        saveAnswers(
          item.SUBMISSION_ID,
          item.QUESTION_ID,
          answers[item.QUESTION_ID]
        );
      }
    });
    dispatch(alertActions.success("Answers saved successfully."));
    setIsSubmitted(true);
  };

  // Move to the next set of questions
  const handleNext = () => {
    setIsLoading(true);

    const timeoutID = setTimeout(() => setIsLoading(false), 800);

    currentQuestions.forEach((item) => {
      if (
        answers[item.QUESTION_ID] !== undefined &&
        answers[item.QUESTION_ID] != item.CURRENT_ANSWER_SELECTED_RESPONSE
      ) {
        saveAnswers(
          item.SUBMISSION_ID,
          item.QUESTION_ID,
          answers[item.QUESTION_ID]
        );
      }
    });

    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }

    return () => clearTimeout(timeoutID);
  };

  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    const intervalID = setTimeout(() => setIsLoading(false), 2000);

    dispatch(
      GettingStartedServices.getCustomerSetupStatus(
        customerID,
        setSetupQuestionList,
        setQueCount
      )
    );
    return () => clearTimeout(intervalID);
  }, [dispatch, customerID]);

  return (
    <Box
      sx={{
        padding: 2,
        marginRight: "100px",
        marginLeft: "100px",
        marginTop: "20px",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading &&
        !isSubmitted &&
        currentQuestions.length > 0 &&
        currentQuestions.map((q, index) => (
          <Box key={q.QUESTION_ID} sx={{ marginBottom: 2 }}>
            <Typography
              variant="h6"
              className={classes.formFieldLabel}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontSize: "1.0rem", fontWeight: "bold" }}>
                {startIndex + index + 1}
              </span>
              <ArrowForwardIcon
                style={{ fontSize: "1rem", margin: "0 8px 0 0px" }}
              />
              <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                {q.QUESTION_TEXT}
              </span>{" "}
            </Typography>
            {q.RESPONSE_TYPE_ID == 3 && (
              <TextField
                variant="outlined"
                multiline={q.type === "longText"}
                rows={q.type === "longText" ? 4 : 1}
                className={classes.inputField}
                value={
                  answers[q.QUESTION_ID] ||
                  q.CURRENT_ANSWER_SELECTED_RESPONSE ||
                  ""
                }
                placeholder={q.RESPONSE_OPTION}
                onChange={(e) =>
                  handleAnswerChange(q.QUESTION_ID, e.target.value)
                }
              />
            )}
            {q.RESPONSE_TYPE_ID == 1 && (
              <div style={{ width: "80%" }}>
                <Select
                  value={q.optionList?.find(
                    (option) =>
                      option.value.toString() ===
                      (
                        answers[q.QUESTION_ID] ||
                        q.CURRENT_ANSWER_SELECTED_RESPONSE
                      )?.toString()
                  )}
                  options={q.optionList}
                  onChange={(selectedOption) =>
                    handleAnswerChange(q.QUESTION_ID, selectedOption.value)
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none", // Remove all borders
                      borderBottom: "1px solid #ccc", // Add only a bottom border
                      borderRadius: 0,
                      boxShadow: "none",
                      "&:hover": {
                        borderBottom: "1px solid #000",
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                  }}
                />
              </div>
            )}

            {q.RESPONSE_TYPE_ID == 8 && (
              <div style={{ width: "80%" }}>
                <Select
                  value={q.optionList?.filter((option) => {
                    const selectedValues = (
                      answers[q.QUESTION_ID] ||
                      q.CURRENT_ANSWER_SELECTED_RESPONSE ||
                      ""
                    ).split(",");

                    return selectedValues.includes(option.value.toString());
                  })}
                  options={q.optionList}
                  onChange={(selectedOptions) => {
                    const selectedOptionStr = selectedOptions
                      .map((option) => option.value)
                      .join(",");

                    handleAnswerChange(q.QUESTION_ID, selectedOptionStr);
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      borderBottom: "1px solid #ccc",
                      borderRadius: 0,
                      boxShadow: "none",
                      "&:hover": {
                        borderBottom: "1px solid #000",
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                  }}
                  isMulti
                />
              </div>
            )}
          </Box>
        ))}

      {!isLoading && !isSubmitted && currentQuestions.length > 0 && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
            padding: "10px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentPage > 0 ? (
            <Grid style={{ marginRight: "10px" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                sx={{
                  marginRight: "10px",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "translateX(-5px)",
                  },
                }}
              >
                Back
              </Button>
            </Grid>
          ) : (
            <Box sx={{ width: "84px" }} />
          )}

          {currentPage < totalPages - 1 ? (
            <Grid style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                endIcon={
                  <ArrowForwardIcon
                    sx={{
                      transition: "transform 0.2s",
                      "&:hover": { transform: "translateX(5px)" },
                    }}
                  />
                }
                sx={{
                  "&.Mui-disabled": {
                    background: "#eaeaea",
                    color: "white",
                  },
                }}
              >
                Next
              </Button>
            </Grid>
          ) : (
            <Grid style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ marginRight: "10px", marginLeft: "10px" }}
              >
                Submit Answers
              </Button>
            </Grid>
          )}
        </Box>
      )}

      {isSubmitted && (
        <Summary setupQuestionList={setupQuestionList} answers={answers} />
      )}
    </Box>
  );
};

ClientSetup.propTypes = {
  customerID: PropTypes.string,
  setQueCount: PropTypes.func,
};

export default ClientSetup;
